import React, { Component } from "react"
import * as am5 from "@amcharts/amcharts5"
import * as am5map from "@amcharts/amcharts5/map"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import burkinaFasoLow from "@amcharts/amcharts5-geodata/burkinaFasoLow"
import kenyaLow from "@amcharts/amcharts5-geodata/kenyaLow"
import ghanaLow from "@amcharts/amcharts5-geodata/ghanaLow"
import nigeriaLow from "@amcharts/amcharts5-geodata/nigeriaLow"
import ethiopiaLow from "@amcharts/amcharts5-geodata/ethiopiaLow"
import maliLow from "@amcharts/amcharts5-geodata/maliLow"
import malawiLow from "@amcharts/amcharts5-geodata/malawiLow"
import mozambiqueLow from "@amcharts/amcharts5-geodata/mozambiqueLow"
import rwandaLow from "@amcharts/amcharts5-geodata/rwandaLow"
import tanzaniaLow from "@amcharts/amcharts5-geodata/tanzaniaLow"
import ugandaLow from "@amcharts/amcharts5-geodata/ugandaLow"
import { getIntelligenceSentimentScores } from "services"

const findColor = value => {
  let colour = ""
  if (value === 0) {
    colour = "#fef100"
  } else if (value <= 0.2 && value > 0) {
    colour = "#b2ffb7"
  } else if (value <= 0.4 && value > 0.2) {
    colour = "#66ff6c"
  } else if (value <= 0.6 && value > 0.4) {
    colour = "#44e84d"
  } else if (value <= 0.8 && value > 0.6) {
    colour = "#12d21d"
  } else if (value <= 1 && value > 0.8) {
    colour = "#03bd0a"
  } else if (value >= -0.2 && value < 0) {
    colour = "#ffcccc"
  } else if (value >= -0.4 && value < -0.2) {
    colour = "#ff4b4c"
  } else if (value >= -0.6 && value < -0.4) {
    colour = "#ff1918"
  } else if (value >= -0.8 && value < -0.6) {
    colour = "#e81817"
  } else if (value >= -1 && value < -0.8) {
    colour = "#d30000"
  }
  return colour
}

const mapsCollection = {
  BF: burkinaFasoLow,
  KE: kenyaLow,
  GH: ghanaLow,
  ET: ethiopiaLow,
  ML: maliLow,
  MW: malawiLow,
  MZ: mozambiqueLow,
  NG: nigeriaLow,
  RW: rwandaLow,
  TZ: tanzaniaLow,
  UG: ugandaLow,
}

const a = []

class CountryMap extends Component {
  renderMap() {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    const root = am5.Root.new("chartdiv")
    const colors = am5.ColorSet.new(root, {})

    const paramCountry = this.props.params.country

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)])

    // Create the map chart
    // https://www.amcharts.com/docs/v5/charts/map-chart/
    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        maxZoomLevel: 1,
        projection: am5map.geoMercator(),
      })
    )

    // Create polygon series for the country map
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
    const countrySeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        visible: false,
      })
    )

    countrySeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      fill: am5.color(0xaaaaaa),
    })

    // countrySeries.mapPolygons.template.states.create('hover', {
    //   fill: colors.getIndex(9),
    // });

    countrySeries.show()
    if (Object.keys(this.props.sentimentScores).length === 1) {
      if (!this.props.sentimentScores.Message) {
        countrySeries.setAll({
          geoJSON: mapsCollection[this.props.params.country],
          fill: am5.color(
            findColor(this.props.sentimentScores[0].sentiment_score)
          ),
        })
      }
    } else {
      const { country, ...newParams } = this.props.params
      getIntelligenceSentimentScores(newParams)
        .then(res => {
          const resObject = Object.values(res.data)
          let countryScore = null
          resObject.map(data => {
            if (data.country_label === this.props.params.country) {
              countryScore = data.sentiment_score
            }
          })
          countrySeries.setAll({
            geoJSON: mapsCollection[this.props.params.country],
            fill: am5.color(findColor(countryScore)),
          })
        })
        .catch(err => {
          console.log(err)
        })
    }

    // Set up data for countries
    // change to african countries only for data savings

    this.root = root
  }

  unRenderMap() {
    if (this.root) {
      this.root.dispose()
    }
  }

  componentDidMount() {
    this.renderMap()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sentimentScores != this.props.sentimentScores) {
      this.unRenderMap()
      this.renderMap()
    }
  }

  componentWillUnmount() {
    this.unRenderMap()
  }

  render() {
    return <div id="chartdiv" style={{ width: "100%", height: "500px" }} />
  }
}

export default CountryMap
