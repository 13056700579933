import React, { useState } from 'react';
import { Group } from '@visx/group';
import { hierarchy, Tree } from '@visx/hierarchy';
import { pointRadial } from 'd3-shape';
import useForceUpdate from '../../../components/Common/useForceUpdate';
import LinkControls from './LinkControls';
import getLinkComponent from './getLinkComponent';
import Kenya from './MapsSvg/Kenya';
import FileText from './SvgIcons/FileText';
import Folder from './SvgIcons/Folder';
import Suitcase from './SvgIcons/Suitcase';
import MinusCircle from './SvgIcons/MinusCircle';
import PlusCircle from './SvgIcons/PlusCircle';
import BF from './MapsSvg/BF';

const defaultMargin = { top: 30, left: 100, right: 170, bottom: 70 };

// export type LinkTypesProps = {
//   width: number;
//   height: number;
//   margin?: { top: number; right: number; bottom: number; left: number };
// };

export default function Example({
  width: totalWidth,
  height: totalHeight,
  margin = defaultMargin,
  data: data,
}) {
  const [layout, setLayout] = useState('polar');
  const [orientation, setOrientation] = useState('horizontal');
  const [linkType, setLinkType] = useState('diagonal');
  const [stepPercent, setStepPercent] = useState(0.1);
  const forceUpdate = useForceUpdate();
  const innerWidth = totalWidth - margin.left - margin.right;
  const innerHeight = totalHeight - margin.top - margin.bottom;

  let origin;
  let sizeWidth;
  let sizeHeight;

  if (layout === 'polar') {
    origin = {
      x: innerWidth / 2,
      y: innerHeight / 2,
    };
    sizeWidth = 2 * Math.PI;
    sizeHeight = Math.min(innerWidth, innerHeight) / 2;
  } else {
    origin = { x: 0, y: 0 };
    if (orientation === 'vertical') {
      sizeWidth = innerWidth;
      sizeHeight = innerHeight;
    } else {
      sizeWidth = innerHeight;
      sizeHeight = innerWidth;
    }
  }

  const LinkComponent = getLinkComponent({ layout, linkType, orientation });

  return totalWidth < 10 ? null : (
    
    <div id="mindmap">
      <LinkControls
        layout={layout}
        orientation={orientation}
        linkType={linkType}
        stepPercent={stepPercent}
        setLayout={setLayout}
        setOrientation={setOrientation}
        setLinkType={setLinkType}
        setStepPercent={setStepPercent}
      />
      {/*<LegendComponent/>*/}
      <svg width={totalWidth} height={totalHeight}>
        { /*<LinearGradient id="links-gradient" from="#fd9b93" to="#fe6e9e" />
        <rect width={totalWidth} height={totalHeight} rx={14} fill="#ffffff" />*/}
        <Group top={margin.top} left={margin.left}>
          <Tree
            root={hierarchy(data, (d) => (d.isExpanded ? null : d.children))}
            size={[sizeWidth, sizeHeight]}
            separation={(a, b) => (a.parent === b.parent ? 1 : 0.5) / a.depth}
          >
            {(tree) => (
              <Group top={origin.y} left={origin.x}>
                {tree.links().map((link, i) => {
                  return(
                    <LinkComponent
                    key={i}
                    data={link}
                    percent={stepPercent}
                    stroke = {link.source.depth === 0 ? "#0E9046FF": link.source.depth === 1 ? "#8353E2FF" : "#7CCFA0FF"} 
                    strokeWidth="1.5"
                    strokeDasharray='2,2'
                    strokeOpacity="1"
                    fill="none"
                  />
                  )
                }
                )}
                {tree.descendants().map((node, key) => {
                  let w = Math.max(120, (node.data.name?.length*7))
                  const width = w;
                  const height = node.data.children? 65: 40;

                  let top;
                  let left;
                  if (layout === 'polar') {
                    const [radialX, radialY] = pointRadial(node.x, node.y);
                    top = radialY;
                    left = radialX;
                  } else if (orientation === 'vertical') {
                    top = node.y;
                    left = node.x;
                  } else {
                    top = node.x;
                    left = node.y;
                  }

                  const clickBase = () => {
                    node.data.isExpanded = !node.data.isExpanded;
                    forceUpdate();
                  }
                  const hasChildren=Boolean(node.data.children);
                  return (
                    <Group top={top} left={left} key={key}>
                      {node.depth === 0 && (
                        <circle
                          r={30}
                          fill="#67c785"
                          onClick={clickBase}
                        />
                        // <BF clickBase={clickBase}/>
                        //<Kenya clickBase={clickBase}/>
                      )}
                      {node.depth !==0  && (
                        <rect
                          height={height}
                          width={width}
                          y={-height / 2}
                          x={-width / 2}
                          fill={node.depth===1?"#947FFF": node.depth === 2? "#C6FAD6FF": "#FFFFD3FF"}
                          stroke='#cccccc'
                          strokeWidth={1}
                          //strokeDasharray={node.data.children ? '0' : '5,4'}
                          // strokeOpacity={node.data.children ? 1 : 0.4}
                          strokeOpacity = "0.4"
                          rx={10}
                          onClick={clickBase}
                        />
                      )}
                      {node.depth === 1 && <Suitcase hasChildren={hasChildren}/>}
                      {node.depth === 2 && <Folder hasChildren={hasChildren}/>}
                      {node.depth === 3 && <FileText hasChildren={hasChildren}/>}
                      {node.depth !== 0 && node.depth !== 3? node.data.children? node.children? <MinusCircle depth={node.depth}/> : <PlusCircle depth={node.depth}/> : null : null}
                      <text
                        dy={node.data.children? "-1.25em":"-0.5em"}
                        dx="0.4em"
                        fontSize={11}
                        fontFamily="Arial"
                        textAnchor="middle"
                        style={{ pointerEvents: 'none' }}
                        fill={ node.depth ===1? "#ffffff" : "#9095A0FF"}
                      >
                      {node.depth === 1? "Category": node.depth === 2? "Sub-category": node.depth === 3? "Policy": null}
                      </text>
                      <text
                        dy={node.data.children? ".33em" : ".7em"}
                        fontSize={13}
                        fontWeight="900"
                        fontFamily="Arial"
                        textAnchor="middle"
                        style={{ pointerEvents: 'none' }}
                        fill={  node.depth ===1? "#ffffff" : "#000000"}
                      >
                      {node.data.name}
                      </text>
                      <text
                        dy="2em"
                        dx="-0.4em"
                        fontSize={11}
                        fontFamily="Arial"
                        textAnchor="middle"
                        style={{ pointerEvents: 'none' }}
                        fill={  node.depth ===1? "#ffffff" : "#000000"
                          // node.depth === 1 && node.data.children && node.children? 
                          //       "#ffffff": 
                          //       node.depth === 1 && !node.data.children? 
                          //         "#ffffff": 
                          //         !node.data.children? 
                          //           "#000000": 
                          //           node.children? 
                          //             "#000000": "blue"
                                    }
                      >
                      {node.data.children && node.depth !== 0? `${node.data.children.length} `: null}
                      {node.data.children? node.depth === 1? "sub-categories": node.depth === 2? "Policies": null : null}
                      </text>
                    </Group>
                  );
                })}
              </Group>
            )}
          </Tree>
        </Group>
      </svg>
    </div>
  );
}
