import React, { useEffect, useState } from "react";
// import {Alert} from "reactstrap";
import PolicyTableContainer from "components/Common/PolicyTableContainer";

function PolicyDirectory(props) {

  // const [visible, setVisible] = useState(false)
  // const onDismiss = () => setVisible(false)
  // const [alertStatus, setAlertStatus] = useState("")
  // const [alertColor, setAlertColor] = useState("")
  // const [alertIcon, setAlertIcon] = useState("")
  // const [alertIconColor, setAlertIconColor] = useState("")
  // const [errorContext, setErrorContext] = useState("")
  // const [alertContent, setAlertContent] = useState("")
  // const [alertObject, setAlertObject] = useState("")

  // const alertOnUpdate = (object, content, err) => {
  //   setVisible(true)
  //   setAlertContent(content)
  //   setAlertObject(object)
  //   if (err) {
  //     if (err.toString() === "Error: Network Error") {
  //       setErrorContext(err.toString())
  //     } else {
  //       setErrorContext(`${err}`)
  //     }
  //     setAlertStatus("failed")
  //     setAlertColor("danger")
  //     setAlertIcon("mdi mdi-close-circle-outline")
  //     setAlertIconColor("red")
  //   } else {
  //     setAlertStatus("successful")
  //     setAlertColor("success")
  //     setAlertIcon("mdi mdi-checkbox-marked-circle-outline")
  //     setAlertIconColor("green")
  //     setErrorContext("")
  //   }
  // }
  //try making seperate alert components- failed and success - might reduce code
  
  return (
    <div className="page-body" style={{boxShadow: "rgb(23 26 31 / 0%) 0px 0px 1px, rgb(23 26 31 / 42%) 0px 0px 2px"}}>
      {/*<Alert color={alertColor} isOpen={visible} toggle={onDismiss}>
        <i className={alertIcon} style={{ color: { alertIconColor } }}></i>
        {alertObject} {alertContent} {alertStatus}! {errorContext}
      </Alert>*/}
      <div className="page-header">
        <i className="bx bx-file-find"/>
          Showing All Policies
      </div>
      <PolicyTableContainer component="directory" alert={props.alert}/>
    </div>
  )
}

export default PolicyDirectory;
