import * as moment from "moment"

export const formateDate = (date, format) => {
  const dateFormat = format ? format : "Y-MM-DD H:m:s"
  //const date1 = moment(date).format(dateFormat) // For local timezone
  const date1 = moment.utc(date).format(dateFormat) // UTC Timezone format
  return date1
}

export const formateOnlyDate = (date, format) => {
  const dateFormat = format ? format : "Y-MM-DD"
  //const date1 = moment(date).format(dateFormat) // For local timezone
  const date1 = moment.utc(date).format(dateFormat) // UTC Timezone format
  return date1
}

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const formatter = new Intl.RelativeTimeFormat("en-us", {
  numeric: "always",
})

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
]
export function formatTimeAgo(date) {
  let duration = (new Date(date) - new Date()) / 1000

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

export function formatDateUTC(dt) {
  const date = new Date(dt)
  const year = date.getUTCFullYear()
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0")
  const day = date.getUTCDate().toString().padStart(2, "0")
  const hours = date.getUTCHours().toString().padStart(2, "0")
  const minutes = date.getUTCMinutes().toString().padStart(2, "0")
  const seconds = date.getUTCSeconds().toString().padStart(2, "0")

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`
}
