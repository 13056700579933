import React, { useState } from "react"
import PropTypes from "prop-types"
import { map, get } from "lodash"
import { Card, CardBody, Col, Row, CardImg, Table } from "reactstrap"
import img1 from "../../../assets/images/companies/img-1.png"
import { formateDate } from "helpers/utlities"

// import countriesFlag from "common/countriesFlag"
import { PROFILE_PHOTO } from "services/CONSTANTS"

const ViewDetail = ({ viewData }) => {
  //   const [userPhoto, setUserPhoto] = useState(
  //     PROFILE_PHOTO + "no-profile-photo.jpg"
  //   )

  //   const reloadSrc = e => {
  //     e.target.src = PROFILE_PHOTO + "no-profile-photo.jpg"
  //     setUserPhoto(PROFILE_PHOTO + "no-profile-photo.jpg")
  //   }

  return (
    <Card>
      <CardBody className="bg-user-background">
        <Row>
          <div className="d-flex font-white p-4">
            <Col sm="12">
              <Row>
                <Col sm="3">
                  {!viewData.user.photo ? (
                    <div className="avatar-lg mx-auto mb-4">
                      <h3
                        className={
                          "avatar-title rounded-circle bg-info text-white"
                        }
                      >
                        {viewData.user.first_name.charAt(0)}
                      </h3>
                    </div>
                  ) : (
                      <CardImg
                      style={{ aspectRatio: 1 }}
                      className="rounded-circle"
                      src={
                        PROFILE_PHOTO +
                        `${viewData.user.photo}`
                      }
                      alt="Profile"
                    />
                  )}
                </Col>

                <Col sm={{ size: 7, offset: 1 }}>
                  <Row>
                    <Col lg={12}>
                      <div className="flex-grow-1 overflow-hidden mb-3">
                        <h4 className="font-white">
                          <i className="bx bx-user"></i>{" "}
                          {viewData.user.first_name +
                            " " +
                            viewData.user.last_name}
                        </h4>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mt-4">
                        <h5 className="font-size-14 font-white">
                          <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                          Email
                        </h5>
                        <p className="mb-0">{viewData.email}</p>
                      </div>
                      <div className="mt-4">
                        <h5 className="font-size-14 font-white">
                          <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                          Organization
                        </h5>
                        <p className="mb-0">
                          {viewData?.user?.organization?.name}
                        </p>
                      </div>
                      <div className="mt-4">
                        <h5 className="font-size-14 font-white">
                          <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                          Designation
                        </h5>
                        <p className="mb-0">
                          {viewData.user.designation || "NA"}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mt-4">
                        <h5 className="font-size-14 font-white">
                          <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                          Locations
                        </h5>
                        {viewData.countries &&
                          map(viewData.countries, (country, index) => (
                            <p key={index}>
                              <i className="mdi mdi-chevron-right text-info me-1" />{" "}
                              {country.label}
                            </p>
                          ))}
                      </div>
                    </Col>
                  </Row>
                </Col>

              </Row>
            </Col>
          </div>

          {/* <div className="text-muted mt-4">
            <div>
              {viewData.countries &&
                map(viewData.countries, (country, index) => (
                  <React.Fragment key={index}>
                    <span className="mdi mdi-chevron-right text-primary me-1" />{" "}
                    <img
                      src={get(countriesFlag, `${country.title}.flag`)}
                      alt="AGRA"
                      height="16"
                    />
                    <span className="badge bg-info font-size-11 m-1 me-3">
                      {country.label}
                    </span>
                  </React.Fragment>
                ))}
            </div>
          </div> */}
        </Row>
      </CardBody>
    </Card>
  )
}

ViewDetail.propTypes = {
  project: PropTypes.object,
}

export default ViewDetail
