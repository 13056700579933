import { EnumStage } from "constants/global"
import React from "react"
import PolicyLink from "./PolicyTable/PolicyLink"

function PolicyActivityTable({ policies, stage, activities, selectedMilestones }) {
  
  selectedMilestones?.sort(function (a, b) {
    return a.value - b.value
  })

  const mapPolicies = () => {
    return policies?.map((policy, i) => {
      let forms = policy.policy_form_map.filter(
        form => form?.form?.stage_id == (stage ? stage.id : EnumStage.BENCHMARKING)
      )
      forms = forms.sort(function (a, b) {
        return a.form_id - b.form_id
      })
      //can create a backend function to get only the forms in the given stage for all policies to reduce time complexity

      return (
        <tr key={i}>
          <td>
            <PolicyLink policy={policy} />
          </td>
          <td>{policy.country?.label}</td>
          {forms.map((form, i) => {
            return <td key={i}>{form.form_status}</td>
          })}
          <td>{policy.policy_score}</td>
        </tr>
      )
    })
  }

  return (
    <div id="report1">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead className="table-header ">
            <tr>
              <th>Policy Name</th>
              <th>Location</th>
              {selectedMilestones.length ? selectedMilestones.map((milestone,i)=> <th key={i}>{milestone.label}</th>) :  activities?.map((activity, i) => <th key={i}>{activity.name}</th>)}
              <th>Policy Score</th>
            </tr>
          </thead>
          <tbody>{mapPolicies()}</tbody>
        </table>
      </div>
    </div>
  )
}

export default PolicyActivityTable
