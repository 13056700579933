import React from "react"
import Trends from "./Trends"

const Dashboard = () => {
  return (
    <div className="page-content">
      <Trends/>
    </div>
  )
}

export default Dashboard
