import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPwd from "../pages/Authentication/ResetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Access Code
import AccessCodeList from "../pages/Access-Codes/AccessCodeList/access-codes-list"
import AccessCodeDetail from "../pages/Access-Codes/AccessCodeDetail/access-code-detail"
import UserList from "../pages/UserManagement/UserList/user-list"
import UserDetail from "../pages/UserManagement/UserDetail/user-detail"

import { Overview } from "pages/E-Tracker/overview"
import { Index } from "pages/FormManagement"
import { Policy } from "pages/Policy/policy"

import Labels from "pages/Labels/Labels"
import Audit from "pages/Audit/Audit"
import PolicyDirectory from "pages/PolicyDirectory/PolicyDirectory"

import StatisticsTracker from "pages/Statistics/index"
import StatisticsPolicyLandscaping from "pages/Statistics/index"

import NewsInsights from "pages/Qualitative/index"

import DataIntelligence from "pages/DataIntelligence"

import Trends from "pages/Trends"

import PolicyLandscaping from "pages/PolicyLandscaping/PolicyLandscaping"
import tasks from "pages/TaskManagement/Policy-Tasks"

import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import FillForm from "pages/E-Tracker/fill-form"
import FillFormBenchmarking from "pages/Benchmarking/FillForm"
import OrganizationManagement from "pages/Organization-Management/Organization-Management"
import SystemsManagement from "pages/Labels/Systems-Management"

import EditMilestone from "pages/FormManagement/EditMilestone/Edit-Milestone"
import CreateMilestone from "pages/FormManagement/CreateMilestone/Create-Milestone"

import Benchmarking from "pages/Benchmarking"
import TasksManagement from "pages/TaskManagement/Task-Management"
import FillFormLandscaping from "pages/FormManagement/FillFormLandscaping"
import AccountSuspended from "pages/Utility/account-suspended"

const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    title: "My Tasks",
    icon: "bx-home-circle",
  },
  {
    path: "/data-intelligence",
    component: DataIntelligence,
    title: "Data Intelligence",
    icon: "bx-line-chart",
  },

  // //profile
  {
    path: "/profile",
    component: UserProfile,
    title: "Profile Information",
    icon: "bx-user",
  },

  // Access Code
  {
    path: "/access-code-list",
    component: AccessCodeList,
    title: "Access Codes",
    icon: "bx-lock-open",
  },
  {
    path: "/access-code-detail/:id",
    component: AccessCodeDetail,
    title: "Access Code Information",
    icon: "bx-lock-open",
  },

  //Organization
  {
    path: "/organization_management",
    component: OrganizationManagement,
    title: "Organization Management",
    icon: "ph-buildings",
  },
  // User
  {
    path: "/user-management",
    component: UserList,
    title: "Users",
    icon: "bx-user",
  },
  {
    path: "/user-management/:id",
    component: UserDetail,
    title: "User Information",
    icon: "bx-user",
  },

  //E-Tracker
  { path: "/overview", component: Overview, title: "Overview" },

  //Policy
  {
    path: "/policy/:id",
    component: Policy,
    title: "Policy Lifecycle Management",
  },

  // Form Management
  {
    path: "/forms",
    component: Index,
    title: "Milestone Management",
    icon: "bx-edit",
  },
  {
    path: "/create-form",
    component: CreateMilestone,
    title: "Create Milestone",
    icon: "bx-edit",
  },
  {
    path: "/edit-form",
    component: EditMilestone,
    title: "Edit Milestone",
    icon: "bx-edit",
  },
  {
    path: "/forms/:policyId/:stageId/:formId",
    component: FillForm,
    title: "Fill Milestone Data",
  },
  {
    path: "/system-forms/:countryId/:labelId/:formId",
    component: FillFormBenchmarking,
    title: "Fill System Benchmarking Form Data",
  },
  {
    path: "/landscaping-forms/:subCategoryId/:formId",
    component: FillFormLandscaping,
    title: "Fill Landscaping Form Data",
  },

  //Indicators
  {
    path: "/labels",
    component: SystemsManagement,
    title: "Systems Management",
    icon: "bx-bookmark",
  },

  //Policy Directory
  {
    path: "/policy-directory",
    component: PolicyDirectory,
    title: "Policy Directory",
    icon: "bx-search",
  },

  //Policy Landscape
  {
    path: "/policy-landscape",
    component: PolicyLandscaping,
    title: "Policy Landscaping",
    icon: "bx-file",
  },

  //Tasks Management
  {
    path: "/tasks",
    component: TasksManagement,
    title: "Tasks Management",
    icon: "ph-check-square-offset",
  },

  //Audit
  {
    path: "/audit",
    component: Audit,
    title: "Audit Logs",
    icon: "ph-clock-counter-clockwise",
  },

  //benchmarking
  {
    path: "/benchmarking",
    component: Benchmarking,
    title: "Benchmarking",
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/statistics/e-tracker" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login, title: "Login | Ycenter" },
  {
    path: "/forgot-password",
    component: ForgetPwd,
    title: "Forgot Password | Ycenter",
  },
  {
    path: "/forgot-password/:token",
    component: ResetPwd,
    title: "Reset Password | Ycenter",
  },
  {
    path: "/register/:token?",
    component: Register,
    title: "Register | Ycenter",
  },
  { path: "/pages-404", component: Pages404, title: "404 | Not Found" },
  { path: "/pages-500", component: Pages500, title: "500 | Server Error" },
  {
    path: "/account-suspended",
    component: AccountSuspended,
    title: "Account Suspended",
  },
]

const reportRoutes = [
  {
    path: "/statistics/e-tracker",
    component: StatisticsTracker,
    title: "Statistics | E-Tracker",
    icon: "ph-chart-line",
  },
  {
    path: "/statistics/policy-landscaping",
    component: StatisticsPolicyLandscaping,
    title: "Policy Landscaping",
    icon: "bx-search",
  },
  {
    path: "/qualitative/news-insights",
    component: NewsInsights,
    title: "Qualitative | News Insights",
    icon: "ph-chart-line",
  },
  {
    path: "/trends",
    component: Trends,
    title: "Qualitative | Trends",
    icon: "ph-chart-line",
  },
]

export { publicRoutes, authProtectedRoutes, reportRoutes }
