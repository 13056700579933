import React, { useState, useEffect } from "react"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import { connect } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap"
import { Link } from "react-router-dom"
import "./index.scss"
import { post, get } from "services/AXIOS"
import DeleteModal from "components/Common/DeleteModal"
import FormsTable from "./FormsTable"
import Filter from "./Filter"
import Sort from "./Sort"
import { FILTERUSERS, GET_FILTERED_FORMS } from "services/CONSTANTS"
import { useSelector } from "react-redux"
import { getMilestones } from "services/milestoneServices"
import LoadingSpinner from "components/Common/Loading-Spinner"
import Select from "react-select"
import { StyledContainer } from "pages/Labels/Systems"
import useDebounce from "hooks/useDebounce"
import ExportTable from "components/Common/ExportTable"
import { getCSV, getPDF } from "./export"

export const Index = props => {
  const { user } = useSelector(state => state.Profile)

  //load Milestones func
  const loadMilestones = async (query, body) => {
    try {
      const { data } = await getMilestones(query, body)
      setTotalEntries(data?.formsCount)
      return data
    } catch (err) {
      console.error(err)
      return err
    }
  }
  //search
  const [searchField, setSearchField] = useState("")
  const debouncedSearchTerm = useDebounce(searchField)

  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase())
    setOffset(0)
    setCurrentPage(1)
    setTotalEntries(milestonesData?.formsCount)
  }

  //filter
  const [selectedStages, setSelectedStages] = useState([])
  const [selectedOwners, setSelectedOwners] = useState([])
  const [owners, setOwners] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const [modalFilter, setModalFilter] = useState(false)
  const toggleFilter = () => setModalFilter(prevState => !prevState)
  const applyFilters = (stages, status, owners) => {
    setSelectedOwners(owners)
    setSelectedStages(stages)
    setSelectedStatus(status)
  }

  //sort
  const [sortForm, setSortForm] = useState("createdAtDESC")
  const sortOptions = [
    {
      value: "createdAtDESC",
      label: "Newest",
    },
    {
      value: "createdAtASC",
      label: "Oldest",
    },
    {
      value: "nameASC",
      label: "A-Z",
    },
    {
      value: "nameDESC",
      label: "Z-A",
    },
  ]
  const sortMap = new Map([
    ["createdAtDESC", { created_at: "DESC" }],
    ["createdAtASC", { created_at: "ASC" }],
    ["nameDESC", { name: "DESC" }],
    ["nameASC", { name: "ASC" }],
  ])

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const [offset, setOffset] = useState(0)

  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50
      setOffset(offsetParam)
      setCurrentPage(page)
    } else {
      console.error("page number should be greater than 0")
    }
  }

  //clear all filters
  const checkForFilters = () => {
    return (
      selectedStages.length > 0 ||
      selectedStatus.length > 0 ||
      selectedOwners.length > 0
    )
  }

  const clearFilters = () => {
    setSelectedOwners([])
    setSelectedStatus([])
    setSelectedStages([])
  }

  const { data: milestonesData, isLoading, refetch } = useQuery({
    queryKey: [
      "Milestones",
      {
        selectedStatus,
        selectedOwners,
        selectedStages,
        debouncedSearchTerm,
        currentPage,
        sortForm,
      },
    ],
    queryFn: () =>
      loadMilestones(
        { limit: 50, offset },
        {
          status: selectedStatus,
          owners: selectedOwners,
          stages: selectedStages,
          searchfield: debouncedSearchTerm,
          sortForm: sortMap.get(sortForm),
        }
      ),
    staleTime: 60 * 1_000,
  })

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    form: {},
  })

  const [forms, setForms] = useState([])

  // const getAllForms = (searchfield = searchField, offsetParam = offset) => {
  //   const limit = 50

  //   const stages = selectedStages.map(stage => stage.value)
  //   const status = selectedStatus.map(status => status.label)
  //   const owners = selectedOwners.length
  //     ? selectedOwners.map(owner => owner.value)
  //     : []

  //   post(`${GET_FILTERED_FORMS}?limit=${limit}&offset=${offsetParam}`, {
  //     // stage_id: 0,
  //     searchfield,
  //     sortForm,
  //     stages,
  //     status,
  //     owners,
  //   })
  //     .then(response => {
  //       if (response) {
  //         setForms(response.data["forms"])
  //         setTotalEntries(response.data["formsCount"])
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       props.alert("Data loading", false)
  //     })
  // }

  useEffect(() => {
    // getAllForms()
    get(FILTERUSERS + "milestones")
      .then(data => {
        setOwners(data)
      })
      .catch(err => {
        props.alert("Filters Data loading", false)
        console.error(err)
      })
  }, [])

  const handleDeleteMilestone = () => {
    post(`/form/remove/${deleteModal?.form?.id}`)
      .then(data => {
        if (data?.statusCode == 200) {
          // getAllForms()
          props.alert(`Milestone Deletion`, true)
        }
      })
      .catch(err => {
        console.error(err)
        props.alert("Milestone deletion", false)
      })
    setDeleteModal({ show: false })
    refetch()
  }

  const exportTable = async (type) => {
    try{
      const {data} = await getMilestones(
        { limit: 0, offset: 0},
        {
          status: selectedStatus,
          owners: selectedOwners,
          stages: selectedStages,
          searchfield: debouncedSearchTerm,
          sortForm: sortMap.get(sortForm),
        })
      type === "pdf" ? getPDF(data.forms) : getCSV(data.forms)
    } catch(err){
      console.log(err);
      alert("Data export", false)
    }
  }

  return (
    <div className="page-content">
      <Card>
        <CardHeader className="bg-white mt-3">
          <h4>
            <i className="bx bx-file"></i> All Milestones
          </h4>
          <div className="d-flex justify-content-between">
            <StyledContainer>
              <SearchBox searchChange={searchChange} />
              <Button
                style={{
                  backgroundColor: "#f8f9fa",
                  color: "black",
                  margin: "15px 10px",
                  border: "none",
                  fontSize: "14px",
                }}
                onClick={toggleFilter}
              >
                <i className="mdi mdi-filter-variant me-1"></i>
                Filter
              </Button>
              <Modal
                isOpen={modalFilter}
                toggle={toggleFilter}
                className="modal-lg"
              >
                <ModalHeader toggle={toggleFilter}>
                  <i className="mdi mdi-filter-variant me-1"></i>
                  Filters
                </ModalHeader>
                <ModalBody>
                  <Filter
                    selectedStagesFilters={selectedStages}
                    selectedStatusFilters={selectedStatus}
                    selectedOwnersFilters={selectedOwners}
                    owners={owners}
                    applyFilters={applyFilters}
                    toggleFilterModal={toggleFilter}
                  />
                </ModalBody>
              </Modal>
              <Select
                options={sortOptions}
                defaultValue={sortForm}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Sort by"
                onChange={option => setSortForm(option?.value || "ASC")}
              />
              {checkForFilters() && (
                <Button
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: "#f8f9fa",
                    color: "black",
                    margin: "15px 10px",
                    border: "none",
                    fontSize: "14px",
                  }}
                  onClick={clearFilters}
                >
                  <i className="ph-x me-1"></i>
                  Clear All Filters
                </Button>
              )}
            </StyledContainer>
            <div className="d-flex">
              <ExportTable loadFilteredData={exportTable} component={"SeperateFn"}></ExportTable>
              {user.role === "admin" && (
                <Link
                  to={{
                    pathname: "/create-form",
                  }}
                >
                  <Button className="common-button">+ Create New</Button>
                </Link>
              )}
            </div>
          </div>
        </CardHeader>
        <DeleteModal
          show={deleteModal.show}
          onDeleteClick={handleDeleteMilestone}
          onCloseClick={() => setDeleteModal({ show: false })}
        />
        {isLoading && <LoadingSpinner />}
        <CardBody>
          {milestonesData?.forms?.length > 0 ? (
            <>
              <FormsTable
                forms={milestonesData.forms}
                setDeleteModal={setDeleteModal}
                userRole={user.role}
              />
              <Pagination
                currentPage={currentPage}
                goToPage={goToPage}
                totalEntries={totalEntries}
              />
            </>
          ) : null}
        </CardBody>
      </Card>

      {/* <Modal
        isOpen={deleteModal.show}
        toggle={() =>
          setDeleteModal({ ...deleteModal, show: !deleteModal.show })
        }
        centered
      >
        <ModalBody>
          <p>Do you want to delete {deleteModal?.form?.name}?</p>
          <div className=" d-flex justify-content-end">
            <Button
              onClick={() =>
                post(`/form/remove/${deleteModal?.form?.id}`).then(data => {
                  if (data?.statusCode == 200) {
                    getAllForms()
                    setDeleteModal({ show: false })
                  }
                })
              }
              color="danger"
              className="mx-2"
            >
              Delete
            </Button>
            <Button onClick={() => setDeleteModal({ show: false })}>
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal> */}
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
