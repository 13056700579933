import React from "react"
import { formateOnlyDate } from "helpers/utlities"
import Organizations from "./Organizations"
import OrganizationTypeManagement from "./Organization-Types"
import { Table } from "reactstrap"
import styled from "@emotion/styled"
import CustomTabs from "components/Common/Custom-Tabs"

const StyledButtonGroup = styled.div`
  display: flex;
  gap: 1em;
  & button {
    all: unset;
  }
`
export const CustomTable = ({ data, initiateEdit, caption, user }) => {
  const isOrganizationData = data[0].hasOwnProperty("organization_type")
  return (
    <Table className="custom-table table-striped">
      <colgroup>
        {!isOrganizationData && <col style={{ width: "25%" }} />}
        {!isOrganizationData && <col style={{ width: "25%" }} />}
        {!isOrganizationData && <col style={{ width: "25%" }} />}
        {!isOrganizationData && <col style={{ width: "25%" }} />}

        {isOrganizationData && <col style={{ width: "15%" }} />}
        {isOrganizationData && <col style={{ width: "20%" }} />}
        {isOrganizationData && <col style={{ width: "25%" }} />}
        {isOrganizationData && <col style={{ width: "15%" }} />}
        {isOrganizationData && <col style={{ width: "15%" }} />}
        {isOrganizationData && <col style={{ width: "10%" }} />}
      </colgroup>
      <thead>
        <tr>
          <th>Name</th>
          {isOrganizationData && <th>Organization Type</th>}
          {isOrganizationData && <th>Countries</th>}
          <th>Updated On</th>
          <th>Updated By</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map(org => (
          <tr key={org.id}>
            <td>{org.name}</td>
            {isOrganizationData && <td>{org?.organization_type?.name}</td>}
            {isOrganizationData && (
              <td>
                <ol
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    listStyle: "none",
                    gap: ".5em",
                    padding: 0,
                  }}
                >
                  {org.countries.map(country => (
                    <li
                      key={country.id}
                      style={{
                        backgroundColor: "rgb(80,165,241)",
                        color: "white",
                        padding: ".2em .4em",
                        borderRadius: ".8em",
                      }}
                    >
                      {country.label}
                    </li>
                  ))}
                </ol>
              </td>
            )}
            <td>{formateOnlyDate(org.updated_at)}</td>
            <td>{`${org.updated_by?.first_name} ${org.updated_by?.last_name}`}</td>
            <td>
              {!isOrganizationData && org?.is_locked ? (
                <i className="ph ph-lock"></i>
              ) : (
                <StyledButtonGroup>
                  <button onClick={() => initiateEdit(org.id)}>
                    <i className="mdi mdi-pencil font-size-18 hover-event text-success" />
                  </button>
                  {/* {user.role == "admin" && (
                    <button>
                      <i className="mdi mdi-delete font-size-18 del-icon text-danger" />
                    </button>
                  )} */}
                </StyledButtonGroup>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default function OrganizationManagement(props) {
  const triggers = [
    { name: "Organizations", value: "tab1" },
    { name: "Organization Types", value: "tab2" },
  ]
  const content = [
    { value: "tab1", component: <Organizations alert={props.alert} /> },
    {
      value: "tab2",
      component: <OrganizationTypeManagement alert={props.alert} />,
    },
  ]
  return (
    <div className="page-content">
      <CustomTabs
        ariaLabel="Organizations Management"
        defaultValue="tab1"
        triggers={triggers}
        content={content}
      />
    </div>
  )
}
