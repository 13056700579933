import React, { useState, useRef } from "react"
import styled from "@emotion/styled"
import { motion, useScroll, useMotionValueEvent } from "framer-motion"

const StyledFiltersContainer = styled.div`
  --_background-color: ${props => (props.isSticky ? "#7F8387" : "#fff")};
  --_color: ${props => (props.isSticky ? "#fff" : "#7F8387")};
  display: flex;
  justify-content: center;
  margin-block-end: 2rem;
  position: sticky;
  top: 70px;
  background-color: var(--_background-color);
  color: var(--_color);
  transition: all 200ms;
  padding: 1em 2em;
  z-index: 7;
`
function RadioFilters({ setFilter, filter }) {
  const containerRef = useRef(null)
  const { scrollY } = useScroll()
  const [isSticky, setIsSticky] = useState(false)

  useMotionValueEvent(scrollY, "change", latest => {
    containerRef.current.getBoundingClientRect().top === 70
      ? setIsSticky(true)
      : setIsSticky(false)
  })
  return (
    <StyledFiltersContainer isSticky={isSticky} ref={containerRef}>
      <div
        className="form-check form-check-inline"
        onClick={() => setFilter("number_of_policies")}
      >
        <input
          className="form-check-input"
          type="radio"
          id="number_of_policies"
          checked={filter === "number_of_policies"}
          onChange={e => {}}
        />
        <label className="form-check-label" htmlFor="number_of_policies">
          No. of Policies
        </label>
      </div>
      <div
        className="form-check form-check-inline"
        onClick={() => setFilter("policy_score")}
      >
        <input
          className="form-check-input"
          type="radio"
          id="policy_score"
          checked={filter === "policy_score"}
          onChange={e => {}}
        />
        <label className="form-check-label" htmlFor="policy_score">
          Policy Score
        </label>
      </div>
      <div
        className="form-check form-check-inline"
        onClick={() => setFilter("policy_win")}
      >
        <input
          className="form-check-input"
          type="radio"
          id="policy_wins"
          checked={filter === "policy_win"}
          onChange={e => {}}
        />
        <label className="form-check-label" htmlFor="policy_wins">
          Policy Wins
        </label>
      </div>
      <div
        className="form-check form-check-inline"
        onClick={() => setFilter("days_past_deadline")}
      >
        <input
          className="form-check-input"
          type="radio"
          id="days_past_deadline"
          checked={filter === "days_past_deadline"}
          onChange={e => {}}
        />
        <label className="form-check-label" htmlFor="days_past_deadline">
          Past Deadline
        </label>
      </div>
    </StyledFiltersContainer>
  )
}
export default RadioFilters
