import React, { Fragment, useState } from "react"
import { formateDate, formatTimeAgo, formatDateUTC } from "helpers/utlities"
import { Table } from "reactstrap"
import { AUDITROLES } from "constants/global"
import styled from "@emotion/styled"
import Dialog from "components/Common/Dialog"
import ChangesDetails from "./Audit-Details"

const StyledTable = styled(Table)`
  th {
    /* position: sticky;
    top: 12.5%;
    z-index: 2; */
    padding-block: 1.5em;
    background-color: #ddd;
  }
`
const StyledTableRow = styled.tr`
  text-transform: capitalize;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background-color: #d3eed6 !important;
  }
  & > .actor_cell {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 5%;
      left: 0;
      width: 5px;
      height: 95%;
      box-shadow: ${props =>
        props.isBackendJob
          ? `inset -10px 0 0 0 #70036B`
          : `inset -10px 0 0 0 #FF84DD`};
    }
    & .actor_name {
      margin-block-end: 0.25rem;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  & .event_cell > p {
    background-color: ${props => props.eventColor};
    /* color: white; */
    padding: 0.4em 0.6em;
    width: max-content;
    border-radius: 1000vmax;
  }

  & .description_cell {
    & > div {
      display: flex;
      gap: 1em;
      align-items: baseline;

      & p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & button {
      all: unset;
      cursor: pointer;
    }
  }
`

const AuditLogRow = ({ log }) => {
  const ACTION_COLORS = new Map([
    ["created", "#E7FFD0"],
    ["updated", "#EFF8FF"],
    ["deleted", "#FEF0F1 "],
  ])
  const hasNoOldAndNewValues =
    Object.keys(log.audit_new_value).length === 0 &&
    Object.keys(log.audit_old_value).length === 0
  const timestamp = formatDateUTC(log.audit_created_at)
  const [open, setOpen] = useState(false)
  return (
    <Dialog open={open} setOpen={setOpen}>
      <StyledTableRow
        isBackendJob={log.audit_role === "cron_bot"}
        eventColor={ACTION_COLORS.get(log.audit_event) ?? "#F9D8C0"}
        onClick={() => setOpen(true)}
      >
        <td className="actor_cell">
          {!log.users_last_name && !log.users_last_name ? (
            <>
              <p style={{ marginBlockEnd: ".25rem" }} className="actor_name">
                System
              </p>
              <span style={{ display: "block" }}>
                {AUDITROLES[log.audit_role]}
              </span>
            </>
          ) : (
            <>
              <p
                style={{
                  marginBlockEnd: ".25rem",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
                className="actor_name"
              >
                {`${log.users_first_name} ${log.users_last_name}`}
              </p>
              <ul
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  listStyle: "none",
                  paddingInlineStart: 0,
                  gap: ".5em",
                  opacity: ".8",
                }}
              >
                <li>{AUDITROLES[log.audit_role]}</li>
                <li style={{ textTransform: "none" }}>{log.users_email}</li>
              </ul>
            </>
          )}
        </td>
        <td>{log.audit_type ?? "NA"}</td>
        <td className="event_cell">
          <p>{log.audit_event ?? "default"}</p>
        </td>
        <td className="description_cell">
          <div>
            <p>{log.audit_description}</p>
          </div>
        </td>
        <td>
          <div className="d-flex flex-column gap-2">
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {log.audit_ip_address}
            </span>
            <span>{log.audit_ip_location ?? "No Location Data"}</span>
          </div>
        </td>
        <td>
          <time dateTime={log.audit_created_at}>{timestamp}</time>
        </td>
      </StyledTableRow>
      <Dialog.Portal>
        <ChangesDetails
          oldValues={log.audit_old_value}
          newValues={log.audit_new_value}
          description={log.audit_description}
          timestamp={timestamp}
          ip={log.audit_ip_address}
          location={log.audit_ip_location ?? "Hyderabad,IN"}
          user={
            !log.users_last_name && !log.users_last_name
              ? "System"
              : `${log.users_first_name} ${log.users_last_name}`
          }
        />
      </Dialog.Portal>
    </Dialog>
  )
}
function AuditTable({ data }) {
  return (
    <StyledTable className="custom-table" striped>
      <colgroup>
        <col style={{ width: "22.5%" }} />
        <col style={{ width: "12.5%" }} />
        <col style={{ width: "10%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "15%" }} />
        <col style={{ width: "20%" }} />
      </colgroup>
      <thead>
        <tr
          style={{
            backgroundColor: "#F3F4F6",
          }}
        >
          <th scope="col">Actor</th>
          <th scope="col">Activity Type</th>
          <th scope="col">Event</th>
          <th scope="col">Description</th>
          <th scope="col">Location & IP</th>
          <th scope="col">Timestamp</th>
        </tr>
      </thead>
      <tbody style={{ verticalAlign: "baseline" }}>
        {data.map(log => (
          <Fragment key={log.audit_id}>
            <AuditLogRow log={log} />
          </Fragment>
        ))}
      </tbody>
    </StyledTable>
  )
}

export default AuditTable
