import React from "react"
import {
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledNoTasksMessage = styled.div`
  height: 200px;
  display: grid;
  place-items: center;
`
const SystemTasks = ({ tasks }) => {
  if (tasks.length <= 0) {
    return (
      <StyledNoTasksMessage>
        <h3>No System tasks have been assigned to you at this point of time</h3>
      </StyledNoTasksMessage>
    )
  }

  return (
    <Table className="custom-table table-striped">
      <colgroup>
        <col style={{ width: "40%" }} />
        <col style={{ width: "30%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "10%" }} />
      </colgroup>
      <thead>
        <tr>
          <th>Benchmarking Form Name</th>
          <th>System Name</th>
          <th>Owner</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {tasks.map((task, idx) => (
          <tr key={idx}>
            <td>
              <Link
                to={`/system-forms/${task.country_id}/${task.label_id}/${task.form_id}`}
                className="text-success"
              >
                <i className="ph-arrow-up-right me-2" />
                {task.form_name}
              </Link>
            </td>
            <td>
              <div
                className="px-2 color-contrast-tab d-flex align-items-center"
                style={{
                  "--tabcolor": `${task.label_color}`,
                  "--bgcolor": `${task.label_color}` + "1a",
                }}
              >
                {task.label_name}
              </div>
            </td>
            <td>{task.owner_name}
              {task.task_owner_id && <div>
                <Link
                  className="text-start d-flex align-items-center"
                  style={{color: 'blue', opacity: 0.5}}
                  to={`/user-management/${task.task_owner_id}`}
                >
                  <i className="ph-user-switch me-1" /> {task.task_owner_first_name}{" "}
                  {task.task_owner_last_name}
                </Link>
              </div>}
            </td>
            <td>{task.form_status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default SystemTasks
