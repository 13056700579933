import React from 'react';
import {Table} from 'reactstrap';
import { get } from 'services/AXIOS';
import {prettifyTimeUpdated} from './PrettifyTime';
import {DOWNLOAD_VERSION} from 'services/CONSTANTS';
import { downloadFile } from './download';

function AllVersions({data, policyName, alert}) {

    const download=(fileName, name)=>{
        get(`${DOWNLOAD_VERSION}/${fileName}`,{ responseType: "blob" })
        .then(file=>{
            downloadFile(fileName, name, file);
            alert(`${name} file download`, true)
        })
        .catch(err=>{
            console.log(err)
            alert(`${name} file download`, false)
        })
    }

    const mapVersions = data.map((version)=>{
            const name = `${policyName}- V${version.version_number}`;
            return(
                <tr key={version.version_id}>
                    <td>
                        <a onClick={()=>download(version.policy_filename, name)} className="text-decoration-underline" style={{color: "#1091F4"}}>{name}</a>
                    </td>
                    <td>
                        {prettifyTimeUpdated(version.uploaded_on)}
                    </td>
                    <td>
                        {version.uploaded_by.first_name}
                    </td>
                </tr>
            );
        })

    return (
        <div>
            <Table striped>
                <thead>
                    <tr>
                        <th>
                            VERSION NO.
                        </th>
                        <th>
                            UPLOADED ON
                        </th>
                        <th>
                            UPLOADED BY
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {mapVersions}
                </tbody>
            </Table>
        </div>
    );
}

export default AllVersions;