import {
  POLICIES_DATA,
  REMOVE_POLICY_EVENT,
  ADD_POLICY_EVENT,
} from "./actionTypes"

const INITIAL_STATE = {
  policies: {},
}

const dataIndicator = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POLICIES_DATA:
      return {
        policies: state.policies,
      }
    case ADD_POLICY_EVENT:
      return {
        ...state,
        policies: { ...state.policies, ...action.payload },
      }
    case REMOVE_POLICY_EVENT:
      return {
        ...state,
        policies: Object.keys(state.policies).reduce((object, key) => {
          if (Number(key) !== action.payload) {
            object[key] = state.policies[key]
          }
          return object
        }, {}),
      }
    default:
      return state
  }
}

export default dataIndicator
