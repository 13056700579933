import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

//redux

import { Link, withRouter } from "react-router-dom"

// Formik Validation
import { useFormik } from "formik"
import * as Yup from "yup"

// action
import { forgotPassword } from "services/authServices"

// import images

const ForgetPasswordPage = props => {
  //meta title
  const [forgetError, setForgetError] = useState()
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      forgotPassword(values, props.history)
        .then(res => {
          setForgetSuccessMsg(res.message)
          validation.resetForm()
        })
        .catch(err => {
          setForgetError(err.response.data.message)
        })
    },
  })

  useEffect(() => {
    document.body.className = "bg-login"
  }, [])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="overflow-hidden">
                <CardBody className="p-0">
                  <Row>
                    <Col lg={6}>
                      <div className="p-5 pt-10 pb-10">
                        <div className="text-primary pb-4">
                          <h2 className="text-primary">Reset Password</h2>
                          <p className="text-muted">
                            Enter the email address linked to your account and we&apos;ll send you a link
                            to reset your password
                          </p>
                        </div>
                        {forgetError && forgetError ? (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {forgetError}
                          </Alert>
                        ) : null}
                        {forgetSuccessMsg ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {forgetSuccessMsg}
                          </Alert>
                        ) : null}

                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="email">Email</Label>
                            <Input
                              name="email"
                              id="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 d-grid">
                            <button
                              className="btn btn-primary w-md"
                              type="submit"
                            >
                              Send Reset Email
                            </button>
                          </div>
                          <div className="mt-5 text-center">
                            <p>
                              Go back to{" "}
                              <Link
                                to="login"
                                className="font-weight-medium text-primary"
                              >
                                Login
                              </Link>{" "}
                            </p>
                          </div>
                        </Form>
                      </div>
                    </Col>
                    <Col lg={6} className="bg-login-img">
                      <div></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
