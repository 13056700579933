import React from 'react';

function Sort({applySort, sortForm, handleSortChange}) {
    
    return (
        <div>
            <div className='mt-2'>System Name</div>
            <div className='d-flex'>
                <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="labelNameAsc"
                        defaultChecked={sortForm?.label_name === "ASC"} 
                        onChange={()=>handleSortChange({label_name: "ASC"})}/>
                    <label className="form-check-label" htmlFor="labelNameAsc">
                        Ascending
                    </label>
                </div>
                <div className="form-check ms-3">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="labelNameDesc" 
                        defaultChecked={sortForm?.label_name === "DESC"}
                        onChange={()=>handleSortChange({label_name: "DESC"})}/>
                    <label className="form-check-label" htmlFor="labelNameDesc">
                        Descending
                    </label>
                </div>
            </div>
            <div>
                <div className = "mt-2">Updated At</div>
                <div className='d-flex'>
                    <div className="form-check">
                        <input
                            className="form-check-input" 
                            type="radio" 
                            name="form" 
                            id="updatedAtAsc" 
                            defaultChecked={sortForm?.updated_at === "ASC"}
                            onChange={()=>handleSortChange({updated_at: "ASC"})}/>
                        <label className="form-check-label" htmlFor="updatedAtAsc">
                            Ascending
                        </label>
                    </div>
                    <div className="form-check ms-3">
                        <input 
                            className="form-check-input" 
                            type="radio" 
                            name="form" 
                            id="updatedAtDesc" 
                            defaultChecked={sortForm?.updated_at === "DESC"}
                            onChange={()=>handleSortChange({updated_at: "DESC"})}/>
                        <label className="form-check-label" htmlFor="updatedAtDesc">
                            Descending
                        </label>
                    </div>
                </div>
            </div>
            <div className='d-flex' style={{justifyContent: "center"}}>
                <div 
                    onClick={applySort} 
                    className="btn btn-success common-button" style={{width: "90%"}}>Apply</div>
            </div>
        </div>
    );
}

export default Sort;