import { get, patch, post } from "./AXIOS"
import {
  CREATE_NEW_ORGANIZATION_TYPE,
  CREATE_NEW_ORGANIZATION,
  LIST_ORGANIZATION_TYPES,
  LIST_ORGANIZATIONS,
  GET_ORGANIZATION_TYPES,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_TYPE_SUGGESTIONS,
  GET_SPECIFIC_ORGANIZATION_TYPE_DETAILS,
  GET_SPECIFIC_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_SUGGESTIONS,
  GET_ORGANIZATION_COUNTRIES_LIST,
} from "./CONSTANTS"

export const listOrganizationTypes = params =>
  post(LIST_ORGANIZATION_TYPES, params)

export const listOrganizations = params => post(LIST_ORGANIZATIONS, params)

export const createNewOrganizationType = body =>
  post(CREATE_NEW_ORGANIZATION_TYPE, body)

export const createNewOrganization = body => post(CREATE_NEW_ORGANIZATION, body)

export const getOrganizationTypes = (query, body) => {
  if (query?.searchTerm) {
    return post(
      `${GET_ORGANIZATION_TYPES}/${query.searchTerm}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  } else {
    return post(
      `${GET_ORGANIZATION_TYPES}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  }
}

export const getOrganizations = (query, body) => {
  if (query?.searchTerm) {
    return post(
      `${GET_ORGANIZATIONS}/${query.searchTerm}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  } else {
    return post(
      `${GET_ORGANIZATIONS}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  }
}

export const getOrgTypeSuggestions = input =>
  get(`${GET_ORGANIZATION_TYPE_SUGGESTIONS}/${input}`)

export const getOrganizationSuggestions = input =>
  get(`${GET_ORGANIZATION_SUGGESTIONS}/${input}`)

export const getSpecificOrgTypeDetails = id =>
  get(`${GET_SPECIFIC_ORGANIZATION_TYPE_DETAILS}/${id}`)

export const editSpecificOrgType = (id, body) =>
  patch(`${GET_SPECIFIC_ORGANIZATION_TYPE_DETAILS}/${id}`, body)

export const editSpecificOrganization = (id, body) =>
  patch(`${GET_SPECIFIC_ORGANIZATION_DETAILS}/${id}`, body)

export const listOrganizationSpecificCountries = org_id =>
  get(`${GET_ORGANIZATION_COUNTRIES_LIST}/${org_id}`)
