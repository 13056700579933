import React from "react"
import ReactApexChart from "react-apexcharts"

const dountchart = props => {
  const series = props.data
  const options = {
    labels: props.labels,
    colors: props.colors,
    legend: {
      show: true,
      position: "left",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 0,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="300"
    />
  )
}

export default dountchart
