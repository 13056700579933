import { csvmaker, downloadCSV } from "components/Common/downloadCSV";
import { formateOnlyDate } from "helpers/utlities"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

export const getSystemsPDF = (data) => {
    const doc = new jsPDF("landscape", "mm")

    const head = []
    let body = []

    const makeTable = () => {
      const headValues = [
        "Name",
        "Description",
        "System Type",
        "Benchmarking",
        "Countries",
        "Updated On",
        "Updated By",
      ]

      headValues.map(value => head.push(value))

      body = data.map(data => {
        const row = [
            data.label_name,
            data.label_description,
            data.label_type?.name,
            data.form?.type ? "Yes" : "No",
            data.label_countries.map(country =>country.label),
            formateOnlyDate(data.updated_at),
            `${data.updated_by?.first_name} ${data.updated_by?.last_name}`
        ]
        return row
      })
    }

    makeTable()

    autoTable(doc, {
      head: [head],
      body: body,
      columnStyles: { 1: {minCellWidth: 55 }, 4: {cellWidth: 60}, 5: {minCellWidth: 25}, 6: {minCellWidth: 25} }
    })

    doc.save("Systems" + ".pdf") 
}

export const getSystemsCSV = (data) => {
    const newData = []
    data.map(data => {
        const newDataObject = {};
        newDataObject["Name"] = data.label_name;
        newDataObject["Description"] = data.label_description.replaceAll(",", ";");
        newDataObject["System Type"] = data.label_type?.name;
        newDataObject["Benchmarking"] = data.form?.type ? "Yes" : "No";
        newDataObject["Countries"] = data.label_countries.map(country =>country.label).join("; ");
        newDataObject["Updated On"] = formateOnlyDate(data.updated_at)
        newDataObject["Updated By"] = `${data.updated_by?.first_name} ${data.updated_by?.last_name}`;
        newData.push(newDataObject)
    })
    const csvdata = csvmaker(newData)
    return downloadCSV(csvdata, "systems.csv")
}

export const getSystemTypesPDF = (data) => {
    const doc = new jsPDF("portrait", "mm")

    const head = []
    let body = []

    const makeTable = () => {
      const headValues = [
        "Name",
        "Updated On",
        "Updated By",
      ]

      headValues.map(value => head.push(value))

      body = data.map(data => {
        const row = [
            data.name,
            formateOnlyDate(data.updated_at),
            `${data.updated_by?.first_name} ${data.updated_by?.last_name}`
        ]
        return row
      })
    }

    makeTable()

    autoTable(doc, {
      head: [head],
      body: body,
    })

    doc.save("System Types" + ".pdf")

}

export const getSystemTypesCSV = (data) => {
    const newData = []
    data.map(data => {
        const newDataObject = {};
        newDataObject["Name"] = data.name;
        newDataObject["Updated On"] = formateOnlyDate(data.updated_at)
        newDataObject["Updated By"] = `${data.updated_by?.first_name} ${data.updated_by?.last_name}`;
        newData.push(newDataObject)
    })
    const csvdata = csvmaker(newData)
    return downloadCSV(csvdata, "system types.csv")
}