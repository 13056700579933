import React from 'react';
import Select from 'react-select';

function Filter({applyFilter, selectedStatus, handleStatus, selectedTaskOwners, handleTaskOwners, ownerOptions}) {

    const statusOptions = [
        {value: '1', label: 'PENDING', color:"brown"},
        {value: '2', label: 'IN PROGRESS', color:"lightgreen"},
        {value: '3', label: 'COMPLETE', color:"green"},
        {value: '4', label: 'PAST DEADLINE', color:"red"},
        {value: '5', label: 'OVERRIDE', color:"orange"},
        {value: '6', label: 'NEW', color:"lightgreen"},
    ]

    return (
        <div>
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='mdi mdi-account-multiple-outline light-icon me-1'/> 
                    <h6 className='mb-0'>Status</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        defaultValue={selectedStatus} 
                        options={statusOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleStatus}
                    />
                </div>
            </div>
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='mdi mdi-account-multiple-outline light-icon me-1'/> 
                    <h6 className='mb-0'>Task Owner</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        defaultValue={selectedTaskOwners} 
                        options={ownerOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleTaskOwners}
                    />
                </div>
            </div>
            <div className='d-flex' style={{justifyContent: "center"}}>
                <div 
                    className="btn btn-success common-button" 
                    style={{width: "90%"}}
                    onClick={applyFilter}
                >
                    Apply
                </div>
            </div>
        </div>
    );
}

export default Filter;