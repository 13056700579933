import { get } from "./AXIOS"
import {
  ALL_LABELS,
  LABEL_SPECIFIC_COUNTRIES,
  LIST_COUNTRIES,
  LIST_LABELS_FOR_BENCHMARKING,
  LIST_STAGES,
} from "./CONSTANTS"

// list Countries
export const listCountries = () => get(LIST_COUNTRIES)

// list Stages
export const listStages = () => get(LIST_STAGES)

//list systems for benchmarking form creation

export const listSystems = () => get(LIST_LABELS_FOR_BENCHMARKING)

//list countries to which the user selected system is mapped to

export const listSystemSpecificCountries = id =>
  get(`${LABEL_SPECIFIC_COUNTRIES}/${id}`)

export const listAllSystems = () => get(ALL_LABELS)
