import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/agra_logo2_cropped.png"
import logoLightPng from "../../assets/images/agra_logo.png"
import logoLightSvg from "../../assets/images/agra_logo.png"
import logoDark from "../../assets/images/agra_logo.png"
import logoSmall from "../../assets/images/collapsed.png"
import styled from "@emotion/styled"

const StyledLogo = styled.img`
  aspect-ratio: ${props => (props.logoSize === "large" ? "2.6/1" : "1/1")};
`
const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <StyledLogo src={logoSmall} alt="" height={35} logoSize="small" />
            </span>
            <span className="logo-lg">
              <StyledLogo src={logo} alt="" height={70} logoSize="large" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <StyledLogo src={logoSmall} alt="" height={60} logoSize="small" />
            </span>
            <span className="logo-lg">
              <StyledLogo src={logo} alt="" height={70} logoSize="large" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-80">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
