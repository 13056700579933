import React from 'react';

function Folder({hasChildren}) {
    return (
        <g transform={hasChildren? 'scale(0.05 0.05) translate(-850,-490)': 'scale(0.05 0.05) translate(-850,-330)'}>
            <rect width="256" height="256" fill="none"></rect><path d="M216.9,208H39.4a7.4,7.4,0,0,1-7.4-7.4V80H216a8,8,0,0,1,8,8V200.9A7.1,7.1,0,0,1,216.9,208Z" fill="none" stroke="#9095A0FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></path><path d="M32,80V56a8,8,0,0,1,8-8H92.7a7.9,7.9,0,0,1,5.6,2.3L128,80" fill="none" stroke="#9095A0FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></path>
        </g>
    );
}
//default value of number without suffix?
export default Folder;
