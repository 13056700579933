import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Button, Card, CardBody, Table, Row, Col, Progress } from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import { post, get } from "services/AXIOS"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import PolicyTableContainer from "components/Common/PolicyTableContainer"
import PolicyActivityTableContainer from "components/Common/PolicyActivityTableContainer"
import DonutChart from "./dountchart"
import { STAGE, TRACKERCARDS } from "services/CONSTANTS"
import AccessDenied from "components/Common/Alerts/Access-Denied"
import LoadingSpinner from "components/Common/Loading-Spinner"

ChartJS.register(ArcElement, Tooltip, Legend)

export const Overview = ({ alert }) => {
  const tabColorObject = {
    7: "#764E36",
    0: "#FFFFFF",
    1: "#1565C0",
    2: "#1976D2",
    3: "#1E88E5",
    4: "#2196F3",
    5: "#42A5F5",
    6: "#56AFF6",
  }

  const [activeTab, setActiveTab] = useState({
    id: "0",
    stage: {
      backgroundColor: "#fff",
      color: "#1565C0",
    },
    search_field: "",
    country_names: [],
    labels: [],
    stages: [],
    status: [],
    orderBy: {
      last_updated_at: "",
      deadline: "",
    },
  })

  const [lifecycle, setLifeCycle] = useState({
    show: false,
  })

  const [showActivity, setShowActivity] = useState({ show: false })

  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const CHART_COLORS = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(54, 162, 235)",
    purple: "rgb(153, 102, 255)",
  }

  const [stagePolicy, setStagePolicy] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: Object.values(CHART_COLORS),
        deadline: [],
      },
    ],
  })

  const [cards, setCards] = useState()

  const toggleTab = (tab, stage) => {
    const tempActiveTab = { ...activeTab }
    if (activeTab.id !== tab) {
      tempActiveTab.id = tab
      tempActiveTab.stage = stage
      tempActiveTab.stages = [stage.id]
      tempActiveTab.status = []
      tempActiveTab.labels = []
      tempActiveTab.search_field = ""
    }
    if (tab == 0) {
      tempActiveTab.stages = []
      tempActiveTab.stage = stage
    }

    setLifeCycle({ show: false })
    getPolicies(tempActiveTab)
    setActiveTab(tempActiveTab)
  }

  const { mutate: getPolicies } = useMutation(
    activeTab => post(`/policies/stage-policy`, activeTab),
    {
      onMutate: () => {
        setIsLoading(true);
      },
      onSuccess: data => {
        const tempData = data.map(d => {
          const lifecycle = {
            start: null,
            end: null,
          }
          d.tasks.forEach(task => {
            if (task.stage_id < lifecycle.start || lifecycle.start == null)
              lifecycle.start = task.stage_id
            if (task.stage_id > lifecycle.end || lifecycle.end == null)
              lifecycle.end = task.stage_id
          })
          d.lifecycle = lifecycle

          return d
        })
        setPolicies(tempData);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
        alert("Loading policies", false);
      }
    }
  )

  // const { data: labels } = useQuery(["labels"], () =>
  //   get("/labels").then(data => data)
  // )

  const { tracker_stages, countries } = useSelector(state => state.list)

  const [stages, setStages] = useState([])

  useEffect(() => {
    get(STAGE).then(data => {
      const stages = data?.filter(data => {
        return data.id < 7 //change to type === tracker
      })
      setStages(stages)
    })
  }, [])

  const [trackerStagesSet, setTrackerStagesSet] = useState({})

  useEffect(() => {
    const trackerStagesSet = new Set(tracker_stages.map(stage => stage.id))
    setTrackerStagesSet(trackerStagesSet)
  }, [tracker_stages])

  const isLocked = (id = 0) => {
    if (id === 0) {
      //for overview stage
      if (tracker_stages.length === 0) {
        return true
      } else {
        return false
      }
    } else {
      if (tracker_stages.length === 0) {
        return true
      } else {
        return !trackerStagesSet?.has(id)
      }
    }
  }

  useEffect(() => {
    const tempStagePolicy = { ...stagePolicy }

    if (tracker_stages && policies.length > 0 && activeTab.id == "0") {
      const data = Array(6).fill(0)
      const deadline = Array(6).fill(0)

      const labels = Array(6).fill("")
      const label_bg_colors = Object.values(CHART_COLORS)

      tracker_stages.forEach((node, index) => {
        labels[index] = `Stage ${node.stage}`
        label_bg_colors[index] = `${node.backgroundColor}`
      })
      policies?.forEach(node => {
        if (node?.stage?.stage && node?.stage?.type == "tracker") {
          data[node.stage.stage - 1] = data[node.stage.stage - 1] + 1
          if (new Date(node.deadline) < new Date()) {
            deadline[node.stage.stage - 1] = deadline[node.stage.stage - 1] + 1
          }
        }
      })
      tempStagePolicy.labels = labels
      tempStagePolicy.datasets[0].data = data
      tempStagePolicy.datasets[0].deadline = deadline
      tempStagePolicy.datasets[0].backgroundColor = label_bg_colors

      setStagePolicy(tempStagePolicy)
    }
  }, [policies, tracker_stages, countries])

  useEffect(() => {
    getPolicies(activeTab)
  }, [])

  useEffect(() => {
    if (activeTab != 0) {
      get(`${TRACKERCARDS}/${activeTab.id}`)
        .then(res => setCards(res))
        .catch(err => {
          console.log(err)
          alert("Data loading", false)
        })
    }
  }, [activeTab])

  return (
    <div
      className={`page-content e-tracker`}
      style={{
        marginTop: "0",
      }}
    >
      <div>
        <div
          className=" d-flex justify-content-between p-0"
          style={{
            height: "60px",
            top: "70px",
            width: "fit",
          }}
        >
          <div
            className={`w-100 d-flex align-items-center justify-content-center ${
              activeTab.id == "0" ? "" : "mt-2"
            }`}
            style={{
              cursor: "pointer",
              backgroundColor: tabColorObject[0],
              color: "#1565C0",
              borderRadius: "10px 10px 0px 0px",
              border: "1px solid #56AFF6",
            }}
            onClick={() => {
              const object = {
                stage: {
                  stage: 0,
                  backgroundColor: "#08857D",
                  color: "#ffffff",
                },
              }
              toggleTab("0", object.stage)
            }}
          >
            <i className="ph-squares-four me-1"></i>
            <p className="mb-0" style={{ letterSpacing: "1px" }}>
              Overview
            </p>
            {isLocked() && <i className="ms-2 ph-lock"></i>}
          </div>
          {stages?.map((node, index) => {
            return (
              <div
                key={index}
                className={`w-100 text-center d-flex justify-content-center align-items-center
                ${activeTab.id == index + 1 ? "" : "mt-2"}`}
                style={{
                  cursor: "pointer",
                  backgroundColor: node.backgroundColor,
                  color: node.color,
                  borderRadius: "10px 10px 0px 0px ",
                }}
                onClick={() => {
                  toggleTab(node.stage.toString(), node)
                }}
              >
                <i className={`${node.icon} me-1`}></i>
                <p
                  className="mb-0"
                  style={{ letterSpacing: "1px" }}
                >{`${node.stage}. ${node.label}`}</p>
                {isLocked(node.id) && <i className="ms-2 ph-lock"></i>}
              </div>
            )
          })}
        </div>
        {isLocked(activeTab.stage.id) ? (
          <AccessDenied />
        ) : (
          <div
            style={{
              backgroundColor: tabColorObject[activeTab.id],
              padding: "50px 10px 10px 10px",
            }}
          >
            {activeTab.id == 0 ? (
              <Card>
                <CardBody>
                  <div className="">
                    <p className=" fs-4 fw-bold">
                      <i className="bx bx-grid-alt bx-sm" /> Stage Overview
                    </p>
                    <Row>
                      <Col md="6">
                        <div>
                          {stagePolicy.labels.length > 0 &&
                          stagePolicy.datasets[0].data != Array(6).fill(0) ? (
                            <DonutChart
                              data={stagePolicy.datasets[0].data}
                              labels={stagePolicy.labels}
                              colors={stagePolicy.datasets[0].backgroundColor}
                            />
                          ) : (
                            <p>Nothing to show in Chart</p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        {" "}
                        <div
                          className="d-flex justify-content-center "
                          style={{
                            marginRight: "5%",
                          }}
                        >
                          <Table hover>
                            <thead
                              style={{
                                backgroundColor: `${tabColorObject[0]}5e`,
                              }}
                            >
                              <tr>
                                {/* <th></th> */}
                                <th>STAGE</th>
                                <th>NO. OF POLICIES </th>
                                <th>PAST DEADLINE</th>
                                {/* <th></th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {tracker_stages.map((node, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      toggleTab(
                                        (node.stage + 1).toString(),
                                        node
                                      )
                                    }
                                  >
                                    {/* <td className="">
                                    <i className="mdi mdi-arrow-top-right" />
                                  </td> */}
                                    <td>
                                      Stage {node.stage}: {node.label}
                                    </td>
                                    <td>
                                      {stagePolicy.datasets[0].data[index]}
                                    </td>
                                    <td>
                                      {stagePolicy.datasets[0].deadline[index]}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* </div> */}
                </CardBody>
              </Card>
            ) : (
              <Col
                md="8"
                style={{
                  backgroundColor: "#fff",
                  marginBlockEnd: "1rem",
                  padding: "1em",
                  borderRadius: "5px",
                }}
              >
                <header
                  style={{
                    fontSize: "18px",
                    fontWeight: 500,
                    marginBlockEnd: ".5rem",
                  }}
                >
                  Stage Progress
                </header>
                <Row>
                  <Col md="4">
                    <Card
                      style={{
                        backgroundColor: "#FFDAC1",
                        marginBlockEnd: 0,
                      }}
                    >
                      <CardBody>
                        <Row>
                          <Col sm={8}>
                            <h5 className="font-size-12 mb-0 pb-3">
                              {"Total Policies"}
                            </h5>
                            <div className="text-muted mt-4 fw-bold">
                              <h4>{cards.length && cards[0]} </h4>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <i
                              className="bx bx-file bx-md rounded-3"
                              style={{
                                backgroundColor: "#EE7D2D",
                                color: "white",
                                padding: "2px",
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card
                      style={{
                        backgroundColor: "#D2BCFC",
                        marginBlockEnd: 0,
                      }}
                    >
                      <CardBody>
                        <Row>
                          <Col sm={8}>
                            <h5 className="font-size-12 mb-0">
                              {"Policies Past Deadline"}
                            </h5>
                            <div className="text-muted mt-4 fw-bold">
                              <h4>{cards[2]} </h4>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <i
                              className="mdi mdi-ribbon bx-sm rounded-3"
                              style={{
                                backgroundColor: "#8352E3",
                                padding: "3px 8px",
                                color: "white",
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card
                      style={{
                        backgroundColor: "#E4FAC1",
                        marginBlockEnd: 0,
                      }}
                    >
                      <CardBody>
                        <Row>
                          <Col sm={8}>
                            <h5 className="font-size-12 mb-0">
                              {"Policies Waiting For Approval"}
                            </h5>
                            <div className="text-muted mt-4 fw-bold">
                              <h4>{cards[1]} </h4>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <i
                              className="bx bxs-crown bx-sm rounded-3"
                              style={{
                                backgroundColor: "#77BD04",
                                padding: "8px ",
                                color: "white",
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            )}
            <Card>
              <CardBody>
                <div>
                  {/*
                <TableContainer
                  id="report"
                  isFilters={true}
                  FilterComponent={FilterComponent}
                  isExport={true}
                  backgroundColor={activeTab?.stage?.backgroundColor}
                  isSort={true}
                  SortComponent={SortComponent}
                  tableTitle={
                    activeTab.id > 0
                      ? `Policy Details `
                      : "Showing all policies in e-Tracker"
                  }
                  topComponent={
                    activeTab.id == "0" ? TopComponent : ViewFormList
                  }
                  hideTable={lifecycle.show}
                  columns={columns}
                  data={policies}
                  isSearch={true}
                  SearchComponent={SearchComponent}
                  customPageSize={10}
                />
                */}
                  <div>
                    <div className="d-flex justify-content-between">
                      <p className=" fw-bold fs-4">
                        {activeTab.id == "0"
                          ? `Policy Details: All Stages`
                          : (showActivity.show ? "Milestone" : "Policy") +
                            ` Details: Stage ${activeTab.id} ${activeTab.stage.label}`}
                      </p>

                      {activeTab.id == "0" && !lifecycle.show && (
                        <Button
                          className="common-button"
                          // style={{
                          //   color: activeTab.stage.color || "green",
                          //   backgroundColor:
                          //     activeTab.stage.backgroundColor || "white",
                          // }}
                          onClick={() => {
                            setLifeCycle({ show: true })
                          }}
                        >
                          View Policy Timeline Tracker
                        </Button>
                      )}
                      {activeTab.id == "0" && lifecycle.show && (
                        <Button
                          className="common-button"
                          // style={{
                          //   color: activeTab.stage.color || "green",
                          //   backgroundColor:
                          //     activeTab.stage.backgroundColor || "white",
                          // }}
                          onClick={() => {
                            setLifeCycle({ show: false })
                          }}
                        >
                          Back To Policy Details
                        </Button>
                      )}
                      {activeTab.id != "0" && !showActivity.show && (
                        <Button
                          style={{
                            color: activeTab.stage.color || "green",
                            backgroundColor:
                              activeTab.stage.backgroundColor || "white",
                          }}
                          onClick={() => {
                            setShowActivity({ show: true })
                          }}
                        >
                          View Milestone Details
                        </Button>
                      )}
                      {activeTab.id != "0" && showActivity.show && (
                        <Button
                          style={{
                            color: activeTab.stage.color || "green",
                            backgroundColor:
                              activeTab.stage.backgroundColor || "white",
                          }}
                          onClick={() => {
                            setShowActivity({ show: false })
                          }}
                        >
                          Back To Policy Details
                        </Button>
                      )}
                    </div>

                    {!lifecycle.show && !showActivity.show && (
                      <PolicyTableContainer 
                        //fetching the policies twice - once in overview component, another in policy table container component
                        component={"tracker"}
                        stage={activeTab}
                      />
                    )}
                  </div>
                  {!lifecycle.show && showActivity.show && (
                    <PolicyActivityTableContainer
                      stage={activeTab}
                      alert={alert}
                    />
                  )}
                  {
                    //just need id and stage from active tab, can remove the rest
                  }
                </div>
                {/* {lifecycle.show && (
                <div>
                  <div className=" d-flex">
                    {tracker_stages.map(node => {
                      return (
                        <div
                          key={node.stage}
                          className=" border rounded-3 p-3 w-100"
                        >
                          Stage {node.stage}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )} */}
                {lifecycle.show &&
                  (isLoading?
                  <div className="d-flex justify-content-center">
                    <LoadingSpinner/>
                  </div> :
                  <div>
                    <table className=" w-100 border">
                      <tr className=" text-center">
                        {tracker_stages.map((node, index) => {
                          return (
                            <th key={index} className="border p-3">
                              {node.label}
                            </th>
                          )
                        })}
                      </tr>
                      {
                      policies.map((policy, index) => {
                        if (policy.tasks.length > 0)
                          return (
                            <tr
                              className=" text-center p-1 my-3 p-3"
                              key={index}
                            >
                              {tracker_stages.map((node, index) => {
                                if (node.stage == policy.lifecycle.start) {
                                  const color = {
                                    background: "#cccccc",
                                    text: "#000000",
                                  }

                                  switch (policy?.current_task?.status) {
                                    case "ongoing": {
                                      color.background = "#FFE103"
                                      color.text = "#000000"
                                      break
                                    }
                                    case "completed": {
                                      color.background = "#00E936"
                                      color.text = "#000000"
                                      break
                                    }
                                    case "overdue": {
                                      color.background = "#FE0100"
                                      color.text = "#ffffff"
                                    }
                                  }
                                  return (
                                    <td
                                      key={index}
                                      className="p-3 m-5"
                                      style={{
                                        color: "white",
                                      }}
                                      colSpan={6}
                                    >
                                      <Progress
                                        value={Math.floor(
                                          ((policy.lifecycle.end -
                                            policy.lifecycle.start +
                                            1) /
                                            (7 - policy.lifecycle.start)) *
                                            100
                                        )}
                                        className="rounded-pill progress-md w-100"
                                        barClassName=" rounded-pill fs-6"
                                        barStyle={{
                                          backgroundColor: color.background,
                                          color: color.text,
                                        }}
                                        style={{
                                          height: "50px",
                                          padding: "0px",
                                          backgroundColor:
                                            color.background + "29",
                                        }}
                                      >
                                        <div className="  d-flex justify-content-between mx-4 rounded-pill ">
                                          <div className=" m-0 align-self-center">
                                            {`${policy.policy_name} `}
                                          </div>
                                          <p className=" fs-4 float-end m-0">
                                            {">"}
                                          </p>
                                        </div>
                                      </Progress>
                                    </td>
                                  )
                                } else return <td></td>
                              })}
                            </tr>
                          )
                      })}
                    </table>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
