import React from "react"
import Tasks from "./tasks"
import SystemTasks from "./System-Tasks"
import CustomTabs from "components/Common/Custom-Tabs"
import AdminTasks from "../TaskManagement/AdminTasks/Admin-Tasks"

const DashboardTasks = ({ policyTasks, systemTasks, user }) => {
  const isUser = user.role === "user"
  const triggers = isUser
    ? [
        {
          name: "E-Tracker Tasks",
          value: "tab1",
        },
        {
          name: "Benchmarking Tasks",
          value: "tab2",
        },
      ]
    : [
        {
          name: "E-Tracker Tasks",
          value: "tab1",
        },
        {
          name: "Benchmarking Tasks",
          value: "tab2",
        },
        {
          name: "Admin Tasks",
          value: "tab3",
        },
      ]
  const content = [
    {
      value: "tab1",
      component: <Tasks tasks={policyTasks} />,
    },
    {
      value: "tab2",
      component: <SystemTasks tasks={systemTasks} />,
    },
    {
      value: "tab3",
      component: <AdminTasks component={"Dashboard"}/>,
    },
  ]
  return (
    <CustomTabs
      triggers={triggers}
      content={content}
      defaultValue="tab1"
      ariaLabel="Dashboard Tasks"
    />
  )
}

export default DashboardTasks
