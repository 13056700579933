import { useMutation } from "@tanstack/react-query"
import Preview from "pages/FormManagement/preview"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Button } from "reactstrap"
import { post } from "services/AXIOS"
import { getForm, getResponsesOfForm } from "services/formServices"

const FillForm = props => {
  const { formId: formID, policyId: policyID, stageId: stageID } = useParams()

  const history = useHistory()
  const { location } = props
  // const taskId = location.state.task_id || location.state.subcategory_id

  const policy_id = policyID
  const [formData, setFormData] = useState(null)
  const [responses, setResponses] = useState([])
  const [mode, setMode] = useState("read")
  const loadForm = async query => {
    try {
      const { data } = await getForm(query)
      setFormData(data)
      setMode(data?.mode)
      setResponses(
        Array.from({ length: data?.questions.length }, (el, idx) => ({
          question_id: data?.questions[idx].id,
          response:
            data?.questions[idx].responses.length > 0
              ? data?.questions[idx].responses[0].response
              : "",
          weight: data?.questions[idx].responses[0]?.weight ?? 0,
        }))
      )
    } catch (err) {
      console.error(err)
      setFormData(null)
    }
  }

  const loadResponses = async query => {
    try {
      const { data } = await getResponsesOfForm(query)
      return data
    } catch (err) {
      console.error(err)
      return err
    }
  }

  useEffect(() => {
    if (!!policyID && !!stageID && !!formID)
      loadForm({ policyID, stageID, formID })
  }, [policyID, stageID, formID])

  const { mutate: saveResponse } = useMutation(
    ["saveResponse"],
    () => {
      const response = {
        questions: responses.map(response => {
          return {
            question_id: response.question_id,
            response: response.response,
          }
        }),
        policy_id: policyID,
        form_id: formID,
        stage_id: stageID,
      }
      // if (location.state.category) {
      //   response.subcategory_id = parseInt(location.state.subcategory_id)
      // } else {
      //   // response.task_id = parseInt(taskId)
      // }
      return post("/form/response", response).then(data => data)
    },
    {
      onSuccess: data => {
        if (data.statusCode == 401) {
          props.alert("Response Save Failed!", false)
        } else if (location?.state?.subcategory_id) {
          props.alert("Responses Saved!", true)
          setTimeout(() => {
            history.push("/policy-landscape")
          }, 2 * 1000)
        } else {
          props.alert("Responses Saved!", true)
          setTimeout(() => {
            history.push(`/policy/${policy_id}`)
          }, 2 * 1000)
        }
      },
    }
  )

  const responseHandler = response => {
    const { id, index, value, weight, responseType } = response
    const tempResponses = [...responses]
    tempResponses[index].response = value
    if (responseType === "answer") tempResponses[index].weight = Number(weight)
    setResponses(tempResponses)
  }
  return (
    <div className="page-content bg-light min-vh-100">
      <div className=" d-flex justify-content-between mx-3 align-items-start">
        <div className=" ">
          <p className=" fs-1 mb-0 fw-bold">{formData?.policy_name}</p>
        </div>
        <div>
          {mode === "edit" ? (
            <Button
              onClick={saveResponse}
              color="primary"
              className=" text-nowrap"
              style={{
                float: "right",
              }}
            >
              Save Response
            </Button>
          ) : (
            <h2>Preview Mode</h2>
          )}
        </div>
      </div>
      {formData && (
        <Preview
          form={formData}
          responseHandler={responseHandler}
          mode={mode}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FillForm)

// export default FillForm
