import { useState, useEffect } from "react"

export default function useFormProgress(formKey, totalSteps) {
  const [completedSteps, setCompletedSteps] = useState(() => {
    const storedData = localStorage.getItem(formKey)
    return storedData ? JSON.parse(storedData).completedSteps : []
  })

  const [formData, setFormData] = useState(() => {
    const storedData = localStorage.getItem(formKey)
    return storedData
      ? JSON.parse(storedData).formData
      : {
          name: "",
          description: "",
          type: "",
          stage_id: "",
          questions: [
            {
              question_text: "",
              question_type: "text",
              question_weight: 0,
              question_number: 1,
              question_options: [""],
              question_options_weight: [0],
              required: false,
              is_file_upload: false,
            },
          ],
          form_owners: [],
          is_published: false,
        }
  })

  useEffect(() => {
    localStorage.setItem(formKey, JSON.stringify({ completedSteps, formData }))
  }, [formKey, completedSteps, formData])

  const markStepAsCompleted = step => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps(prevSteps => [...prevSteps, step])
    }
  }

  const updateFormData = data => {
    setFormData(prevData => ({ ...prevData, ...data }))
  }

  const resetFormProgress = () => {
    setCompletedSteps([])
    setFormData({
      name: "",
      description: "",
      type: "",
      stage_id: "",
      questions: [
        {
          question_text: "",
          question_type: "text",
          question_weight: 0,
          question_number: 1,
          question_options: [""],
          question_options_weight: [0],
          required: false,
        },
      ],
      form_owners: [],
      is_published: false,
    })
    localStorage.removeItem(formKey)
  }

  const isStepCompleted = step => completedSteps.includes(step)

  const isFormCompleted = () => completedSteps.length === totalSteps

  return {
    markStepAsCompleted,
    updateFormData,
    resetFormProgress,
    isStepCompleted,
    isFormCompleted,
    formData,
    completedSteps,
  }
}
