import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Badge,
} from "reactstrap"
import { map } from "lodash"
import { Link } from "react-router-dom"

import Breadcrumbs from "components/Common/Breadcrumb"
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import Vector from "./Vectormap"

import ColumnWithDataLabels from "../AllCharts/apex/ColumnWithDataLabels"
import BarChart from "../AllCharts/apex/barchart"
import PolarAreaChart from "../AllCharts/apex/PolarAreaChart"
import { useSelector } from "react-redux"

import LoadingOverlay from "react-loading-overlay"

import Tasks from "./tasks"
import Progress from "./progress"

import {
  dashboardCounts,
  getDashboardNotificationsCount,
  getDashboardTasks,
  getDashboardBenchmarkingTasks,
} from "services"
import Updates from "./Updates"
import DashboardTasks from "./Dashboard-Tasks"

const Dashboard = () => {
  LoadingOverlay.propTypes = undefined

  const [dashboardCount, setDashboardCount] = useState([])
  const [dashboardPolicyTasks, setDashboardPolicyTasks] = useState([])
  const [dashboardSystemTasks, setDashboardSystemTasks] = useState([])
  const [dashboardNotificationsCount, setDashboardNotificationsCount] =
    useState()
  const [tasksLoading, setTasksLoading] = useState(true)

  const { country, country_id, user } = useSelector(state => state.Profile)
  const { countries } = useSelector(state => state.list)

  const [countryName, setCountryName] = useState("")

  //redundant function
  useEffect(
    function () {
      if (country) {
        let params = {}
        params = { country: country, country_id: country_id }
        dashboardCounts(params)
          .then(res => {
            setDashboardCount(res.data)
          })
          .catch(err => setDashboardCount([]))

        getDashboardNotificationsCount(params)
          .then(res => {
            setDashboardNotificationsCount(res.data)
          })
          .catch(err => setDashboardNotificationsCount())

        getDashboardTasks(params)
          .then(res => {
            setDashboardPolicyTasks(res.data)
          })
          .catch(err => setDashboardPolicyTasks([]))

        getDashboardBenchmarkingTasks(params)
          .then(res => setDashboardSystemTasks(res.data))
          .catch(err => setDashboardSystemTasks([]))
      }
    },
    [country]
  )

  useEffect(
    function () {
      if (country) {
        let params = {}
        params = { country: country, country_id: country_id }
        setTasksLoading(true)
        getDashboardTasks(params)
          .then(res => {
            setDashboardPolicyTasks(res.data)
          })
          .catch(err => setDashboardPolicyTasks([]))
        getDashboardBenchmarkingTasks(params)
          .then(res => setDashboardSystemTasks(res.data))
          .catch(err => setDashboardSystemTasks([]))
          .finally(() => setTasksLoading(false))
      }
    },
    [country]
  )

  useEffect(() => {
    if (country == localStorage.getItem("COUNTRY")) {
      setCountryName(
        countries.filter(c => c.title == country).map(cn => cn.label)
      )
    }
  }, [countries, country])

  useEffect(() => {
    document.body.classList.remove("bg-login")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Home" breadcrumbItem={`Home - ${countryName}`} /> */}

          <Row>
            <Col xl="12">
              <Card className={`bg-white`}>
                <CardBody>
                  <Row>
                    {dashboardCount.length > 0 &&
                      dashboardCount.map((data, key) => (
                        <Col md="3" key={"_col_" + key}>
                          <Card className={`mini-stats-wid ${data.background}`}>
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium">
                                    {data.title}
                                  </p>
                                  <h4 className="mb-1">{data.count}</h4>
                                </div>
                                <div
                                  className={`avatar-sm align-self-center mini-stat-icon rounded`}
                                >
                                  <span
                                    className={`avatar-title ${data.iconBackground}`}
                                  >
                                    <i
                                      className={
                                        "bx " + data.iconClass + " font-size-24"
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    <Col md="3" key={4}>
                      {
                        <div className="d-grid gap-2">
                          {user.role == "admin" &&
                            dashboardNotificationsCount && (
                              <Link
                                to="/user-management"
                                type="button"
                                className="btn btn-primary font-black bg-btn-light-green mb-3"
                              >
                                <i className="bx bx-user me-1 label-icon"></i>{" "}
                                New User Registrations{" "}
                                <span className="ms-1 badge bg-danger">
                                  {dashboardNotificationsCount.new_users}
                                </span>
                              </Link>
                            )}
                          {user.role == "user" && (
                            <Link
                              to="/policy-directory"
                              type="button"
                              className="btn btn-primary font-black bg-btn-light-green mb-3"
                            >
                              <i className="bx bx-user me-1 label-icon"></i> Add
                              New Policy{" "}
                            </Link>
                          )}
                          {dashboardNotificationsCount && (
                            <Link
                              to="/policy-directory"
                              type="button"
                              className="btn btn-secondary font-black bg-btn-light-purple"
                            >
                              <i className="bx bx-file me-1 label-icon"></i>
                              New Policy Uploads{" "}
                              <span className="ms-1 badge bg-danger">
                                {dashboardNotificationsCount.new_policies}
                              </span>
                            </Link>
                          )}
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <div>
              <LoadingOverlay
                active={tasksLoading}
                spinner
                text="Generating data..."
              >
                <DashboardTasks
                  policyTasks={dashboardPolicyTasks?.tasks ?? []}
                  systemTasks={dashboardSystemTasks?.data ?? []}
                  user={user}
                />
              </LoadingOverlay>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
