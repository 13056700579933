import React from "react"
import { formateOnlyDate } from "helpers/utlities"
import { Link } from "react-router-dom"

function PolicyActivitiesTable({ activities, policy, routeResponseForm }) {
  const mapActivities = () => {
    if (activities && activities.length > 0) {
      return activities.map((activity, i) => {
        const deadlineMissed =
          activity?.form_status != "COMPLETE" &&
          new Date(policy?.current_task?.deadline) <= new Date()
        if (activity) {
          return (
            <tr key={i}>
              <td
                scope="row"
                className="col-wdt-1"
                // onClick={() =>
                //   routeResponseForm(
                //     activity.id,
                //     policy.tasks[0].id,
                //     policy.policy_id
                //   )
                // }
              >
                <div className="form-link">
                  <Link
                    to={`/forms/${policy.policy_id}/${activity.stage_id}/${activity.id}`}
                    className="text-success"
                  >
                    <i className="ph-arrow-up-right me-2"></i>
                    {activity.name}
                  </Link>
                </div>
              </td>
              <td className="col-wdt-2">
                <div
                  className="rounded-pill"
                  style={{
                    backgroundColor:
                      deadlineMissed || activity.form_status == "OVERRIDE"
                        ? "#ffebeb"
                        : activity.form_status == "COMPLETE"
                        ? "#EEFCF5"
                        : "#FEFFCC",
                    color: deadlineMissed
                      ? "red"
                      : activity.form_status == "COMPLETE" && "#009230",
                    width: "fit-content",
                  }}
                >
                  {activity?.form_status || "PENDING"}
                </div>
              </td>
              <td className="col-wdt-3">
                {activity.updated_at && formateOnlyDate(activity.updated_at)}
              </td>
              <td className="col-wdt-4">
                <Link
                  className="text-start"
                  to={`/user-management/${activity.owner_id}`}
                >
                  <i className="mdi mdi-account" /> {activity.owner_name}
                </Link>
                {activity.temp_owner_id && (
                  <div>
                    <Link
                      className="text-start d-flex align-items-center"
                      style={{ color: "blue", opacity: 0.5 }}
                      to={`/user-management/${activity.temp_owner_id}`}
                    >
                      <i className="ph-user-switch me-1" />{" "}
                      {activity.temp_first_name} {activity.temp_last_name}
                    </Link>
                  </div>
                )}
              </td>
              <td className="col-wdt-5">{activity.calculated_weight} </td>
            </tr>
          )
        }
      })
    } else {
      return (
        <tr>
          <td colSpan={5} scope="row">
            Milestone not available
          </td>
        </tr>
      )
    }
  }

  return (
    <div>
      <div className="table-responsive ">
        <table className="table table-striped" style={{ textAlign: "center" }}>
          <thead className="table-header ">
            <tr>
              <th className="col-wdt-1">Milestone Name</th>
              <th className="col-wdt-2">Status</th>
              <th className="col-wdt-3">Last updated At</th>
              <th className="col-wdt-4">Milestone Owner</th>
              <th className="col-wdt-5">Score</th>
            </tr>
          </thead>
          <tbody>{mapActivities()}</tbody>
        </table>
      </div>
    </div>
  )
}

export default PolicyActivitiesTable
