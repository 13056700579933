import { del, get, post, put } from "./AXIOS"
import {
  LIST_ALL_USERS,
  USER,
  USER_POLICY_TASKS_HISTORY,
  USER_BENCHMARKING_TASKS_HISTORY,
} from "./CONSTANTS"

export const getUsers = params => post(`${USER}/country`, params)

export const getUserDetail = id => get(`${USER}/${id}`)

export const getAllUsers = () => get(LIST_ALL_USERS)

export const updateUser = (id, user) => put(`${USER}/${id}`, user)

export const deleteUser = id => del(`${USER}/${id}`)

export const getPolicyTasksHistory = query =>
  get(
    `${USER_POLICY_TASKS_HISTORY}?limit=${query.limit}&offset=${query.offset}`
  )
export const getBenchmarkingTasksHistory = query =>
  get(
    `${USER_BENCHMARKING_TASKS_HISTORY}?limit=${query.limit}&offset=${query.offset}`
  )
