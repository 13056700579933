import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

//redux
import { useDispatch, useSelector } from "react-redux"

import { Link, withRouter } from "react-router-dom"

// Formik validation
import { useFormik } from "formik"
import * as Yup from "yup"

// actions
import { apiError, loginUser } from "../../store/actions"

// import images
import ReCAPTCHA from "react-google-recaptcha"

import white_border from "assets/images/white-border.png"

const Login = props => {
  //meta title
  const dispatch = useDispatch()
  const captchaRef = useRef(null)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password").min(4),
    }),
    onSubmit: values => {
      if (captchaRef.current.getValue() == "") {
        dispatch(apiError("Please Fill recaptcha"))
      } else {
        values.recaptchaValue = captchaRef.current.getValue()
        dispatch(loginUser(values, props.history))
      }
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  useEffect(() => {
    document.body.className = "bg-login"
  }, [])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="overflow-hidden">
                <CardBody className="p-0">
                  <Row>
                    <Col lg={6}>
                      <div className="p-5 pt-7 pb-7">
                        <div className="text-primary pb-4">
                          <h2 className="text-primary">SIGN IN</h2>
                          <p className="text-muted">
                            Sign in to continue to AGRA Policy Tracker
                          </p>
                        </div>
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {error === "Account Suspended" ? (
                            <Alert color="danger">
                              <>
                                <p>Your account has been suspended.</p>
                                <span className="text-muted">
                                  Please contact your System Administrator for
                                  more information.
                                </span>
                              </>
                            </Alert>
                          ) : error ? (
                            <Alert color="danger">{error}</Alert>
                          ) : null}

                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              //value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              //value={validation.values.password || ""}
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_SITE_KEY}
                              ref={captchaRef}
                            />
                          </div>
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                          <div className="mt-4 text-center">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock me-1" />
                              Forgot your password?
                            </Link>
                          </div>
                          {/* <div className="mt-2 text-center">
                        <p className="text-muted">
                          Don&#39;t have an account ?{" "}
                          <Link to="/register" className="fw-medium text-primary">
                            {" "}
                            Signup now{" "}
                          </Link>{" "}
                        </p>
                      </div> */}
                        </Form>
                      </div>
                    </Col>
                    <Col lg={6} className="bg-login-img"></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <img src={white_border} className="img-fluid" />
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
