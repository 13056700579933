import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { USER_SESSION, EDIT_PROFILE } from "./actionTypes"
import {
  userSessionSuccess,
  userSessionError,
  profileSuccess,
  profileError,
} from "./actions"

import { userSession, updateProfile } from "../../../services/authServices"

function* getUserSession() {
  try {
    const response = yield call(userSession)
    yield put(userSessionSuccess(response.data))
  } catch (error) {
    yield put(userSessionError(error))
  }
}

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(updateProfile, user)
    yield put(profileSuccess(response.data))
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(USER_SESSION, getUserSession)
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
