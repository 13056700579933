import { del, get, post, put } from "./AXIOS"
import {
  ACCESS_CODES,
  ADD_NEW_ACCESS_CODE,
  GET_ACCESS_CODE_DETAIL,
  DELETE_ACCESS_CODE,
  UPDATE_ACCESS_CODE,
  RESEND_EMAIL_CODE
} from "./CONSTANTS"

// get Access Codes
export const getAccessCodes = params => post(`${ACCESS_CODES}/country`, params)

// add Access Code
export const addNewAccessCode = access_code =>
  post(ADD_NEW_ACCESS_CODE, access_code)

// get Access Code Detail
export const getAccessCodeDetail = id => get(`${GET_ACCESS_CODE_DETAIL}/${id}`)

// delete Access Code Detail
export const deleteAccessCode = id => del(`${DELETE_ACCESS_CODE}/${id}`)

export const resendAccessCodeEmail = id => post(`${RESEND_EMAIL_CODE}/${id}`)

// update Access Code
export const updateAccessCode = (id, access_code) =>
  put(`${UPDATE_ACCESS_CODE}/${id}`, access_code)
