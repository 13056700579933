import React from 'react';
import { Button, Input } from 'reactstrap';

function AddCategory({addCategory, addCategoryName}) {
    return (
        <div>
            <div className='d-flex align-items-center'>
                Name:
                <Input
                    placeholder="Enter Category Name"
                    className='ms-2'
                    onChange={(e)=>addCategoryName(e)}
                />
            </div>
            <br />
            <div className="d-flex justify-content-center">
                <Button
                style={{
                    backgroundColor: "#000000",
                    color: "white",
                    border: "none",
                }}
                onClick={addCategory}
                >
                Add
                </Button>{" "}
            </div>
        </div>
    );
}

export default AddCategory;