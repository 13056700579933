import React, { useState, useEffect } from "react"
import ExistingPolicies from "./PolicyTable/ExistingPolicies.js"
import SearchBox from "components/Common/SearchBox"
import { ALL_LABELS, AUDIT, POLICIES, SSE } from "services/CONSTANTS"
import { get, post } from "../../services/AXIOS"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import FilterPolicies from "./PolicyTable/FilterPolicies"
import SortPolicies from "./PolicyTable/SortPolicies.js"
import CreatePolicy from "./PolicyTable/CreatePolicy.js"
import ExportTable from "./ExportTable.js"
import Pagination from "./Pagination.js"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
// import html2canvas from "html2canvas"
import { getCSV } from "./ExportCsv.js"
import { stages, organizationType } from "constants/global.js"
import LoadingSpinner from "./Loading-Spinner.jsx"
import { formatTimeAgo } from "helpers/utlities.js"
import { useSelector, useDispatch } from "react-redux"
import { addPolicyEvent } from "store/actions.js"

const COUNTRY_IDS = localStorage.getItem("countries_ids")
  ? JSON.parse(localStorage.getItem("countries_ids"))
  : []

function PolicyTableContainer({ component, stage, country, alert }) {
  //component = "landscape, tracker, directory",
  //[stage for tracker stage-> activeTab-> id & bg color]
  //country for policy landscaping country
  const dispatch = useDispatch()

  const [labels, setLabels] = useState([])
  const [policies, setPolicies] = useState()
  const [isLoading, setIsLoading] = useState(true)

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)

  //filter
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedLabels, setSelectedLabels] = useState([])
  const [selectedStages, setSelectedStages] = useState([])
  const [selectedOwners, setSelectedOwners] = useState([])
  const [selectedPolicyPartners, setSelectedPolicyPartners] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const [modalFilter, setModalFilter] = useState(false)
  const toggleFilter = () => setModalFilter(prevState => !prevState)

  //sort
  const [sortForm, setSortForm] = React.useState({ last_updated_at: "DESC" })
  const [modalSort, setModalSort] = useState(false)
  const toggleSort = () => setModalSort(prevState => !prevState)

  //search
  const [searchField, setSearchField] = useState("")

  useEffect(() => {
    loadLabels()
  }, [])

  useEffect(() => {
    loadPoliciesFiltered()
  }, [stage, country])

  const loadLabels = () => {
    get(ALL_LABELS)
      .then(data => setLabels(data))
      .catch(err => {
        alert("Data loading", false)
        console.error(err)
      })
  }

  //runs filter, order, search, pagination query on backend
  const loadPoliciesFiltered = (
    searchfield = searchField,
    offsetParam = offset,
    exportTable = false
  ) => {
    setIsLoading(true)
    let limit = 50
    if (exportTable) {
      limit = totalEntries
      offsetParam = 0
    }
    let stages = selectedStages.length
      ? selectedStages.map(stage => stage.value)
      : stage
      ? stage.id != "0"
        ? [Number(stage.id)]
        : [1, 2, 3, 4, 5, 6]
      : []
    let labels = selectedLabels.length
      ? selectedLabels.map(label => label.value)
      : []
    const owners = selectedOwners.length
      ? selectedOwners.map(owner => owner.value)
      : []
    const policyPartners = selectedPolicyPartners.length
      ? selectedPolicyPartners.map(partner => partner.value)
      : []

    const policyStatus = selectedStatus.length
      ? selectedStatus.map(status => status.label)
      : []
    post(`${POLICIES}?limit=${limit}&offset=${offsetParam}`, {
      component,
      searchfield,
      selectedCountries,
      labels,
      stages,
      sortForm,
      owners,
      policyPartners,
      country,
      policyStatus,
      //country for landscape
    })
      .then(data => {
        //check data on empty response without error i.e. else condition and does it need an alert - checked, need a if-else condition for data & no, doesn't need alert
        if (exportTable) {
          if (exportTable === "csv") {
            getCSV(component, data[0], stage)
          } else {
            generatePDF(data[0])
          }
          const folder =
            component === "directory"
              ? "policy"
              : component === "tracker"
              ? "E-tracker"
              : "Benchmarking"
          const trackerStage = stage
            ? stage?.id === "0"
              ? "Overview"
              : `in Stage ${stage?.id} ${stage?.stage.label}`
            : null
          post(AUDIT, {
            description: `Exported ${folder} directory ${
              trackerStage ? trackerStage : ""
            } as ${exportTable}`,
          })
        } else {
          if (data) {
            setPolicies(data[0])
            setTotalEntries(data[1])
          }
        }
      })
      .catch(err => {
        console.error(err)
        alert("Action", false)
      })
      .finally(() => setIsLoading(false))
  }

  //search
  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase())
    loadPoliciesFiltered(e.target.value.toLowerCase())
  }

  const applyFilter = () => {
    loadPoliciesFiltered()
    toggleFilter()
  }

  const applySort = () => {
    loadPoliciesFiltered()
    toggleSort()
  }

  //export to pdf
  // const generatePDF = () => {
  //   const input = document.getElementById("report")
  //   html2canvas(input, {
  //     logging: true,
  //     letterRendering: 1,
  //     useCors: true,
  //   }).then(canvas => {
  //     const imgWidth = 800
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width
  //     const imgData = canvas.toDataURL("image/png")
  //     const pdf = new jsPDF("l", "pt", "a4")
  //     pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
  //     //pdf.output('dataurlnewwindow');
  //     pdf.save(component + ".pdf")
  //   })
  // const report = new jsPDF('portrait','pt','a4');
  // report.html(document.querySelector('#report')).then(() => {
  //     report.save('report.pdf');
  // });
  //add width, height, icons readability or remove icons
  //}

  //pdf-new
  const generatePDF = policies => {
    const doc = new jsPDF("landscape", "mm")
    const totalWidth = doc.internal.pageSize.getWidth() - 20
    const columnWidths = [17.5, 10, 10, 10, 5, 10, 10, 5, 5, 17.5]
    const columnWidthsInUnits = columnWidths.map(
      width => (totalWidth * width) / 100
    )

    // It can parse html:
    // <table id="my-table"><!-- ... --></table>
    // autoTable(doc, { html: '#report1' })

    const head = []
    let body = []

    const makeTable = () => {
      const headValues = [
        "Policy Name",
        "Location",
        "Systems",
        (component === "directory" || stage?.id === "0") && "Progress",
        "Year",
        "Owners",
        "Partners",
        "Status",
        "Policy Score",
        "Updates & Emerging Outcomes",
      ]

      headValues.map(value => {
        value ? head.push(value) : null
      })

      body = policies.map(policy => {
        const row = []

        const indicators = policy.labels.map(label => label.label_name)

        const bodyValues = [
          policy.policy_name,
          policy.country.label,
          indicators,
          (component === "directory" || stage?.id === "0") &&
            stages[policy.stage_id],
          policy.year,
          policy.orgs
            .filter(org => org?.organization_type.id === organizationType.OWNER)
            .map(owner => owner.name),
          policy.orgs
            .filter(
              org => org?.organization_type.id === organizationType.PARTNER
            )
            .map(partner => partner.name),
          policy.policy_status,
          policy.policy_score,
          policy.policy_notes.map(note => note.description),
        ]

        bodyValues.map(value => {
          if (value) {
            row.push(value)
          }
        })
        return row
      })
    }

    makeTable()

    autoTable(doc, {
      head: [head],
      body: body,
      styles: { overflow: "linebreak" },
      columnStyles: {
        0: { cellWidth: columnWidthsInUnits[0] },
        1: { cellWidth: columnWidthsInUnits[1] },
        2: { cellWidth: columnWidthsInUnits[2] },
        3: { cellWidth: columnWidthsInUnits[3] },
        4: { cellWidth: columnWidthsInUnits[4] },
        5: { cellWidth: columnWidthsInUnits[5] },
        6: { cellWidth: columnWidthsInUnits[6] },
        7: { cellWidth: columnWidthsInUnits[7] },
      },
    })

    doc.save(component + ".pdf")
  }

  const checkForFilters = () => {
    if (
      selectedLabels.length ||
      selectedCountries.length ||
      selectedOwners.length ||
      selectedPolicyPartners.length ||
      selectedStages.length ||
      selectedStatus.length
    ) {
      return true
    } else {
      return false
    }
  }

  const [clearedFilters, setClearedFilters] = useState(false)

  const clearFilters = () => {
    setSelectedLabels([])
    setSelectedCountries([])
    setSelectedOwners([])
    setSelectedPolicyPartners([])
    setSelectedStatus([])
    setSelectedStages([])
    setClearedFilters(true)
    return true
  }

  useEffect(() => {
    loadPoliciesFiltered()
    setClearedFilters(false)
  }, [clearedFilters])

  //pagination
  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50
      setOffset(offsetParam)
      loadPoliciesFiltered(searchField, offsetParam)
      setCurrentPage(page)
    } else {
      console.error("page number should be greater than 0")
    }
  }
  const [listening, setListening] = useState(false)
  const [hasNewData, setHasNewData] = useState(false)
  const [updatedTime, setUpdatedTime] = useState(new Date())
  const [readableUpdatedTime, setReadableUpdatedTime] = useState(null)
  useEffect(() => {
    const interval = setInterval(() => {
      setReadableUpdatedTime(formatTimeAgo(updatedTime))
    }, 10 * 1_000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!listening) {
      const events = new EventSource(SSE)

      events.onmessage = event => {
        const parsedData = JSON.parse(event.data)
        const country_ids = JSON.parse(localStorage.getItem("countries_ids"))
        if (country_ids.includes(Number(parsedData?.country_id))) {
          dispatch(
            addPolicyEvent({ [parsedData?.policy_id]: parsedData?.event })
          )
          setHasNewData(true)
        }
      }
      setListening(true)
    }
  }, [listening])
  return (
    <div
      style={{
        paddingBlockStart: "3rem",
        position: "relative",
      }}
    >
      <Button
        style={{ position: "absolute", right: "10px", top: "10px" }}
        onClick={() => {
          clearFilters()
          goToPage(1)
          setUpdatedTime(new Date())
          setHasNewData(false)
        }}
        disabled={!hasNewData}
        color="primary"
      >
        Refresh
      </Button>
      <div
        className="d-flex justify-content-end align-items-center gap-1"
        style={{ paddingInlineEnd: "1rem", opacity: "0.5" }}
      >
        {updatedTime && (
          <>
            <i className="ph-timer" />
            <span>
              Updated{" "}
              {readableUpdatedTime
                ? readableUpdatedTime
                : formatTimeAgo(updatedTime)}
            </span>
          </>
        )}
      </div>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="d-flex flex-wrap">
          <SearchBox searchChange={searchChange} />
          <div>
            <Button
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={toggleFilter}
            >
              <i className="mdi mdi-filter-variant me-1"></i>
              Filter
            </Button>
            <Modal
              isOpen={modalFilter}
              toggle={toggleFilter}
              className="modal-lg"
            >
              <ModalHeader toggle={toggleFilter}>
                <i className="mdi mdi-filter-variant me-1"></i>
                Filters
              </ModalHeader>
              <ModalBody>
                <FilterPolicies
                  labels={labels}
                  handleCountries={setSelectedCountries}
                  handleLabels={setSelectedLabels}
                  handleStages={setSelectedStages}
                  handleOwners={setSelectedOwners}
                  handlePolicyPartners={setSelectedPolicyPartners}
                  selectedCountries={selectedCountries}
                  selectedLabels={selectedLabels}
                  selectedStages={selectedStages}
                  selectedOwners={selectedOwners}
                  selectedPolicyPartners={selectedPolicyPartners}
                  selectedStatus={selectedStatus}
                  handleStatus={setSelectedStatus}
                  applyFilter={applyFilter}
                  stage={stage}
                  component={component}
                />
              </ModalBody>
            </Modal>
          </div>
          <div>
            <Button
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={toggleSort}
            >
              <div>
                <i className="mdi mdi-sort me-1"></i>
                Sort
              </div>
            </Button>
            <Modal isOpen={modalSort} toggle={toggleSort}>
              <ModalHeader toggle={toggleSort}>
                <i className="mdi mdi-sort me-1"></i>Sort
              </ModalHeader>
              <ModalBody>
                <SortPolicies
                  applySort={applySort}
                  sortForm={sortForm}
                  handleSortChange={setSortForm}
                  component={component}
                />
              </ModalBody>
            </Modal>
          </div>
          <div>
            {checkForFilters() && (
              <Button
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "#f8f9fa",
                  color: "black",
                  margin: "15px 10px",
                  border: "none",
                  fontSize: "14px",
                }}
                onClick={clearFilters}
              >
                <i className="ph-x me-1"></i>
                Clear All Filters
              </Button>
            )}
          </div>
        </div>
        <div className="d-flex flex-wrap">
          <CreatePolicy component={component} alert={alert} />
          <ExportTable
            loadFilteredData={loadPoliciesFiltered}
            searchField={searchField}
          />
        </div>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <ExistingPolicies
            policies={policies}
            id="report"
            stage={stage}
            component={component}
            alert={alert}
          />
          <Pagination
            currentPage={currentPage}
            goToPage={goToPage}
            totalEntries={totalEntries}
          />
        </div>
      )}
    </div>
  )
}

export default PolicyTableContainer
