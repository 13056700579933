import React, { useState } from "react"
import Select from "react-select"

function Filter({
  selectedStagesFilters,
  selectedStatusFilters,
  applyFilters,
  toggleFilterModal,
  owners,
  selectedOwnersFilters,
}) {
  const [selectedStages, setSelectedStages] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const [selectedOwners, setSelectedOwners] = useState([])

  const stageOptions = [
    { value: "1", label: "Stage 1" },
    { value: "2", label: "Stage 2" },
    { value: "3", label: "Stage 3" },
    { value: "4", label: "Stage 4" },
    { value: "5", label: "Stage 5" },
    { value: "6", label: "Stage 6" },
    { value: "7", label: "Collection" },
    { value: "11", label: "Benchmarking" },
  ]

  const statusOptions = [
    { value: "1", label: "Published" },
    { value: "2", label: "Unpublished" },
    { value: "3", label: "Archived" },
  ]

  const ownerOptions = owners.map(owner => {
    return {
      value: owner.id,
      label: owner.first_name + " " + owner.last_name,
    }
  })
  const handleFilterApply = () => {
    const stages = selectedStages.map(stage => stage.value)
    const status = selectedStatus.map(status => status.label)
    const owners = selectedOwners.map(owner => owner.value)
    applyFilters(stages, status, owners)
    toggleFilterModal()
  }
  return (
    <div>
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-buffer light-icon me-1" />
          <h6 className="mb-0">Stage</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={stageOptions}
            defaultValue={
              selectedStages.length > 0
                ? stageOptions.filter(stage =>
                    selectedStagesFilters.includes(stage.value)
                  )
                : null
            }
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Multiple"
            onChange={setSelectedStages}
          />
        </div>
      </div>
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-buffer light-icon me-1" />
          <h6 className="mb-0">Status</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={statusOptions}
            defaultValue={
              selectedStatus.length > 0
                ? statusOptions.filter(status =>
                    selectedStatusFilters.includes(status.value)
                  )
                : null
            }
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Multiple"
            onChange={setSelectedStatus}
          />
        </div>
      </div>
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-account-outline light-icon me-1" />
          <h6 className="mb-0">Milestone Owner</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            defaultValue={
              selectedOwners.length > 0
                ? ownerOptions.filter(owner =>
                    selectedOwnersFilters.includes(owner.value)
                  )
                : null
            }
            options={ownerOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Multiple"
            onChange={setSelectedOwners}
          />
        </div>
      </div>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div
          className="btn btn-success common-button"
          style={{ width: "90%" }}
          onClick={handleFilterApply}
        >
          Apply
        </div>
      </div>
    </div>
  )
}

export default Filter
