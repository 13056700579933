import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { useSelector, useDispatch } from "react-redux"

import countriesFlag from "common/countriesFlag"
import { setCountryFlag } from "store/actions"
import { setCountryID } from "store/actions"

const LanguageDropdown = ({ title }) => {
  // Declare a new state variable, which we'll call "menu"
  const { countries } = useSelector(state => state.list)
  const { country, user } = useSelector(state => state.Profile)
  //useSelector(state => console.log(state))
  const [selectedCountry, setSelectedCountry] = useState("")
  const [countriesDropdown, setCountriesDropdown] = useState([])
  const [countryName, setCountryName] = useState("")
  const [menu, setMenu] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (countries.length > 0 && user.role) {
      if (
        user.role == "admin" &&
        countries.id != 0 &&
        title != "Policy Landscaping" &&
        title != "Tasks Management" &&
        title != "Benchmarking"
      ) {
        setCountriesDropdown([
          { id: 0, title: "all", label: "All Locations" },
          ...countries,
        ])
        // countries.unshift({ id: 0, title: "all", label: "All Countries" })
      } else {
        setCountriesDropdown(countries)
      }
    }
  }, [countries, user])

  useEffect(() => {
    if (countriesDropdown.length > 0) {
      if (
        (["Policy Landscaping", "Tasks Management", "Benchmarking", "My Tasks"].includes(title)) &&
        localStorage.getItem("COUNTRY") == "all"
      ) {
        localStorage.setItem("COUNTRY", countriesDropdown[0].title)
      }

      if (localStorage.getItem("COUNTRY")) {
        if (country == localStorage.getItem("COUNTRY")) {
          setCountryName(
            countriesDropdown
              .filter(c => c.title == country)
              .map(cn => cn.label)
          )
          setSelectedCountry(country)
        } else {
          setCountryName(
            countriesDropdown
              .filter(c => c.title == localStorage.getItem("COUNTRY"))
              .map(cn => cn.label)
          )
          setSelectedCountry(localStorage.getItem("COUNTRY"))
          dispatch(setCountryFlag(localStorage.getItem("COUNTRY")))
          let country_detail = countries.filter(function (c) {
            if (c.title == localStorage.getItem("COUNTRY")) return c.id
          })
          if (country_detail && country_detail[0]?.id) {
            dispatch(setCountryID(country_detail[0].id))
          }
        }
      } else {
        localStorage.setItem("COUNTRY", countriesDropdown[0].title)
        setCountryName(countriesDropdown[0].label)
        setSelectedCountry(countriesDropdown[0].title)
        dispatch(setCountryFlag(countriesDropdown[0].title))
        let country_detail = countries.filter(function (c) {
          if (c.title == countriesDropdown[0].title) return c.id
        })
        if (country_detail && country_detail[0]?.id) {
          dispatch(setCountryID(country_detail[0].id))
        }
      }
    }
  }, [countriesDropdown])

  const changeCountryAction = country => {
    localStorage.setItem("COUNTRY", country.title)
    setSelectedCountry(country.title)
    setCountryName(country.label)
    let country_detail = countries.filter(function (c) {
      if (c.title == country.title) return c.id
    })
    if (country_detail) {
      dispatch(setCountryID(country_detail[0]?.id || ""))
    }
    dispatch(setCountryFlag(country.title))
    //toggle()
  }

  // console.log(useSelector(state => state.Profile))

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    countriesDropdown &&
    countriesDropdown.length > 0 && (
      <>
        <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
          <DropdownToggle className="btn header-item " tag="button">
            <img
              src={get(countriesFlag, selectedCountry)}
              alt="AGRA"
              height="16"
              className="me-1"
            />
            {countryName}
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="language-switch dropdown-menu-end">
            {map(countriesDropdown, country => (
              <DropdownItem
                key={country.title}
                onClick={() => changeCountryAction(country)}
                className={`notify-item ${
                  selectedCountry === country.title ? "active" : "none"
                }`}
              >
                <img
                  src={get(countriesFlag, country.title)}
                  alt="AGRA"
                  className="me-1"
                  height="12"
                />
                <span className="align-middle">{country.label}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </>
    )
  )
}

export default LanguageDropdown
