import React from 'react';
import Select from 'react-select';
import { EnumStage } from 'constants/global';

function FilterTasks({milestones, owners, handleMilestones, selectedMilestones, handleStages, handleOwners, selectedStages ,applyFilter, selectedOwners, selectedStatuses, handleStatuses}) {

    // const { countries } = useSelector(
    //     state => state.list
    // )

    // const countryOptions = countries.map((country)=>{
    //     return (
    //         {value: country.id, label: country.label }
    //     )
    // });

    let stageOptions = [
        {value: '1', label: 'Stage 1'},
        {value: '2', label: 'Stage 2'},
        {value: '3', label: 'Stage 3'},
        {value: '4', label: 'Stage 4'},
        {value: '5', label: 'Stage 5'},
        {value: '6', label: 'Stage 6'},
        {value: EnumStage.COLLECTION, label: 'Collection'},
        {value: EnumStage.BENCHMARKING, label: 'Benchmarking'}
    ]

    const statusOptions = [
        {value: '1', label: 'PENDING', color:"brown"},
        {value: '2', label: 'IN PROGRESS', color:"lightgreen"},
        {value: '3', label: 'COMPLETE', color:"green"},
        {value: '4', label: 'PAST DEADLINE', color:"red"},
        {value: '5', label: 'OVERRIDE', color:"orange"},
    ]

    const milestoneOptions = milestones?.map((milestone)=>{
        return({
            value: milestone.id, 
            label: milestone.name
        })
    })

    return (
        <div>
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='bx bx-world light-icon me-1'/> 
                    <h6 className='mb-0'>Milestone</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        defaultValue={selectedMilestones}
                        options={milestoneOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleMilestones}
                    />
                </div>
            </div>
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='mdi mdi-buffer light-icon me-1'/> 
                    <h6 className='mb-0'>Stage</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        options={stageOptions}
                        defaultValue={selectedStages}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleStages}
                    />
                </div>
            </div>
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='mdi mdi-account-outline light-icon me-1'/> 
                    <h6 className='mb-0'>Task Owner</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        defaultValue={selectedOwners} 
                        options={owners}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleOwners} 
                    />
                </div>
            </div>
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='mdi mdi-account-multiple-outline light-icon me-1'/> 
                    <h6 className='mb-0'>Status</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        defaultValue={selectedStatuses} 
                        options={statusOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleStatuses}
                    />
                </div>
            </div>
            <div className='d-flex' style={{justifyContent: "center"}}>
                <div 
                    className="btn btn-success common-button" 
                    style={{width: "90%"}}
                    onClick={applyFilter}
                >
                    Apply
                </div>
            </div>
        </div>
    );
}

export default FilterTasks;