import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { formateDate } from "helpers/utlities"
const toLowerCase1 = str => {
  return str === "" || str === undefined ? "" : str.toLowerCase()
}

const Pdate = cell => {
  return cell.value ? cell.value : ""
}

const Ddate = cell => {
  return cell.value ? formateDate(cell.value) : ""
}

const Name = cell => {
  return cell.value ? cell.value : ""
}

const Idno = cell => {
  return cell.value ? cell.value : ""
}

const Budget = cell => {
  return cell.value ? cell.value : ""
}

export { Pdate, Ddate, Name, Idno, Budget }
