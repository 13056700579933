export const Role = {
  c_admin: "Country Admin",
  user: "User",
  admin: "Super Admin"
}

export const accountStatus = {
  active: "Active",
  suspended: "Suspended",
  archived: "Archived"
}

export const stages = {
  1: "Stage 1: Initiation",
  2: "Stage 2: Development",
  3: "Stage 3: Validation",
  4: "Stage 4: Approval",
  5: "Stage 5: Legislation",
  6: "Stage 6: Implementation",
  7: "Collection",
  8: "Validation",
  10: "Benchmarking",
}

export const EnumStage = {
  IMPLEMENTATION: 6,
  COLLECTION: 7,
  VALIDATION: 8,
  BENCHMARKING: 10,
}

export const organizationType = {
  PARTNER: 1,
  OWNER: 2,
  OTHER: 3,
}

export const Country = {
  KE: "Kenya",
  ML: "Mali",
  BF: "Burkina Faso",
  ET: "Ethiopia",
  GH: "Ghana",
  MW: "Malawi",
  MZ: "Mozambique",
  NG: "Nigeria",
  RW: "Rwanda",
  TZ: "Tanzania",
  UG: "Uganda",
  EAC: "EAC",
}

export const ETRACKER_STAGES_OBJ = {
  1: "1. Initiation",
  2: "2. Development",
  3: "3. Validation",
  4: "4. Approval",
  5: "5. Legislation",
  6: "6. Implementation",
}
// export const EnumStatus = {
//   "new" : 'NEW',
//   "pending" : 'PENDING',
//   "inprogress" : 'IN PROGRESS',
//   "waitingForApproval" : 'WAITING FOR APPROVAL',
//   "complete" : 'COMPLETE',
//   "override" : 'OVERRIDE',
//   "pastDeadline" : 'PAST DEADLINE',
//   "archived" : 'ARCHIVED',
// }

export const AUDITROLES = {
  c_admin: "Country Admin",
  cron_bot: "Cron Bot",
  admin: "Admin",
}

export const COUNTRIES = [
  { code: "KE", name: "Kenya" },
  { code: "ML", name: "Mali" },
  { code: "BF", name: "Burkina Faso" },
  { code: "ET", name: "Ethiopia" },
  { code: "GH", name: "Ghana" },
  { code: "MW", name: "Malawi" },
  { code: "MZ", name: "Mozambique" },
  { code: "NG", name: "Nigeria" },
  { code: "RW", name: "Rwanda" },
  { code: "TZ", name: "Tanzania" },
  { code: "UG", name: "Uganda" },
  { code: "EAC", name: "EAC" },
]

export const ETRACKER_STAGES = [
  { id: "1", name: "1. Initiation" },
  { id: "2", name: "2. Development" },
  { id: "3", name: "3: Validation" },
  { id: "4", name: "4: Approval" },
  { id: "5", name: "5: Legislation" },
  { id: "6", name: "6: Implementation" },
]

export const LANDSCAPING_STAGES = [
  { id: 7, name: "1. Collection" },
  { id: 8, name: "2. Validation" },
  { id: 9, name: "3. Mapping" },
]

export const BENCHMARKING_STAGES = [{ id: 10, name: "1. Benchmarking" }]
