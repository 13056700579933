import bfFlag from "../assets/images/flags/BF.png"
import etFlag from "../assets/images/flags/ET.png"
import ghFlag from "../assets/images/flags/GH.png"
import keFlag from "../assets/images/flags/KE.png"
import mlFlag from "../assets/images/flags/ML.png"
import mwFlag from "../assets/images/flags/MW.png"
import mzFlag from "../assets/images/flags/MZ.png"
import ngFlag from "../assets/images/flags/NG.png"
import rwFlag from "../assets/images/flags/RW.png"
import tzFlag from "../assets/images/flags/TZ.png"
import ugFlag from "../assets/images/flags/UG.png"
import zaFlag from "../assets/images/flags/ZA.png"
import eacFlag from "../assets/images/flags/EAC.png"
import allFlag from "../assets/images/flags/all.png"

const countriesFlag = {
  all: allFlag,
  ZA: zaFlag,
  UG: ugFlag,
  TZ: tzFlag,
  RW: rwFlag,
  NG: ngFlag,
  MZ: mzFlag,
  MW: mwFlag,
  ML: mlFlag,
  KE: keFlag,
  GH: ghFlag,
  ET: etFlag,
  BF: bfFlag,
  EAC: eacFlag,
}

export default countriesFlag
