import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import Metrics from "./Metrics"
import { getComparisonInsights } from "services/intelligence/overviewServices"
import { listAllSystems } from "services/listServices"
import { useQueries, useQuery } from "@tanstack/react-query"
import Insights from "./Insigths"
import DimensionCards from "./Dimension-Cards"

// const payloadWithTwoDimensions = {
//   dimension: [],
//   dimension_type: [],
//   metric: "",
//   dimension_2: [],
//   dimension_type_2: [],
// }
// const payloadWithOneDimension = {
//   dimension: [],
//   dimension_type: [],
//   metric: "",
// }

const loadInsights = async body => {
  try {
    const data = await getComparisonInsights(body)
    return data
  } catch (err) {
    console.error(err)
    return err
  }
}
const loadAllSystems = async () => {
  try {
    const data = await listAllSystems()
    return data.map(system => system.label_name)
  } catch (err) {
    console.error(err)
    return []
  }
}
export default function ETrackerComparison() {
  const [policyScore, setPolicyScore] = useState({
    selected: true,
    value: "policy_score",
  })
  const [policyNum, setPolicyNum] = useState({
    selected: true,
    value: "number_of_policies",
  })
  const [policyWins, setPolicyWins] = useState({
    selected: false,
    value: "policy_win",
  })
  const [policyDeadline, setPolicyDeadline] = useState({
    selected: false,
    value: "days_past_deadline",
  })
  const [selectedMetrics, setSelectedMetrics] = useState([])
  //   const [dimension1, setDimension1] = useState(["indicator", "agriculture"])
  // const [dimension1A, setDimension1A] = useState("indicator")
  // const [dimension1AType, setDimension1AType] = useState("agriculture")
  // const [dimension1B, setDimension1B] = useState(null)
  // const [dimension1BType, setDimension1BType] = useState(null)
  // const [dimension2A, setDimension2A] = useState("indicator")
  // const [dimension2AType, setDimension2AType] = useState("water")
  // const [dimension2B, setDimension2B] = useState(null)
  // const [dimension2BType, setDimension2BType] = useState(null)
  const [dimensions, setDimensions] = useState([
    {
      type1: "agriculture",
      value1: "indicator",
      type2: null,
      value2: null,
    },
    {
      type1: "BF",
      value1: "country_label",
      type2: null,
      value2: null,
    },
  ])

  const updateDimensions = (type, value, updateType) => {
    const tempDimensions = [...dimensions]
    if (updateType === "1A") {
      tempDimensions[0].type1 = type
      tempDimensions[0].value1 = value
    } else if (updateType === "1B") {
      tempDimensions[0].type2 = type
      tempDimensions[0].value2 = value
    } else if (updateType === "2A") {
      tempDimensions[1].type1 = type
      tempDimensions[1].value1 = value
    } else if (updateType === "2B") {
      tempDimensions[1].type2 = type
      tempDimensions[1].value2 = value
    } else if (updateType === "3A") {
      tempDimensions[2].type1 = type
      tempDimensions[2].value1 = value
    } else if (updateType === "3B") {
      tempDimensions[2].type2 = type
      tempDimensions[2].value2 = value
    }
    setDimensions(tempDimensions)
  }
  const deleteDimensionCard = idx =>
    setDimensions(prev => prev.filter((_, index) => index !== idx))
  const addDimensionCard = () =>
    setDimensions(prev => [
      ...prev,
      {
        type1: "GH",
        value1: "country_label",
        type2: null,
        value2: null,
      },
    ])
  useEffect(() => {
    setSelectedMetrics(
      [policyDeadline, policyNum, policyScore, policyWins].filter(
        metric => !!metric.selected
      )
    )
  }, [policyDeadline, policyNum, policyScore, policyWins])

  const { data: allSystems } = useQuery({
    queryKey: ["All Systems"],
    queryFn: () => loadAllSystems(),
    staleTime: 60 * 60 * 1_000,
  })

  const firstComparisonResults = useQueries({
    queries: [policyDeadline, policyNum, policyScore, policyWins]
      .filter(metric => !!metric.selected)
      .map(metric => ({
        queryKey: [
          `First Card ${metric.value}`,
          {
            dimension: dimensions[0],
            policyDeadline,
            policyNum,
            policyScore,
            policyWins,
          },
        ],
        queryFn: () =>
          !!dimensions[0].type2
            ? loadInsights({
                dimension: [dimensions[0].value1],
                dimension_type: [dimensions[0].type1],
                metric: metric.value,
                dimension_2: [dimensions[0].value2],
                dimension_type_2: [dimensions[0].type2],
              })
            : loadInsights({
                dimension: [dimensions[0].value1],
                dimension_type: [dimensions[0].type1],
                metric: metric.value,
              }),
        staleTime: 5 * 60 * 1_000,
      })),
  })
  const secondComparisonResults = useQueries({
    queries: [policyDeadline, policyNum, policyScore, policyWins]
      .filter(metric => !!metric.selected)
      .map(metric => ({
        queryKey: [
          `Second Card ${metric.value}`,
          {
            dimension: dimensions[1],
            policyDeadline,
            policyNum,
            policyScore,
            policyWins,
          },
        ],
        queryFn: () =>
          !!dimensions[1].type2
            ? loadInsights({
                dimension: [dimensions[1].value1],
                dimension_type: [dimensions[1].type1],
                metric: metric.value,
                dimension_2: [dimensions[1].value2],
                dimension_type_2: [dimensions[1].type2],
              })
            : loadInsights({
                dimension: [dimensions[1].value1],
                dimension_type: [dimensions[1].type1],
                metric: metric.value,
              }),
        staleTime: 5 * 60 * 1_000,
        enabled: dimensions.length >= 2,
      })),
  })
  const thirdComparisonResults = useQueries({
    queries: [policyDeadline, policyNum, policyScore, policyWins]
      .filter(metric => !!metric.selected)
      .map(metric => ({
        queryKey: [
          `Third Card ${metric.value}`,
          {
            dimension: dimensions[2],
            policyDeadline,
            policyNum,
            policyScore,
            policyWins,
          },
        ],
        queryFn: () =>
          !!dimensions[2].type2
            ? loadInsights({
                dimension: [dimensions[2].value1],
                dimension_type: [dimensions[2].type1],
                metric: metric.value,
                dimension_2: [dimensions[2].value2],
                dimension_type_2: [dimensions[2].type2],
              })
            : loadInsights({
                dimension: [dimensions[2].value1],
                dimension_type: [dimensions[2].type1],
                metric: metric.value,
              }),
        staleTime: 5 * 60 * 1_000,
        enabled: dimensions.length === 3,
      })),
  })
  const handlePolicyScoreToggle = bool =>
    setPolicyScore(prev => ({ ...prev, selected: bool }))
  const handlePolicyNumToggle = bool =>
    setPolicyNum(prev => ({ ...prev, selected: bool }))
  const handlePolicyWinsToggle = bool =>
    setPolicyWins(prev => ({ ...prev, selected: bool }))
  const handlePolicyDeadlineToggle = bool =>
    setPolicyDeadline(prev => ({ ...prev, selected: bool }))
  return (
    <StyledComparisonPage>
      <header>Compare Policy Dimensions</header>
      <Metrics
        hasUserSelectedPolicyDeadline={policyDeadline.selected}
        hasUserSelectedPolicyNum={policyNum.selected}
        hasUserSelectedPolicyWins={policyWins.selected}
        hasUserSelectedPolicyScore={policyScore.selected}
        setHasUserSelectedPolicyDeadline={handlePolicyDeadlineToggle}
        setHasUserSelectedPolicyNum={handlePolicyNumToggle}
        setHasUserSelectedPolicyScore={handlePolicyScoreToggle}
        setHasUserSelectedPolicyWins={handlePolicyWinsToggle}
      />
      <DimensionCards
        dimensions={dimensions}
        systems={allSystems}
        updateDimensions={updateDimensions}
        deleteDimensionCard={deleteDimensionCard}
        addDimensionCard={addDimensionCard}
      />
      <Insights
        selectedMetrics={selectedMetrics}
        dimensions={dimensions}
        firstComparison={firstComparisonResults}
        secondComparison={secondComparisonResults}
        thirdComparison={thirdComparisonResults}
      />
    </StyledComparisonPage>
  )
}

const StyledComparisonPage = styled.div`
  padding: 2em;

  & > header {
    font-size: 24px;
    font-weight: 500;
  }
`
