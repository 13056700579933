import React from "react"
import { Link } from "react-router-dom"
import { size, map } from "lodash"

const Name = cell => {
  return cell.value ? cell.value : ""
}

const Tags = (cell) => {
  return (
    <>
      {map(
        cell.value,
        (tag, index) => (
          //index < 2 && (
          <Link
            to="#"
            className="badge font-size-11 m-1"
            key={"_skill_" + cell.value + index}
            style={{background: cell.bg_color}}
          >
            {tag}
          </Link>
        )
        // )
      )}
    </>
  )
}

export { Name, Tags }
