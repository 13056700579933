import React from 'react';
import './map.css';

function BF(props) {
    return (
            <g id="Burkina_Faso"
                transform= "scale(0.16 0.16) translate(-400, -300)"
                onClick={props.clickBase}
            >
            <path className="st0" d="M766.906,333.637l-3.345,9.162l-1.304,2.462l-3.565,4.009l-0.997,2.682l-0.058,1.654l0.077,1.037l-0.335,0.9
                l-1.284,1.253l-4.965,2.794l-6.776,5.567l-12.2,12.506l-0.433,0.444l-2.54,1.22l-3.278-0.257l-3.92-1.526l-2.406-1.137
                l-1.131-0.166l-0.891,0.596l-1.61-0.489l-1.773,0.367l-3.642,1.474l-1.754,0.127l-4.102-0.293l-1.447,0.46l-1.773,0.858
                l-4.284,0.912l-1.581,0.846l-1.812,0.623l-1.946-3.63l-1.61,0.098l0.163-0.626l0.211-1.33l0.192-0.626l-8.722-0.707l-1.792-0.372
                l-1.514-0.858l-0.537,1.359l-0.949,0.523l-0.037,0.012l-1.123,0.369l-1.208,0.902l-0.767,1.127l-0.115,0.794l0.882,2.017
                l-1.524,0.946l-2.099,0.926l-1.85,1.21l-0.805,1.792l0.537,1.62l0.901,1.295l0.182,1.215l-1.62,1.385l-0.642-0.78l-0.661-0.264
                l-0.652,0.264l-0.633,0.78l-0.585-1.019l-1.083-1.239l-1.035-0.521l-0.46,1.136l0.201,0.75l0.911,0.906l0.211,0.928l-0.144,0.736
                l-1.179,2.194l-3.077-1.473l-1.505-0.432l-1.754-0.088l-0.805,0.186l-0.479,0.296l-0.489,0.24l-0.815-0.012l0.125-0.484l-0.7-1.078
                l-0.891-0.714l-0.968,1.483l-0.958,0.921l-0.46,1.185l1.735,2.868l0.364,3.222l0.479,1.629l-0.958-0.337l-1.85-0.887l-0.987-0.01
                l-0.412,0.484l-0.633,1.722l-0.537,0.381l-3.057,0.694l-0.479,0.21l0.096,1.426l0.345-0.129l0.585-0.53l0.872,0.203l0.105,0.386
                l-0.163,1.175l0.057,0.435l0.268,0.186l0.882,0.405l0.172-0.005l0.192,1.123l0.019,1.069l0.153,1.028l0.585,1.004l1.054,1.091
                l-0.105,1.516l-0.307,0.427l-0.68-0.637l-0.661-0.94l-0.249-0.464l-0.939-0.327l-2.856-0.315l-0.364,0.1l-1.006,0.513l-0.585,0.029
                l-0.163-0.217l-0.489-0.923l-0.268-0.205l-0.498-0.205l-0.47-0.442l-0.623-0.444l-2.032-0.325l-0.431-0.154l-0.412,0.166
                l-0.614,0.84l-0.546,1.14l-0.786,1.199l-0.326,0.22l-0.106,0.955l0.048,1.06l0.326,0.859l0.738,0.352l0.537,0.508l0.086,1.108
                l-0.23,1.103l-0.393,0.505l-1.658-0.015l-1.246-0.171l-2.828-1.572l-12.048,0.224l-8.923,0.173l-5.818,1.13l-11.588,4.093
                l1.121-4.01l-1.495-0.378l0.326-0.376l0.134-0.386l0.067-0.393l0.125-0.42l-0.048-0.298l-0.125-0.215l-0.201-0.134l-10.371-2.135
                l-8.089-1.665l-21.141-4.344l-11.38-2.339l-4.817-0.993l-4.734-0.976l-12.573-2.643l-0.259,1.526l0.364,0.887l0.208,0.852
                l-0.726,1.404l-1.177,1.15l-4.648,3.416l-1.668-2.002l-1.759-1.423h-1.792l-1.778,2.271l-0.431,1.375l-0.206,2.144l-0.503,1.001
                l-1.097,0.896l-2.439,1.164l-0.872,1.081v0.015l-0.23,0.239l-0.278,0.21l-0.302,0.161l-0.326,0.105l-3.613-0.125l-1.926,0.208
                l-1.332,0.503l-0.858,1.574l-0.676,2.319l-0.939,2.133l-1.625,1.008l-4.452-4.31l0.278-0.659l0.695-0.749l0.398-0.757l-0.551-0.661
                l-0.863-0.078l-0.647,0.239l-0.412-0.088l-0.125-1.067l-0.651-0.012l-10.568-0.193v-0.024l-0.479-0.676l-0.522-0.21l-0.546,0.229
                l-0.532,0.657l-1.984,0.334l-2.075,2.294l-1.615,0.227l0.618-1.291l-0.968,0.039l-0.474-0.303l-0.647-1.277l-0.704-0.493
                l-0.781-0.159l-12.786-1.02l-2.631,0.41l-0.819,1.438l-11.176-0.107l-11.799-0.117l-2.324-0.43l-3.079-1.208l-0.93-0.365
                l-1.157-0.454l-11.717-0.366l-1.615,0.508l-0.666,1.658l-1.083,0.618l-14.411,0.142l-20.291,0.2l-20.866,0.21l-22.433,0.222
                l-18.101,0.185l-8.703,0.087l-2.032,0.02l-0.038-1.003l-7.996-0.147l0.129,0.547l-0.256,0.671l0.556,1.804l1.016,1.674l0.556,1.809
                l-0.522,2.162l-1.248,1.384l-1.479,1.139l-1.246,1.442l-0.685,1.966l-1.023,4.379l-1.56,2.556l-0.335,2.156l-0.508,0.895
                l-0.992,0.988l-0.283,0.566l0.219,0.955l0.097,0.423h0.182l0.4,0.076l0.427,0.205l0.268,0.42l0.002,0.61l-0.206,0.417l-0.261,0.361
                l-0.565,1.548l-1.244,2.253l-0.264,0.934l0.393,1.802l2.78,4.963l0.225,0.929l0.153,2.013l0.249,0.921l0.429,0.553l1.253,1.255
                l0.273,0.443l0.283,1.842l0.726,1.192l3.257,2.885l1.141,0.585l1.51,0.256l2.267,0.059l0.359,0.404l-0.402,0.884l-0.848,0.887
                l-1.016,0.402l-1.201,0.097l-0.899,0.322l-0.714,0.577l-0.661,0.877l-0.633,1.379l-0.465,1.922v1.978l0.752,1.537l0.81,0.356
                l0.949,0.107l0.791,0.195l0.333,0.631l0.225,1.074l0.601,0.224l0.81-0.024l0.891,0.336l0.925,0.577l0.604,0.283l0.316,0.502
                l0.053,1.21l-0.307,1.254l-0.69,0.83l-1.879,1.483l-1.212,1.938l-0.043,1.877l0.968,4.255v8.07l0.455,1.908l2.104,3.178
                l0.604,1.693l-0.11,2.413l-0.863,4.274l0.402,2.374l2.204,3.573l0.264,0.922l-0.589,0.958l-3.206,3.601l-1.553,3.144l-0.345,1.969
                l0.633,1.952l0.757,0.848l0.508,0.087l0.518-0.058l0.81,0.408l0.47,0.761l0.115,0.861l0.297,0.863l0.706,0.551l0.301,0.234
                l-1.184,1.028l-0.796,1.378l-0.46,1.538l-0.144,1.514l0.163,1.531l0.479,0.967l3.321,3.045l3.345,4.245l-2.899,2.774l-2.267,4.805
                l-1.74,1.525l-2.219-0.172l-2.116-1.635l-9.249-12.375l-4.613-9.018l-1.663-4.38l-1.129-2.188l-1.454-1.481l-1.759-0.418
                l-3.192,0.946l-1.672-0.08l-1.62-1.359l-0.911-2.191l-1.162-4.532l-1.857-2.281l-2.149-0.486l-2.082-0.044l-1.618-0.961
                l-0.407-1.882l0.201-2.221l-0.031-1.815l-1.131-0.696l-1.435,1.026l-3.137,4.485l-1.28,1.282l-2.214,0.219l-0.935-1.223
                l-0.163-1.924l0.077-1.878l-0.412-0.642l-0.958,0.379l-1.088,0.679l-0.844,0.292l-0.99-0.518l-1.627-1.506l-1.169-0.448
                l-14.588-1.214l-5.545,0.749l-1.584-0.336l-0.99-0.706l-0.606-0.766l-0.707-0.679l-1.251-0.409l-0.314,0.219l-0.86,1.068
                l-0.494,0.287l-0.585-0.117l-1.21-0.691l-0.692-0.097l-4.505,0.56l-1.351,0.428l-0.939,0.625l-1.486,1.603l-0.788,0.591
                l-1.783,0.535l-6.141,0.117l-0.178,0.053l-1.713,0.509l-3.491,2.272l-1.989,0.506l-1.378,0.737l-0.391,1.539l-0.59,1.32
                l-2.974,0.044l-0.769,0.253l-1.423,0.688l-0.096,0.258l-0.034,0.491l-0.182,0.445l-0.575,0.126l-0.542-0.17l-0.968-0.537
                l-5.276-1.564l-1.596,0.199l-1.673,1.267l-2.66,3.752l-1.711,0.951l-3.494,0.452l-1.598,0.576l-1.234,1.121l0.412,0.083
                l1.083,0.365l-2.808,2.956l-0.51,0.858l-0.014,0.829l0.276,2.222l-0.232,1.184l-1.2,2.36l-0.607,0.719l-0.007,0.008l-2.516-1.047
                l-1.423-0.389l-0.278,1.485l0.019,1.543l-0.312,0.848l-1.27-0.608l-0.623-1.023l-0.796-3.089l-0.829-1.373l-2.25-0.54l-3.324,0.16
                l-3.014-0.306l-1.318-1.945l0.211-1.724l0.91-2.436l0.206-1.619l-0.218-0.462l-0.556-0.287l-0.726-0.146h-1.685l-0.292,0.18
                l-0.074,0.37l-0.292,0.637l-0.455,1.293l-0.043,1.267l-0.35,0.972l-1.347,0.382l-1.076-0.386l-1.335-2.005l-1.049-0.878
                l-1.198,1.252l-1.093,0.579l-1.148,0.158l-1.363-0.005l0.101,0.238l-0.328,0.559l-0.609,0.635l-0.75,0.496l-0.757,0.331
                l-0.067-0.073l0.024-0.365l-0.47-0.535l-1.251-0.518l-0.498-0.394l-0.201-0.722l-0.134-0.992l-0.345-0.615l-1.074-0.965
                l-0.522-0.671l-0.307-0.508l-0.465-0.331l-0.956-0.126l-0.923,0.112l-0.328,0.316l0.156,0.435l0.527,0.481l-1.409,0.267
                l-1.227-1.977l-1.229,0.365l-0.266-0.756l-0.177-0.37l-0.824-0.742v-0.7l0.901,0.063l0.788-0.117l1.483-0.532l-0.882-1.583
                l-0.455-2.135l-0.755-1.413l-1.771,0.571l-0.623-1.931l-1.316-1.175l-1.843-0.589l-2.171-0.161l-0.771-0.197l-0.319-0.443
                l-0.419-0.443l-1.054-0.202l-0.714,0.431l-0.489,0.849l-0.525,0.613l-0.803-0.287l-2.727-2.544l-0.115-1.068l1.332-3.861
                l-0.173-0.596l-1.011-2.204l-1.04-1.365l-0.415-0.954l1.965-0.07l0.352-1.273l-0.642-1.725l-1.038-1.433l-1.124-0.728l-1.22-0.584
                l-0.994-0.903l-0.47-1.701l-0.923,0.789l-1.086,0.47l-1.03-0.151l-0.755-1.107l1.263-0.586l-0.604-0.19l-0.534-0.253l-0.609-0.19
                l-0.779-0.01l0.841-0.781l2.173-0.757l-0.173-0.74l-2.343-1.551l-0.556-0.962l0.628-1.699l-2.482,0.105l0.053-1.025l0.793-1.704
                l-0.261-1.936l-0.271,0.623l-0.424,0.611l-0.551,0.477l-0.654,0.219L70.7,493.19l-0.105-0.419l0.352-0.548l0.242-0.511v-5.177
                l-0.685-1.403l-0.064-0.042l-1.465-0.971l-1.519-0.127l-0.69,1.254l-0.829,0.093l-4.558-2.696l-1.481,0.402l-4.289-0.636
                l-1.562-0.059l-0.695,0.436l-0.47,0.653l-0.585,0.587l-1.074,0.253l-1.059,0.034l-0.489-0.083l-0.407-0.273l-0.479-0.214
                l-0.345,0.365l-0.35,0.531l-0.436,0.287l-1.874-0.073l-1.557-0.385l-1.27-0.658l-0.997-0.879l-0.288-0.572l-0.316-0.843
                l-0.403-0.767l-0.551-0.331l-0.858-0.039l-0.642-0.115l-1.318-0.487l-0.105-1.639l-1.131-2.378l-1.634-2.134l-1.62-0.936
                l0.508-1.367l0.752-4.805l0.355-0.777l2.181-3.41l1.256-4.953l-0.273-0.9l-0.695-0.648l0.105-1.446l0.642-1.514l0.92-0.846
                l-1.423-0.549l-0.115-0.795l-0.46-1.548l-0.048-0.851l0.887-5.063l0.474-1.397l0.628-0.695l0.762-0.371l0.604-0.446l0.134-0.932
                l-0.029-0.795l1.289-5.447l-0.34-1.154l-1.107-1.991l-1.72-6.601l-1.256-2.612l-2.104,0.503l0.575-2.456l-0.927-4.892l-0.161-0.847
                l0.288-1.487l1.342-0.891l9.24-1.189l1.586-0.479l1.553-0.674l2.204-1.387l0.944-1.321l0.57-3.854l0.877-2.181l2.919-2.961
                l1.15-1.974l0.177-1.527l-0.642-9.863l0.407-1.718l1.419-1.229l2.017-0.975l0.752-0.824l-0.081-1.225l-0.503-2.164l-0.005-7.832
                l-0.858-3.334l-3.297-2.661l-1.045-0.157l-0.949,0.122l-0.834-0.186l-0.709-1.062l-0.019-1.076l0.513-1.027l0.695-0.988
                l0.513-0.959l0.796-3.7l0.196-2.08l-0.091-1.664l-0.704-1.884l-1.097-1.302l-1.466-0.93l-1.807-0.788l-0.963-0.294l-0.8-0.059
                l-1.61,0.328l-0.359-0.397l-2.401-1.415l-0.517-0.201l-0.575-1.067l-0.014-0.47l0.594-0.181l1.265-0.181l0.407,0.147l1.141,0.813
                l0.532,0.196l0.494-0.152l0.695-0.744l0.302-0.201l3.225-0.539l0.388-0.181l0.35-0.338l0.422-0.299l0.589-0.029l0.623,0.299
                l0.503,0.387l0.513,0.255l0.681-0.093l0.642-0.534l1.855-2.595l3.594-2.943l2.463-2.787l1.399-1.21l2.859-0.896l3.065-1.861
                l1.356-0.617l1.442-0.118l3.659,0.118l1.694-0.191l1.052,0.059l0.971-0.245l0.901-0.505l0.841-0.73l2.013-0.838l2.01,0.186
                l1.984,0.475l1.922,0.02l3.961-1.436l1.948,0.034l1.802,1.558l1.603-0.524l2.619-0.456l1.335-0.804l0.733-0.96l0.621-1.143
                l0.663-1.219l0.975-0.843l0.982-0.235l1.883,0.23l0.939-0.064l1.843-0.892l0.091-0.236l0.376-0.979l0.187-1.524l0.978-1.853
                l3.321-1.897l2.789-0.128l0.906-1.005l-2.324-4.53l8.171-6.782l0.359-0.392l0.12-0.569l-0.163-1.069l0.326-1.486l-0.019-0.765
                l0.235-0.652l1.018-0.628l1.363-0.083l0.911,0.751l0.728,1.03l0.834,0.731l2.753-0.544l-0.855-4.067l-3.364-8.072l0.738-2.292
                l1.581-1.919l1.764-1.738l1.313-1.757l0.609-1.306l-0.034-0.358l-0.501-0.329l-0.812-1.232l-1.955-4.449l-1.538-1.527l-4.03-3.133
                l-0.815-1.572l0.563-4.932l0.328-0.26l0.628,0.157l0.98-0.141l2.7-1.062l3.081-0.774l3.077-0.231l2.727,0.59l2.645,1.594
                l1.27,0.368l1.941-0.241l1.73-0.594l1.129-0.761l0.139-0.094l0.913-1.182l1.934-6.165l0.129-1.143l-1.184-4.311l0.309-5.398
                l-0.743-2.636l-1.718-2.188l-2.696-2.311l-1.188-1.64l-2.473-5.463l-2.686-2.495l-0.53-0.859v-1.166l0.479-0.839l0.585-0.778
                l1.164-3.167l1.562-0.667l5.938,0.293l1.054-0.298l0.652-0.842l-0.208-0.829l-1.965-1.105l-0.64-0.893l0.426-1.425l1.366-1.686
                l1.68-1.529l1.406-0.968l0.896-0.163l0.87,0.177l0.889,0.044l1.006-0.551l1.754-2.044l1.44-2.31l1.57-3.879l0.963-1.731l1.55-1.458
                l0.764-0.394l2.482-0.655l0.839-0.52l1.419-1.628l1.129-0.404l0.99-0.357l1.404-0.917l1.234-1.096l0.491-0.889l-0.728-0.517
                l-1.184-0.081l-0.532-0.409l1.251-1.481l0.299-0.279l0.326-0.091l0.338,0.094l0.35,0.266l0.45,0.662l0.091,0.134l0.482,1.895
                l0.299,0.599l0.796,0.33l2.233,0.424l0.503,0.256l-0.057,1.025l-0.645,0.382l-0.87,0.135l-0.724,0.281l-1.881,1.791l-0.798,0.461
                l-1.153,1.131l1.153,0.926l2.152,0.564l1.816,0.054l1.85-0.163l1.51,0.291l3.201,1.278l3.8,1.032l1.713,0.719l6.882,5.558
                l4.239,2.58l7.589,5.94l0.657,0.192l1.225-0.182l0.616,0.241l1.488,1.573l0.822,0.549l0.609,0.103l2.075-0.047l3.913,0.6
                l1.143,0.175l1.124-0.29l0.927-1.528l-0.103-1.713l-1.083-3.303l0.345-2.693l2.042-0.805l15.22-0.923l1.277-0.844l-3.276-23.779
                l1.371-3.52l0.489-2.248l0.388-5.311l-0.609-2.294l-1.917-1.472l1.845-1.84l2.47,0.866l4.136,3.157l2.586,0.266l2.032-0.434
                l1.941-0.059l2.324,1.388l1.653,1.576l0.455,0.9l0.259,1.398l0.649,1.467l1.066-0.389l1.613-1.788l1.081-0.55l0.728-0.207
                l2.257,0.242l0.776,0.279l0.656,0.429l0.649,0.296l0.678-0.113l3.203-1.228l3.267-1.253l-2.609-9.009l-0.434-5.117l1.474-3.66
                l1.641-2.734l3.625-12.383l-0.153-0.153l-0.146-0.235l-0.273-0.143l-0.532,0.114l2.451-4.058l13.694-7.668l1.57-1.772l5.24-5.918
                l2.075-1.79l7.534-4.358l5.051-1.249l7.054,1.55l21.676,10.387l2.971,0.704l1.438-0.23l1.793-0.812l4.553-2.062l0.8-0.66
                l0.359-1.006l1.706-23.506l0.738-2.534l2.784-1.25l4.443-0.203l7.62,0.601l6.518-0.344l6.47-1.24l32.147-20.973l3.944-1.888
                l17.717-4.391l3.537-1.527l3.268-2.067l0.016-0.015l19.172-17.075l0.014-0.01l4.955-5.021l1.07-0.791l1.704-1.259l3.077-0.839
                l20.344-0.121L501.855,30l0.858,0.427l2.137,5.791l0.915,1.206l2.602,0.045l3.357-1.498l5.777-3.553l5.794-1.045l6.465,1.531
                l12.388,5.16h0.019l23.339,8.155l0.22,2.184l-0.834,1.287l-1.284,1.056l-1.136,1.497l-0.153,2.746l2.689,5.556l0.613,2.916
                l-6.192,17.685l0.546,4.847l2.765,4.633l1.273,1.169l13.353,12.251l3.935,5.356l0.264,0.544l-0.096,0.794l-0.879,1.145
                l-0.343,0.868l-0.489,2.961l-0.441,1.134l-2.219,3.467l-0.4,1.124l0.41,1.095l1.177,1.781l0.575,0.655l0.582,0.45l0.508,0.504
                l0.393,0.808l-0.105,0.62l-0.767,1.539l0.01,0.84l0.853,0.98l1.208,0.595l0.973,0.84l0.192,1.694l0.736,1.687l1.531,1.044
                l1.627,0.852l0.999,1.121l-0.041,0.832l-0.76,1.723l0.053,1.002l0.573,0.694l1.934,1.281l0.728,0.822l0.786,1.77l0.736,1.032
                l0.385,0.54l1.409,1.31l3.125,2.063l0.949,1.266l1.045,1.123l1.783,0.661l0.633,0.896l-0.556,1.801l-0.901,1.991l-0.403,1.453
                l1.284,2.378l2.444,0.851l12.307-0.395l0.939,0.197l0.268,0.661l-0.019,0.777l0.077,0.582l-0.067,0.353l-0.316,0.395l0.077,0.631
                l1.112,1.063l1.821,0.89l1.649,0.089l3.489-0.34l4.284,1.304l5.013,3.027l3.748,4.015l0.479,4.298l1.735,2.898l3.057,2.262
                l10.102,4.7l1.658,0.547l3.93,0.49l1.562,0.399l1.419,1.002l1.773,2.002l-2.521,1.002l-1.802-0.864l-1.514-1.362l-1.677-0.498
                l-0.489,0.517l-0.441,1.056l-0.93,3.258l-0.642,0.202l-2.061-0.864l-4.984-1.258l-7.418-2.98l-1.965-0.34l-1.217,3.82l0.038,8.081
                l0.048,8.966l0.03,6.058l0.009,1.758l0.268,1.808l0.824,1.52l2.664,1.473l6.633,0.561l2.799,1.404l5.281,4.475l5.55,4.705
                l6.867,5.826l2.392,2.029l7.553,6.401l5.109,4.324l6.374,5.408l2.598,1.459l3.077,0.806l9.498,0.835l11.741,1.017l1.639-0.182
                l1.572-1.041l1.112-1.63l0.978-1.876l1.572-2.328l0.633-1.272l0.412-0.457l0.757-0.162l0.652,0.319l0.728,0.138l1.026-0.693
                l1.572-1.739l0.853-0.486l9.019,0.757l3.719,1.012l2.425,2.854l0.537,1.852l0.355,0.511l0.987,1.022l0.978,0.786l1.696,1.056
                l0.834,1.061l0.623,1.287l0.297,1.198l0.709,5.725l0.451,1.208l0.757,1.473l0.527,0.731l1.179,1.301l0.374,0.717v0.402
                l-0.297,0.996l-0.01,0.476l0.182,2.091l-0.652,1.016l-1.361,0.353l-5.511,0.471l-0.47-0.083l-0.997-0.486l-0.278-0.093
                l-0.585,0.324l-0.671,0.932l-0.422,0.324l-5.348,1.119l-1.399,1.177l-0.22,2.752l1.773,3.326l3.978,5.591l6.93,9.728l6.518,9.17
                l7.352,10.303L766.906,333.637z"/>        
            </g>
    );
}

export default BF;