import React, { useState } from "react"
import Select from "react-select"
import { Button } from "reactstrap"

const OrganizationFilters = ({
  typeOptions,
  countryOptions,
  applyFilters,
  toggleModalFilter,
  selectedCountryFilters,
  selectedTypeFilters,
}) => {
  const [selectedCountries, setSelectedCountries] = useState(
    countryOptions.filter(countryOption =>
      selectedCountryFilters.includes(countryOption.value)
    )
  )
  const [selectedTypes, setSelectedTypes] = useState(
    typeOptions.filter(type => selectedTypeFilters.includes(type.value))
  )

  const handleFilterApply = () => {
    const countries = selectedCountries.map(country => country.value)

    const types = selectedTypes.map(type => type.value)
    applyFilters(countries, types)
    toggleModalFilter()
  }
  return (
    <div>
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-buffer light-icon me-1" />
          <h6 className="mb-0">Type:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            options={typeOptions}
            isMulti
            defaultValue={selectedTypes}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Multiple"
            onChange={setSelectedTypes}
          />
        </div>
      </div>
      <div className="d-flex my-2">
        <div className="d-flex align-items-center gap-1">
          <i className="ph-globe-hemisphere-east" />
          <h6 className="mb-0">Countries:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            options={countryOptions}
            isMulti
            defaultValue={selectedCountries}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Multiple"
            onChange={setSelectedCountries}
          />
        </div>
      </div>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <Button
          className="btn btn-success common-button"
          style={{ width: "90%" }}
          onClick={handleFilterApply}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

export default OrganizationFilters
