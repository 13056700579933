import React from "react"
import PolicyTasks from "./Policy-Tasks"
import Benchmarking from "pages/Benchmarking/index"
import CustomTabs from "components/Common/Custom-Tabs"
import AdminTasks from "pages/TaskManagement/AdminTasks/Admin-Tasks"

export default function TasksManagement({alert}) {
  const triggers = [
    { name: "E-Tracker Tasks", value: "tab1" },
    { name: "Benchmarking Tasks", value: "tab2" },
    { name: "Admin Tasks", value: "tab3" },
  ]
  const content = [
    { value: "tab1", component: <PolicyTasks alert={alert}/> },
    { value: "tab2", component: <Benchmarking isNotMainPageContent alert={alert}/> },
    { value: "tab3", component: <AdminTasks alert={alert}/> },
  ]
  return (
    <div className="page-content">
      <CustomTabs
        ariaLabel="Tasks Management"
        defaultValue="tab1"
        triggers={triggers}
        content={content}
      />
    </div>
  )
}
