export const downloadCSV = function (data, name) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: "text/csv" })
  
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
  
    // Creating an anchor(a) tag of HTML
    const a = document.createElement("a")
  
    // Passing the blob downloading url
    a.setAttribute("href", url)
  
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
  
    a.setAttribute("download", name)
  
    // Performing a download with click
    a.click()
  }

export const csvmaker = function (data) {
    // Empty array for storing the values
    const csvRows = []
  
    const headers = Object.keys(data[0])
  
    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    csvRows.push(headers.join(","))
  
    data.map(data => {
      const values = Object.values(data)
      const csvValues = values.join()
      csvRows.push(csvValues)
    })
    // Pushing Object values into array
    // with comma separation
  
    // Returning the array joining with new line
    return csvRows.join("\n")
  }