import styled from "@emotion/styled"
import { useMutation, useQuery } from "@tanstack/react-query"
import Dialog from "components/Common/Dialog"
import LoadingSpinner from "components/Common/Loading-Spinner"
import { EditorState } from "draft-js"
import "draft-js/dist/Draft.css"
import { formatTimeAgo } from "helpers/utlities"
import React, { useState } from "react"
import { getPolicyUpdates, postPolicyUpdate } from "services/policyServices"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { Button } from "reactstrap"
import DOMPurify from "dompurify"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { AnimatePresence, motion } from "framer-motion"

const loadPolicyNotes = async policyID => {
  try {
    const { data } = await getPolicyUpdates(policyID)
    return data
  } catch (err) {
    console.error(err)
    return err
  }
}
const addPolicyNote = async body => {
  try {
    const { data } = await postPolicyUpdate(body)
    return data
  } catch (err) {
    console.error(err)
    return err
  }
}

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
}

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
]
export default function UpdatesAndEmergingOutcomes({ policyID, stageID }) {
  const { pathname } = useLocation()
  const showAddNoteForm = pathname !== "/policy-directory"
  const [update, setUpdate] = useState("")
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [updateIDBeingEdited, setUpdateIDBeingEdited] = useState(null)
  const [editedUpdate, setEditedUpdate] = useState(null)

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const {
    data: updates,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["Policy Updates", { policyID }],
    queryFn: () => loadPolicyNotes(policyID),
    enabled: !!policyID,
    staleTime: 60 * 1_000,
  })
  const { mutate: addNote } = useMutation({
    mutationFn: () =>
      addPolicyNote({
        policy_id: policyID,
        stage_id: stageID,
        description: update,
      }),
  })
  const { mutate: updateNote } = useMutation({
    mutationFn: () =>
      addPolicyNote({
        policy_id: policyID,
        stage_id: stageID,
        description: editedUpdate,
        id: updateIDBeingEdited,
      }),
  })
  const handleUpdateNote = () => {
    updateNote(
      {
        policy_id: policyID,
        stage_id: stageID,
        description: editedUpdate,
        id: updateIDBeingEdited,
      },
      {
        onSuccess: () => {
          cancelEdit()
          refetch()
        },
      }
    )
  }
  const handleSubmit = evt => {
    evt.preventDefault()
    addNote(
      {
        policy_id: policyID,
        stage_id: stageID,
        description: update,
      },
      {
        onSuccess: () => {
          setUpdate("")
          refetch()
        },
      }
    )
  }
  const initiateEdit = (id, text) => {
    setEditedUpdate(text)
    setUpdateIDBeingEdited(id)
    setIsUserEditing(true)
  }
  const cancelEdit = () => {
    setEditedUpdate(null)
    setUpdateIDBeingEdited(null)
    setIsUserEditing(false)
  }
  return (
    <Dialog.Content>
      <Flex>
        <Dialog.Title>Updates & Emerging Outcomes</Dialog.Title>
        <Dialog.Close
          leading="ph-x"
          isCloseSVG
          handleClick={() => {
            setIsUserEditing(false)
          }}
        />
      </Flex>
      <Dialog.Description>
        These are the high-level policy highlights, changes taking place and the
        impacts the policy is having on the landscape.
      </Dialog.Description>
      <ContentContainer showingForm={showAddNoteForm}>
        <div className="notes__container">
          {isLoading && <LoadingSpinner />}
          {updates?.length > 0 ? (
            <ul className="updates__list">
              {updates.map(update => (
                <li key={update?.notes_id}>
                  <NoteContainer
                    update={update}
                    editedUpdate={editedUpdate}
                    setEditedUpdate={setEditedUpdate}
                    isUserEditing={isUserEditing}
                    setIsUserEditing={setIsUserEditing}
                    initiateEdit={initiateEdit}
                    updateIDBeingEdited={updateIDBeingEdited}
                    cancelEdit={cancelEdit}
                    handleUpdateNote={handleUpdateNote}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <div className="center__content">
              <p>No notes have been added to this policy yet.</p>
            </div>
          )}
        </div>
        {showAddNoteForm && (
          <StyledForm onSubmit={handleSubmit}>
            <ReactQuill
              theme="snow"
              value={update}
              onChange={setUpdate}
              modules={modules}
              formats={formats}
            />
            <Button color="primary" disabled={update.length === 0}>
              Post
            </Button>
          </StyledForm>
        )}
      </ContentContainer>
    </Dialog.Content>
  )
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block-end: 1rem;
  & .notes__container {
    flex: 1;
  }

  & .center__content {
    height: 100%;
    display: grid;
    place-items: center;

    & p {
      opacity: 0.6;
    }
  }

  & .updates__list {
    height: ${props => (props.showingForm ? "45vh" : "60vh")};
    overflow-y: auto;
    display: grid;
    gap: 0.5rem;

    & .note__container {
      background-color: #f2f8de;
      box-shadow: var(--shadow-elevation-low);
      padding: 0.4em 0.8em;
    }
  }
`
const StyledUpdatesList = styled.ul``
const StyledForm = styled.form`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4.5fr 0.5fr;
  gap: 0.5rem;

  button {
    align-self: end;
  }
`
const Flex = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  max-height: 100%;
  flex-direction: ${props => props.isEditBox && "column"};
  gap: ${props => props.isEditBox && "0.75rem"};

  & > button {
    align-self: flex-start;
  }

  & .button__group {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }
`

const variants = {
  initial: {
    opacity: 0,
    y: -10,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 10,
  },
}
const NoteContainer = ({
  update,
  isUserEditing,
  editedUpdate,
  setEditedUpdate,
  initiateEdit,
  updateIDBeingEdited,
  setIsUserEditing,
  cancelEdit,
  handleUpdateNote,
}) => {
  return (
    <AnimatePresence>
      {isUserEditing && updateIDBeingEdited === update.notes_id ? (
        <Flex
          isEditBox
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <ReactQuill
            theme="snow"
            value={editedUpdate}
            onChange={setEditedUpdate}
            modules={modules}
            formats={formats}
          />
          <div className="button__group">
            <Button color="secondary" onClick={cancelEdit}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={editedUpdate === update.notes_description}
              onClick={handleUpdateNote}
            >
              Save
            </Button>
          </div>
        </Flex>
      ) : (
        <motion.div
          className="note__container"
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(update.notes_description),
            }}
          ></div>
          <Flex>
            <p>
              <span>
                Added by <strong>{update?.user_name}</strong>{" "}
                <em>{formatTimeAgo(update.notes_updated_at)}</em>
              </span>
            </p>

            <Button
              color="info"
              onClick={() =>
                initiateEdit(update?.notes_id, update.notes_description)
              }
            >
              <i className="ph-pencil-simple-line" />
            </Button>
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
