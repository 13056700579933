import { csvmaker, downloadCSV } from "components/Common/downloadCSV";
import { formateOnlyDate } from "helpers/utlities"

export const getCSV = (data) => {
  const newData = []
  data.map(data => {
    const newDataObject = {};
    newDataObject["System"] = data.label_name;
    newDataObject["Benchmarking Milestone"] = data.form_name.replaceAll(",", ";");
    newDataObject["Status"] = data.form_status || "PENDING";
    newDataObject["Score"] = data.score;
    newDataObject["Task Owner"] = data.owner_name;
    newDataObject["Temporary Owner"] = data.task_owner_first_name ? (data.task_owner_first_name + data.task_owner_last_name) : "None"
    newDataObject["Updated At"] = data.updated_at && formateOnlyDate(data.updated_at);
    newData.push(newDataObject)
  })
  const csvdata = csvmaker(newData)
  return downloadCSV(csvdata, "benchmarking_tasks.csv")
}
