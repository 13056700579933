import { Role, accountStatus } from "constants/global"
import React from "react"
import { Input, CustomInput } from "reactstrap"

export const Filter = ({ column }) => {
  return (
    column.canFilter && (
      <div style={{ marginTop: 5 }}>{column.render("Filter")}</div>
    )
  )
}

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <Input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`search (${length}) ...`}
    />
  )
}

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <>
      {/* <label className="form-label">{id}</label> */}
      <select
        id="custom-select"
        className="form-select"
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">{id === 'role'? 'All roles': `All ${id}`}</option>
        {options.map(option => (
          <option key={option} value={option}>
            {id === 'role'? Role[option]: id === 'status' ? accountStatus[option]: option}
          </option>
        ))}
      </select>
    </>
  )
}
