import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody, CloseButton } from "reactstrap"
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/delete-warning';

const ConfirmationModal = ({ show, onConfirmClick, onCloseClick, toggleParent }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody>
        <div className="d-flex">
          <div className='me-4'>
            <Lottie 
            options={defaultOptions}
            height={60}
            width={60}
            />
          </div>
          <div className='w-100'>
            <div className='d-flex justify-content-between'>
                <h4 style={{color: "#ED7D2DFF"}}>
                    Warning
                </h4>
                <CloseButton onClick={onCloseClick}/>
            </div>
            {"Are you sure you want to make changes?"}
            <div className='d-flex mt-3' style={{color: "#EF9834FF"}}>
              <div
                className="me-5 new-btn-light"
                onClick={onCloseClick}
              >
                Cancel
              </div>
              <div
                className='new-btn-light'
                onClick={()=>{
                  onConfirmClick();
                  onCloseClick();
                  toggleParent();
                }}
              >
                Confirm
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any
}

export default ConfirmationModal
