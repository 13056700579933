import React from 'react';

function FileText({hasChildren}) {
    return (
        <g transform={hasChildren? 'scale(0.05 0.05) translate(-490,-490)': 'scale(0.05 0.05) translate(-490,-330)'}>
            <rect width="256" height="256" fill="none"></rect>
            <path d="M200,224H56a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h96l56,56V216A8,8,0,0,1,200,224Z" fill="none" stroke="#9095A0FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></path>
            <polyline points="152 32 152 88 208 88" fill="none" stroke="#9095A0FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></polyline>
            <line x1="96" y1="136" x2="160" y2="136" fill="none" stroke="#9095A0FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line>
            <line x1="96" y1="168" x2="160" y2="168" fill="none" stroke="#9095A0FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line>
        </g>
    );
}

export default FileText;