import FormUpload from "components/Common/PolicyTable/PolicyUpload"
import { downloadFile } from "components/Common/download"
import React, { useState } from "react"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
  ModalBody,
  Modal,
  Button,
  Row,
  Col,
} from "reactstrap"
import { get } from "services/AXIOS"
import { SYSTEMFORMFILEDOWNLOAD } from "services/CONSTANTS"
import { ScoringGuide } from "pages/Benchmarking/FillForm"

export const Preview = props => {
  const {
    form,
    responseHandler,
    mode,
    onUpload,
    files,
    setFileKey,
    modalUpload,
    toggleUpload,
    alert,
  } = props
  const isDisabled = mode === "read" ? true : false

  const responseName = props.responseName ? props.responseName : "responses"

  const download = file => {
    const link = document.createElement("a")
    const url = URL.createObjectURL(file)

    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const downloadFromServer = fileName => {
    get(`${SYSTEMFORMFILEDOWNLOAD}/${fileName}`, { responseType: "blob" })
      .then(file => {
        downloadFile(fileName, fileName, file)
      })
      .catch(err => {
        console.log(err)
        alert("File downloading", false)
      })
  }

  return (
    <div className=" p-3">
      <Card>
        <CardBody style={{ border: "2px solid #d6c4f3", borderRadius: "5px" }}>
          <div className=" d-flex justify-content-between align-items-start">
            <p className=" fw-bold fs-3">{form.name}</p>
            <div className="d-flex">
              {form.type != "benchmarking" && (
                <div
                  className="p-1 rounded-pill px-2 mx-2"
                  style={{
                    backgroundColor: "#F6F2FD",
                    color: "#576175",
                  }}
                >
                  {form.type === "tracker"
                    ? `Stage ${form?.stage?.stage} - ${form?.stage?.label}`
                    : `Category - ${form?.sub_category?.category_name}`}
                </div>
              )}
              {form.type === "landscaping" && (
                <div
                  className="p-1 rounded-pill px-2 mx-2"
                  style={{
                    backgroundColor: "#F6F2FD",
                    color: "#576175",
                  }}
                >
                  {form?.sub_category?.country_label}
                </div>
              )}
              {/* <div
                className="p-1 rounded-pill px-2"
                style={{
                  backgroundColor: "#EAFFFC",
                  color: "#0B847F",
                }}
              >
                {typeof form?.owner == "object"
                  ? form?.owner?.first_name + " " + form?.owner?.last_name
                  : form?.owner}
              </div> */}
            </div>
          </div>
          <div
            style={{
              color: "#9095A0",
            }}
          >
            <p>{form?.description}</p>
          </div>
          {form?.type === "benchmarking" && <ScoringGuide />}
        </CardBody>
      </Card>
      {form.questions?.map((question, index) => {
        return (
          <Card
            key={index}
            style={{ border: "2px solid #d6c4f3", borderRadius: "5px" }}
          >
            <CardBody>
              <Row>
                <Col md={question.required ? "11" : "12"}>
                  <p className="p-1 px-2 fs-5">
                    <span
                      style={{
                        color: "#8C6BE8",
                        marginInlineEnd: ".4em",
                      }}
                    >
                      {" "}
                      {question.question_number}
                      {"."}
                    </span>
                    {question.question_text}{" "}
                  </p>

                  <div className="align-self-center mt-3">
                    {question.question_type === "text" ? (
                      <Input
                        onChange={e =>
                          responseHandler({
                            id: question.id,
                            index,
                            value: e.target.value,
                            responseType: "answer",
                          })
                        }
                        defaultValue={
                          form.type === "benchmarking"
                            ? question.label_answers[0]?.response?.answer
                            : (question[responseName]?.length > 0 &&
                                question[responseName][0]?.response) ||
                              null
                        }
                        placeholder="Type your answer here"
                        className=" fs-5 border-0 border-bottom border-2 rounded-0"
                        disabled={isDisabled}
                      />
                    ) : question.question_type == "dropdown" ? (
                      <div>
                        <FormGroup tag="fieldset">
                          {question?.question_options?.length > 0 &&
                            question?.question_options?.map((node, optIdx) => {
                              return (
                                <FormGroup check key={optIdx} className="mb-3">
                                  <Input
                                    name={`radio${index}`}
                                    type="radio"
                                    value={node}
                                    defaultChecked={
                                      form.type === "benchmarking"
                                        ? question.label_answers[0]?.response
                                            ?.answer === node
                                        : node ==
                                          (question[responseName] &&
                                            question[responseName][0]?.response)
                                    }
                                    onChange={e =>
                                      responseHandler({
                                        id: question.id,
                                        index,
                                        value: e.target.value,
                                        responseType: "answer",
                                        weight:
                                          question.question_options_weight[
                                            optIdx
                                          ],
                                      })
                                    }
                                    disabled={isDisabled}
                                    id={`Q${index + 1}-Opt${optIdx + 1}`}
                                  />
                                  <Label
                                    check
                                    className="fs-5"
                                    htmlFor={`Q${index + 1}-Opt${optIdx + 1}`}
                                  >
                                    {node}
                                  </Label>
                                </FormGroup>
                              )
                            })}
                        </FormGroup>
                        {question.is_file_upload &&
                          form.type === "benchmarking" && (
                            <Row>
                              <Col
                                md="6"
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                }}
                              >
                                <Input
                                  type="textarea"
                                  onChange={e =>
                                    responseHandler({
                                      id: question.id,
                                      index,
                                      value: e.target.value,
                                      responseType: "reason",
                                    })
                                  }
                                  defaultValue={
                                    question.label_answers[0]?.response
                                      ?.reason || null
                                  }
                                  placeholder="Please provide rationale"
                                  disabled={isDisabled}
                                  style={{}}
                                />
                              </Col>
                              <Col
                                md="3"
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                }}
                              >
                                <Button
                                  color="primary"
                                  className=" align-self-center bg-white text-primary"
                                  onClick={() => {
                                    toggleUpload()
                                    setFileKey(`question_${question.id}`)
                                  }}
                                >
                                  <i className="bx bx-upload mx-1 bx-xs" />{" "}
                                  Upload Evidence
                                </Button>
                                <Modal
                                  isOpen={modalUpload}
                                  toggle={toggleUpload}
                                >
                                  <ModalBody>
                                    <FormUpload
                                      toggle={toggleUpload}
                                      onNextClick={onUpload}
                                    />
                                  </ModalBody>
                                </Modal>
                              </Col>
                              {files[`question_${question.id}`] ||
                              question.label_answers[0]?.response?.file ? (
                                <Col
                                  md="3"
                                  style={{
                                    display: "grid",
                                    placeItems: "center",
                                  }}
                                >
                                  <a
                                    className="text-decoration-underline"
                                    style={{ color: "#1091F4" }}
                                    onClick={
                                      files[`question_${question.id}`]
                                        ? () =>
                                            download(
                                              files[
                                                `question_${question.id}`
                                              ][0]
                                            )
                                        : () =>
                                            downloadFromServer(
                                              question.label_answers[0]
                                                ?.response?.file
                                            )
                                    }
                                  >
                                    Download Evidence
                                  </a>
                                </Col>
                              ) : null}
                            </Row>
                          )}
                      </div>
                    ) : (
                      <div>
                        <Input
                          onChange={e =>
                            responseHandler({
                              id: question.id,
                              index,
                              value: e.target.value,
                              responseType: "answer",
                            })
                          }
                          defaultValue={
                            form.type === "benchmarking"
                              ? question.label_answers[0]?.response?.answer
                              : (question[responseName]?.length > 0 &&
                                  question[responseName][0]?.response) ||
                                null
                          }
                          type="number"
                          min="0"
                          max="5"
                          placeholder="Type your answer here"
                          className=" fs-5 border-0 border-bottom border-2 rounded-0"
                          disabled={isDisabled}
                        />

                        <div
                          style={{
                            color: "#aaaaaa",
                          }}
                        >
                          <div className="mt-3">
                            On a scale of 0-5, please avoid rating it 3 or
                            neutral
                          </div>
                          <div>
                            More detiails on AGRA's rating framework{" "}
                            <span className=" text-decoration-underline fst-italic">
                              here
                            </span>
                          </div>
                        </div>
                        {/* <div>
                          {question &&
                            question.responses &&
                            question?.responses[0]?.response && (
                              <i className="bx bxs-check-circle bx-sm text-primary" />
                            )}
                        </div> */}
                      </div>
                    )}
                  </div>
                </Col>
                {question.required && (
                  <Col md="1" style={{ paddingInline: 0 }}>
                    <i
                      style={{
                        verticalAlign: "middle",
                        fontSize: "1.25rem",
                        color: "hotpink",
                      }}
                      className="ph-asterisk-simple"
                    />
                    <span> Required</span>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        )
      })}
      {/* <Row className=" mb-2  py-2 shadow-sm bg-white rounded-3 border-primary border-bottom border-5">
        <div className="d-flex fs-4 bg-white py-2 rounded-3 ">
          <p className="mx-5">
            <span className="fw-bold">Activity Name:</span> {form.name}
          </p>
          <p>
            <span className="fw-bold">Stage:</span> Stage {form?.stage?.stage}
          </p>
        </div>
      </Row>

      <br />
      {form.questions?.map((question, index) => {
        return (
          <Row
            key={index}
            className="border mb-5 shadow-sm px-5 py-4 bg-white rounded-3"
          >
            <div className=" d-flex mb-1">
              <div className=" mb-4">
                <p className=" fs-4 m-0 p-0  text-start align-self-center">
                  {question.question_number}.
                </p>
              </div>
              <div className=" mx-2">
                <p className="fs-4 m-0 p-0 align-self-center text-start">
                  {question.question_text}
                </p>
              </div>
              <div>
                {question &&
                  question.responses &&
                  question?.responses[0]?.response && (
                    <i className="bx bxs-check-circle bx-sm text-primary" />
                  )}
              </div>
            </div>

            <Col md="12">
              <div className="align-self-center ">
                {question.question_type === "text" ? (
                  <Input
                    onChange={e =>
                      responseHandler({
                        id: question.id,
                        index,
                        value: e.target.value,
                      })
                    }
                    value={
                      (question?.responses?.length > 0 &&
                        question?.responses[0]?.response) ||
                      null
                    }
                    placeholder="Text Input"
                    className=" fs-5 border-0 border-bottom border-2 border-secondary rounded-0"
                  />
                ) : (
                  <select
                    className={`form-control select2 fs-5 border border-2 border-secondary `}
                    title="Questions"
                    onChange={e =>
                      responseHandler({
                        id: question.id,
                        index,
                        value: e.target.value,
                      })
                    }
                  >
                    <option value="0">DropDown</option>
                    {question?.question_options?.length > 0 &&
                      question?.question_options?.map((node, index) => {
                        return (
                          <option
                            key={index}
                            selected={
                              node ==
                                (question &&
                                  question.responses &&
                                  question?.responses[0]?.response) || ""
                            }
                          >
                            {node}
                          </option>
                        )
                      })}
                  </select>
                )}
              </div>
            </Col>
          </Row>
        )
      })} */}
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
