import React from 'react';
import { PROFILE_PHOTO } from 'services/CONSTANTS';

const UserImage = ({user, photo}) => {
    return (
        user?
            photo?
                <img src={PROFILE_PHOTO+photo} width="32px" height="32px" className='user-img'/>:
                <div 
                    className='avatar-sm'
                    style={{
                        color:"white",
                        backgroundColor:"grey",
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "18px",
                        zIndex: "2"
                    }}
                >
                    <span>{user.charAt(0).toUpperCase()}</span>
                </div>
            :
            null
        );
}

export default UserImage;