import React, { useEffect, useRef, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"
import { Redirect } from "react-router"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import ReCAPTCHA from "react-google-recaptcha"

import white_border from "assets/images/white-border.png"

import { getTokenInfo } from "services"

const Register = props => {
  //meta title
  const [organizationName, setOrganizationName] = useState("")

  const {
    match: { params },
  } = props

  const dispatch = useDispatch()
  const captchaRef = useRef(null)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      organization: organizationName,
      designation: "",
      access_code: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password").min(4),
      // organization: Yup.string().required("Please Enter Your Organization"),
      designation: Yup.string().required("Please Enter Your Designation"),
      access_code: Yup.string().required("Please Enter Access Code"),
    }),
    onSubmit: values => {
      if (captchaRef.current.getValue() == "") {
        dispatch(registerUserFailed("Please Fill recaptcha"))
      } else if (!params.token) {
        dispatch(registerUserFailed("Invalid Request"))
      } else {
        const token = captchaRef.current.getValue()
        values.recaptchaValue = token
        dispatch(registerUser(values, params.token || "", props.history))
        validation.resetForm()
      }
      captchaRef.current.reset()
    },
  })

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  useEffect(function () {
    getTokenInfo(params.token)
      .then(function (res) {
        if(res?.data?.is_exist) {
          dispatch(registerUserFailed('User already exists. Please login.'))
          setTimeout(() => {
            window.location.replace('/login');
          }, 3000);
        }
        setOrganizationName(res?.data?.organization_name || "")
      })
      .catch((err) => {
        setOrganizationName("");
        // Update the registrationError state with the error message
        dispatch(registerUserFailed("Error fetching token information."))
      })
  }, [])

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  useEffect(() => {
    document.body.className = "bg-signup"
  }, [])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div
        className="account-pages pt-sm-5"
        style={{ backgroundColor: "#CDE381" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card>
                <CardBody style={{ padding: 0 }}>
                  <Row>
                    <Col lg={6} style={{ padding: "2em 4em" }}>
                      <div className="text-primary pb-4">
                        <h2 className="text-primary">SIGN UP</h2>
                        <p className="text-muted">
                          Sign up to continue to AGRA Policy Tracker
                        </p>
                      </div>
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {user && user ? (
                          <>
                            <Alert color="success">
                              Register User Successfully
                            </Alert>
                            <Redirect to="/login" />
                          </>
                        ) : null}
                        {registrationError && registrationError ? (
                          <Alert color="danger">{registrationError}</Alert>
                        ) : null}
                        <Row>
                          <div className="col-lg-6 mb-3">
                            <Label className="form-label">First Name</Label>
                            <Input
                              name="first_name"
                              type="text"
                              placeholder="Enter First Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ""}
                              invalid={
                                validation.touched.first_name &&
                                validation.errors.first_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.first_name &&
                            validation.errors.first_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.first_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="col-lg-6 mb-3">
                            <Label className="form-label">Last Name</Label>
                            <Input
                              name="last_name"
                              type="text"
                              placeholder="Enter Last Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || ""}
                              invalid={
                                validation.touched.last_name &&
                                validation.errors.last_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.last_name &&
                            validation.errors.last_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.last_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        <Row>
                          <div className="col-lg-12 mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        <Row>
                          <div className="col-lg-6 mb-3">
                            <Label className="form-label">Organization</Label>
                            <Input
                              name="organization"
                              type="text"
                              placeholder="Enter Organization"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={organizationName}
                              invalid={
                                validation.touched.organization &&
                                validation.errors.organization
                                  ? true
                                  : false
                              }
                              className="lock-icon"
                              disabled={true}
                            />
                            {validation.touched.organization &&
                            validation.errors.organization ? (
                              <FormFeedback type="invalid">
                                {validation.errors.organization}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="col-lg-6 mb-3">
                            <Label className="form-label">Designation</Label>
                            <Input
                              name="designation"
                              type="text"
                              placeholder="Enter Designation"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.designation || ""}
                              invalid={
                                validation.touched.designation &&
                                validation.errors.designation
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.designation &&
                            validation.errors.designation ? (
                              <FormFeedback type="invalid">
                                {validation.errors.designation}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        <Row>
                          <div className="col-lg-6 mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="col-lg-6 mb-3">
                            <Label className="form-label">Access Code</Label>
                            <Input
                              name="access_code"
                              type="text"
                              placeholder="Enter Access Code"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.access_code || ""}
                              invalid={
                                validation.touched.access_code &&
                                validation.errors.access_code
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.access_code &&
                            validation.errors.access_code ? (
                              <FormFeedback type="invalid">
                                {validation.errors.access_code}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        <div className="mb-3">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            ref={captchaRef}
                          />
                        </div>
                        <div className="mt-4 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </Form>
                      <div className="mt-5 text-center">
                        <p>
                          Already have an account ?{" "}
                          <Link
                            to="/login"
                            className="font-weight-medium text-primary"
                          >
                            {" "}
                            Login
                          </Link>{" "}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6} className="bg-signup-img"></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <img src={white_border} className="img-fluid" />
      </div>
    </React.Fragment>
  )
}

export default Register
