import React from "react"
import { Row, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { Table } from "reactstrap"
import { map } from "lodash"
import { StyledNoTasksMessage } from "./System-Tasks"
//redux
import { formateOnlyDate } from "helpers/utlities"

import SimpleBar from "simplebar-react"

function Tasks({ tasks }) {
  if (tasks.length <= 0) {
    return (
      <StyledNoTasksMessage>
        <h3>No Policy tasks have been assigned to you at this point of time</h3>
      </StyledNoTasksMessage>
    )
  }
  return (
    <Card>
      <CardBody>
        <Row>
          <SimpleBar style={{ maxHeight: "400px" }}>
            <div className="table-responsive">
              <Table className="project-list-table table-borderless">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "30px" }}>
                      &nbsp;
                    </th>
                    <th scope="col">Policy Name</th>
                    <th scope="col">Progress</th>
                    <th scope="col">Owner Name</th>
                    <th scope="col">Deadline</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {map(tasks, (task, index) => (
                    <tr key={index}>
                      <td>
                        <i className="mdi mdi-arrow-top-right" />
                      </td>
                      <td>
                        <Link to={`policy/${task.policy_id}`} className="form-link">
                          <h5 className="font-size-14">{task.policy_name}</h5>
                        </Link>
                        <p className="text-muted mb-0">
                          {task.form_status == "NEW" && "NEW"}
                        </p>
                      </td>
                      <td>
                        <span
                          className="badge badge-break-word"
                          style={{
                            color: task.color,
                            background: task.backgroundColor,
                          }}
                        >
                          Stage {task.stage_id}: {task.label}
                        </span>
                        <Link
                          to={`/forms/${task.policy_id}/${task.stage_id}/${task.form_id}`}
                          className="form-link"
                        >
                          <span
                            className="badge badge-break-word d-flex align-items-center"
                            style={{
                              color: task.color,
                              background: task.backgroundColor,
                            }}
                          >
                            <i className="ph-arrow-up-right me-2"></i>
                            Milestone: {task.name}
                          </span>
                        </Link>
                      </td>
                      <td>
                        <Link
                          className="text-start"
                          to={`/user-management/${task.user_id}`}
                        >
                          <i className="mdi mdi-account" /> {task.first_name}{" "}
                          {task.last_name}
                        </Link>
                        {task.task_owner_id && (
                          <div>
                            <Link
                              className="text-start d-flex align-items-center"
                              style={{ color: "blue", opacity: 0.5 }}
                              to={`/user-management/${task.task_owner_id}`}
                            >
                              <i className="ph-user-switch me-1" />{" "}
                              {task.task_owner_first_name}{" "}
                              {task.task_owner_last_name}
                            </Link>
                          </div>
                        )}
                      </td>
                      <td>
                        <span className={task.progress_font_color}>
                          <i className="bx bx-bell me-1"></i>
                          {task.deadline
                            ? formateOnlyDate(task.deadline)
                            : "NA"}
                        </span>
                      </td>
                      <td>{task?.form_status ?? "NEW"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </SimpleBar>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Tasks
