import React, { useEffect } from "react"
import Tabs from "components/Common/Tabs"
import ETrackerOverview from "./Overview"
import ETrackerComparison from "./Comparison/Comparison"

export default function ETrackerDashboard({ alert }) {
  useEffect(() => {
    document.body.classList.remove("bg-login")
  }, [])

  return (
    <div className="page-content">
      <Tabs defaultValue="tab1">
        <Tabs.Triggers>
          <Tabs.TriggerButton value="tab1">At a Glance</Tabs.TriggerButton>
          <Tabs.TriggerButton value="tab2">Comparison</Tabs.TriggerButton>
        </Tabs.Triggers>
        <Tabs.TabContent value="tab1">
          <ETrackerOverview alert={alert} />
        </Tabs.TabContent>
        <Tabs.TabContent value="tab2">
          <ETrackerComparison />
        </Tabs.TabContent>
      </Tabs>
    </div>
  )
}
