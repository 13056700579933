import { csvmaker, downloadCSV } from "components/Common/downloadCSV";
import { formateOnlyDate } from "helpers/utlities"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

export const getOrgPDF = (data) => {
    const doc = new jsPDF("landscape", "mm")

    const head = []
    let body = []

    const makeTable = () => {
      const headValues = [
        "Name",
        "Organization Type",
        "Countries",
        "Updated On",
        "Updated By",
      ]

      headValues.map(value => head.push(value))

      body = data.map(org => {
        const row = [
            org.name,
            org.organization_type?.name,
            org.countries.map(country => country.label),
            formateOnlyDate(org.updated_at),
            `${org.updated_by?.first_name} ${org.updated_by?.last_name}`,
        ]
        return row
      })
    }

    makeTable()

    autoTable(doc, {
      head: [head],
      body: body,
      columnStyles: {0: {cellWidth: 70}, 2: {cellWidth: 60}}
    })

    doc.save("Organizations" + ".pdf")

}

export const getOrgCSV = (data) => {
    const newData = []
    data.map(org => {
        const newDataObject = {};
        newDataObject["Name"] = org.name.replaceAll(",", ";");
        newDataObject["Organization Type"] = org.organization_type?.name;
        newDataObject["Countries"] =  org.countries.map(country => country.label).join("; ");
        newDataObject["Updated On"] = formateOnlyDate(org.updated_at);
        newDataObject["Updated By"] = `${org.updated_by?.first_name} ${org.updated_by?.last_name}`;
        newData.push(newDataObject)
    })
    const csvdata = csvmaker(newData)
    return downloadCSV(csvdata, "Organizations.csv")
}

export const orgTypesPDF = (data) => {
    const doc = new jsPDF("portrait", "mm")

    const head = []
    let body = []

    const makeTable = () => {
      const headValues = [
        "Name",
        "Updated On",
        "Updated By",
      ]

      headValues.map(value => head.push(value))

      body = data.map(org => {
        const row = [
            org.name,
            formateOnlyDate(org.updated_at),
            `${org.updated_by?.first_name} ${org.updated_by?.last_name}`,
        ]
        return row
      })
    }

    makeTable()

    autoTable(doc, {
      head: [head],
      body: body,
    })

    doc.save("Organization Types" + ".pdf") 
}

export const orgTypesCSV = (data) => {
    const newData = []
    data.map(org => {
        const newDataObject = {};
        newDataObject["Name"] = org.name.replaceAll(",", ";");
        newDataObject["Updated On"] = formateOnlyDate(org.updated_at);
        newDataObject["Updated By"] = `${org.updated_by?.first_name} ${org.updated_by?.last_name}`;
        newData.push(newDataObject)
    })
    const csvdata = csvmaker(newData)
    return downloadCSV(csvdata, "Organization Types.csv")
}