import PropTypes from "prop-types"
import React, { useState,useEffect } from "react"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

//redux

import { Link, withRouter } from "react-router-dom"

// Formik Validation
import { useFormik } from "formik"

// action
import { resetPassword } from "services/authServices"

// import images
import logo from "../../assets/images/logo.svg"
import profile from "../../assets/images/profile-img.png"

const ResetPassword = props => {
  //meta title
  const [forgetError, setForgetError] = useState()
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState()

  const {
    match: { params },
  } = props

  // params.token

  const validate = values => {
    let errors = {}
    const passwordRegex = /(?=.*[0-9])/
    if (!values.password) {
      errors.password = "Password Required"
    } else if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password Required"
    } else if (values.password.length < 8) {
      errors.password = "*Password must be 8 characters long."
    } else if (values.confirm_password.length < 8) {
      errors.confirm_password = "*Password must be 8 characters long."
    } else if (!passwordRegex.test(values.password)) {
      errors.password = "Invalid password. Must contain one number."
    } else if (!passwordRegex.test(values.password)) {
      errors.confirm_password = "Invalid password. Must contain one number."
    } else if (values.password !== values.confirm_password) {
      errors.password = "Password not matched"
      errors.confirm_password = "Password not matched"
    }
    return errors
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validate,
    onSubmit: values => {
      resetPassword(values, params.token)
        .then(res => {
          setForgetSuccessMsg(res.message)
          validation.resetForm()
        })
        .catch(err => {
          setForgetError(err.response.data.message)
        })
    },
  })
   useEffect(() => {
     document.body.className = "bg-login"
   }, [])
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="overflow-hidden">
                <CardBody className="p-0">
                  <Row>
                    <Col lg={6}>
                      <div className="p-5 pt-10 pb-10">
                        <h2 className="text-primary mb-4">Create Your New Password</h2>
                        {forgetError && forgetError ? (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {forgetError}
                          </Alert>
                        ) : null}
                        {forgetSuccessMsg ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {forgetSuccessMsg}
                          </Alert>
                        ) : null}

                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="password">Password</Label>
                            <Input
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder="Enter Password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label" htmlFor="confirm_password">
                              Confirm Password
                            </Label>
                            <Input
                              name="confirm_password"
                              id="confirm_password"
                              className="form-control"
                              placeholder="Enter Confirm Password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirm_password || ""}
                              invalid={
                                validation.touched.confirm_password &&
                                validation.errors.confirm_password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.confirm_password &&
                            validation.errors.confirm_password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirm_password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <Row className="mb-3">
                            <Col className="text-end">
                              <button
                                className="btn btn-primary w-md "
                                type="submit"
                              >
                                Reset Password
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                    <Col lg={6} className="bg-login-img">
                      <div></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} AGRA. Designed and Developed by
                  Ycenter
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassword)
