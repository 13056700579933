import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"

import { useSelector } from "react-redux"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { EnumStage } from "constants/global"

const SidebarContent = props => {
  const ref = useRef()
  const [menu, setMenu] = useState(false)
  const { user } = useSelector(state => state.Profile)
  const { stages } = useSelector(state => state.list)
  const [hasBenchmarkingAccess, setHasBenchmarkingAccess] = useState(false)
  const [hasTrackerAccess, setHasTrackerAccess] = useState(false)
  const [hasLandscapingAccess, setHasLandscapingAccess] = useState(false)

  useEffect(() => {
    stages.map(stage=>{
      if (stage.type === "tracker") {
        setHasTrackerAccess(true)
      }
      if (stage.type === "benchmarking") {
        setHasBenchmarkingAccess(true)
      }
      if (stage.type === "landscaping") {
        setHasLandscapingAccess(true)
      }
    })
  }, [stages])
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    if (user && "id" in user) {
      setMenu(!menu)
    }
  }, [user])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Work space")} </li>
            <li>
              <Link to="/statistics/e-tracker">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Home")}</span>
              </Link>
              {/* <ul className="sub-menu">
                <li>
                  <Link to="/statistics/e-tracker">
                    <span>{props.t("Home")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard">
                    <span>{props.t("My Tasks")}</span>
                  </Link>
                </li>
              </ul> */}
            </li>
            <li>
              <Link to="/dashboard">
                <i className="bx bx-task"></i>
                <span>{props.t("My Tasks")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-line-chart"></i>
                <span>{props.t("Data Intelligence")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#" className="has-arrow">
                    <span>{props.t("Statistics")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/statistics/e-tracker">
                        {props.t("e-Tracker")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/#">{props.t("Policy Landscape")}</Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <span>{props.t("Qualitative Analysis")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/qualitative/news-insights">
                        {props.t("News Insights")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/trends">{props.t("Trends")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/#">{props.t("Policy Interventions")}</Link>
                    </li> */}
                  </ul>
                </li>
                {/* <li>
                  <Link to="/#" className="has-arrow">
                    <span>{props.t("Investment Landscape")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Impact Insights")}</Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </li>

            <li>
              <Link to="/policy-landscape">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {hasLandscapingAccess ? (
                      <i className="bx bx-file"></i>
                    ) : (
                      <i className="ph-lock"></i>
                    )}
                    <span>{props.t("Policy Landscaping")}</span>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/benchmarking">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {hasBenchmarkingAccess ? (
                      <i className="ph-book-bookmark"></i>
                    ) : (
                      <i className="ph-lock"></i>
                    )}
                    <span>{props.t("Benchmarking")}</span>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/overview">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {hasTrackerAccess ? (
                      <i className="bx bx-edit"></i>
                    ) : (
                      <i className="ph-lock"></i>
                    )}
                    <span>{props.t("e-Tracker")}</span>
                  </div>
                </div>
              </Link>
              {/* <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Stage 1")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Stage 2")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Stage 3")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Stage 4")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Stage 5")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Stage 6")}</Link>
                </li>
              </ul> */}
            </li>

            <li>
              <Link to="/policy-directory">
                <i className="bx bx-search"></i>
                <span>{props.t("Policy Directory")}</span>
              </Link>
            </li>

            {user && (user.role == "admin" || user.role == "c_admin") && (
              <>
                <hr style={{ color: "gray" }} />
                <li className="menu-title">{props.t("Admin Features")} </li>
                <li>
                  <Link to="/labels">
                    <i className="bx bx-bookmark"></i>
                    <span>{props.t("System Management")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/forms">
                    <i className="bx bx-edit"></i>
                    <span>{props.t("Milestone Management")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/user-management">
                    <i className="bx bx-user"></i>
                    <span>{props.t("User Management")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/access-code-list">
                    <i className="bx bx-lock-open"></i>
                    <span>{props.t("Access Management")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/organization_management">
                    <i className="ph-buildings" />
                    <span>{props.t("Organization Management")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/tasks">
                    <i className="ph-check-square-offset"></i>
                    <span>{props.t("Tasks Management")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/audit">
                    <i className="ph ph-clock-counter-clockwise"></i>
                    <span>{props.t("Audit Trail")}</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
