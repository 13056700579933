import { useQuery } from "@tanstack/react-query"
import ExportTable from "components/Common/ExportTable"
import LoadingSpinner from "components/Common/Loading-Spinner"
import Pagination from "components/Common/Pagination"
import "flatpickr/dist/themes/material_blue.css"
import { formateOnlyDate } from "helpers/utlities"
import useDebounce from "hooks/useDebounce"
import React, { useRef, useState } from "react"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap"
import { getAllUsers, searchAudits } from "services"
import styled from "styled-components"
import AuditFilters from "./AuditFilters"
import AuditTable from "./AuditTable"
import { getAuditCSV, getAuditPDF } from "./export"

const StyledAuditPage = styled.div`
  & > .actions {
    & > .basic-single {
      flex-basis: 100px;
    }

    & > * {
      flex: 1;
    }
  }
  & .search {
    position: relative;
    & > input {
      width: 30%;
    }
    &:has(input:focus) .animated_tooltip {
      transform: translateY(60px);
      z-index: 1;
      opacity: 1;
    }
    & > .animated_tooltip {
      position: absolute;
      top: 0;
      opacity: 0;
      z-index: -1;
      transition: all 250ms ease, opacity 200ms;
    }
    .basic-single {
      width: 75px;
    }
  }
`

const Audit = props => {
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  //Filter
  const [selectedUser, setSelectedUser] = useState("")
  const [selectedRole, setSelectedRole] = useState("")
  const [startPickDate, setStartPickDate] = useState("")
  const [endPickDate, setEndPickDate] = useState("")
  const startDateRef = useRef(null)
  const endDateRef = useRef(null)
  const [selectedActivityType, setSelectedActivityType] = useState("")
  const [modalFilter, setModalFilter] = useState(false)
  const toggleFilter = () => setModalFilter(prevState => !prevState)

  //sort
  const [sortBy, setSortBy] = useState("DESC")
  const sortOptions = [
    {
      value: "DESC",
      label: "Newest",
    },
    {
      value: "ASC",
      label: "Oldest",
    },
  ]
  const loadData = async (
    debouncedSearchTerm,
    startPickDate,
    endPickDate,
    offsetParam = offset,
    user = "",
    role = "",
    activity_type = ""
  ) => {
    const limit = 50
    try {
      const res = await searchAudits(
        { limit, offset: offsetParam },
        {
          description: debouncedSearchTerm,
          user,
          role,
          activity_type,
          sort: {
            created_at: sortBy,
          },
          start_date: startPickDate ? formateOnlyDate(startPickDate) : "",
          end_date: endPickDate ? formateOnlyDate(endPickDate) : "",
        }
      )
      setTotalEntries(res?.data?.auditCount)
      return res.data
    } catch (err) {
      return err
    }
  }
  const fetchUsers = async () => {
    try {
      const { data } = await getAllUsers()
      return data.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      }))
    } catch (err) {
      console.error(err)
    }
  }
  const { data: userOptions } = useQuery({
    queryKey: ["User Options"],
    queryFn: () => fetchUsers(),
    staleTime: 60 * 60 * 1_000,
  })
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "audits",
      {
        searchTerm: debouncedSearchTerm,
        page: currentPage,
        selectedUser,
        selectedRole,
        selectedActivityType,
        sortBy,
        startPickDate,
        endPickDate,
      },
    ],
    queryFn: () =>
      loadData(
        debouncedSearchTerm,
        startPickDate,
        endPickDate,
        offset,
        selectedUser,
        selectedRole,
        selectedActivityType
      ),
    // staleTime: 15 * 1000,
    // keepPreviousData: true,
  })

  const handleSearchInputChange = evt => {
    setSearchTerm(evt.target.value)
    setOffset(0)
    setCurrentPage(1)
    setTotalEntries(data?.auditCount)
  }
  const handlePageChange = pageNum => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }
  const areAnyFiltersSelected = () => {
    if (
      selectedActivityType !== "" ||
      selectedRole !== "" ||
      selectedUser !== "" ||
      ![undefined, ""].includes(startPickDate) ||
      ![undefined, ""].includes(endPickDate)
    ) {
      return true
    } else {
      return false
    }
  }
  const clearFilters = () => {
    setSelectedActivityType("")
    setSelectedUser("")
    setSelectedRole("")
    setStartPickDate("")
    setEndPickDate("")
    if (startDateRef && endDateRef) {
      startDateRef.current.flatpickr.clear()
      endDateRef.current.flatpickr.clear()
    }
  }
  const exportTable = async type => {
    try {
      const { data } = await await searchAudits(
        { limit: 0, offset },
        {
          description: debouncedSearchTerm,
          user: selectedUser,
          role: selectedRole,
          activity_type: selectedActivityType,
          sort: {
            created_at: sortBy,
          },
          start_date: startPickDate ? formateOnlyDate(startPickDate) : "",
          end_date: endPickDate ? formateOnlyDate(endPickDate) : "",
        }
      )
      type === "pdf" ? getAuditPDF(data.audits) : getAuditCSV(data.audits)
    } catch (err) {
      console.error(err)
      props.alert("Data export", false)
    }
  }
  return (
    <StyledAuditPage className="page-content">
      <div className="d-flex justify-content-end">
        <ExportTable loadFilteredData={exportTable} component={"SeperateFn"} />
      </div>
      <div
        className="d-flex align-items-center gap-1 actions"
        style={{ width: "96%", marginInline: "auto" }}
      >
        <div className="search">
          <InputGroup>
            <Input
              placeholder="Enter text to search"
              onChange={handleSearchInputChange}
            />
            <InputGroupText addontype="append">
              <span id="tooltipTarget" style={{ cursor: "pointer" }}>
                ?
              </span>
            </InputGroupText>
          </InputGroup>
          <UncontrolledTooltip target="tooltipTarget" placement="bottom">
            To get an exact match please enter your search term in double
            quotes(""). For example: "Created a Policy"
          </UncontrolledTooltip>
        </div>

        <Select
          options={sortOptions}
          defaultValue={sortBy}
          className="basic-single"
          classNamePrefix="select"
          placeholder="Sort by"
          onChange={option => setSortBy(option?.value || "ASC")}
        />
        <Flatpickr
          name="start_date"
          className="form-control d-block select-light-purple"
          placeholder="Start Period"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            defaultDate: startPickDate,
            mode: "single",
            maxDate: new Date() - 24 * 60 * 60 * 1000 * 1,
          }}
          onChange={val => {
            setStartPickDate(val[0])
          }}
          ref={startDateRef}
        />
        <Flatpickr
          name="end_date"
          className="form-control d-block select-light-purple"
          placeholder="End Period"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            defaultDate: endPickDate,
            mode: "single",
            maxDate: new Date(),
          }}
          onChange={val => setEndPickDate(val[0])}
          ref={endDateRef}
        />
        <Button
          style={{
            backgroundColor: "#f8f9fa",
            color: "black",
            margin: "15px 10px",
            border: "none",
            fontSize: "14px",
          }}
          onClick={toggleFilter}
        >
          <i className="mdi mdi-filter-variant me-1"></i>
          Filter
        </Button>
        {areAnyFiltersSelected() && (
          <Button
            className="d-flex align-items-center"
            style={{
              backgroundColor: "#f8f9fa",
              color: "black",
              margin: "15px 10px",
              border: "none",
              fontSize: "14px",
            }}
            onClick={clearFilters}
          >
            <i className="ph-x me-1"></i>
            Clear All Filters
          </Button>
        )}
      </div>
      <Modal isOpen={modalFilter} toggle={toggleFilter} className="modal-lg">
        <ModalHeader toggle={toggleFilter}>
          <i className="mdi mdi-filter-variant me-1"></i>
          Filters
        </ModalHeader>
        <ModalBody>
          <AuditFilters
            userOptions={userOptions}
            handleUser={setSelectedUser}
            handleRole={setSelectedRole}
            handleActivityType={setSelectedActivityType}
            toggleModal={toggleFilter}
          />
        </ModalBody>
      </Modal>
      {!isLoading && !isError && data?.auditCount === 0 && (
        <p
          style={{ color: "red" }}
        >{`We couldn't find any data for ${debouncedSearchTerm}. Please try another search `}</p>
      )}
      {isLoading && <LoadingSpinner />}
      <div style={{ width: "96%", marginInline: "auto" }}>
        {!isLoading && data?.audits.length ? (
          <>
            <AuditTable data={data?.audits} />
            <Pagination
              currentPage={currentPage}
              goToPage={handlePageChange}
              totalEntries={totalEntries}
            />
          </>
        ) : null}
      </div>
    </StyledAuditPage>
  )
}

export default Audit
