import React, { useState } from "react"
import styled from "@emotion/styled"
import * as Toggle from "@radix-ui/react-toggle"

export default function Metrics({
  hasUserSelectedPolicyDeadline,
  setHasUserSelectedPolicyDeadline,
  hasUserSelectedPolicyWins,
  setHasUserSelectedPolicyWins,
  hasUserSelectedPolicyNum,
  setHasUserSelectedPolicyNum,
  hasUserSelectedPolicyScore,
  setHasUserSelectedPolicyScore,
}) {
  return (
    <StyledContainer>
      <header>Select Metrics:</header>
      <div className="toggles_container">
        <ToggleButton
          pressed={hasUserSelectedPolicyScore}
          onPressChange={setHasUserSelectedPolicyScore}
        >
          Policy Score
        </ToggleButton>
        <ToggleButton
          pressed={hasUserSelectedPolicyNum}
          onPressChange={setHasUserSelectedPolicyNum}
        >
          No. of Policies
        </ToggleButton>
        <ToggleButton
          pressed={hasUserSelectedPolicyWins}
          onPressChange={setHasUserSelectedPolicyWins}
        >
          Policy Wins
        </ToggleButton>
        <ToggleButton
          pressed={hasUserSelectedPolicyDeadline}
          onPressChange={setHasUserSelectedPolicyDeadline}
        >
          Deadlines
        </ToggleButton>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  padding-block: 0.8em;
  margin-block-end: 2rem;
  & header {
    font-size: 14px;
    font-weight: 500;
  }
  & .toggles_container {
    display: flex;
    gap: 1em;
  }
`

function ToggleButton({ children, pressed, onPressChange }) {
  return (
    <Toggle.Root asChild pressed={pressed} onPressedChange={onPressChange}>
      <StyledToggleButton>{children}</StyledToggleButton>
    </Toggle.Root>
  )
}

const StyledToggleButton = styled.button`
  all: unset;
  padding: 0.5em 1em;
  background-color: #e5e5e5;
  border-radius: 1000vmax;
  transition: all 150ms;
  box-shadow: var(--shadow-elevation-low);
  &[data-state="on"],
  &:hover {
    background-color: #1091f4;
    color: white;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2px black;
  }
`
