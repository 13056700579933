import { useMutation } from "@tanstack/react-query"
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
} from "reactstrap"
import { get } from "services/AXIOS"
import {
  POLICIES_WITH_SUBCATEGORY,
  SOFTDELETEPOLICY,
} from "services/CONSTANTS"
import ReactApexChart from "react-apexcharts"
import "./policylandscapping.scss"
import CreatePolicy from "components/Common/PolicyTable/CreatePolicy"
import SubCategoryTable from "./SubCategoryTable"
import { patch } from "services/AXIOS"
import "./Step2.css"
import { CATEGORY } from "services/CONSTANTS"

function Step2({ country, alert }) {
  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "13px",
            color: void 0,
            offsetY: 60,
          },
          value: {
            offsetY: 22,
            fontSize: "16px",
            color: void 0,
            formatter: function (e) {
              return ""
            },
          },
        },
      },
    },
    colors: ["#13A800FF"],
    labels: [""],
  }

  const [open, setOpen] = useState("1")
  const [categories, setCategories] = useState([])
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const { mutate: getAllCategories } = useMutation(
    ["categories"],
    () => get(`${CATEGORY}/${country}`),
    {
      onSuccess: category => {
        setSelectedCategoryId(category[0]?.id)
        setCategories(category)
      },
    }
  )

  const [series, setSeries] = useState(0)

  const [value, setValue] = useState(0)
  const forceUpdate = () => setValue(value => value + 1)
  // update state to force render

  const [selectedCategoryId, setSelectedCategoryId] = useState()
  const [selectedCategory, setSelectedCategory] = useState()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleCategory = () => setDropdownOpen(prevState => !prevState)

  const [policies, setPolicies] = useState([])
  useEffect(() => {
    getAllCategories()
    loadPolicies() //loads policies with subcategories for tables //reemove
  }, [])

  useEffect(() => {
    loadPolicies()
    getAllCategories()
  }, [value])

  useEffect(() => {
    getAllCategories()
    setSelectedCategory()
    loadPolicies()
  }, [country])

  useEffect(() => getCategory(selectedCategoryId), [categories])

  useEffect(() => getAllCategories(), [policies])

  const loadPolicies = () => {
    get(`${POLICIES_WITH_SUBCATEGORY}`)
      .then(data => setPolicies(data))
      .catch(err => {
        alert("Data loading", false)
        console.log(err)
      })
  }

  const deletePolicy = id => {
    patch(`${SOFTDELETEPOLICY}/${id}`)
      .then(() => {
        alert("Policy deletion", true)
        loadPolicies()
      })
      .catch(err => {
        alert("Policy deletion", false)
        console.log(err)
      })
  }

  // const getCategory = (id) => {
  //   get(`/category/${id}`)
  //   .then((data)=>setSelectedCategory(data))
  //   .catch(err=> {//add alert
  //   })
  // }

  const getCategory = id => {
    categories.map(category => {
      if (category.id === id) {
        setSelectedCategory(category)
      }
    })
  }

  //useEffect(() => {}, [categories])

  useEffect(() => {
    let count = 0
    let policies = 0
    if (selectedCategory) {
      selectedCategory?.sub_category?.forEach(sub_cat => {
        count = count + sub_cat.policy_count
        policies = policies + sub_cat?.policy.length
      })
    }
    policies && count ? setSeries((policies * 100) / count) : setSeries(0)
  }, [selectedCategory])

  return (
    <div>
      <Card>
        <CardBody>
          <div className=" d-flex justify-content-between align-items-start">
            <p className=" fs-5 mb-0 fw-bold d-flex align-items-center">
              <i className="ph-check-square-offset me-1 fs-3"></i>Step 2:
              Validation
            </p>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleCategory}
              direction={"down"}
            >
              <DropdownToggle
                caret
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  border: "none",
                }}
              >
                Select Category
                <i className="ph-caret-down ms-1"></i>
              </DropdownToggle>
              <DropdownMenu>
                {categories.length ? (
                  categories.map((category, i) => {
                    return (
                      <DropdownItem
                        key={i}
                        onClick={() => {
                          setSelectedCategoryId(category.id)
                          getCategory(category.id)
                        }}
                      >
                        {category.name}
                      </DropdownItem>
                    )
                  })
                ) : (
                  <DropdownItem>
                    No category available in the country
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        </CardBody>
      </Card>
      {selectedCategory ? (
        <div>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <i className="ph-suitcase me-2 fs-4"></i>
                <p className="fw-bold fs-5 m-0">{selectedCategory.name}</p>
                <div
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "10px",
                    fontSize: "11px",
                    fontWeight: "lighter",
                  }}
                  className="px-2 ms-2"
                >
                  Category
                </div>
              </div>
              <br />
              <div className=" d-flex">
                <Card
                  style={{
                    backgroundColor: "#FFE5EEFF",
                    width: "200px",
                  }}
                >
                  <CardBody className="p-0">
                    <div className="d-flex justify-content-between align-items-start p-2">
                      <div>
                        <p className="fw-bold" style={{ fontSize: "12px" }}>
                          {" "}
                          Total Sub Categories
                        </p>
                        <p className=" fs-3 fw-bold">
                          {selectedCategory?.sub_category?.length}
                        </p>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#FF669BFF",
                          color: "white",
                        }}
                        className="p-1 rounded-2"
                      >
                        <i className="ph-folder fs-2" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  style={{
                    backgroundColor: "#FFE9E5FF",
                    width: "200px",
                  }}
                  className="mx-5"
                >
                  <CardBody className="p-0">
                    <div className="d-flex justify-content-between align-items-start p-2">
                      <div>
                        <p className="fw-bold" style={{ fontSize: "12px" }}>
                          {" "}
                          Total Policies
                        </p>
                        <p className=" fs-3 fw-bold">
                          {selectedCategory?.sub_category.reduce(
                            (prev, curr) => {
                              return prev + curr?.policy_count
                            },
                            0
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#FF785CFF",
                          color: "white",
                        }}
                        className="p-1 rounded-2"
                      >
                        <i className="ph-file-text fs-2" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  style={{
                    backgroundColor: "#E5FFED",
                    width: "200px",
                  }}
                >
                  <CardBody className="p-0">
                    <div className="d-flex justify-content-between align-items-start p-2 pb-0">
                      <div>
                        <p className="fw-bold m-0" style={{ fontSize: "12px" }}>
                          Progress
                        </p>
                        <div className="d-flex align-items-center">
                          <ReactApexChart
                            options={options}
                            series={[series]}
                            type="radialBar"
                            height={120}
                            width={100}
                            className="apex-charts"
                          />
                          <p className=" fs-3 fw-bold">
                            {Math.round(series)} %
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#008D5BFF",
                          color: "white",
                        }}
                        className="p-1 rounded-2"
                      >
                        <i className="ph-align-right fs-2"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <hr />
              <div className="d-flex align-items-center mb-2">
                <i className="ph-folder me-2 fs-4"></i>
                <p className=" fw-bold fs-5 mb-0">Sub-Categories</p>
              </div>
              <br />
              <Accordion open={open} toggle={toggle}>
                {selectedCategory?.sub_category?.map((subcategory, index) => {
                  const policiesUploaded = subcategory.policy.length
                  const totalPolicies = subcategory.policy_count
                  const color = policiesUploaded
                    ? policiesUploaded >= totalPolicies
                      ? "#008D5BFF"
                      : "#F18F01FF"
                    : "red"
                  return (
                    <div key={index}>
                      <AccordionItem>
                        <AccordionHeader
                          targetId={(index + 1).toString()}
                          className="step-accordion"
                        >
                          <div className="w-100"> {subcategory.name}</div>
                          <CreatePolicy
                            subId={subcategory.id}
                            lCountry={country}
                            isVisible={
                              open === (index + 1).toString() &&
                              policiesUploaded < totalPolicies
                            }
                            alert={alert}
                            updateParent={forceUpdate}
                          />
                          <div
                            className="mx-5"
                            style={{ width: "230px", "--color": color }}
                          >
                            <div className="p-0 pb-1 progress-bar-text text-center">{`${policiesUploaded}/${totalPolicies} Policies Uploaded`}</div>
                            <Progress
                              style={{
                                height: "6px",
                              }}
                              value={(policiesUploaded / totalPolicies) * 100}
                            />
                          </div>
                        </AccordionHeader>
                        <AccordionBody accordionId={(index + 1).toString()}>
                          <SubCategoryTable
                            policies={policies}
                            subId={subcategory.id}
                            deletePolicy={deletePolicy}
                          />
                        </AccordionBody>
                      </AccordionItem>
                      <br />
                    </div>
                  )
                })}
              </Accordion>
            </CardBody>
          </Card>
        </div>
      ) : null}
    </div>
  )
}
export default Step2
