import React from "react"
import { formateOnlyDate } from "helpers/utlities"
import { Link } from "react-router-dom"

function BenchmarkingTable({ data }) {
  const mapData = () => {
    if (data) {
      return data.map((data, i) => {
        return (
          <tr key={i}>
            <td className="d-flex justify-content-center col-wdt-4">
              <div
                className="px-2 color-contrast-tab d-flex align-items-center"
                style={{
                  "--tabcolor": `${data.label_color}`,
                  "--bgcolor": `${data.label_color}` + "1a",
                }}
              >
                {data.label_name}
              </div>
            </td>
            <td className="col-wdt-4">
              <div className="form-link">
                <Link
                  to={`/system-forms/${data.country_id}/${data.label_id}/${data.form_id}`}
                  className="text-success"
                >
                  <i className="ph-arrow-up-right me-2"></i>
                  {data.form_name}
                </Link>
              </div>
            </td>
            {/* add link */}
            <td className="col-wdt-4">{data.form_status || "PENDING"}</td>
            <td className="col-wdt-5">{data.score || 0}</td>
            <td className="col-wdt-4">
              <Link
                className="text-start"
                to={`/user-management/${data.user_id}`}
              >
                <i className="mdi mdi-account me-1" />
                {data.owner_name}
              </Link>
              {data.task_owner_id && (
                <div>
                  <Link
                    className="text-start"
                    style={{ color: "blue", opacity: 0.5 }}
                    to={`/user-management/${data.task_owner_id}`}
                  >
                    <i className="ph-user-switch me-1" />{" "}
                    {data.task_owner_first_name} {data.task_owner_last_name}
                  </Link>
                </div>
              )}
            </td>
            <td className="col-wdt-4">
              {data.updated_at && formateOnlyDate(data.updated_at)}
            </td>
          </tr>
        )
      })
    } else {
      return (
        <tr>
          <td colSpan={5} scope="row">
            Milestone not available
          </td>
        </tr>
      )
    }
  }

  return (
    <div>
      <div className="table-responsive ">
        <table className="table table-striped" style={{ textAlign: "center" }}>
          <thead className="table-header ">
            <tr>
              <th className="col-wdt-4">System</th>
              <th className="col-wdt-4">Benchmarking Milestone</th>
              <th className="col-wdt-4">Status</th>
              <th className="col-wdt-5">Score</th>
              <th className="col-wdt-4">Task Owner</th>
              <th className="col-wdt-4">Updated At</th>
            </tr>
          </thead>
          <tbody>{mapData()}</tbody>
        </table>
      </div>
    </div>
  )
}

export default BenchmarkingTable
