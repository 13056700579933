import React from "react"
import { useSelector } from "react-redux"
import Select from "react-select"
import colourStyles from "./LabelColorStyles"
import styled from "styled-components"

const StyledModalBody = styled.div`
  & > :not(:last-child) {
    margin-block-end: 1rem;
  }
  & label {
    align-self: center;
  }
  & .form_group:first-of-type {
    display: ${props => (props.component === "tracker" ? "flex" : "none")};
    gap: 1em;

    & > div {
      flex-grow: 1;
    }
  }
`
const StyledCompositeFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  height: min(250px, 450px);
  overflow-y: auto;
  background-color: #f3f4f6;
  & button {
    all: unset;
    border-radius: 3px;
    text-align: center;
  }
  & > .filter_group {
    display: grid;
    padding-inline: 1em;
    grid-template-columns: 6.5fr 3fr 0.5fr;
    gap: 2em;
    margin-block-end: 1em;

    & > button {
      align-self: end;
      height: 36px;
      width: 36px;
      background-color: transparent;
      color: #b90b11;
      border: 1px solid;
      border-radius: 3px;

      &:focus {
        outline: 1px solid !important;
        outline-offset: 2px;
      }
    }
  }
  & > .add_new_pair {
    align-self: flex-end;
    background-color: #3b852d;
    color: whitesmoke;
    padding: 0.5em 1em;
    margin-inline-end: 74px;
  }
`
function FilterPolicyActivities({
  handleCountries,
  selectedCountries,
  applyFilter,
  component,
  activities,
  compositeFilters,
  handleAddCompositeFilter,
  handleRemoveCompositeFilter,
  handleCompositeFilterChange,
}) {
  const { countries } = useSelector(state => state.list)

  const countryOptions = countries.map(country => {
    return { value: country.id, label: country.label }
  })

  const statusOptions = [
    { value: "1", label: "PENDING", color: "brown" },
    { value: "2", label: "IN PROGRESS", color: "lightgreen" },
    { value: "3", label: "COMPLETE", color: "green" },
    { value: "4", label: "PAST DEADLINE", color: "red" },
    { value: "5", label: "OVERRIDE", color: "orange" },
  ]

  const milestoneOptions = activities?.map(activity => {
    return {
      value: activity.id,
      label: activity.name,
    }
  })

  return (
    <StyledModalBody component={component}>
      <div className="form_group">
        <label htmlFor="location">
          <i className="bx bx-world light-icon me-1" />
          Location:
        </label>
        <Select
          isMulti
          aria-labelledby="location"
          defaultValue={selectedCountries}
          options={countryOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Select Multiple"
          onChange={handleCountries}
          id="location"
        />
      </div>

      <StyledCompositeFilter>
        {compositeFilters.map((filter, idx) => (
          <div className="filter_group" key={idx}>
            <div>
              <label htmlFor={`Milestone${idx + 1}`}>
                Milestone {`${idx + 1}`}
              </label>
              <Select
                aria-labelledby={`Milestone${idx + 1}`}
                value={
                  milestoneOptions.find(
                    option => option.value == filter.milestone
                  ) ?? null
                }
                options={milestoneOptions}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a Milestone"
                maxMenuHeight={180}
                onChange={option => {
                  handleCompositeFilterChange(idx, "milestone", option.value)
                }}
              />
            </div>
            <div>
              <label htmlFor={`Status${idx + 1}`}>Status {`${idx + 1}`}</label>
              <Select
                aria-labelledby={`Status${idx + 1}`}
                value={
                  statusOptions.find(
                    option => option.label === filter.status
                  ) ?? null
                }
                options={statusOptions}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a status"
                onChange={option => {
                  handleCompositeFilterChange(idx, "status", option.label)
                }}
                styles={colourStyles}
              />
            </div>
            <button
              type="button"
              onClick={() => handleRemoveCompositeFilter(idx)}
            >
              <i className="ph-trash-simple"></i>
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddCompositeFilter}
          className="add_new_pair"
        >
          Add new pair
        </button>
      </StyledCompositeFilter>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div
          className="btn btn-success common-button"
          style={{ width: "90%" }}
          onClick={applyFilter}
        >
          Apply
        </div>
      </div>
    </StyledModalBody>
  )
}

export default FilterPolicyActivities
