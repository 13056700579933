import styled from "@emotion/styled"
import React from "react"
import Lottie from "react-lottie"
import animationData from "../../assets/lotties/account-suspended.json"
import background from "../../assets/images/permissions_background.svg"

const StyledAccountSuspendedPage = styled.div`
  display: grid;
  place-items: center;
  min-height: 100vh;
  gap: 2.5em;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
`
const StyledCard = styled.div`
  background-color: whitesmoke;
  padding: 2em 4em;
  border-radius: 2em;
  text-align: center;
`

const defaultOptions = {
  loop: 1,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}
export default function AccountSuspended() {
  return (
    <StyledAccountSuspendedPage>
      <StyledCard>
        <Lottie options={defaultOptions} height={250} width={250} />
        <p>Your account has been suspended.</p>
        <p className="text-muted">
          Please contact your system administrator for more information.
        </p>
      </StyledCard>
    </StyledAccountSuspendedPage>
  )
}
