import React from "react"
import { formateOnlyDate } from "helpers/utlities"
import { Table, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

function FormsTable({ forms, setDeleteModal, userRole }) {
  return (
    <Table className="custom-table table-striped ">
      <thead>
        <tr
          style={{
            backgroundColor: "#F3F4F6",
          }}
        >
          {/* <th style={{ width: "50px" }}></th> */}
          <th>Milestone Name</th>
          {/* <th>Milestone Owner</th> */}
          <th>Mapped To</th>
          <th>Type</th>
          {/* <th>Last policy Evaluated</th> */}
          <th>Status</th>
          <th>Created At</th>
          <th style={{ width: "80px" }}>Actions</th>
        </tr>
      </thead>
      <tbody className="custom-table">
        {forms?.map(node => (
          <tr key={node.id}>
            {/* <td>
                      <i className="mdi mdi-arrow-top-right" />
                    </td> */}
            <td>{node.name}</td>
            {/* <td>
                      <Link
                        className="btn-sm"
                        to={`/user-management/${node.owner.id}`}
                      >
                        <i className="mdi mdi-account" />{" "}
                        {`${node?.owner?.first_name} ${node?.owner?.last_name}`}
                      </Link>
                    </td> */}

            <td>
              {node.type == "benchmarking" && (
                <p
                  style={{
                    backgroundColor: node.label?.label_color,
                    color: "#fff",
                    width: " fit-content",
                  }}
                  className="px-1 rounded-pill"
                >
                  {node.label?.label_name}
                </p>
              )}
              {node.type != "benchmarking" && (
                <p
                  style={{
                    backgroundColor: node.stage?.backgroundColor,
                    color: node.stage?.color,
                    width: " fit-content",
                  }}
                  className="px-1 rounded-pill"
                >
                  Stage {node.stage.stage} - {node.stage.label}
                </p>
              )}
            </td>
            <td>{node.type}</td>

            {/* <td>{"Last policy Evaluated"}</td> */}
            <td>
              {node.is_deleted
                ? "Archived"
                : node.is_published
                ? "Published"
                : "Unpublished"}
            </td>
            <td>{formateOnlyDate(node.created_at)}</td>
            <td className=" align-items-center m-0">
              <div className="d-flex justify-content-between">
                <Link
                  to={{
                    pathname: "/edit-form",
                    state: {
                      form: node,
                      userRole: userRole,
                    },
                  }}
                  className="text-success"
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <p
                  style={{ cursor: "pointer" }}
                  className="m-0 text-danger"
                  onClick={() => setDeleteModal({ form: node, show: true })}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </p>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default FormsTable
