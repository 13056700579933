import React from "react"
import styled from "@emotion/styled"
import { Country } from "constants/global"
import LoadingSpinner from "components/Common/Loading-Spinner"

const StyledTable = styled.table`
  margin-block: 3rem;
  box-shadow: var(--shadow-elevation-low);

  & colgroup {
    & > * {
      width: 20%;
    }
  }

  & th,
  & td {
    transition: all 250ms;
  }
  & th:nth-of-type(2),
  & td:nth-of-type(2) {
    background-color: ${props =>
      props.selectedFilter === "number_of_policies" && "#A80091"};
    color: ${props => props.selectedFilter === "number_of_policies" && "#fff"};
  }

  & th:nth-of-type(3),
  & td:nth-of-type(3) {
    background-color: ${props =>
      props.selectedFilter === "policy_score" && "#A80091"};
    color: ${props => props.selectedFilter === "policy_score" && "#fff"};
  }

  & th:nth-of-type(4),
  & td:nth-of-type(4) {
    background-color: ${props =>
      props.selectedFilter === "policy_win" && "#A80091"};
    color: ${props => props.selectedFilter === "policy_win" && "#fff"};
  }

  & th:nth-of-type(5),
  & td:nth-of-type(5) {
    background-color: ${props =>
      props.selectedFilter === "days_past_deadline" && "#A80091"};
    color: ${props => props.selectedFilter === "days_past_deadline" && "#fff"};
  }
`
export default function LocationStatsTable({ data, filterValue, isLoading }) {
  if (isLoading) return <LoadingSpinner />
  return (
    <StyledTable selectedFilter={filterValue} className="custom-table table">
      <colgroup>
        <col />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <thead>
        <tr>
          <th>Location</th>
          <th>Total Policies</th>
          <th>Avg. Policy Score</th>
          <th>Policies Implemented</th>
          <th>Policies Delayed</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(data)?.map(stat => (
          <tr key={stat?.country_label}>
            <td>{Country[stat?.country_label]}</td>
            <td>{stat?.number_of_policies}</td>
            <td>{stat?.policy_score}</td>
            <td>{stat?.policy_win}</td>
            <td>{stat?.days_past_deadline}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}
