import React from "react"
import Lottie from "react-lottie"
import styled from "@emotion/styled"
import animationData from "../../../assets/lotties/access-denied"

const StyledAccessDeniedContainer = styled.div`
  min-height: 70vh;
  display: grid;
  place-items: center;

  & p {
    opacity: 0.7;
  }
`

const AccessDenied = () => {
  const defaultOptions = {
    loop: 1,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <StyledAccessDeniedContainer>
      <Lottie options={defaultOptions} height={300} width={300} />
      <h3>You do not have access to this page.</h3>
      <p>Please contact your Site Administrator(s) for more information.</p>
    </StyledAccessDeniedContainer>
  )
}

export default AccessDenied
