import React from "react"
import { Link } from "react-router-dom"
import { size, map } from "lodash"
import { formateOnlyDate } from "helpers/utlities"

const toLowerCase1 = str => {
  return str === "" || str === undefined ? "" : str.toLowerCase()
}

const Name = cell => {
  return cell.value ? cell.value : ""
}

const Boolean = cell => {
  return cell.value ? "Yes" : "No"
}

const Code = cell => {
  return cell.value ? cell.value : ""
}

const Date = cell => {
  return cell.value ? formateOnlyDate(cell.value) : ""
}

const Email = cell => {
  return cell.value ? cell.value : ""
}

const Countries = cell => {
  if (cell.value) {
    let list = '<ul className="ps-4">'
    list += cell.value
      .map(
        country =>
          `<li key="${country.id}" className="py-1">${country.label}</li>`
      )
      .join("")
    list += "</ul>"
    return list
  }
  return ""
}

const Tags = cell => {
  return (
    <>
      {map(
        cell.value,
        (tag, index) => (
          //index < 2 && (
          <Link
            to="#"
            className="badge bg-info font-size-11 m-1"
            key={"_skill_" + cell.value + index}
          >
            {tag.label}
          </Link>
        )
        // )
      )}
      {/* {size(cell.value) > 2 && (
        <Link
          to="#"
          className="badge badge-soft-primary font-size-11 m-1"
          key={"_skill_" + cell.value}
        >
          {size(cell.value) - 1} + more
        </Link>
      )} */}
    </>
  )
}

const Projects = cell => {
  return cell.value ? cell.value : ""
}

const Img = cell => {
  return (
    <>
      {!cell.value ? (
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">
            {cell.data[0].name.charAt(0)}
          </span>
        </div>
      ) : (
        <div>
          <img className="rounded-circle avatar-xs" src={cell.value} alt="" />
        </div>
      )}
    </>
  )
}

export { Code, Name, Email, Tags, Projects, Img, Date, Boolean, Countries }
