import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

import { postRegister } from "../../../services/authServices"
import { listCountries, listStages } from "../../list/actions"
import { userSession } from "../profile/actions"

import {
  setAccessToken,
} from "../login/actions"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, token, history } }) {
  try {
    const response = yield call(postRegister, user, token)
    localStorage.setItem("token", response.data.access_token)
    localStorage.setItem("loggedInTime", new Date())
    localStorage.setItem(
      "countries",
      JSON.stringify(response.data.user.countries)
    )
    localStorage.setItem(
      "countries_ids",
      JSON.stringify(response.data.user.countries_id)
    )
    localStorage.setItem("stages", JSON.stringify(response.data.user.stages))
    localStorage.setItem("role", response.data.user.role)
    yield put(setAccessToken(response.data.access_token))
    yield put(userSession())
    yield put(listCountries())
    yield put(listStages())
    history.push("/dashboard")
    yield put(registerUserSuccessful(response))
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
