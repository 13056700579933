import { useMutation } from "@tanstack/react-query"
import Preview from "pages/FormManagement/preview"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Button } from "reactstrap"
import { post } from "services/AXIOS"
import { getLandscapingForm } from "services/formServices"

const FillFormLandscaping = props => {
  const { formId: formID, subCategoryId: subCategoryId } = useParams()

  const history = useHistory()
  const { location } = props
  const [formData, setFormData] = useState(null)
  const [responses, setResponses] = useState([])
  const [mode, setMode] = useState("read")
  const loadForm = async query => {
    try {
      const { data } = await getLandscapingForm(query)
      setFormData(data)
      setMode(data?.mode)
      setResponses(
        Array.from({ length: data?.questions.length }, (el, idx) => ({
          question_id: data?.questions[idx].id,
          response:
            data?.questions[idx].category_answers.length > 0
              ? data?.questions[idx].category_answers[0].response
              : "",
          weight: data?.questions[idx].category_answers[0]?.weight ?? 0,
        }))
      )
    } catch (err) {
      console.error(err)
      setFormData(null)
    }
  }

  useEffect(() => {
    if (subCategoryId && formID) {
      loadForm({ subCategoryId, formID })
    }
  }, [subCategoryId, formID])

  const { mutate: saveResponse } = useMutation(
    ["saveResponse"],
    () => {
      const response = {
        questions: responses.map(response => {
          return {
            question_id: response.question_id,
            response: response.response,
          }
        }),
        subcategory_id: subCategoryId,
        form_id: formID,
      }
      return post("/form/subcategory/response", response).then(data => data)
    },
    {
      onSuccess: data => {
        if (data.statusCode == 401) {
          props.alert("Response Save Failed!", false)
        } else if (location?.state?.subcategory_id) {
          props.alert("Responses Saved!", true)
          setTimeout(() => {
            history.push("/policy-landscape")
          }, 2 * 1000)
        } else {
          props.alert("Responses Saved!", true)
          setTimeout(() => {
            history.push(`/policy-landscape`)
          }, 2 * 1000)
        }
      },
    }
  )

  const responseHandler = response => {
    const { id, index, value, weight, responseType } = response
    const tempResponses = [...responses]
    tempResponses[index].response = value
    if (responseType === "answer") tempResponses[index].weight = Number(weight)
    setResponses(tempResponses)
  }
  return (
    <div className="page-content bg-light min-vh-100">
      <div className=" d-flex justify-content-between mx-3 align-items-start">
        <div className=" ">
          <p className=" fs-1 mb-0 fw-bold">
            {formData?.sub_category?.subcategory_name}
          </p>
        </div>
        <div>
          <Button
            onClick={saveResponse}
            color="primary"
            className=" text-nowrap"
            style={{
              float: "right",
            }}
          >
            Save Response
          </Button>
        </div>
      </div>
      {formData && (
        <Preview
          form={formData}
          responseHandler={responseHandler}
          mode={mode}
          responseName="category_answers"
        />
      )}
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FillFormLandscaping)

// export default FillForm
