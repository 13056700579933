import React, { useState } from "react"
import { Link } from "react-router-dom"
import Reassign from "./Reassign"
import { EnumStage } from "constants/global"
import { REASSIGN_BENCHMARKING_TASK } from "services/CONSTANTS"
import { post } from "services/AXIOS"

function BenchmarkingTasksTable({ data, loadData, alert }) {
  const [reassignModal, setReassignModal] = useState(false)

  const toggleReassign = () => setReassignModal(!reassignModal)

  const [selectedMilestoneOwner, setSelectedMilestoneOwner] =
    useState(undefined)
  const [selectedTaskOwner, setSelectedTaskOwner] = useState(undefined)
  const [selectedFormsLabelId, setSelectedFormsLabelId] = useState(null)
  const [selectedFormId, setSelectedFormId] = useState(null)
  const [selectedLabelId, setSelectedLabelId] = useState(null)
  const [selectedTask, setSelectedTask] = useState({})

  const reassign = selectedOwner => {
    post(REASSIGN_BENCHMARKING_TASK, {
      selectedOwnerId: selectedOwner,
      milestoneOwnerId: selectedMilestoneOwner.ownerId,
      formsLabelId: selectedFormsLabelId,
      form_id: selectedFormId,
      label_id: selectedLabelId,
      country_id: selectedTask.country_id,
    })
      .then(data => loadData())
      .catch(err => {
        alert("Task Reassign", false)
        console.log(err)
      })
  }

  const mapData = () => {
    if (data.length) {
      return data.map((data, i) => {
        return (
          <tr key={i}>
            <td className="col-wdt-4">
              <Link
                className="text-start"
                to={`/user-management/${data.user_id}`}
              >
                <i className="mdi mdi-account me-1" />
                {data.owner_name}
              </Link>
              {data.task_owner_id && (
                <div>
                  <Link
                    className="text-start"
                    style={{ color: "blue", opacity: 0.5 }}
                    to={`/user-management/${data.task_owner_id}`}
                  >
                    <i className="ph-user-switch me-1" />{" "}
                    {data.task_owner_first_name} {data.task_owner_last_name}
                  </Link>
                </div>
              )}
            </td>
            <td className="d-flex justify-content-center col-wdt-4">
              <div
                className="px-2 color-contrast-tab d-flex align-items-center"
                style={{
                  "--tabcolor": `${data.label_color}`,
                  "--bgcolor": `${data.label_color}` + "1a",
                }}
              >
                {data.label_name}
              </div>
            </td>
            <td className="col-wdt-4">
              <div className="form-link">
                <Link
                  to={`/system-forms/${data.country_id}/${data.label_id}/${data.form_id}`}
                  className="text-success"
                >
                  <i className="ph-arrow-up-right me-2"></i>
                  {data.form_name}
                </Link>
              </div>
            </td>
            {/* add link */}
            <td className="col-wdt-4">{data.form_status || "PENDING"}</td>
            <td className="col-wdt-4">
              {data.form_status != "COMPLETE" && (
                <button
                  className="common-outlined-button"
                  onClick={() => {
                    toggleReassign()
                    setSelectedMilestoneOwner({
                      owner: data.owner_name,
                      ownerId: data.user_id,
                    })
                    setSelectedFormsLabelId(data.form_label_map_id)
                    setSelectedFormId(data.form_id)
                    setSelectedLabelId(data.label_id)
                    setSelectedTaskOwner(
                      data.task_owner_id && {
                        owner:
                          data.task_owner_first_name +
                          " " +
                          data.task_owner_last_name,
                        ownerId: data.task_owner_id,
                      }
                    )
                    setSelectedTask({
                      stage_id: EnumStage.BENCHMARKING,
                      country_id: data.country_id,
                      index: i,
                    })
                  }}
                >
                  + reassign
                </button>
              )}
            </td>
          </tr>
        )
      })
    } else {
      return (
        <tr>
          <td colSpan={5} scope="row">
          No Data Available
          </td>
        </tr>
      )
    }
  }

  return (
    <div>
      <div className="table-responsive ">
        <table className="table table-striped" style={{ textAlign: "center" }}>
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "25%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <thead className="table-header ">
            <tr>
              <th>Task Owner</th>
              <th>System Name</th>
              <th>Milestone</th>
              <th>Status</th>
              <th>Reassign</th>
            </tr>
          </thead>
          <tbody>{mapData()}</tbody>
        </table>
        <Reassign
          modal={reassignModal}
          toggle={toggleReassign}
          milestoneOwner={selectedMilestoneOwner}
          selectedTask={selectedTask}
          taskOwner={selectedTaskOwner}
          reassign={reassign}
          alert={alert}
        />
      </div>
    </div>
  )
}

export default BenchmarkingTasksTable
