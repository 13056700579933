export const downloadFile = (fileName, name, file) => {
    const ext = fileName.split(".")[1];
    const fileURL = window.URL.createObjectURL(new Blob([file]));
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    const downloadName = `${name}.${ext}`;
    fileLink.setAttribute("download", downloadName);
    fileLink.setAttribute("target", "_blank");
    fileLink.click();
    URL.revokeObjectURL(fileURL);
}