import React from 'react';

function MinusCircle({depth}) {
    let primaryColor, secondaryColor;
    if(depth === 1){
        primaryColor = "#ffffff";
        secondaryColor = "#947FFF"
    }else{
        primaryColor = "green";
        secondaryColor = "#ffffff"
    }
    return (
        <g transform={depth=== 2? 'scale(0.05 0.05) translate(410,250)': 'scale(0.05 0.05) translate(750,250)'}>
            <rect width="256" height="256" fill="none"></rect>
            <circle cx="128" cy="128" r="96" fill={primaryColor} stroke={primaryColor} strokeMiterlimit="10" strokeWidth="16"></circle>
            <line x1="88" y1="128" x2="168" y2="128" fill="none" stroke={secondaryColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line>
        </g>
    );
}

export default MinusCircle;
