import React from 'react';
import { Button, Input } from 'reactstrap';

function AddSubCategory({addSubCategory, addSubCategoryDetails}) {
    return (
        <div>
            <div className='d-flex align-items-center'>
                <p className='mb-0 fw-bold' style={{width: "140px"}}>Name:</p>
                <Input
                    id="name"
                    placeholder="Enter subcategory Name"
                    className='ms-2'
                    onChange={(e)=>addSubCategoryDetails(e)}
                />
            </div>
            <br />
            <div className='d-flex align-items-center'>
                <p className='mb-0 fw-bold' style={{width: "150px"}}>No. of policies:</p>
                <Input
                    onChange={e => addSubCategoryDetails(e)}
                    id="policies"
                    type="number"
                    defaultValue={1}
                    min="1"
                />
            </div>
            <br/>
            <div className='d-flex align-items-center'>
                <p className='mb-0 fw-bold' style={{width: "150px"}}>Add Notes:</p>
                <Input
                    onChange={e => addSubCategoryDetails(e)}
                    id="notes"
                    placeholder="Enter item description"
                    type='textarea'
                />
            </div>
            <br/>
            <div className="d-flex justify-content-center">
                <Button
                style={{
                    backgroundColor: "#000000",
                    color: "white",
                    border: "none",
                }}
                onClick={addSubCategory}
                >
                Add Subcategory
                </Button>{" "}
            </div>
        </div>
    );
}

export default AddSubCategory;