import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"
import DefaultTableContainer from "../../../components/Common/DefaultTableContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
} from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Country,
  Role,
  Permission,
  LANDSCAPING_STAGES,
  ETRACKER_STAGES,
  BENCHMARKING_STAGES,
} from "constants/global"
import { Name, Date, Tags, Status } from "./userCol"

import { useSelector } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import ErrorModal from "components/Common/ErrorModal"
import SuccessModal from "components/Common/SuccessModal"

import { getUsers, deleteUser, updateUser } from "services"
import LoadingSpinner from "components/Common/Loading-Spinner"

const UsersList = props => {
  const [userValue, setUserValue] = useState()
  const [userList, setUserList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [modal, setModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [errorModal, setErrorModal] = useState("")
  const [successModal, setSuccessModal] = useState("")
  const [filterCountries, setFilterCountries] = useState([])
  const [countryOptions, setCountryOptions] = useState([])

  const { countries, stages } = useSelector(state => state.list)
  const stageOptions = [
    {
      label: "E-Tracker",
      options: ETRACKER_STAGES.map(stage => ({
        value: Number(stage.id),
        label: stage.name,
      })),
    },
    {
      label: "Landscaping",
      options: LANDSCAPING_STAGES.map(stage => ({
        value: stage.id,
        label: stage.name,
      })),
    },
    {
      label: "Benchmarking",
      options: BENCHMARKING_STAGES.map(stage => ({
        value: stage.id,
        label: stage.name,
      })),
    },
  ]
  const groupHeadingStyles = {
    color: "blue",
    fontWeight: "bold",
    letterSpacing: 1.125,
  }
  const { country, user } = useSelector(state => state.Profile)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      countries:
        (userValue &&
          userValue.countryOptionsThatAdminHasAccessTo?.filter(country =>
            userValue.countries.some(
              existingCountry => existingCountry.value === country.value
            )
          )) ||
        [],
      stages: (userValue && userValue.stages) || [],
      status: (userValue && userValue.status) || [],
    },
    validationSchema: Yup.object({
      countries: Yup.array()
        .min(1, "Pick at least 1 country")
        .required("Please Select Country"),
      stages: Yup.array().required("Please Fill Stages"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const userData = {
          countries: values["countries"].map(country => country.value),
          stages: values["stages"].map(stage => stage.value),
          status: values["status"].value,
        }
        updateUser(userValue.id, userData)
          .then(res => {
            setRefresh(!refresh)
            props.alert("User updated", true)
          })
          .catch(err => {
            props.alert("User not udpated", false)
          })
        validation.resetForm()
        setIsEdit(false)
      }

      validation.resetForm()
      toggle()
    },
  })

  const columns = useMemo(
    () => [
      // {
      //   Header: "ID",
      //   accessor: "id",
      //   disableGlobalFilter: true,
      //   disableFilters: true,
      // },
      {
        Header: "Full Name",
        accessor: "user.first_name",
        disableSortBy: true,
        disableFilters: true,
        Cell: cellProps => {
          return (
            cellProps.row.original.user.first_name +
            " " +
            cellProps.row.original.user.last_name
          )
        },
      },
      // {
      //   Header: "Last Name",
      //   accessor: "user.last_name",
      //   disableSortBy: true,
      //   Cell: cellProps => {
      //     return <Name {...cellProps} />
      //   },
      // },
      {
        Header: "Email",
        accessor: "email",
        disableSortBy: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Role",
        accessor: "role",
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: cellProps => {
          return Role[cellProps.row.original.role]
        },
      },
      {
        Header: "Organization",
        accessor: "user.organization.name",
        disableSortBy: true,
        disableGlobalFilter: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Location",
        accessor: "countries",
        disableSortBy: true,
        disableGlobalFilter: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Tags {...cellProps} countries={countries} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        disableGlobalFilter: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableSortBy: true,
        disableFilters: true,
        disableGlobalFilter: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/user-management/${cellProps.row.original.id}`}
                className="text-success"
              >
                <i
                  className="mdi mdi-open-in-app font-size-18"
                  id="viewtooltip"
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <>
                {user.id !== cellProps.row.original.id &&
                  cellProps.row.original.status != "archived" && (
                    <Link
                      to="#"
                      className="text-success"
                      onClick={() => {
                        const userData = cellProps.row.original
                        handleUserClick(userData)
                      }}
                    >
                      <i
                        className="mdi mdi-pencil font-size-18"
                        id="edittooltip"
                      />
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  )}
                {user.role == "admin" &&
                  cellProps.row.original.status != "archived" && (
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => {
                        const userData = cellProps.row.original
                        onClickDelete(userData)
                      }}
                    >
                      <i
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  )}
              </>
            </div>
          )
        },
      },
    ],
    [user]
  )

  useEffect(
    function () {
      setIsLoading(true)
      let params = {}
      params = { country: [] }
      getUsers(params)
        .then(res => {
          setUserList(res.data)
        })
        .catch(err => setUserList([]))
        .finally(setIsLoading(false))
    },
    [(country, userValue, refresh)]
  )

  useEffect(
    function () {
      if (filterCountries.length > 0) {
        setIsLoading(true)
        let params = {}
        params = { country: filterCountries.map(data => data.value) }
        getUsers(params)
          .then(res => {
            setUserList(res.data)
          })
          .catch(err => setUserList([]))
          .finally(setIsLoading(false))

      } else {
        setIsLoading(true)

        let params = {}
        params = { country: [] }
        getUsers(params)
          .then(res => {
            setUserList(res.data)
          })
          .catch(err => setUserList([]))
          .finally(setIsLoading(false))
      }
    },
    [filterCountries]
  )

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = userBeingEdited => {
    setUserValue({
      id: userBeingEdited.id,
      role: userBeingEdited.role,
      countries: userBeingEdited.countries.map(data => ({
        label: data.label,
        value: data.id,
      })),
      stages: userBeingEdited.stages.map(data => ({
        label: data.label,
        value: data.id,
      })),
      status: {
        value: userBeingEdited.status,
        label: userBeingEdited.status,
      },
      countryOptionsThatAdminHasAccessTo:
        userBeingEdited.user.organization.countries
          .filter(country =>
            countries.some(adminCountry => adminCountry.id === country.id)
          )
          .map(data => ({
            label: data.label,
            value: data.id,
          })),
      organization: userBeingEdited.user.organization.name,
      organization_type:
        userBeingEdited.user.organization.organization_type.name,
    })
    setIsEdit(true)
    toggle()
  }

  const handleUserClicks = () => {
    setUserValue("")
    setIsEdit(false)
    toggle()
  }

  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = userArg => {
    setUserValue(userArg)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    deleteUser(userValue.id)
      .then(res => {
        setRefresh(!refresh)
        props.alert(`User Deleted`, true)
      })
      .catch(err => {
        props.alert("User Not Deleted", false)
      })
    setDeleteModal(false)
  }

  const keyField = "id"
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      {/* <ErrorModal show={errorModal} onCloseClick={() => setErrorModal("")} />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal("")}
      /> */}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="User" breadcrumbItem="User Management" /> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {user.id && (
                    <>
                      <DefaultTableContainer
                        columns={columns}
                        data={userList}
                        isLoading={isLoading}
                        isGlobalFilter={true}
                        isExport={"user"}
                        handleUserClick={handleUserClicks}
                        customPageSize={10}
                        setFilterCountries={setFilterCountries}
                        customSort={{ id: "created_at", desc: true }}
                        className="custom-header-css"
                      />

                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                          Edit User
                        </ModalHeader>
                        <ModalBody>
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return "false"
                            }}
                          >
                            <Row>
                              <Col xs={12}>
                                {validation.errors.countries && (
                                  <Alert color="danger">
                                    {validation.errors.countries}
                                  </Alert>
                                )}

                                <div className="mb-3">
                                  <Label className="form-label">Location</Label>
                                  <Select
                                    name="countries"
                                    options={
                                      userValue?.countryOptionsThatAdminHasAccessTo
                                    }
                                    isMulti={true}
                                    classNamePrefix="select2-selection"
                                    value={validation.values.countries}
                                    onChange={selectedOption => {
                                      let event = {
                                        target: {
                                          name: "countries",
                                          value: selectedOption,
                                        },
                                      }
                                      validation.handleChange(event)
                                    }}
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">Role</Label>
                                  <Input
                                    name="organization"
                                    type="text"
                                    value={Role[userValue?.role]}
                                    className="lock-icon"
                                    disabled={true}
                                  />
                                </div>

                                {userValue && userValue.role == "user" && (
                                  <div className="mb-3">
                                    <Label className="form-label">Stages</Label>
                                    <Select
                                      name="stages"
                                      options={stageOptions}
                                      isMulti={true}
                                      classNamePrefix="select2-selection"
                                      value={stageOptions.reduce(
                                        (acc, group) => {
                                          const matchingOptions =
                                            group.options.filter(option =>
                                              validation.values.stages
                                                .map(
                                                  preselectedOption =>
                                                    preselectedOption.value
                                                )
                                                .includes(option.value)
                                            )
                                          return [...acc, ...matchingOptions]
                                        },
                                        []
                                      )}
                                      onChange={selectedOption => {
                                        let event = {
                                          target: {
                                            name: "stages",
                                            value: selectedOption,
                                          },
                                        }
                                        validation.handleChange(event)
                                      }}
                                      styles={{
                                        groupHeading: base => ({
                                          ...base,
                                          ...groupHeadingStyles,
                                        }),
                                      }}
                                    />
                                  </div>
                                )}

                                <div className="mb-3">
                                  <Label className="form-label">
                                    Organization
                                  </Label>
                                  <Input
                                    name="organization"
                                    type="text"
                                    value={userValue?.organization}
                                    className="lock-icon"
                                    disabled={true}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Organization Type
                                  </Label>
                                  <Input
                                    name="organization type"
                                    type="text"
                                    value={userValue?.organization_type}
                                    className="lock-icon"
                                    disabled={true}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Status</Label>
                                  <Select
                                    name="status"
                                    options={[
                                      { label: "Active", value: "active" },
                                      {
                                        label: "Suspended",
                                        value: "suspended",
                                      },
                                    ]}
                                    classNamePrefix="select2-selection"
                                    value={validation.values.status}
                                    onChange={selectedOption => {
                                      let event = {
                                        target: {
                                          name: "status",
                                          value: selectedOption,
                                        },
                                      }
                                      validation.handleChange(event)
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UsersList)
