import Preview from "pages/FormManagement/preview"
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useHistory } from "react-router-dom"
import { Button, Table } from "reactstrap"
import { postFormData } from "services/AXIOS"
import { getBenchmarkingFormDetails } from "services/milestoneServices"
import { BENCHMARKING_RESPONSE } from "services/CONSTANTS"
import styled from "@emotion/styled"

const StyledScoringGuide = styled(Table)`
  width: 80%;
  margin-inline: auto;
  caption-side: top;
  border-radius: 0.8em;

  & caption {
    text-align: center;
    font-size: 17px;
    background-color: #000;
    color: #fff;
  }
`
export const ScoringGuide = () => {
  return (
    <StyledScoringGuide>
      <caption>Scoring Guide</caption>
      <colgroup>
        <col style={{ width: "85%" }} />
        <col style={{ width: "15%" }} />
      </colgroup>
      <thead>
        <tr>
          <th>Option</th>
          <th>Weight</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Not at all</td>
          <td>0</td>
        </tr>
        <tr>
          <td>
            Policy exists but it's either outdated and/or there are
            implementation challenges
          </td>
          <td>1</td>
        </tr>
        <tr>
          <td>
            There is considerable policy implementation but still room for
            improvement
          </td>
          <td>2</td>
        </tr>
        <tr>
          <td>Satisfactory progress with policy implementation</td>
          <td>3</td>
        </tr>
      </tbody>
    </StyledScoringGuide>
  )
}

function FillFormBenchmarking(props) {
  const { formId, labelId, countryId } = useParams()

  const [formData, setFormData] = useState(null)
  const [responses, setResponses] = useState([])
  const [mode, setMode] = useState("read")

  const history = useHistory()

  const loadBenchmarkingFormDetails = async () => {
    try {
      const { data } = await getBenchmarkingFormDetails({
        formId,
        countryId,
        labelId,
      })
      setMode(data?.mode)
      setFormData(data)
      setResponses(
        Array.from({ length: data?.questions.length }, (el, idx) => ({
          question_id: data?.questions[idx].id,
          response:
            data?.questions[idx].label_answers.length > 0
              ? data?.questions[idx].label_answers[0].response
              : { answer: "", file: "", reason: "" },
          weight: data?.questions[idx].label_answers[0]?.weight ?? 0,
        }))
      )
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    loadBenchmarkingFormDetails()
  }, [])

  const [files, setFiles] = useState({})

  const [fileKey, setFileKey] = useState("")

  const [modalUpload, setModalUpload] = useState(false)

  const toggleUpload = () => setModalUpload(!modalUpload)

  const onUpload = file => {
    toggleUpload()
    setFiles({ ...files, [fileKey]: [file, file.name] })
    //add response handler call here to store file name
    if (file) props.alert("File uploading", true)
  }

  // const responseObject = {
  //     // task_id: +taskId,
  //     question_id: null,
  //     form_id: +formId,
  //     response: "",
  //   }

  // const { data: form } = useQuery(["form"], () =>
  //   get(`form/label/${countryId}/${labelId}/${formId}`).then(res => {
  //     console.log("get req running")
  //     setMode(res?.data?.mode)
  //     setFormData(res?.data)
  //     setResponses(
  //       Array.from({ length: res?.data?.questions.length }, (el, idx) => ({
  //         question_id: res?.data?.questions[idx].id,
  //         response:
  //           res?.data?.questions[idx].label_answers.length > 0
  //             ? res?.data?.questions[idx].label_answers[0].response
  //             : { answer: "", file: "", reason: "" },
  //       }))
  //     )
  //     return res.data
  //   })
  // )
  // useEffect(() => {
  //   if (form && form?.questions.length > 0) {
  //     setResponses(
  //       Array.from({ length: form.questions.length }, (el, idx) => ({
  //         question_id: form?.questions[idx].id,
  //         response: form?.questions[idx].label_answers[0].response ?? {},
  //       }))
  //     )
  //   }
  // }, [form])

  const { mutate: saveResponse } = useMutation(
    ["saveResponse"],
    () => {
      const response = new FormData()
      response.append("form_id", formId)
      response.append("label_id", labelId)
      response.append("country_id", countryId)
      response.append("questions", JSON.stringify(responses))
      Object.entries(files).forEach(([key, value]) => {
        response.append(key, value[0])
      })

      return postFormData(BENCHMARKING_RESPONSE, response).then(data => data)
    },
    {
      onSuccess: data => {
        if (data.statusCode == 401) {
          props.alert("Response Save Failed!", false)
        } else {
          props.alert("Response Saved!", true)
          history.push("/benchmarking")
        }
      },
    }
  )

  const responseHandler = response => {
    const { id, index, value, responseType, weight } = response
    const tempResponses = [...responses]
    /*  tempResponses[index] = !tempResponses[index]
      ? { question_id: "", response: { answer: "", file: "", reason: "" } }
      : tempResponses[index] */
    // tempResponses[index].question_id = id
    tempResponses[index].response[responseType] = value
    if (responseType === "answer") tempResponses[index].weight = Number(weight)
    setResponses(tempResponses)
  }

  // useEffect(() => {
  //   if (systemForm) {
  //     const tempFormData = {
  //       ...systemForm,
  //       // questions: [],
  //     }

  //     // for (let i = 0; i < systemForm.questions.length; i++) {
  //     //   const tempResponse = JSON.parse(JSON.stringify(responseObject))
  //     //   const quest = systemForm.questions[i]
  //     //   const existing = systemForm.form_label_map.form_label_answers?.find(resp => resp.question_id === quest.id)
  //     //   if (existing) {
  //     //     if (quest.responses) quest.responses.push(existing)
  //     //     else quest["responses"] = [existing]
  //     //   } else {
  //     //     tempResponse.question_id = quest.id
  //     //     if (quest.responses) quest.responses.push(tempResponse)
  //     //     else quest["responses"] = [tempResponse]
  //     //   }
  //     //   tempFormData.questions.push(quest)
  //     // }

  //     setFormData(tempFormData)
  //   }
  // }, [systemForm])
  return (
    <div className="page-content bg-light min-vh-100">
      <div className=" d-flex justify-content-between mx-3 align-items-start">
        <div className=" ">
          <p className="fs-1 mb-0 fw-bold">{formData?.label?.label_name}</p>
        </div>
        <div>
          {mode === "edit" ? (
            <Button
              onClick={() => saveResponse()}
              color="primary"
              className=" text-nowrap"
              style={{
                float: "right",
              }}
            >
              Save Response
            </Button>
          ) : (
            <h2>Preview Mode</h2>
          )}
        </div>
      </div>
      {formData && (
        <Preview
          form={formData}
          responseHandler={responseHandler}
          mode={mode}
          setFileKey={setFileKey}
          files={files}
          onUpload={onUpload}
          modalUpload={modalUpload}
          toggleUpload={toggleUpload}
          alert={props.alert}
        />
      )}
    </div>
  )
}

export default FillFormBenchmarking
