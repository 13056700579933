export const USER_SESSION = "USER_SESSION"
export const USER_SESSION_SUCCESS = "USER_SESSION_SUCCESS"
export const USER_SESSION_ERROR = "USER_SESSION_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"
export const COUNTRY_FLAG = "COUNTRY_FLAG"
export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const COUNTRY_ID = "COUNTRY_ID"
export const RESET_PROFILE = "RESET_PROFILE"
