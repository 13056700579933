import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile } from "../../store/actions"
import { PROFILE_PHOTO } from "../../services/CONSTANTS"
import { Role, accountStatus } from "constants/global"
//import profile from "store/auth/profile/reducer"

const UserProfile = () => {
  const dispatch = useDispatch()

  const [profile, setProfile] = useState()
  const [selectedImage, setSelectedImage] = useState(null);
  
  const { error, success, user } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
    user: state.Profile.user,
  }))

  useEffect(() => {
    if (user) {
      setProfile(user)
    }
  }, [user])

  const validate = values => {
    const errors = {}
    const passwordRegex = /(?=.*[0-9])(?=.*[a-zA-Z])/
    const supportedFormats = ["jpg", "jpeg", "png"];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (!values.first_name) {
      errors.first_name = "Please Enter First Name"
    }

    if (!values.last_name) {
      errors.last_name = "Please Enter Last Name"
    }

    if (values.password.length > 1) {
      if (!passwordRegex.test(values.password)) {
        errors.password = "Invalid password. Must contain atleast one number, and atleast one alphabet."
      }

      if (values.password.length < 8) {
        errors.password = "Password must be atleast 8 characters long."
      }
    }

    if (values.photo) {
      const fileExtension = values.photo.name.split(".").pop().toLowerCase();
      if (!supportedFormats.includes(fileExtension)) {
        errors.photo = "Supported Formats: jpg | jpeg | png";
      }

      if (values.photo.size > maxFileSize) {
        errors.photo = "Max Size: 5 MB";
      }
    }
    return errors
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: (profile && profile.first_name) || "",
      last_name: (profile && profile.last_name) || "",
      password: "",
      photo: "",
    },
    validate,
    onSubmit: values => {
      let formData = new FormData()
      for (let value in values) {
        formData.append(value, values[value])
      }
      dispatch(editProfile(formData))
    },
  })

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setSelectedImage(URL.createObjectURL(img));
      validation.setFieldValue("photo", img);
    }
  };

  useEffect(() => {
    return () => {
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage); // Free up memory when component unmounts
      }
    };
  }, [selectedImage]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="User" breadcrumbItem="Profile" />
          {error && error.response && (
            <Alert color="danger">{error.response.data.message}</Alert>
          )}
          {success && (
            <Alert color="success">Profile successfully updated</Alert>
          )}
          <Card>
            <CardBody>
              <Row>
                <Col sm="2">
                  {user.id &&
                    (!user.photo ? (
                      <div className="avatar-lg mb-4">
                        <h3 className={"avatar-title bg-info text-white"}>
                          {user.first_name.charAt(0)}
                        </h3>
                      </div>
                    ) : (
                      <CardImg
                        style={{ aspectRatio: 1 }} 
                        className="rounded-circle"
                        src={
                          selectedImage? selectedImage : PROFILE_PHOTO + `${user.photo}?${global.Date.now()}`
                        }
                        alt="Profile"
                      />
                    ))}
                </Col>
                <Col sm={{ size: 6, offset: 1 }}>
                  <div className="flex-grow-1 overflow-hidden">
                    <h4 className="text-truncate">
                      {user.first_name + " " + user.last_name}
                    </h4>
                    <p className="text-muted mb-1">
                      <strong>Email:</strong> {user.email}
                    </p>
                    <p className="text-muted mb-1">
                      <strong>Organization: </strong> {user.organization}
                    </p>
                    <p className="text-muted">
                      <strong>Role:</strong> {Role[user.role]}
                    </p>
                    <p className="badge bg-info font-size-13 m-1">
                      Status: {accountStatus[user.status]}
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <h4 className="card-title">Edit Section</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group mb-3">
                  <Label className="form-label">First Name</Label>
                  <Input
                    name="first_name"
                    // value={name}
                    className="form-control"
                    placeholder="Enter First Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name || ""}
                    invalid={
                      validation.touched.first_name &&
                      validation.errors.first_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.first_name &&
                  validation.errors.first_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.first_name}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group mb-3">
                  <Label className="form-label">Last Name</Label>
                  <Input
                    name="last_name"
                    // value={name}
                    className="form-control"
                    placeholder="Enter Last Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.last_name || ""}
                    invalid={
                      validation.touched.last_name &&
                      validation.errors.last_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.last_name &&
                  validation.errors.last_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.last_name}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group mb-3">
                  <Label className="form-label">
                    Profile Photo{" "}
                  </Label>
                  <Input
                    name="photo"
                    className="form-control"
                    placeholder="Upload Profile Photo"
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    onChange={handleImageChange}
                    invalid={
                      validation.touched.photo && validation.errors.photo ? true : false
                    }
                  />
                  <p>* Supported Formats: jpg | jpeg | png & Max Size: 5 MB</p>
                  {validation.touched.photo && validation.errors.photo ? (
                    <FormFeedback type="invalid">{validation.errors.photo}</FormFeedback>
                  ) : null}
                </div>

                <div className="form-group mb-3">
                  <Label className="form-label">
                    Password{" "}
                  </Label>
                  <Input
                    name="password"
                    className="form-control"
                    placeholder="new password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    // value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  <p>* Password must be at least 8 characters long and contain at least one number and one alphabet.</p>
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="text-center mt-4">
                  <Button
                    type="submit"
                    color="danger"
                    className="btn btn-success"
                  >
                    Update Profile
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
