//redux
import { formateOnlyDate } from "helpers/utlities";

export const getCSV = (tasks) => {

    const newTasks = []
    tasks.map(task => {
        const newTask = {}
        newTask["Task Owner"] = task.first_name + " " + task.last_name;
        newTask["Policy Name"] = task.policy_name;
        newTask["Stage"] = task.stage_id > 6 ? task.label : task.stage_id + "-" + task.label;
        newTask["Milestone"] = task.name;
        newTask["Deadline"] = task.deadline
          ? formateOnlyDate(task.deadline)
          : "NA";
        newTask["Status"] = task.form_status;
        newTasks.push(newTask)
    })
    const csvdata = csvmaker(newTasks)
    return download(csvdata)
}

const csvmaker = function (data) {
  // Empty array for storing the values
  const csvRows = []

  const headers = Object.keys(data[0])

  // As for making csv format, headers
  // must be separated by comma and
  // pushing it into array
  csvRows.push(headers.join(","))

  data.map(data => {
      const values = Object.values(data)
      const csvValues = values.join()
      csvRows.push(csvValues)
      // const {
      //     labels,
      //     partners,
      //     owner,
      //     ...dataRest
      // } = data;
      // const values = Object.values(dataRest);
      // values.push(labelNames);
      // values.push(partnerNames);
      // values.push(data.owner.name);
      // const csvValues = values.join();
      // csvRows.push(csvValues)
  })
  // Pushing Object values into array
  // with comma separation

  // Returning the array joining with new line
  return csvRows.join("\n")
}

const download = function (data) {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: "text/csv" })

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob)

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a")

  // Passing the blob downloading url
  a.setAttribute("href", url)

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", "tasks_list.csv")

  // Performing a download with click
  a.click()
}
