import { exp } from "@amcharts/amcharts5/.internal/core/util/Ease"

//REGISTER
export const API_URL = process.env.REACT_APP_APIURL

//SSE
export const SSE = API_URL + "/policies/sse"

//FILTER
export const FILTERUSERS = API_URL + "/user/filter-users/"

//ACCESS USER LIST
export const ACCESSUSERLIST = API_URL + "/user/stage-country-users/"

// AUTH
export const SIGN_UP = API_URL + "/auth/signup"
export const SIGN_IN = API_URL + "/auth/login"
export const USER_SESSION = API_URL + "/auth/session"
export const USER_LOGOUT = API_URL + "/auth/logout"
export const FORGOT_PASSWORD = API_URL + "/auth/forgotPassword"
export const RESET_PASSWORD = API_URL + "/auth/resetPassword"
export const TOKEN_INFO = API_URL + "/auth/token"

// PROFILE
export const UPDATE_PROFILE = API_URL + "/user/profile"
export const PROFILE_PHOTO = API_URL + "/user/profile/"

// ACCESS CODE
export const ACCESS_CODES = API_URL + "/access-code"
export const ADD_NEW_ACCESS_CODE = API_URL + "/access-code"
export const GET_ACCESS_CODE_DETAIL = API_URL + "/access-code"
export const DELETE_ACCESS_CODE = API_URL + "/access-code"
export const UPDATE_ACCESS_CODE = API_URL + "/access-code"
export const RESEND_EMAIL_CODE = API_URL + "/access-code/resend-email"

// User
export const USER = API_URL + "/user"
export const USER_POLICY_TASKS_HISTORY = API_URL + "/task/history-policy-tasks"
export const USER_BENCHMARKING_TASKS_HISTORY =
  API_URL + "/task/history-benchmarking-tasks"

export const LIST_COUNTRIES = API_URL + "/list/countries"
export const LIST_STAGES = API_URL + "/list/stages"
export const LIST_ALL_STAGES = API_URL + "/stage"

//Labels
export const DELETE_LABEL = API_URL + "/labels/remove"
export const DELETE_LABEL_TYPE = API_URL + "/labels/remove-type"
export const LABELS = API_URL + "/labels"
export const FIND_LABELS = API_URL + "/labels/find"
export const CREATE_LABELS = API_URL + "/labels/create"
export const LABELTYPES = API_URL + "/labels/list/labels/types"
export const GET_LABEL_TYPES = API_URL + "/labels/types/find"
export const GET_SPECIFIC_LABEL_TYPE_DETAILS = API_URL + "/labels/type"
export const CREATE_NEW_LABEL_TYPE = API_URL + "/labels/type"
export const GET_LABEL_SUGGESTIONS = API_URL + "/labels/suggest"
export const CREATE_NEW_LABEL = API_URL + "/labels/create"
export const GET_SPECIFIC_LABEL_DETAILS = API_URL + "/labels/type"
export const LIST_LABELS_FOR_BENCHMARKING = API_URL + "/labels/list/labels"
export const LABEL_SPECIFIC_COUNTRIES = API_URL + "/labels/countries"
export const COUNTRY_SPECIFIC_LABELS = API_URL + "/labels/country"
export const ALL_LABELS = API_URL + "/labels/all"
//Policies
export const POLICIES = API_URL + "/policies"
export const POLICIES_SSE = API_URL + "/policies/sse"
export const CREATE_POLICY = API_URL + "/policies/create"
export const UPDATE_POLICY_VERSION = API_URL + "/policies/update-policy-version"
export const GET_VERSIONS = API_URL + "/policies/policy-versions"
export const DOWNLOAD_VERSION = API_URL + "/policies/download/version"
export const DOWNLOAD_FILE = API_URL + "/policies/download"
export const PARTNERS = API_URL + "/partner/all"
export const OWNERS = API_URL + "/owner/all"
export const POLICIES_WITH_SUBCATEGORY = API_URL + "/policies/sub-policies"
export const DELETE = API_URL + "/policies/delete"
export const SOFTDELETEPOLICY = API_URL + "/policies/soft-delete"
export const POLICYACTIVITY = API_URL + "/policies/activity"
export const LATEST_V_NUM = API_URL + "/policies/latest-v-num"
export const POLICY_NAME_VALID = API_URL + "/policies/name-validation"
export const EDIT_POLICY_METADATA = API_URL + "/policies/update"
export const POLICY_UPDATES = API_URL + "/policies/notes"
// Reports
export const DASHBOARD_COUNTS = API_URL + "/report/dashboard/counts"
// export const DASHBOARD_LABELS_SDG = API_URL + "/report/dashboard/labels/sdg"
// export const DASHBOARD_STAGES_POLICIES =
//   API_URL + "/report/dashboard/stages/policies"
// export const RECENT_COMMENTS = API_URL + "/report/comments/recent"
// export const DASHBOARD_LANDSCAPING_STAGES_POLICIES =
//   API_URL + "/report/policies/stages/landscaping"
// export const DASHBOARD_TRACKER_STAGES_POLICIES =
//   API_URL + "/report/policies/stages/tracker"
export const DASHBOARD_NOTIFICATIONS_COUNT =
  API_URL + "/report/notifications/count"
export const PROFILE_STATS = API_URL + "/report/profile/stats"
export const DASHBOARD_TASKS = API_URL + "/report/dashboard/tasks"
export const DASHBOARD_BENCHMARKING_TASKS =
  API_URL + "/report/dashboard/benchmarking-tasks"
export const DASHBOARD_ADMIN_TASKS = API_URL + "/report/dashboard/admin-tasks"
export const INTELLIGENCE_INDICATOR_TRENDS =
  API_URL + "/report/intelligence/indicator/trends"
export const INTELLIGENCE_POLICIES_AFFECTED =
  API_URL + "/report/intelligence/policies-affected"
export const INTELLIGENCE_SENTIMENT_SCORES =
  API_URL + "/report/intelligence/sentiment/scores"
export const INTELLIGENCE_TWEETS = API_URL + "/report/intelligence/tweets"

export const USER_LOGS = API_URL + "/report/user/logs"

export const STATISTICS_ETRACKER = API_URL + "/report/statistics/etracker"

export const STATISTICS_TRACKER = API_URL + "/report/statistics/tracker"

//landscape
export const CATEGORY = API_URL + "/category/country"
export const DELETECATEGORY = API_URL + "/category/delete"
export const DELETESUBCATEGORY = API_URL + "/sub-category/delete"

//get sub categories in a category
export const GETCATEGORY = API_URL + "/category/catId"

//forms
export const FORMSINSTAGE = API_URL + "/form/stage"

export const STAGE = API_URL + "/stage"

export const INDICATOR_TRENDS =
  API_URL + "/report/intelligence/indicator/trends"

export const INDICATOR_TRENDS_MAP =
  API_URL + "/report/intelligence/indicator/trends"

export const TRENDS_MAP_HOVER =
  API_URL + "/report/intelligence/indicator/trends"

export const GETALLFORMS = API_URL + "/form/getall"
export const GETFILTEREDFORMS = API_URL + "/form/find"

//audit
export const AUDIT = API_URL + "/audit/add"

//qualitative stats
export const ETRACKERSTATS = API_URL + "/report/statistics/tracker"

//tracker card details
export const TRACKERCARDS = API_URL + "/policies/tracker-cards"

export const SEARCH_AUDITS = API_URL + "/audit/search"

//tasks
export const GET_TASKS = API_URL + "/task/search"
export const LIST_ALL_USERS = API_URL + "/user/list-users"
export const REASSIGN_POLICY_TASK = API_URL + "/task/reassign-policy-tasks"
export const REASSIGN_BENCHMARKING_TASK =
  API_URL + "/task/reassign-benchmarking-tasks"

export const LIST_ORGANIZATIONS = API_URL + "/organization/list/organizations"
export const LIST_ORGANIZATION_TYPES =
  API_URL + "/organization/list/organizations/types"

export const CREATE_NEW_ORGANIZATION_TYPE = API_URL + "/organization/type"
export const CREATE_NEW_ORGANIZATION = API_URL + "/organization"

export const GET_ORGANIZATION_TYPES = API_URL + "/organization/types/find"

export const GET_ORGANIZATIONS = API_URL + "/organization/find"

export const GET_ORGANIZATION_TYPE_SUGGESTIONS =
  API_URL + "/organization/types/suggest"

export const GET_ORGANIZATION_SUGGESTIONS = API_URL + "/organization/suggest"
export const GET_SPECIFIC_ORGANIZATION_TYPE_DETAILS =
  API_URL + "/organization/type"

export const GET_SPECIFIC_ORGANIZATION_DETAILS = API_URL + "/organization"
export const GET_ORGANIZATION_COUNTRIES_LIST = API_URL + "/organization/find"

//Milestone
export const CREATE_NEW_FORM = API_URL + "http://localhost:3001/form/create"
export const SPECIFIC_FORM_QUESTIONS = API_URL + "/form/question"
export const LIST_CURRENT_MILESTONE_OWNERS = API_URL + "/form/owners"
export const PUBLISH_MILESTONE = API_URL + "/form/create"
export const GET_QUESTIONS_OF_EXISTING_MILESTONE = API_URL + "/form/questions"
export const EDIT_SPECIFIC_MILESTONE = API_URL + "/form/edit"

//Benchmarking
export const BENCHMARKING = API_URL + "/form/benchmarking"
export const BENCHMARKING_RESPONSE = API_URL + "/form/label/response"
export const GET_BENCHMARKING_FORM_DETAILS = API_URL + "/form/label"

export const SYSTEMFORMFILEDOWNLOAD = API_URL + "/form/download"

//Policy Form
export const GET_FORM = API_URL + "/form"
export const GET_LANDSCAPING_FORM = API_URL + "/form/subcategory"
export const GET_RESPONSES_OF_FORM = API_URL + "/form/response"

//Comment
export const COMMENTS = API_URL + '/comment'
export const GET_COMMENTS = API_URL + '/comment/get-all/'
export const REMOVE_COMMENTS = API_URL + '/comment/delete/'
