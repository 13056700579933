import { EnumStage } from 'constants/global';
import React, {useState} from 'react';
import Select from 'react-select';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

function Filter({applyFilters, selectedStageFilters, setSelectedStageFilters}) {

    const [modalFilter, setModalFilter] = useState(false)
    const toggleFilter = () => setModalFilter(prevState => !prevState)

    let stageOptions = [
      {value: 1, label: 'Stage 1'},
      {value: 2, label: 'Stage 2'},
      {value: 3, label: 'Stage 3'},
      {value: 4, label: 'Stage 4'},
      {value: 5, label: 'Stage 5'},
      {value: 6, label: 'Stage 6'},
      {value: EnumStage.VALIDATION, label: 'Validation'}
    ]

    return (
      <div>
        <Button
          style={{
            backgroundColor: "#f8f9fa",
            color: "black",
            margin: "15px 10px",
            border: "none",
            fontSize: "14px",
          }}
          onClick={toggleFilter}
        >
          <i className="mdi mdi-filter-variant me-1"></i>
          Filter
        </Button>
        <Modal
          isOpen={modalFilter}
          toggle={toggleFilter}
          className="modal-lg"
        >
          <ModalHeader toggle={toggleFilter}>
            <i className="mdi mdi-filter-variant me-1"></i>
            Filters
          </ModalHeader>
          <ModalBody>
            <div className='d-flex my-2'>
              <div className='d-flex align-items-center'>
                <i className='mdi mdi-buffer light-icon me-1'/>
                <h6 className='mb-0'>Stage</h6>
              </div>
              <div className='ms-2 w-100'>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={stageOptions}
                  defaultValue={selectedStageFilters}
                  placeholder="Select Multiple" 
                  onChange={setSelectedStageFilters}
                />
              </div>
            </div>
            <div className='d-flex' style={{justifyContent: "center"}}>
              <div 
                className="btn btn-success common-button" 
                style={{width: "90%"}}
                onClick={()=>{
                  applyFilters(); 
                  toggleFilter();
                }}
              >
                Apply
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
}

export default Filter;