import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map, isEmpty } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import Tabs from "components/Common/Tabs"

// TableContainer

import { Pdate, Ddate, Name, Idno, Budget } from "./userCol"

import TableContainer from "../../../components/Common/TableContainer"
import { useSelector } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Images
import profile1 from "assets/images/profile-img.png"

import { getUserDetail, getUserLogs, getProfileStats } from "services"
import ViewDetail from "./ViewDetail"
import DefaultTableContainer from "../../../components/Common/DefaultTableContainer"
import { formateDate } from "helpers/utlities"
import TaskHistory from "./Task-History"
import { Role, accountStatus } from "constants/global"

const UserDetail = props => {
  
  const {
    match: { params },
  } = props

  const [userDetail, setUserDetail] = useState()
  const [userLogs, setUserLogs] = useState([])
  const [analyticsData, setAnalyticsData] = useState([])
  // eslint-disable-next-line no-unused-vars
  const { user } = useSelector(state => state.Profile)

  useEffect(() => {
    if (params && params.id) {
      getUserDetail(params.id).then(res => {
        setUserDetail(res.data)
      })

      getUserLogs(params.id).then(res => {
        setUserLogs(res.data)
      })

      getProfileStats(params.id)
        .then(res => {
          setAnalyticsData([
            {
              title: "Task Completed",
              count: res.data.task_completed,
              iconClass: "bx-file",
              iconBackground: "#ef2327",
            },
            {
              title: "Task Past Deadline",
              count: res.data.task_past_deadline,
              iconClass: "bx-star",
              iconBackground: "#00bdd3",
            },
            {
              title: "Current Tasks",
              count: res.data.tasks,
              iconClass: "bx-left-arrow",
              iconBackground: "#eb7b31",
            },
            {
              title: "Comments Posted",
              count: res.data.comments,
              iconClass: "bx-comment",
              iconBackground: "#4069e4",
            },
          ])
        })
        .catch(err => setAnalyticsData([]))
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Idno {...cellProps} />
        },
      },
      {
        Header: "Title",
        accessor: "title",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },
      {
        Header: "Label",
        accessor: "label",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
    ],
    []
  )

  const logColumns = useMemo(() => [
    {
      Header: "Activity",
      accessor: "description",
      disableGlobalFilter: true,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <>
            <i className="bx bx-user"></i> <Name {...cellProps} />
          </>
        )
      },
    },
    {
      // Header: "Created At",
      Header: () => <span className="d-block text-end">Created At</span>,
      accessor: "created_at",
      disableGlobalFilter: true,
      disableFilters: true,
      desc: true,
      Cell: cellProps => {
        return (
          <span className="d-block text-end">
            <Ddate {...cellProps} />
          </span>
        )
      },
    },
  ])

  const columnsStages = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Idno {...cellProps} />
        },
      },
      {
        Header: "Stage",
        accessor: "stage",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Title",
        accessor: "title",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Label",
        accessor: "label",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "type",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
    ],
    []
  )

  const Analytics_Data = [
    {
      title: "Policies Evaluated",
      count: 7,
      iconClass: "bx-file",
      iconBackground: "#ef2327",
    },
    {
      title: "Evaluations Completed",
      count: 20,
      iconClass: "bx-star",
      iconBackground: "#00bdd3",
    },
    {
      title: "Comments Posted",
      count: 15,
      iconClass: "bx-comment",
      iconBackground: "#4069e4",
    },
    {
      title: "Current Tasks",
      count: 5,
      iconClass: "bx-left-arrow",
      iconBackground: "#eb7b31",
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="User Management" breadcrumbItem="Detail" /> */}

          <Row>
            <Col lg="12">
              {!isEmpty(userDetail) && <ViewDetail viewData={userDetail} />}
            </Col>

            {/* <Col lg="12">
              <Row>
                <Col lg="12">
                  <div>
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Activity</th>
                            <th scope="col">Timestamp</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userLogs.length > 0 &&
                            map(userLogs, (activity, index) => (
                              <tr key={index}>
                                <td>
                                  <p className="text-truncate">
                                    {activity.description}
                                  </p>
                                </td>
                                <td>
                                  <p className="text-muted mb-0">
                                    {formateDate(activity.created_at)}
                                  </p>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col> */}
          </Row>

          {!isEmpty(userDetail) && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      <i className="bx bx-menu"></i> Access Information
                    </CardTitle>

                    <Row>
                      <Col sm="4">
                        <div className="mt-4">
                          <h5 className="font-size-14">
                            <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                            Access Level
                          </h5>
                          <p className="text-muted mb-0">
                            {Role[userDetail.role]}
                          </p>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-14">
                            <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                            User Status
                          </h5>
                          <p className="text-muted mb-0">{accountStatus[userDetail.status]}</p>
                        </div>
                        {params.id == user.id && (
                          <div className="mt-4">
                            <a
                              className="btn btn-primary waves-effect waves-light btn-lg"
                              href="/profile"
                            >
                              Edit Profile{" "}
                              <i className="mdi mdi-arrow-right ms-1"></i>
                            </a>
                          </div>
                        )}
                      </Col>
                      <Col sm="4">
                        <div className="mt-4">
                          <h5 className="font-size-14">
                            <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                            Date Joined
                          </h5>
                          <p className="text-muted mb-0">
                            {userDetail.logined_at
                              ? formateDate(userDetail.created_at)
                              : "N/A"}
                          </p>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-14">
                            <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                            Last Active
                          </h5>
                          <p className="text-muted mb-0">
                            {userDetail.logined_at
                              ? formateDate(userDetail.logined_at)
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                      <Col sm="4">
                        {/* {userDetail.stages.length > 0 && ( */}
                        <h5 className="font-size-14 mt-4">
                          <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                          Stages
                        </h5>
                        {/* )} */}
                        {(userDetail.role != "user" && (
                          <p>
                            <i className="mdi mdi-chevron-right text-info me-1" />{" "}
                            All
                          </p>
                        )) ||
                          (userDetail.stages.length > 0 &&
                            map(userDetail.stages, (stage, index) => (
                              <p key={index} className="mb-0">
                                <i className="mdi mdi-chevron-right text-info me-1" />{" "}
                                {stage.label}
                              </p>
                            )))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <i className="bx bx-menu"></i> Analytics this month
                  </CardTitle>
                  <Row>
                    {analyticsData.length > 0 &&
                      map(analyticsData, (data, index) => (
                        <Col sm="3" key={index}>
                          <Card className="mini-stats-wid border-grey-1">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <h3 className="mb-4">{data.count}</h3>
                                  <h5 className="text-muted mb-2">
                                    {data.title}
                                  </h5>
                                </div>
                                <div className={`avatar-xs ms-auto`}>
                                  <span
                                    className={`avatar-title rounded font-size-20`}
                                    style={{ background: data.iconBackground }}
                                  >
                                    <i className={"bx " + data.iconClass}></i>
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Tabs defaultValue="tab1">
              <div className="d-flex justify-content-end">
                <Tabs.Triggers>
                  <Tabs.TriggerButton value="tab1" leading="bx bx-slider">
                    Audit Trail
                  </Tabs.TriggerButton>
                  <Tabs.TriggerButton
                    value="tab2"
                    leading="ph-clock-counter-clockwise"
                  >
                    Task History
                  </Tabs.TriggerButton>
                </Tabs.Triggers>
              </div>
              <Tabs.TabContent value="tab1">
                <TableContainer
                  columns={logColumns}
                  data={userLogs || []}
                  isGlobalFilter={false}
                  customPageSize={10}
                  customPageSizeOptions={true}
                />
              </Tabs.TabContent>
              <Tabs.TabContent value="tab2">
                <TaskHistory />
              </Tabs.TabContent>
            </Tabs>
            {/* <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Detail</CardTitle>
                  <div className="table-responsive">
                    {userDetail && (
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">First Name :</th>
                            <td>{userDetail.user.first_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Last Name :</th>
                            <td>{userDetail.user.last_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Email :</th>
                            <td>{userDetail.user.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Organization :</th>
                            <td>{userDetail.user.organization}</td>
                          </tr>
                          <tr>
                            <th scope="row">Role :</th>
                            <td>{userDetail.role}</td>
                          </tr>
                          <tr>
                            <th scope="row">Created At :</th>
                            <td>{userDetail.created_at}</td>
                          </tr>
                          <tr>
                            <th scope="row">Updated At :</th>
                            <td>{userDetail.updated_at}</td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-0">
                    <i className="bx bx-slider"></i> Audit Trail
                  </CardTitle>

                  {userLogs && (
                    <TableContainer
                      columns={logColumns}
                      data={userLogs || []}
                      isGlobalFilter={false}
                      customPageSize={10}
                      customPageSizeOptions={true}
                    />
                  )}
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Access Countries</CardTitle>

                  {userDetail && (
                    <TableContainer
                      columns={columns}
                      data={userDetail.countries || []}
                      isGlobalFilter={false}
                      customPageSize={5}
                      customPageSizeOptions={true}
                    />
                  )}
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Access Stages</CardTitle>

                  {userDetail && (
                    <TableContainer
                      columns={columnsStages}
                      data={userDetail.stages || []}
                      isGlobalFilter={false}
                      customPageSize={5}
                      customPageSizeOptions={true}
                    />
                  )}
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserDetail.propTypes = {
  userDetail: PropTypes.any,
  getUserDetail: PropTypes.func,
}

export default withRouter(UserDetail)
