import React, { useEffect, useState } from "react"
import Tabs from "components/Common/Tabs"
import styled from "@emotion/styled"
import { useQuery } from "@tanstack/react-query"
import {
  getPolicyTasksHistory,
  getBenchmarkingTasksHistory,
} from "services/userServices"
import LoadingSpinner from "components/Common/Loading-Spinner"
import { Table } from "reactstrap"
import { formateDate, formateOnlyDate } from "helpers/utlities"
import Pagination from "components/Common/Pagination"
import Select from "react-select"

const loadPolicyTasksHistory = async query => {
  try {
    const { data } = await getPolicyTasksHistory(query)
    return data
  } catch (err) {
    console.error(err)
    return err
  }
}
const loadBenchmarkingTasksHistory = async query => {
  try {
    const { data } = await getBenchmarkingTasksHistory(query)
    return data
  } catch (err) {
    console.error(err)
    return err
  }
}
export default function TaskHistory() {
  const [selectedOption, setSelectedOption] = useState("policy tasks")
  const options = [
    { label: "Policy Tasks", value: "policy tasks" },
    { label: "Benchmarking Tasks", value: "benchmarking tasks" },
  ]
  return (
    <>
      <Flex>
        <Select
          className="basic-single"
          classNamePrefix="select"
          styles={{ flex: 1 }}
          placeholder="Type"
          options={options}
          onChange={option => setSelectedOption(option.value)}
          defaultValue={selectedOption}
        />
      </Flex>
      {selectedOption === "policy tasks" ? (
        <TaskHistoryTable type="policy tasks" />
      ) : (
        <TaskHistoryTable type="benchmarking tasks" />
      )}
    </>
  )
}

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-block: 1.25rem;
  & > * {
    min-width: 200px;
  }
`

function TaskHistoryTable({ type }) {
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)

  const { data: benchmarkingTasks, isLoading: isBenchmarkingTasksLoading } =
    useQuery({
      queryKey: ["Benchmarking Tasks History", { offset, currentPage }],
      queryFn: () => loadBenchmarkingTasksHistory({ offset, limit: 20 }),
      enabled: type === "benchmarking tasks",
      staleTime: 2 * 60 * 1_000,
    })
  const { data: policyTasks, isLoading: isPolicyTasksLoading } = useQuery({
    queryKey: ["Policy Tasks History", { offset, currentPage }],
    queryFn: () => loadPolicyTasksHistory({ offset, limit: 20 }),
    enabled: type === "policy tasks",
    staleTime: 2 * 60 * 1_000,
  })
  useEffect(() => {
    type === "policy tasks"
      ? setTotalEntries(policyTasks?.tasksCount || 0)
      : setTotalEntries(benchmarkingTasks?.tasksCount || 0)
  }, [policyTasks, type, benchmarkingTasks])

  const pageChangeHandler = pageNum => {
    setOffset(pageNum * 20 - 20)
    setCurrentPage(pageNum)
  }

  if (isBenchmarkingTasksLoading && type === "benchmarking tasks")
    return <LoadingSpinner />
  if (isPolicyTasksLoading && type === "policy tasks") return <LoadingSpinner />

  const mapPolicyTasks = () => {
    return policyTasks?.tasks?.map((task, idx) => (
      <tr key={idx}>
        <td>{task?.form_name}</td>
        <td>{task?.policy_name || "NA"}</td>
        <td>{formateOnlyDate(task.updated_at)}</td>
      </tr>
    ))
  }
  const mapBenchmarkingTasks = () => {
    return benchmarkingTasks?.tasks?.map((task, idx) => (
      <tr key={idx}>
        <td>{task?.form_name}</td>
        <td>{task?.label_name || "NA"}</td>
        <td>{formateOnlyDate(task.updated_at)}</td>
      </tr>
    ))
  }
  return (
    <>
      <Table
        style={{
          boxShadow: "var(--shadow-elevation-low)",
        }}
      >
        <thead>
          <tr>
            <th>Task</th>
            {<th>System</th>}
            <th>Completed On</th>
          </tr>
        </thead>
        <tbody>
          {type === "policy tasks" ? mapPolicyTasks() : mapBenchmarkingTasks()}
        </tbody>
      </Table>
      <Pagination
        currentPage={currentPage}
        goToPage={pageChangeHandler}
        totalEntries={totalEntries}
        entriesPerPage={20}
      />
    </>
  )
}
