import SearchBox from "components/Common/SearchBox"
import React, { useState, useEffect } from "react"
import { Button, Container, Modal, ModalBody, ModalHeader } from "reactstrap"
import { post } from "services/AXIOS"
import { ACCESSUSERLIST, AUDIT, BENCHMARKING } from "services/CONSTANTS"
import BenchmarkingTable from "./table"
import Pagination from "components/Common/Pagination"
import { useSelector } from "react-redux"
import Filter from "./Filter"
import Sort from "./Sort"
import LoadingSpinner from "components/Common/Loading-Spinner"
import { EnumStage } from "constants/global"
import AccessDenied from "components/Common/Alerts/Access-Denied"
import BenchmarkingTasksTable from "pages/TaskManagement/BenchmarkingTasksTable"
import ExportTable from "components/Common/ExportTable"
import { getCSV } from "./exportCSV"
import { generatePDF } from "./generatePDF"

const Benchmarking = ({ alert, isNotMainPageContent }) => {
  const { country_id } = useSelector(state => state.Profile)
  const { stages } = useSelector(state => state.list)
  const [hasAccess, setHasAccess] = useState(false)
  useEffect(() => {
    setHasAccess(stages.some(obj => obj["id"] === EnumStage.BENCHMARKING))
  }, [stages])

  // loadData
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const loadData = (
    searchfield = searchField,
    offsetParam = offset,
    exportTable = false
  ) => {
    setIsLoading(true)
    let limit = 50
    if (exportTable) {
      limit = totalEntries
      offsetParam = 0
    }

    let newStatusFilter = false

    const statuses = []
    selectedStatus.length &&
      selectedStatus.forEach(status => {
        if (status.label === "NEW") {
          newStatusFilter = true
        } else {
          statuses.push(status.label)
        }
      })

    const owners = selectedTaskOwners.length
      ? selectedTaskOwners.map(owner => owner.value)
      : []

    post(`${BENCHMARKING}?limit=${limit}&offset=${offsetParam}`, {
      searchfield,
      sortForm,
      country_id,
      statuses,
      newStatusFilter,
      owners,
    })
      .then(data => {
        //check data on empty response without error i.e. else condition and does it need an alert - checked, need a if-else condition for data & no, doesn't need alert
        if (exportTable) {
          if (exportTable === "csv") {
            getCSV(data.data.forms)
          } else {
            generatePDF(data.data.forms)
          }
          post(AUDIT, {
            description: `Exported benchmarking directory as ${exportTable}`,
          })
        } else {
          if (data) {
            setData(data.data.forms)
            setTotalEntries(data.data.formsCount)
          }
        }
      })
      .catch(err => {
        console.log(err)
        alert("Action", false)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (country_id) loadData()

    post(ACCESSUSERLIST, { country_id })
      .then(data => {
        setOwners(
          data.data?.map(owner => {
            return {
              value: owner.id,
              label: owner.first_name + " " + owner.last_name,
            }
          })
        )
      })
      .catch(err => {
        alert("Filters Data loading", false)
        console.log(err)
      })
  }, [country_id])

  //search
  const [searchField, setSearchField] = useState("")
  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase())
    loadData(e.target.value.toLowerCase())
  }

  //filter
  const [modalFilter, setModalFilter] = useState(false)
  const toggleFilter = () => setModalFilter(prevState => !prevState)

  const [selectedStatus, setSelectedStatus] = useState([])
  const [selectedTaskOwners, setSelectedTaskOwners] = useState("")
  const [owners, setOwners] = useState([])

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)

  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50
      setOffset(offsetParam)
      loadPoliciesFiltered(searchField, offsetParam)
      setCurrentPage(page)
    } else {
      console.log("page number should be greater than 0")
    }
  }

  //clear filters
  const checkForFilters = () => {
    if (selectedStatus.length || selectedTaskOwners.length) {
      return true
    } else {
      return false
    }
  }

  const [clearedFilters, setClearedFilters] = useState(false)

  const clearFilters = () => {
    setSelectedStatus([])
    setSelectedTaskOwners([])
    setClearedFilters(true)
    return true
  }

  useEffect(() => {
    if (country_id) loadData()
    setClearedFilters(false)
  }, [clearedFilters])

  const applyFilter = () => {
    loadData()
    toggleFilter()
  }

  //sort
  const [sortForm, setSortForm] = React.useState({ updated_at: "DESC" })
  const [modalSort, setModalSort] = useState(false)
  const toggleSort = () => setModalSort(prevState => !prevState)
  const applySort = () => {
    loadData()
    toggleSort()
  }

  return (
    <>
      <div className={isNotMainPageContent ? "" : "page-content"}>
        <Container fluid={true}>
          {hasAccess ? (
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <SearchBox searchChange={searchChange} />
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#f8f9fa",
                        color: "black",
                        margin: "15px 10px",
                        border: "none",
                        fontSize: "14px",
                      }}
                      onClick={toggleFilter}
                    >
                      <i className="mdi mdi-filter-variant me-1"></i>
                      Filter
                    </Button>
                    <Modal
                      isOpen={modalFilter}
                      toggle={toggleFilter}
                      className="modal-lg"
                    >
                      <ModalHeader toggle={toggleFilter}>
                        <i className="mdi mdi-filter-variant me-1"></i>
                        Filters
                      </ModalHeader>
                      <ModalBody>
                        <Filter
                          selectedStatus={selectedStatus}
                          handleStatus={setSelectedStatus}
                          selectedTaskOwners={selectedTaskOwners}
                          handleTaskOwners={setSelectedTaskOwners}
                          applyFilter={applyFilter}
                          ownerOptions={owners}
                        />
                      </ModalBody>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#f8f9fa",
                        color: "black",
                        margin: "15px 10px",
                        border: "none",
                        fontSize: "14px",
                      }}
                      onClick={toggleSort}
                    >
                      <div>
                        <i className="mdi mdi-sort me-1"></i>
                        Sort
                      </div>
                    </Button>
                    <Modal isOpen={modalSort} toggle={toggleSort}>
                      <ModalHeader toggle={toggleSort}>
                        <i className="mdi mdi-sort me-1"></i>Sort
                      </ModalHeader>
                      <ModalBody>
                        <Sort
                          applySort={applySort}
                          sortForm={sortForm}
                          handleSortChange={setSortForm}
                        />
                      </ModalBody>
                    </Modal>
                  </div>
                  <div>
                    {checkForFilters() && (
                      <Button
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "#f8f9fa",
                          color: "black",
                          margin: "15px 10px",
                          border: "none",
                          fontSize: "14px",
                        }}
                        onClick={clearFilters}
                      >
                        <i className="ph-x me-1"></i>
                        Clear All Filters
                      </Button>
                    )}
                  </div>
                </div>
                <ExportTable loadFilteredData={loadData} searchField={searchField}/>
              </div>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  {isNotMainPageContent ? (
                    <BenchmarkingTasksTable
                      data={data}
                      id="report"
                      loadData={loadData}
                      alert={alert}
                    />
                  ) : (
                    <BenchmarkingTable data={data} id="report" />
                  )}
                  <Pagination
                    currentPage={currentPage}
                    goToPage={goToPage}
                    totalEntries={totalEntries}
                  />
                </div>
              )}
            </div>
          ) : (
            <AccessDenied />
          )}
        </Container>
      </div>
    </>
  )
}

export default Benchmarking
