import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import { DESTROY_SESSION, CREATE_SESSION } from "./actionTypes"

//contacts
import list from "./list/reducer"

//read concurrency state
import dataIndicator from "./data-indicators/reducer"

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  //state: (state = {}) => state,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  list,
  dataIndicator,
})

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) {
    state = undefined
  }

  return appReducer(state, action)
}

// const rootReducer = combineReducers({
//   // public
//   Layout,
//   Login,
//   Account,
//   ForgetPassword,
//   Profile,
//   list,
// })

export default rootReducer
