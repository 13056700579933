import React, { useState } from "react"
import {
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import { get } from "services/AXIOS"
import { GET_VERSIONS } from "services/CONSTANTS"
import AllVersions from "../AllVersions"
import "./ExistingPolicies.css"
import PolicyLink from "./PolicyLink"
import { organizationType, stages } from "constants/global"
import UpdatesAndEmergingOutcomes from "pages/Policy/Updates-And-Emerging-Outcomes"
import Dialog from "../Dialog"

function PolicyRow({ policy, component, getVersions, alert, stage }) {
  let policyPartners = 0
  let policyOwners = 0
  const [open, setOpen] = useState(false)
  const uid = "id" + policy.policy_id
  return (
    <tr>
      <td scope="row" className="col-w-lg fix-col">
        <PolicyLink policy={policy} alert={alert} />
      </td>
      <td className="col-w-md fix-col-1">{policy.country?.label}</td>
      <td className="col-w-lg">
        <div className="d-flex flex-wrap gap-2">
          {policy.labels[0] && (
            <div
              style={{
                "--tabcolor": policy.labels[0].label_color,
                "--bgcolor": policy.labels[0].label_color + "1a",
                borderRadius: "1em",
              }}
              className="px-2 d-flex align-items-center color-contrast-tab"
            >
              <i className={`${policy.labels[0].label_class}`} />
              {policy.labels[0].label_name}
            </div>
          )}
          {policy.labels[1] ? (
            <div
              style={{
                "--tabcolor": policy.labels[1].label_color,
                "--bgcolor": policy.labels[1].label_color + "1a",
                borderRadius: "1em",
              }}
              className="px-2 d-flex align-items-center color-contrast-tab"
            >
              <i className={`${policy.labels[1].label_class}`} />
              {policy.labels[1].label_name}
            </div>
          ) : null}
          {policy.labels.length > 2 && (
            <div>
              <div className="d-flex align-items-center ms-1">
                <div
                  className="mdi mdi-chevron-down d-flex justify-content-center align-items-center dropdown-display-tabs"
                  id={uid}
                ></div>
              </div>
              <UncontrolledPopover
                placement="bottom"
                target={uid}
                trigger="legacy"
              >
                <PopoverBody
                  className="d-flex flex-wrap gap-2"
                  style={{
                    boxShadow: "var(--shadow-elevation-medium)",
                    borderRadius: "1em",
                  }}
                >
                  {policy.labels.map((label, i) => {
                    return (
                      <div
                        style={{
                          "--tabcolor": label.label_color,
                          "--bgcolor": label.label_color + "1a",
                          borderRadius: "1em",
                        }}
                        key={i}
                        className="px-2 d-flex align-items-center color-contrast-tab"
                      >
                        <i className={`${policy.labels[i].label_class}`} />
                        {label.label_name}
                      </div>
                    )
                  })}
                </PopoverBody>
              </UncontrolledPopover>
            </div>
          )}
        </div>
        {/*edit */}
      </td>
      {(component === "directory" || stage?.id === "0") && (
        <td className="col-w-lg">{stages[policy.stage_id]}</td>
      )}
      <td className="col-w-sm">{policy.year}</td>
      {/* {component != "landscape" && (
              <td className="col-w-1 ">{
                policy?.orgs?.find(org => org.organization_type?.id === organizationType.OWNER)?.name
                }</td>
            )} */}
      {component != "landscape" && (
        <td className="col-w-lg">
          <div className="d-flex align-items-center flex-wrap gap-2">
            {policy.orgs.map((org, i) => {
              if (org.organization_type?.id === organizationType.OWNER) {
                policyOwners = policyOwners + 1
                if (policyOwners <= 2) {
                  return (
                    <div
                      style={{
                        "--tabcolor": "#495057",
                        "--bgcolor": "#495057" + "1a",
                        borderRadius: "1em",
                      }}
                      key={i}
                      className="px-2 d-flex align-items-center color-contrast-tab"
                    >
                      {org.name}
                    </div>
                  )
                }
              }
            })}
            {policyOwners > 2 && (
              <div>
                <div
                  className="mdi mdi-chevron-down d-flex justify-content-center align-items-center dropdown-display-tabs"
                  id={`${uid}1a`}
                ></div>
                <UncontrolledPopover
                  placement="bottom"
                  target={`${uid}1a`}
                  trigger="legacy"
                >
                  <PopoverBody
                    className="d-flex flex-wrap gap-2"
                    style={{
                      boxShadow: "var(--shadow-elevation-medium)",
                      borderRadius: "1em",
                    }}
                  >
                    {policy.orgs.map((org, i) => {
                      if (org.organization_type.id === organizationType.OWNER) {
                        return (
                          <div
                            style={{
                              "--tabcolor": "#495057",
                              "--bgcolor": "#495057" + "1a",
                              borderRadius: "1em",
                            }}
                            key={i}
                            className="px-2 d-flex align-items-center color-contrast-tab"
                          >
                            {org.name}
                          </div>
                        )
                      }
                    })}
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            )}
          </div>
        </td>
      )}
      {component != "landscape" && (
        <td className="col-w-lg">
          <div className="d-flex align-items-center flex-wrap gap-2">
            {policy.orgs.map((org, i) => {
              if (org.organization_type?.id === organizationType.PARTNER) {
                policyPartners = policyPartners + 1
                if (policyPartners <= 2) {
                  return (
                    <div
                      style={{
                        "--tabcolor": "#495057",
                        "--bgcolor": "#495057" + "1a",
                        borderRadius: "1em",
                      }}
                      key={i}
                      className="px-2 d-flex align-items-center color-contrast-tab"
                    >
                      {org.name}
                    </div>
                  )
                }
              }
            })}
            {policyPartners > 2 && (
              <div>
                <div
                  className="mdi mdi-chevron-down d-flex justify-content-center align-items-center dropdown-display-tabs"
                  id={`${uid}1a`}
                ></div>
                <UncontrolledPopover
                  placement="bottom"
                  target={`${uid}1a`}
                  trigger="legacy"
                >
                  <PopoverBody
                    className="d-flex flex-wrap gap-2"
                    style={{
                      boxShadow: "var(--shadow-elevation-medium)",
                      borderRadius: "1em",
                    }}
                  >
                    {policy.orgs.map((org, i) => {
                      if (
                        org.organization_type.id === organizationType.PARTNER
                      ) {
                        return (
                          <div
                            style={{
                              "--tabcolor": "#495057",
                              "--bgcolor": "#495057" + "1a",
                              borderRadius: "1em",
                            }}
                            key={i}
                            className="px-2 d-flex align-items-center color-contrast-tab"
                          >
                            {org.name}
                          </div>
                        )
                      }
                    })}
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            )}
          </div>
        </td>
      )}
      {component === "landscape" && (
        <td className="col-w-lg">{policy.sub_category.category.name}</td>
      )}
      {component === "landscape" && <td>{policy.sub_category.name}</td>}
      <td className="col-w-md">
        <div
          style={{
            "--tabcolor": "#FFB000",
            "--bgcolor": "#FFB000" + "1a",
            borderRadius: "1em",
          }}
          className="px-2 d-flex align-items-center color-contrast-tab"
        >
          {/*change color*/}
          {policy.policy_status}
        </div>
      </td>
      <td className="col-w-sm">{policy.policy_score}</td>
      <td className="col-w-sm">
        <a
          onClick={() => getVersions(policy.policy_id, policy.policy_name)}
          className="link-hover"
        >
          All Versions
        </a>
      </td>
      <td className="col-w-sm">
        <Dialog open={open} setOpen={setOpen}>
          <Button onClick={() => setOpen(true)}>View Notes</Button>
          <Dialog.Portal>
            <UpdatesAndEmergingOutcomes
              stageID={policy.stage_id}
              policyID={policy.policy_id}
            />
          </Dialog.Portal>
        </Dialog>
      </td>
    </tr>
  )
}

function ExistingPolicies({ policies, stage, component, alert }) {
  const [versions, setVersions] = useState(undefined)
  const [selectedPolicyName, setSelectedPolicyName] = useState(undefined)

  const getVersions = (id, name) => {
    get(`${GET_VERSIONS}/${id}`)
      .then(response => {
        toggleVersionsModal()
        setVersions(response)
        setSelectedPolicyName(name)
      })
      .catch(err => {
        alert("Version loading", false)
        console.log(err)
      })
  }

  const [modalVersions, setModalVersions] = useState(false)
  const toggleVersionsModal = () => setModalVersions(!modalVersions)

  return (
    <div id="report">
      <div className="table-responsive ">
        <table className="table table-striped">
          <thead className="table-header ">
            <tr>
              <th className="col-w-lg fix-col ">Policy Name</th>
              <th className="col-w-md fix-col-1">Location</th>
              <th className="col-w-lg">Systems</th>
              {(component === "directory" || stage?.id === "0") && (
                <th className="col-w-lg">Progress</th>
              )}
              <th className="col-w-sm">Year</th>
              {component != "landscape" && (
                <th className="col-w-lg ">Owners</th>
              )}
              {component != "landscape" && (
                <th className="col-w-lg">Partners</th>
              )}
              {component === "landscape" && <th>Category</th>}
              {component === "landscape" && <th>Subcategory</th>}
              <th className="col-w-md">Status</th>
              <th className="col-w-sm">Policy Score</th>
              <th className="col-w-sm"></th>
              <th className="col-w-sm"></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(policies) && policies.length > 0
              ? policies.map((policy, idx) => (
                  <PolicyRow
                    policy={policy}
                    key={idx}
                    component={component}
                    getVersions={getVersions}
                    alert={alert}
                    stage={stage}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
      <Modal isOpen={modalVersions} toggle={toggleVersionsModal}>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <h4
              style={{
                color: "black",
              }}
            >
              <i className="mdi mdi-clock mx-1" />
              All Versions
            </h4>
            <i
              style={{ cursor: "pointer", fontSize: "1.2rem" }}
              className="mdi mdi-close"
              onClick={toggleVersionsModal}
            ></i>
          </div>
          <AllVersions data={versions} policyName={selectedPolicyName} alert={alert}/>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ExistingPolicies
