import { csvmaker, downloadCSV } from "components/Common/downloadCSV";
import { formateOnlyDate } from "helpers/utlities"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

export const getPDF = (data) => {
    const doc = new jsPDF("portrait", "mm")

    const head = []
    let body = []

    const makeTable = () => {
      const headValues = [
        "Milestone Name",
        "Mapped To",
        "Type",
        "Status",
        "Created At"
      ]

      headValues.map(value => head.push(value))

      body = data.map(node => {
        const row = [
            node.name,
            node.type == "benchmarking" ? node.label?.label_name : `Stage ${node.stage.stage} - ${node.stage.label}`,
            node.type,
            node.is_deleted
                ? "Archived"
                : node.is_published
                ? "Published"
                : "Unpublished",
            formateOnlyDate(node.created_at)
        ]
        return row
      })
    }

    makeTable()

    autoTable(doc, {
      head: [head],
      body: body,
    })

    doc.save("Milestones" + ".pdf")

}

export const getCSV = (data) => {
    const newData = []
    data.map(node => {
        const newDataObject = {};
        newDataObject["Milestone Name"] = node.name.replaceAll(",",";");
        newDataObject["Mapped To"] = node.type == "benchmarking" ? node.label?.label_name : `Stage ${node.stage.stage} - ${node.stage.label}`;
        newDataObject["Type"] =  node.type;
        newDataObject["Status"] = node.is_deleted ? "Archived" : node.is_published ? "Published" : "Unpublished";
        newDataObject["Created At"] = formateOnlyDate(node.created_at);
        newData.push(newDataObject)
    })
    const csvdata = csvmaker(newData)
    return downloadCSV(csvdata, "Milestones.csv")
}