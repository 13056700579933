import React, { useEffect, useState } from "react"
import { post, get } from "services/AXIOS"
import {
  AUDIT,
  GET_TASKS,
  GETALLFORMS,
  ACCESSUSERLIST,
} from "services/CONSTANTS"
import { Button, Modal, ModalBody, ModalHeader, Container } from "reactstrap"
import Tasks from "./Tasks"
import { useSelector } from "react-redux"
import { getCSV } from "./ExportCsv.js"
import SearchBox from "components/Common/SearchBox"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { useQuery } from "@tanstack/react-query"
//redux
import { formateOnlyDate } from "helpers/utlities"
import FilterTasks from "./FilterTasks"
import SortTasks from "./SortTasks"
import Pagination from "components/Common/Pagination"
import { getTasks } from "services/taskServices"
import LoadingSpinner from "components/Common/Loading-Spinner"
import ExportTable from "components/Common/ExportTable"

const PolicyTasks = ({ alert }) => {
  const { country_id } = useSelector(state => state.Profile)
  const [tasks, setTasks] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const loadTasks = async (query, body) => {
    try {
      const { data } = await getTasks(query, body)
      return data
    } catch (err) {
      console.error(err)
      return err
    }
  }
  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)

  //filter
  const [selectedMilestones, setSelectedMilestones] = useState([])
  const [selectedStages, setSelectedStages] = useState([])
  const [selectedOwners, setSelectedOwners] = useState([])
  const [selectedStatuses, setSelectedStatuses] = useState([])

  const [modalFilter, setModalFilter] = useState(false)
  const toggleFilter = () => setModalFilter(prevState => !prevState)

  //sort
  const [sortForm, setSortForm] = React.useState({ deadline: "ASC" })
  const [modalSort, setModalSort] = useState(false)
  const toggleSort = () => setModalSort(prevState => !prevState)

  //search
  const [searchField, setSearchField] = useState("")

  const [milestones, setMilestones] = useState([])
  const [owners, setOwners] = useState([])

  //get task owners list or users AND milestones list
  useEffect(() => {
    get(GETALLFORMS)
      .then(data => {
        setMilestones(data.data)
      })
      .catch(err => {
        alert("Filters Data loading", false)
        console.log(err)
      })
  }, [])

  const query = useQuery({
    queryKey: [
      "Policy Tasks",
      {
        country_id,
        searchField,
        sortForm,
        selectedStatuses,
        selectedOwners,
        selectedStages,
        selectedMilestones,
      },
    ],
    queryFn: () =>
      loadTasks(
        { limit: 50, offset },
        {
          country_id,
          searchfield: searchField,
          sortForm,
          stages: selectedStages,
          owners: selectedOwners,
          statuses: selectedStatuses,
          milestones: selectedMilestones,
        }
      ),
    enabled: !!country_id,
  })
  //runs filter, order, search, pagination query on backend
  const loadTasksFiltered = (
    searchfield = searchField,
    offsetParam = offset,
    exportTable = false
  ) => {
    setIsLoading(true)
    let limit = 50
    if (exportTable) {
      limit = totalEntries
      offsetParam = 0
    }
    let stages = selectedStages.length
      ? selectedStages.map(stage => stage.value)
      : []
    const owners = selectedOwners.length
      ? selectedOwners.map(owner => owner.value)
      : []
    const milestones = selectedMilestones.map(milestone => milestone.value)
    const statuses = selectedStatuses.length
      ? selectedStatuses.map(status => status.label)
      : []
    post(`${GET_TASKS}?limit=${limit}&offset=${offsetParam}`, {
      country_id,
      searchfield,
      sortForm,
      stages,
      owners,
      statuses,
      milestones,
    })
      .then(data => {
        //check data on empty response without error i.e. else condition and does it need an alert - checked, need a if-else condition for data & no, doesn't need alert
        if (exportTable) {
          if (exportTable === "csv") {
            getCSV(data.data[0])
          } else {
            generatePDF(data.data[0])
          }
          post(AUDIT, { description: `Exported tasks list as ${exportTable}` })
        } else {
          if (data.data) {
            setTasks(data.data[0])
            setTotalEntries(data.data[1])
          }
        }
      })
      .catch(err => {
        console.log(err)
        alert("Data loading", false)
        setIsLoading(false);
      })
      .finally(()=>setIsLoading(false))
  }

  //search
  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase())
    loadTasksFiltered(e.target.value.toLowerCase())
  }

  const applyFilter = () => {
    loadTasksFiltered()
    toggleFilter()
  }

  const applySort = () => {
    loadTasksFiltered()
    toggleSort()
  }

  //pdf-new
  const generatePDF = policies => {
    const doc = new jsPDF()

    // It can parse html:
    // <table id="my-table"><!-- ... --></table>
    // autoTable(doc, { html: '#report1' })

    const head = []
    let body = []

    const makeTable = () => {
      const headValues = [
        "Task Owner",
        "Policy Name",
        "Stage",
        "Milestone",
        "Deadline",
        "Status",
      ]

      headValues.map(value => {
        head.push(value)
      })

      body = tasks.map(task => {
        const row = []

        const bodyValues = [
          task.first_name + " " + task.last_name,
          task.policy_name,
          task.stage_id + "-" + task.label,
          task.name,
          task.deadline ? formateOnlyDate(task.deadline) : "NA",
          task.form_status,
        ]

        bodyValues.map(value => {
          if (value) {
            row.push(value)
          }
        })
        return row
      })
    }

    makeTable()

    autoTable(doc, {
      head: [head],
      body: body,
    })

    doc.save("task list.pdf")
  }

  const checkForFilters = () => {
    if (
      selectedStatuses.length ||
      selectedMilestones.length ||
      selectedOwners.length ||
      selectedStages.length
    ) {
      return true
    } else {
      return false
    }
  }

  const [clearedFilters, setClearedFilters] = useState(false)

  const clearFilters = () => {
    setSelectedOwners([])
    setSelectedMilestones([])
    setSelectedStatuses([])
    setSelectedStages([])
    setClearedFilters(true)
    return true
  }

  useEffect(() => {
    loadTasksFiltered()
    setClearedFilters(false)
  }, [clearedFilters])

  //pagination
  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50
      setOffset(offsetParam)
      loadTasksFiltered(searchField, offsetParam)
      setCurrentPage(page)
    } else {
      console.log("page number should be greater than 0")
    }
  }

  useEffect(() => {
    loadTasksFiltered()

    post(ACCESSUSERLIST, { country_id })
      .then(data => {
        setOwners(
          data.data?.map(owner => {
            return {
              value: owner.id,
              label: owner.first_name + " " + owner.last_name,
            }
          })
        )
      })
      .catch(err => {
        alert("Filters Data loading", false)
        console.log(err)
      })
  }, [country_id])

  return (
    <Container fluid={true}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <SearchBox searchChange={searchChange} />
          <div>
            <Button
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={toggleFilter}
            >
              <i className="mdi mdi-filter-variant me-1"></i>
              Filter
            </Button>
            <Modal
              isOpen={modalFilter}
              toggle={toggleFilter}
              className="modal-lg"
            >
              <ModalHeader toggle={toggleFilter}>
                <i className="mdi mdi-filter-variant me-1"></i>
                Filters
              </ModalHeader>
              <ModalBody>
                <FilterTasks
                  handleMilestones={setSelectedMilestones}
                  handleStatuses={setSelectedStatuses}
                  handleStages={setSelectedStages}
                  handleOwners={setSelectedOwners}
                  selectedMilestones={selectedMilestones}
                  selectedStatuses={selectedStatuses}
                  selectedStages={selectedStages}
                  selectedOwners={selectedOwners}
                  milestones={milestones}
                  owners={owners}
                  applyFilter={applyFilter}
                />
              </ModalBody>
            </Modal>
          </div>
          <div>
            <Button
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={toggleSort}
            >
              <div>
                <i className="mdi mdi-sort me-1"></i>
                Sort
              </div>
            </Button>
            <Modal isOpen={modalSort} toggle={toggleSort}>
              <ModalHeader toggle={toggleSort}>
                <i className="mdi mdi-sort me-1"></i>Sort
              </ModalHeader>
              <ModalBody>
                <SortTasks
                  applySort={applySort}
                  sortForm={sortForm}
                  handleSortChange={setSortForm}
                />
              </ModalBody>
            </Modal>
          </div>
          <div>
            {checkForFilters() && (
              <Button
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "#f8f9fa",
                  color: "black",
                  margin: "15px 10px",
                  border: "none",
                  fontSize: "14px",
                }}
                onClick={clearFilters}
              >
                <i className="ph-x me-1"></i>
                Clear All Filters
              </Button>
            )}
          </div>
        </div>
        <div>
          <ExportTable
            loadFilteredData={loadTasksFiltered}
            searchField={searchField}
          />
        </div>
      </div>
      {isLoading ? <LoadingSpinner /> : (
        <>
          <Tasks
            tasks={tasks}
            loadTasksFiltered={loadTasksFiltered}
            alert={alert}
          />
          <Pagination
            currentPage={currentPage}
            goToPage={goToPage}
            totalEntries={totalEntries}
          />
        </>
      )}
    </Container>
  )
}

export default PolicyTasks
