import React from 'react';

const SearchBox = ({searchChange}) => {
    return (
        <div style={{width: "370px"}}>
          <form className="app-search">
            <div className="position-relative">
              <span style={{color:"black"}} className="bx bx-search"></span>
              <input
                onChange={searchChange}
                type="search"
                className="form-control"
                placeholder="Enter text to search"
              />
            </div>
          </form>
        </div>
    );
}

export default SearchBox;