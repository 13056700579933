import React, { useEffect, useState } from "react"
import * as Tabs from "@radix-ui/react-tabs"
import StepOne from "./Step-One"
import StepTwo from "./Step-Two"
import StepThree from "./Step-Three"
import Preview from "./Step-Four"
import { publishMilestone } from "services/milestoneServices"
import { listSystemSpecificCountries } from "services/listServices"
import styled from "@emotion/styled"
import { useSelector } from "react-redux"
import useFormProgress from "hooks/useFormProgress"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import { useHistory } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { Button } from "reactstrap"
import { useQueryClient } from "@tanstack/react-query"
const TabsRoot = styled(Tabs.Root)`
  display: flex;
  flex-direction: column;
`

const TabsList = styled(Tabs.List)`
  flex-shrink: 0;
  display: flex;
  margin-block-end: 1.5rem;
  border-bottom: 1px solid hsla(0deg 0% 0%/0.3);
`

const TabsTrigger = styled(Tabs.Trigger)`
  all: unset;
  font-size: 1.15rem !important;
  padding: 0 20px;
  height: 45px;
  flex: 1;
  flex-basis: 200px;
  display: grid;
  place-content: center;
  font-size: 1px;
  line-height: 1;
  user-select: none;
  color: white;
  transition: all 250ms;

  &:first-of-type {
    border-top-left-radius: 6px;
  }
  &:last-child {
    border-top-right-radius: 6px;
  }
  &:hover {
    color: black;
  }
  &[data-state="active"] {
    box-shadow: inset 0 -3px 0 0 red;
  }
  &[data-disabled] {
    cursor: not-allowed;
  }
  &:focus-visible {
    position: relative;
    box-shadow: 0 0 0 2px black;
  }

  &:nth-of-type(1) {
    background-color: #168aad;
  }
  &:nth-of-type(2) {
    background-color: #1a759f;
  }
  &:nth-of-type(3) {
    background-color: #1e6091;
  }
  &:nth-of-type(4) {
    background-color: #184e77;
  }
`

const TabsContent = styled(Tabs.Content)`
  flex-grow: 1;
  padding: 20px;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
  &:focus-visible {
    box-shadow: 0 0 0 2px black;
  }
`

const AlertDialogOverlay = styled(AlertDialog.Overlay)`
  background-color: hsla(0deg 0% 0% /0.7);
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 990;
`
const AlertDialogContent = styled(AlertDialog.Content)`
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  min-height: 30vh;
  border-radius: 0.8em;
  padding: 1em 2em;
  z-index: 999;
`

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2em;
  margin: 2em 1em;

  & button {
    all: unset;
    cursor: pointer;
    padding: 0.5em 1em;
    border-radius: 0.8em;
    background-color: hsla(
      137.92207792207793,
      93.90243902439025%,
      67.84313725490196%,
      0.4
    );

    &:nth-of-type(2n) {
      background-color: hsla(315, 95.86776859504131%, 47.450980392156865%, 0.4);
    }
  }
`
const CreateMilestone = props => {
  const { user } = useSelector(state => state.Profile)
  const history = useHistory()
  const queryClient = useQueryClient()
  const { alert } = props
  const [activeTab, setActiveTab] = useState("step1")
  //saving form to local storage
  const formKey = "newMilestone"
  const totalSteps = 4
  const {
    updateFormData,
    resetFormProgress,
    formData,
    markStepAsCompleted,
    completedSteps,
  } = useFormProgress(formKey, totalSteps)

  const [isOpen, setIsOpen] = useState(
    !localStorage.getItem("newMilestone")
      ? false
      : JSON.parse(localStorage.getItem("newMilestone")).formData?.name.length >
          0 ?? false
  )

  //benchmarking specific fetching logic
  const loadSystemSpecificCountries = async id => {
    try {
      const { data } = await listSystemSpecificCountries(id)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const { data: systemSpecificCountries } = useQuery({
    queryKey: ["System Specific Countries", { id: formData?.stage_id }],
    queryFn: () => loadSystemSpecificCountries(formData.stage_id),
    enabled: formData.type === "benchmarking" && !!formData.stage_id,
    initialData: [],
  })

  const handleClose = () => {
    setIsOpen(false) // Close the dialog
  }
  const handleResume = () => {
    const sortedCompletedSteps = completedSteps.sort((a, b) => b - a)
    setActiveTab(`step${String(sortedCompletedSteps[0] ?? 1)}`)
    handleClose()
  }
  const handleStartFresh = () => {
    resetFormProgress()
    handleClose()
  }

  const handleInputChange = (key, value) => {
    updateFormData({ [key]: value })
  }
  const handleQuestionsChange = questionsArr =>
    updateFormData({ questions: questionsArr })

  const handleOwnersChange = ownersArr =>
    updateFormData({ form_owners: ownersArr })

  const handleTabChange = val => setActiveTab(val)

  const handleCompleteStep = stepNum => markStepAsCompleted(stepNum)

  const questionsHaveNoError = () => {
    const tempQuestions = [...questions]
    tempQuestions.map(question => {
      if (!question.question_text) {
        //|| !question.question_weight
        question.error = true
        result = true
      } else question.error = false

      if (question.question_type === "dropdown") {
        question.question_options.map(option => {
          if (!option) {
            question.error = true
            result = true
          }
        })
      }
    })
    setQuestions(tempQuestions)
    return result
  }
  const publish = () => {
    try {
      const res = publishMilestone({ ...formData, is_published: true })
      props.alert("Milestone published successfully", true)
      resetFormProgress()
      queryClient.invalidateQueries({ queryKey: ["Milestones"] })
      setTimeout(() => {
        history.push(`/forms`)
      }, 2500)
    } catch (err) {
      console.error(err)
      props.alert("Milestone publish Failed", false)
    }
  }
  return (
    <>
      <AlertDialog.Root open={isOpen} onOpenChange={handleClose}>
        <AlertDialog.Portal>
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialog.Title>Resume Journey</AlertDialog.Title>
            <AlertDialog.Description>
              Would you like to resume where you left off or start creating a
              new milestone
            </AlertDialog.Description>
            <StyledButtonGroup>
              <button onClick={handleStartFresh}>Start Fresh</button>
              <button onClick={handleResume}>Resume</button>
            </StyledButtonGroup>
          </AlertDialogContent>
        </AlertDialog.Portal>
      </AlertDialog.Root>
      <div className="page-content">
        <div className="d-flex mb-2 px-2">
          <Button
            className="common-button d-flex align-items-center"
            onClick={() => history.push("/forms")}
          >
            <i className="ph ph-caret-left" />
            <span>Go back</span>
          </Button>
        </div>
        <TabsRoot
          defaultValue={user.role === "admin" ? "step1" : "step3"}
          value={activeTab}
        >
          <TabsList>
            <TabsTrigger
              value="step1"
              disabled={user.role !== "admin"}
              onClick={() => setActiveTab("step1")}
            >
              1. Enter Details
            </TabsTrigger>
            <TabsTrigger
              value="step2"
              disabled={user.role !== "admin"}
              onClick={() => setActiveTab("step2")}
            >
              2. Add Questions
            </TabsTrigger>
            <TabsTrigger
              value="step3"
              disabled={formData.type === "landscaping"}
              onClick={() => setActiveTab("step3")}
            >
              3. Assign Owners
            </TabsTrigger>
            <TabsTrigger value="step4" onClick={() => setActiveTab("step4")}>
              4. Preview
            </TabsTrigger>
          </TabsList>
          <TabsContent value="step1">
            <StepOne
              form={formData}
              handleInputChange={handleInputChange}
              changeTab={handleTabChange}
              save={handleCompleteStep}
              alert={alert}
            />
          </TabsContent>
          <Tabs.Content value="step2">
            <StepTwo
              questions={formData.questions}
              setQuestions={handleQuestionsChange}
              save={handleCompleteStep}
              changeTab={handleTabChange}
              alert={alert}
              formType={formData?.type}
            />
          </Tabs.Content>
          <Tabs.Content value="step3">
            <StepThree
              form={formData}
              changeTab={handleTabChange}
              showPrevButton={user.role === "admin"}
              setOwners={handleOwnersChange}
              save={handleCompleteStep}
              alert={alert}
              systemSpecificCountries={systemSpecificCountries}
            />
          </Tabs.Content>
          <Tabs.Content value="step4">
            <Preview
              form={{ ...formData }}
              questions={formData.questions}
              publish={publish}
              showPublish={user.role === "admin"}
              changeTab={handleTabChange}
            />
          </Tabs.Content>
        </TabsRoot>
      </div>
    </>
  )
}

export default CreateMilestone
