import React from 'react';
import { Table } from "reactstrap"
import { formateOnlyDate } from "helpers/utlities"
import { Link } from "react-router-dom"
import styled from "@emotion/styled"

const CenterContent = styled.div`
  min-height: 40vh;
  display: grid;
  place-items: center;
`

function AdminTasksTable({adminTasks}) {
    if (!adminTasks?.length) {
        return (
          <CenterContent>
            <p>
              There are no policies in the selected location that are currently pending for approval.
            </p>
          </CenterContent>
        )
    }

    return (
        <Table className="custom-table table-striped">
            <colgroup>
                <col style={{ width: "35%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
            </colgroup>
            <thead>
                <tr>
                <th>Policy Name</th>
                <th>Current Stage</th>
                <th>Deadline</th>
                <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {adminTasks.map(task => (
                <tr key={task.task_id}>
                    <td>
                        <Link to={`policy/${task.task_policy_id}`} target="_blank">
                            <i className="mdi mdi-arrow-top-right" />
                            <span className="font-size-14">{task.policy_policy_name}</span>
                        </Link>
                    </td>
                    <td>
                    <span
                        className="badge badge-break-word"
                        style={{
                        color: task.stage_color,
                        background: task.stage_backgroundColor,
                        fontSize: "13px",
                        }}
                    >
                        {task.stage_id}: {task.stage_label}
                    </span>
                    </td>
                    <td>
                    <span>
                        <i className="bx bx-bell me-1"></i>
                        {task.policy_deadline
                        ? formateOnlyDate(task.policy_deadline)
                        : "NA"}
                    </span>
                    </td>
                    <td className="status_cell">{task.task_status}</td>
                </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default AdminTasksTable;