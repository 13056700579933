import React from "react"
import ReactApexChart from "react-apexcharts"

const barchart = props => {
  let positive_indicators = []
  let negative_indicators = []
  let neutral_indicators = []
  let indicators = []
  for (var key in props.data) {
    if (props.country == "all") {
      indicators.push(
        `${props.data[key]["country"]} (${props.data[key]["sentiment_score"]})`
      )
    } else {
      indicators.push(
        `${props.data[key]["indicator"]} (${props.data[key]["sentiment_score"]})`
      )
    }

    if (props.data[key]["sentiment_score"] == 0.0) {
      neutral_indicators.push(0.01)
    } else {
      neutral_indicators.push(0)
    }

    if (props.data[key]["sentiment_score"] > 0.0) {
      positive_indicators.push(props.data[key]["sentiment_score"])
    } else {
      positive_indicators.push(0)
    }

    if (props.data[key]["sentiment_score"] < 0.0) {
      negative_indicators.push(props.data[key]["sentiment_score"])
    } else {
      negative_indicators.push(0)
    }
  }
  const series = [
    {
      name: "Positive",
      data: positive_indicators,
    },
    {
      name: "Negative",
      data: negative_indicators,
    },
    {
      name: "Neutral",
      data: neutral_indicators,
    },
  ]

  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: ["#5ed100", "#FB1010", "#fef000"],
    grid: {
      borderColor: "#f1f1f1",
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -1,
      max: 1,
      title: {
        // text: 'Age',
      },
    },
    xaxis: {
      categories: indicators,
      title: {
        text: "Sentiment Score",
      },
    },
  }

  return (
    <ReactApexChart options={options} series={series} type="bar" height="350" />
  )
}

export default barchart
