import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Redirect } from "react-router"

import { logoutUser } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

const Logout = props => {
  const dispatch = useDispatch()

  const { loggedIn } = useSelector(state => state.Login)

  useEffect(() => {
    dispatch(logoutUser(props.history))
  }, [dispatch])

  return <>{!loggedIn && <Redirect to="/login" />}</>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
