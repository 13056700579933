import { get, post, patch } from "./AXIOS"
import {
  CREATE_NEW_FORM,
  EDIT_SPECIFIC_MILESTONE,
  GETFILTEREDFORMS,
  GET_BENCHMARKING_FORM_DETAILS,
  GET_QUESTIONS_OF_EXISTING_MILESTONE,
  LIST_CURRENT_MILESTONE_OWNERS,
  PUBLISH_MILESTONE,
  SPECIFIC_FORM_QUESTIONS,
  ACCESSUSERLIST,
} from "./CONSTANTS"

export const createNewForm = body => post(CREATE_NEW_FORM, body)

export const updateSpecificFormQuestions = (id, body) => {
  patch(`${SPECIFIC_FORM_QUESTIONS}/${id}`, body)
}

export const getCountrySpecificUsers = body => post(`${ACCESSUSERLIST}`, body)

export const getMilestoneOwners = id =>
  get(`${LIST_CURRENT_MILESTONE_OWNERS}/${id}`)

export const updateMilestoneOwners = (id, body) => {
  patch(`${LIST_CURRENT_MILESTONE_OWNERS}/${id}`, body)
}

export const publishMilestone = body => post(`${PUBLISH_MILESTONE}`, body)

export const getQuestionsOfExistingMilestone = id =>
  get(`${GET_QUESTIONS_OF_EXISTING_MILESTONE}/${id}`)

export const updateSpecificMilestoneDetails = (id, body) =>
  patch(`${EDIT_SPECIFIC_MILESTONE}/${id}`, body)

export const getBenchmarkingFormDetails = query =>
  get(
    `${GET_BENCHMARKING_FORM_DETAILS}/${query.countryId}/${query.labelId}/${query.formId}`
  )

export const getMilestones = (query, body) =>
  post(`${GETFILTEREDFORMS}?limit=${query.limit}&offset=${query.offset}`, body)
