import React from 'react';

function Suitcase({hasChildren}) {
    return (
        <g transform={hasChildren?'scale(0.05 0.05) translate(-660,-490)':'scale(0.05 0.05) translate(-660,-330)'}>
            <rect width="256" height="256" fill="none"></rect><rect x="32" y="72" width="192" height="144" rx="8" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></rect><path d="M168,216V56a16,16,0,0,0-16-16H104A16,16,0,0,0,88,56V216" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></path>
        </g>
    );
}


export default Suitcase;
