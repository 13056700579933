import React from 'react';
import { Button, Input } from 'reactstrap';

function EditSubCategory({editSubCategory, editSubCategoryDetails, subCategory}) {
    return (
        <div>
            <div className='d-flex align-items-center'>
                <p className='mb-0 fw-bold' style={{width: "140px"}}>Name:</p>
                <Input
                    id="name"
                    placeholder="Enter subcategory Name"
                    className='ms-2'
                    onChange={(e)=>editSubCategoryDetails(e)}
                    defaultValue={subCategory.name}
                />
            </div>
            <br />
            <div className='d-flex align-items-center'>
                <p className='mb-0 fw-bold' style={{width: "150px"}}>No. of policies:</p>
                <Input
                    onChange={e => editSubCategoryDetails(e)}
                    id="policy_count"
                    type="number"
                    placeholder="1"
                    min="1"
                    defaultValue={subCategory.policy_count}
                />
            </div>
            <br/>
            <div className='d-flex align-items-center'>
                <p className='mb-0 fw-bold' style={{width: "150px"}}>Add Notes:</p>
                <Input
                    onChange={e => editSubCategoryDetails(e)}
                    id="notes"
                    placeholder="Enter item description"
                    type='textarea'
                    defaultValue={subCategory.notes}
                />
            </div>
            <br/>
            <div className="d-flex justify-content-center">
                <Button
                style={{
                    backgroundColor: "#000000",
                    color: "white",
                    border: "none",
                }}
                onClick={editSubCategory}
                >
                Save Changes
                </Button>{" "}
            </div>
        </div>
    );
}

export default EditSubCategory;