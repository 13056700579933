import React from "react"
import styled from "@emotion/styled"
import ReactApexChart from "react-apexcharts"
import { Country, stages } from "constants/global"
import LoadingSpinner from "components/Common/Loading-Spinner"
import { StyledSeparator } from "pages/Audit/Audit-Details"
import DOMPurify from "dompurify"

const typeMap = new Map([
  [
    "Systems",
    {
      background: "#FAF4FA",
      headingColor: "#8D00A6",
      headingContainer: "#FBE5FF",
      icon: "ph-tag",
    },
  ],
  [
    "Locations",
    {
      background: "#FFFCE5",
      heading: "#504700",
      headingContainer: "#fff6b2",
      icon: "ph-globe-hemisphere-east",
    },
  ],
  [
    "Stages",
    {
      background: "#F5FBF3",
      heading: "#0A5700",
      headingContainer: "#E8FFE5",
      icon: "ph-stack-simple",
    },
  ],
])

const StyledCard = styled.article`
  min-height: 350px;
  padding: 1em 2em;
  background-color: ${props => typeMap.get(props.type).background};
  border-radius: 0.8em;
  box-shadow: var(--shadow-elevation-medium);

  & .stat__content {
    display: grid;
    gap: 0.25rem;

    & > header {
      background-color: ${props => typeMap.get(props.type).headingContainer};
      color: ${props => typeMap.get(props.type).headingColor};
      text-transform: uppercase;
      font-size: 16px;
      width: fit-content;
      padding: 0.4em 0.8em;
      & i {
        margin-inline-end: 0.25rem;
        vertical-align: middle;
      }
    }

    & > .best,
    .worst {
      display: flex;
      align-items: center;
    }

    & .stat__text {
      & .green__accent {
        color: #13a800ff;
        font-weight: 600;
      }

      & .red__accent {
        color: #f22128;
        font-weight: 600;
      }
    }
  }
`
const CenterContent = styled.div`
  min-height: 200px;
  display: grid;
  place-items: center;
`

export default function OverviewCard({ type, data, filter, isLoading }) {
  return (
    <StyledCard type={type}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="stat__content">
          <header>
            <i className={typeMap.get(type).icon} />
            {type}
          </header>
          {filter === "policy_win" && type === "Stages" ? (
            <CenterContent>
              <p>No Stage stats available for Policy Win metric.</p>
            </CenterContent>
          ) : (
            <>
              <div className="best">
                <div className="stat__text">
                  <h5>
                    {Country[data?.best?.value] ??
                      stages[data?.best?.value] ??
                      data?.best?.value}
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data?.best?.statement),
                    }}
                  />
                </div>
                <Stat rank="best" value={data?.best?.percentage} />
              </div>
              <StyledSeparator orientation="horizontal" />

              <div className="worst">
                <div className="stat__text">
                  <h5>
                    {Country[data?.worst?.value] ??
                      stages[data?.worst?.value] ??
                      data?.worst?.value}
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data?.worst?.statement),
                    }}
                  />
                </div>
                <Stat rank="worst" value={data?.worst?.percentage} />
              </div>
            </>
          )}
        </div>
      )}
    </StyledCard>
  )
}

const Stat = ({ rank, value }) => {
  const options = {
    plotOptions: {
      radialBar: {
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: "50%",
          background: "transparent",
          image: undefined,
          imageWidth: 150,
          imageHeight: 150,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: "front",
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: "#f2f2f2",
          strokeWidth: "97%",
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          show: true,
          name: {
            show: false,
            fontSize: "16px",
            fontFamily: undefined,
            fontWeight: 600,
            color: undefined,
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: "16px",
            fontFamily: undefined,
            fontWeight: 500,
            color: rank === "best" ? "#13A800FF" : "#F22128",
            offsetY: 0,
            formatter: function (val) {
              return val + "%"
            },
          },
        },
      },
    },
    colors: rank === "best" ? ["#13A800FF"] : ["#F22128"],
    labels: [value],
  }

  return (
    <div>
      <ReactApexChart
        options={options}
        series={[value]}
        type="radialBar"
        height={150}
        width={100}
      />
    </div>
  )
}
