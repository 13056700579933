import React, { useEffect, useRef, useState } from "react"
import { connect, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Row,
  Table,
} from "reactstrap"

import { useMutation } from "@tanstack/react-query"
import countriesFlag from "common/countriesFlag.js"
import Dialog from "components/Common/Dialog.jsx"
import { prettifyTimeUpdated } from "components/Common/PrettifyTime.js"
import { organizationType } from "constants/global.js"
import { formatDateUTC } from "helpers/utlities"
import moment from "moment"
import { useHistory, useParams } from "react-router-dom"
import { get, post, postFormData } from "services/AXIOS"
import {
  COMMENTS,
  GET_COMMENTS,
  GET_VERSIONS,
  LATEST_V_NUM,
  REMOVE_COMMENTS,
  UPDATE_POLICY_VERSION,
} from "services/CONSTANTS.js"
import AllVersions from "../../components/Common/AllVersions"
import FormUpload from "../../components/Common/PolicyTable/PolicyUpload.js"
import PolicyActivitiesTable from "./PolicyActivitiesTable.jsx"
import UpdatePolicyStatus from "./Update Policy Status/PolicyStatus.jsx"
import UpdatePolicy from "./UpdatePolicy.jsx"
import UpdatesAndEmergingOutcomes from "./Updates-And-Emerging-Outcomes.jsx"

export const Policy = ({ alert }) => {
  const { id } = useParams()

  const [replies, setReplies] = useState([])

  const history = useHistory()

  const [newComment, setNewComment] = useState("")

  const [audit, setAudit] = useState([])

  const [comment, setComment] = useState([])
  const [open, setOpen] = useState(false)
  const [stageSelectDropdown, setStageSelectDropdown] = useState({
    stage: null,
    show: false,
    hover: false,
  })

  const toggleDropDown = () => {
    setStageSelectDropdown({
      ...stageSelectDropdown,
      show: !stageSelectDropdown.show,
    })
  }

  const [policy, setPolicy] = useState(null)
  const [policyTracker, setPolicyTracker] = useState([])
  const [policyStages, setPolicyStages] = useState()

  const [forms, setForms] = useState([])

  const [comments, setComments] = useState([])
  const [deleteComment, setDeleteComment] = useState(null)
  const [deleteReply, setDeleteReply] = useState(null)

  const [stageFormInfo, setStageFormInfo] = useState({
    percentage: 0,
  })

  const [value, setValue] = useState(0)
  const forceUpdate = () => setValue(value => value + 1)

  const [modalUpload, setModalUpload] = useState(false)
  const toggleUpload = () => setModalUpload(!modalUpload)

  const [modalComment, setModalComment] = useState(false)
  const toggleComment = () => setModalComment(!modalComment)

  const [modalReply, setModalReply] = useState(false)
  const toggleReply = () => setModalReply(!modalReply)

  const [modalConfirmUpload, setModalConfirmUpload] = useState(false)
  const toggleConfirmUpload = () => setModalConfirmUpload(!modalConfirmUpload)

  const [auditModal, setAuditModal] = useState({ show: false, audit: {} })

  const [file, setFile] = useState(undefined)

  const onNextClick = file => {
    setFile(file)
    toggleUpload()
    toggleConfirmUpload()
  }

  const routeResponseForm = (
    form_id,
    task_id,
    policy_id,
    policy_name,
    stage_id
  ) => {
    history.push({
      pathname: `/forms/${form_id}/${task_id}`,
      state: {
        policy_id,
        policy_name,
        stage_id,
        task_id,
      },
    })
  }

  const onUpload = () => {
    const data = new FormData()
    data.append("file", file)
    postFormData(`${UPDATE_POLICY_VERSION}/${id}`, data)
      .then(function (response) {
        toggleConfirmUpload()
        getVersionNumber()
        getAudit()
        alert("New policy document upload", true)
      })
      .catch(function (error) {
        alert("New policy document upload", false)
        console.log(error)
      })
  }

  const [versions, setVersions] = useState(undefined)

  const getVersions = () => {
    get(`${GET_VERSIONS}/${policy.policy_id}`)
      .then(response => {
        toggleVersionsModal()
        setVersions(response)
      })
      .catch(err => {
        alert("Data loading", false)
        console.log(err)
      })
  }

  const [versionNumber, setVersionNumber] = useState(undefined)

  const getVersionNumber = () => {
    get(`${LATEST_V_NUM}/${policy.policy_id}`)
      .then(response => {
        setVersionNumber(response)
      })
      .catch(err => console.log(err))
  }

  const [modalVersions, setModalVersions] = useState(false)

  const toggleVersionsModal = () => setModalVersions(!modalVersions)

  const commentRef = useRef()

  const [flagObj, setFlagObj] = useState()

  const { mutate: getPolicy } = useMutation(
    ["policies"],
    () =>
      post(`/policies/get-policy`, {
        policy_id: +id,
      }),
    {
      onSuccess: data => {
        setPolicy(data)
        setFlagObj(countriesFlag[data?.country?.title])
        setPolicyTracker(data.policy_tracker)
        // const policyStages = {};
        // data.policy_tracker.map(obj => {
        //   policyStages[obj.stage_id] = obj.stage
        // })
        // setPolicyStages(Object.values(policyStages));
        setPolicyStages(new Set(data.policy_tracker.map(obj => obj.stage_id)))
      },
    }
  )

  const { mutate: updatePolicy } = useMutation(
    ["UpdatePolicy"],
    data => {
      return post("/policies/update-policy", {
        policy_id: +id,
        stage_id: +data.stage,
        status: data.status,
        deadline: data.deadline,
        new_stage_id: data.new_stage_id,
        new_deadline: data.new_deadline,
      })
    },
    {
      onSuccess: () => {
        getPolicy()
        getPolicyForms({ id: policy.stage_id })
        getAudit()
      },
    }
  )

  const { mutate: getPolicyForms } = useMutation(
    ["policyForms"],
    stage =>
      post(`/policies/policy-forms`, {
        policy_id: +id,
        stage_id: stage?.id || stageSelectDropdown?.stage.id,
      }),
    {
      onSuccess: response => {
        if (response) {
          const data = response.data
          let count = 0
          const tempStageFormInfo = { ...stageFormInfo }
          data.forEach(form => {
            if (form.form_status != "COMPLETE") {
              count++
            }
          })
          tempStageFormInfo.percentage = 100 - (count / data.length) * 100
          setStageFormInfo(tempStageFormInfo)
          setForms(data)
        } else {
          setForms([])
        }
      },
    }
  )

  const { mutate: getComments } = useMutation(
    () => get(GET_COMMENTS + id).then(data => data),
    {
      onSuccess: comments => {
        setComments(comments)
      },
    },
    {
      cacheTime: 0,
    }
  )

  const { mutate: getAudit } = useMutation(
    () =>
      post(`/policies/audit`, {
        policy_id: id,
      }),
    {
      onSuccess: audit => {
        setAudit(audit)
      },
    }
  )

  const { mutate: addComment } = useMutation(
    comment => {
      return post(COMMENTS, comment).then(data => data)
    },
    {
      onSuccess: d => {
        const tempComment = [...comment]
        const tempReplies = [...replies]
        const data = d?.data

        if (data?.parent_comment_id) {
          tempComment.map(node => {
            if (node.comment_id === data.parent_comment_id) {
              node.replies.unshift(data)
            }
          })
          commentRef.current.reset()
          alert("Reply Saving", true)
        } else {
          tempComment.unshift(data)

          replyObject["parent_comment_id"] = data.comment_id
          tempReplies.unshift(replyObject)

          setReplies(tempReplies)
          setNewComment("")
          alert("Comment Saving", true)
        }
        setComment(tempComment)
        getAudit()
      },
      onError: e => {
        console.log(e, "error")
      },
    }
  )

  const { mutate: downloadPolicy } = useMutation(
    ["download"],
    () =>
      get(`/policies/download/${policy.policy_id}`, { responseType: "blob" }),
    {
      onSuccess: d => {
        const ext = policy.policy_filename.split(".")[1]
        const fileURL = window.URL.createObjectURL(new Blob([d]), {
          type: `application/${ext}`,
        })
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        const fileName = `${policy.policy_name}.${ext}`
        fileLink.setAttribute("download", fileName)
        fileLink.setAttribute("target", "_blank")
        document.body.appendChild(fileLink)
        fileLink.click()
        fileLink.remove()
      },
      onError: d => {
        alert("Download", false)
      },
    }
  )

  const { mutate: removeComment } = useMutation(
    comment => {
      return get(REMOVE_COMMENTS + comment.comment_id)
    },
    {
      onSuccess: async d => {
        getComments()
        setModalComment(false)
        setModalReply(false)
        getAudit()
      },
    }
  )

  const { tracker_stages } = useSelector(state => state.list)
  const { user } = useSelector(state => state.Profile)

  const replyObject = {
    policy_id: id,
    comment: "",
    parent_comment_id: null,
  }

  useEffect(() => {
    const tempReplies = []
    if (comments?.data) {
      setComment(comments?.data)

      comments.data.map(node => {
        tempReplies.push({
          policy_id: node.policy_id,
          comment: "",
          parent_comment_id: node.comment_id,
        })
      })
      setReplies(tempReplies)
    }
  }, [comments?.data])

  // useEffect(() => {
  //   getComments()
  // }, [comment, replies])

  useEffect(() => {
    if (policy && policy?.stage) {
      setStageSelectDropdown({
        ...stageSelectDropdown,
        stage: policy?.stage,
      })
      getPolicyForms(policy.stage)
    }
    if (policy) {
      getVersionNumber()
    }
  }, [policy])

  useEffect(() => {
    getPolicy()
    getComments()
    getAudit()
  }, [])

  useEffect(() => {
    getPolicy()
    getAudit()
  }, [value])

  const addNewComment = () => {
    const commentObj = {
      policy_id: id,
      comment: newComment,
    }
    addComment({ ...commentObj })
  }

  const onReply = comment_id => {
    const tempReply = replies.find(
      reply => reply.parent_comment_id === comment_id
    )
    if (tempReply) addComment({ ...tempReply })
  }

  const onChangeReply = (comment_id, value) => {
    const tempReply = [...replies]
    tempReply.map(reply => {
      if (reply.parent_comment_id === comment_id) {
        reply.comment = value
      }
    })
    setReplies(tempReply)
  }

  const ProgressComponent = () => (
    <div className=" d-flex w-100 ">
      <p className=" mb-0 mx-2">{Math.round(stageFormInfo?.percentage)}%</p>
      <div className=" w-100 align-self-center">
        <Progress
          value={stageFormInfo?.percentage || 0}
          color="primary"
          className="bg-light progress-sm w-100"
          size="sm"
        />
      </div>
    </div>
  )
  return (
    <div className="page-content">
      <div className=" mb-3 d-flex justify-content-between">
        <p className="p-1 fs-4 fw-bold">
          {policy?.policy_name} ({policy?.year}){" "}
        </p>
        {(user.role == "admin" || user.role == "c_admin") && (
          <UpdatePolicyStatus policy={policy} update={updatePolicy} />
        )}
      </div>

      <Row>
        <Col md="6">
          <Card className="bg-light">
            <CardBody>
              <div className=" d-flex justify-content-between">
                <p>
                  <img
                    src={flagObj}
                    height="23"
                    width="23"
                    className="me-2 country-flag"
                  />
                  {policy?.country?.label}
                </p>
                <UpdatePolicy
                  policy={policy}
                  updateParent={forceUpdate}
                  alert={alert}
                />{" "}
              </div>
              <div className=" d-flex justify-content-between align-items-center">
                <h1 className="fs-2">{policy?.policy_name}</h1>
                {policy?.is_win && (
                  <div
                    className="rounded-pill d-flex align-items-center mx-1 py-2"
                    style={{ background: "green" }}
                  >
                    <i
                      className="ph-trophy fs-4"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                )}
                <div className="d-flex">
                  <p className="border border-primary rounded-pill py-2 mb-0">
                    Score {policy?.policy_score}
                  </p>
                  {/* <p className="border border-primary rounded-pill mx-2 py-2">
                  Stage {policy?.stage?.stage}
                </p> */}
                </div>
              </div>
              <div className=" mt-3">
                <Row>
                  <Col md="4">
                    {" "}
                    <i className="bx bx-windows bx-xs" /> Current Stage
                  </Col>
                  <Col>
                    <div
                      className=" border-1 rounded-pill"
                      style={{
                        backgroundColor: policy?.sub_category_id
                          ? "#E96325"
                          : policy?.stage?.backgroundColor,
                        color: policy?.stage?.color,
                        width: "fit-content",
                      }}
                    >
                      {policy?.sub_category_id
                        ? "Benchmarking"
                        : `Stage ${policy?.stage?.stage} -${" "} ${
                            policy?.stage?.label
                          }`}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mt-3">
                <Row>
                  <Col md="4">
                    <div className=" d-flex  align-items-center">
                      <i className="bx bx-purchase-tag-alt bx-xs" />
                      <p className=" mx-2 mb-0">Systems</p>
                    </div>
                  </Col>
                  <Col md="8" className=" d-flex flex-row flex-wrap">
                    <div
                      style={{
                        height: "auto",
                        maxHeight: "60px",
                        overflowY: "auto",
                      }}
                    >
                      {
                        policy?.labels.map(label => {
                          return (
                            <div
                              key={label.label_id}
                              className={`m-1 rounded-pill d-flex`}
                              style={{
                                border: "1px solid",
                                borderColor: label.label_color,
                                color: label.label_color,
                              }}
                            >
                              <i
                                className={`${label.label_class} align-self-center`}
                              />
                              <p className="mb-0">{label.label_name}</p>
                            </div>
                          )
                        })
                        // <Label
                        //   data={policy?.labels}
                        //   policyId={policy?.policy_id}
                        //   onDelete={() => console.log("Nothing")}
                        // />
                      }
                    </div>
                  </Col>
                </Row>
                {/* <Row className=" mt-3">
                  <Col md="4">
                    <div className=" d-flex">
                      <i className="bx bx-user-circle bx-xs" />
                      <p className=" mx-2 mb-0">Owner</p>
                    </div>
                  </Col>
                  <Col md="8">
                    <p className="">
                    {policy?.orgs?.find(org => org.organization_type?.id === organizationType.OWNER)?.name}
                    </p>
                  </Col>
                </Row> */}
                <Row className=" mt-3">
                  <Col md="4">
                    <div className=" d-flex">
                      <i className="bx bx-user-plus bx-xs" />
                      <p className=" mx-2 mb-0">Owners</p>
                    </div>
                  </Col>
                  <Col md="8">
                    <div className=" d-flex flex-row flex-wrap">
                      {policy?.orgs?.map(org => {
                        if (
                          org.organization_type.id === organizationType.OWNER
                        ) {
                          return (
                            <p
                              key={org.id}
                              className=" rounded-pill mx-1 px-2"
                              style={{
                                color: "#8353E2",
                                backgroundColor: "#F6F2FD",
                                width: "fit-content",
                              }}
                            >
                              {org.name}
                            </p>
                          )
                        }
                      })}
                    </div>
                  </Col>
                </Row>
                <Row className=" mt-3">
                  <Col md="4">
                    <div className=" d-flex">
                      <i className="bx bx-user-plus bx-xs" />
                      <p className=" mx-2 mb-0">Partners</p>
                    </div>
                  </Col>
                  <Col md="8">
                    <div className=" d-flex flex-row flex-wrap">
                      {policy?.orgs?.map(org => {
                        if (
                          org.organization_type.id === organizationType.PARTNER
                        ) {
                          return (
                            <p
                              key={org.id}
                              className=" rounded-pill mx-1 px-2"
                              style={{
                                color: "#8353E2",
                                backgroundColor: "#F6F2FD",
                                width: "fit-content",
                              }}
                            >
                              {org.name}
                            </p>
                          )
                        }
                      })}
                    </div>
                  </Col>
                </Row>
                <Row className=" mt-3">
                  <Col md="4">
                    <div className=" d-flex">
                      <i className="bx bx-user-circle bx-xs" />
                      <p className=" mx-2 mb-0">Year</p>
                    </div>
                  </Col>
                  <Col md="8">
                    <p className="">{policy?.year}</p>
                  </Col>
                </Row>
                {policy?.sub_category_id && (
                  <Row className=" mt-3">
                    <Col md="4">
                      <div className=" d-flex">
                        <i className="bx bx-user-circle bx-xs" />
                        <p className=" mx-2 mb-0">Category</p>
                      </div>
                    </Col>
                    <Col md="8">
                      <p className="">{policy?.sub_category?.category?.name}</p>
                    </Col>
                  </Row>
                )}
                {policy?.sub_category_id && (
                  <Row className=" mt-3">
                    <Col md="4">
                      <div className=" d-flex">
                        <i className="bx bx-user-circle bx-xs" />
                        <p className=" mx-2 mb-0">Subcategory</p>
                      </div>
                    </Col>
                    <Col md="8">
                      <p className="">{policy?.sub_category?.name}</p>
                    </Col>
                  </Row>
                )}
                <hr />
                <div>
                  <p className=" fw-bold">TIMELINE</p>
                  <br />
                  <Row>
                    <Col>
                      <div className=" d-flex align-items-center">
                        <i className="bx bxs-calendar-alt mx-1" />
                        Date added to Stage
                      </div>
                    </Col>
                    <Col>
                      <div>
                        {moment(policy?.current_task?.created_at).format(
                          "MMM D, YYYY"
                        )}
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div className=" d-flex align-items-center">
                        <i className="bx bxs-bell mx-1" />
                        Stage Deadline
                      </div>
                    </Col>
                    <Col>
                      <div>
                        {moment(policy?.tasks[0]?.deadline).format(
                          "MMM D, YYYY"
                        )}
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div className=" d-flex align-items-center">
                        <i className="bx bx-time-five mx-1" />
                        Last Milestone
                      </div>
                    </Col>
                    <Col>
                      <div>
                        {moment(policy?.last_updated_at).format("MMM D, YYYY")}
                      </div>
                    </Col>
                  </Row>

                  <hr />
                </div>

                <div className=" w-100 d-flex justify-content-between">
                  <div className=" d-flex flex-column justify-content-between">
                    <Button
                      color="dark"
                      onClick={() => {
                        downloadPolicy()
                      }}
                    >
                      <i className="mdi mdi-file-download" /> Download Policy
                    </Button>
                    <p className="my-1 ">
                      Version [{versionNumber}]{" "}
                      {prettifyTimeUpdated(policy?.created_at).slice(0, 8)}
                    </p>
                    {/* add dynamic file name */}
                    <p className=" text-decoration-underline my-1">
                      <a onClick={getVersions} style={{ color: "#1091F4" }}>
                        View All Versions
                      </a>
                    </p>
                  </div>
                  <div className="align-self-end">
                    <Dialog open={open} setOpen={setOpen}>
                      <Dialog.Trigger color="#529230">
                        View Updates
                      </Dialog.Trigger>
                      <Dialog.Portal>
                        <UpdatesAndEmergingOutcomes
                          policyID={id}
                          stageID={policy?.stage_id}
                        />
                      </Dialog.Portal>
                    </Dialog>
                  </div>
                  <div className=" d-flex flex-column justify-content-between">
                    <Button
                      color="primary"
                      className=" align-items-center bg-white text-primary"
                      onClick={() => {
                        setAuditModal({ show: true })
                      }}
                    >
                      <i className="bx bx-slider-alt mx-1 bx-xs" />
                      View Audit Trail
                    </Button>

                    {/*Upload new version */}
                    <div>
                      <Button
                        color="primary"
                        className=" align-items-center bg-white text-primary"
                        onClick={toggleUpload}
                      >
                        <i className="bx bx-upload mx-1 bx-xs" /> Update
                        Document
                      </Button>
                      <Modal isOpen={modalUpload} toggle={toggleUpload}>
                        <ModalBody>
                          <FormUpload
                            toggle={toggleUpload}
                            onNextClick={onNextClick}
                          />
                        </ModalBody>
                      </Modal>
                      <Modal
                        isOpen={modalConfirmUpload}
                        toggle={toggleConfirmUpload}
                      >
                        <ModalBody>
                          <h4
                            style={{
                              color: "red",
                              textAlign: "center",
                              fontSize: "18px",
                            }}
                          >
                            <i className="bx bx-error me-2" />
                            Update Policy Document
                          </h4>
                          <hr />
                          <p
                            style={{
                              color: "red",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            Warning! This action will replace the current
                            document.
                          </p>
                          <div className="d-flex justify-content-evenly">
                            <Button
                              className="common-button"
                              onClick={onUpload}
                            >
                              Upload
                            </Button>{" "}
                            <Button
                              className="common-cancel-button"
                              onClick={toggleConfirmUpload}
                            >
                              Cancel
                            </Button>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card
            style={{
              border: "1px dotted",
              borderColor: "#dddddd",
            }}
          >
            <CardBody>
              {policy && policy.tasks && (
                // <TableContainer
                //   tableTitle="Activity Data"
                //   isDropDown={true}
                //   isProgress={true}
                //   ProgressComponent={ProgressComponent}
                //   DropDownComponent={DropDownComponent}
                //   columns={columns}
                //   PolicyDetailsHeader={PolicyDetailsHeader}
                //   data={forms || []}
                //   isGlobalFilter={false}
                //   customPageSize={10}
                //   className="table-borderless"
                // />
                <div>
                  <h4
                    className="d-flex align-items-center mb-5"
                    style={{ color: "black" }}
                  >
                    <i className="ph-file-text me-2"></i>Milestone Data
                  </h4>
                  <div className="d-flex justify-content-between mb-3">
                    <div style={{ width: "30%" }}>
                      <ProgressComponent />
                    </div>
                    {policy?.sub_category_id ? (
                      <div
                        style={{
                          backgroundColor: "#E96325",
                          borderColor:
                            stageSelectDropdown?.stage?.backgroundColor,
                          color: stageSelectDropdown?.stage?.color,
                          padding: "5px",
                          borderRadius: "4px",
                        }}
                      >
                        View Benchmarking Milestone Data
                      </div>
                    ) : (
                      <Dropdown
                        isOpen={stageSelectDropdown.show}
                        toggle={toggleDropDown}
                      >
                        <DropdownToggle
                          caret
                          //size="md"
                          //outline
                          // color="primary"
                          className="p-1"
                          style={{
                            backgroundColor:
                              stageSelectDropdown?.stage?.backgroundColor,
                            borderColor:
                              stageSelectDropdown?.stage?.backgroundColor,
                            color: stageSelectDropdown?.stage?.color,
                          }}
                        >
                          View Stage {stageSelectDropdown?.stage?.stage}{" "}
                          Milestone Data
                          {/* {stageSelectDropdown?.stage?.label} {} */}
                          <i className="bx bx-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu style={{ minWidth: "200px" }}>
                          {tracker_stages.map(stage => {
                            return (
                              <div
                                key={stage.id}
                                className="px-1 mt-2 d-flex justify-content-between gap-1"
                                style={
                                  policyStages.has(stage.id)
                                    ? {
                                        cursor: "pointer",
                                      }
                                    : {
                                        cursor: "not-allowed",
                                        opacity: 0.7,
                                      }
                                }
                                onClick={() => {
                                  if (policyStages.has(stage.id)) {
                                    setStageSelectDropdown({
                                      show: false,
                                      stage: stage,
                                    })
                                    getPolicyForms(stage)
                                  }
                                }}
                              >
                                {!policyStages.has(stage.id) ? (
                                  <i className="ph-eye-slash align-self-center" />
                                ) : (
                                  <i className="ph-eye align-self-center" />
                                )}
                                <span>
                                  Stage {stage?.stage} ({stage?.label})
                                </span>
                              </div>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </div>
                  <PolicyActivitiesTable
                    activities={forms}
                    policy={policy}
                    routeResponseForm={routeResponseForm}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />
      <Card>
        <CardBody>
          <h4>
            <i className="bx bx-comment-dots bx-sm" /> Policy Stage History
          </h4>
          <br />
          <Table>
            <thead>
              <tr>
                <th>STAGE</th>
                <th>USER </th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {policyTracker &&
                policyTracker.map((tracker, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        Stage {tracker.stage_id} - {tracker.stage.label}
                      </td>
                      <td>
                        {tracker.user.first_name} {tracker.user.last_name}
                      </td>
                      <td>{formatDateUTC(tracker.created_at)}</td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card>
        <CardBody className=" shadow-sm">
          <h4>
            <i className="bx bx-comment-dots bx-sm" /> Comments
          </h4>
          <br />
          <div className="d-flex">
            <div>
              <div className="avatar-sm ">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-" +
                    "gray-500" +
                    " text-" +
                    "white" +
                    " font-size-16"
                  }
                >
                  {user?.first_name?.charAt(0)}
                </span>
              </div>
            </div>

            <Input
              placeholder="Leave a public comment"
              onChange={e => setNewComment(e.target.value)}
              value={newComment}
              onKeyDown={e => {
                if (e.key == "Enter") {
                  addNewComment()
                }
              }}
              className="border-0 border-1 rounded-pill h-50"
              style={{
                backgroundColor: " #F3F4F6",
                marginLeft: "20px",
              }}
            />
            <InputGroupText
              className="mx-1 border-0 border-1 rounded-pill h-50"
              style={{
                cursor: "pointer",
              }}
            >
              <i onClick={addNewComment} className="bx bx-send bx-xs" />
            </InputGroupText>
            {/* <Button onClick={addNewComment}>Comment</Button> */}
          </div>
          <br />
          <br />
          <form
            ref={commentRef}
            className="p-4 m-2 rounded-2"
            style={{
              backgroundColor: "#eeeeee",
            }}
            onSubmit={evt => evt.preventDefault()}
          >
            {comment?.map(eachComment => {
              return (
                <div key={eachComment.comment_id} className=" d-block">
                  <div className="d-flex  mb-3">
                    <div className="avatar-sm ">
                      <span
                        className={
                          "avatar-title rounded-circle bg-soft bg-" +
                          "gray-500" +
                          " text-" +
                          "white" +
                          " font-size-16"
                        }
                      >
                        {eachComment.created_by.charAt(0)}
                      </span>
                    </div>
                    <div className="d-block mx-3">
                      <div className="d-flex">
                        <p className="mb-0  fw-bold">
                          {eachComment.created_by}{" "}
                        </p>
                        <p className=" mb-0">
                          {" "}
                          &nbsp;
                          {"at "}
                          {moment(eachComment?.created_at).format(
                            "MMM D, YYYY"
                          )}
                        </p>
                      </div>

                      <div className="mt-2">
                        <p>{eachComment.comment}</p>
                      </div>

                      <div>
                        {eachComment?.replies?.map(reply => {
                          return (
                            <div
                              key={reply.comment_id}
                              className="d-flex  mb-3"
                            >
                              <div className="avatar-sm ">
                                <span
                                  className={
                                    "avatar-title rounded-circle bg-soft bg-" +
                                    "gray-500" +
                                    " text-" +
                                    "white" +
                                    " font-size-16"
                                  }
                                >
                                  {reply.created_by.charAt(0)}
                                </span>
                              </div>
                              <div className="d-block mx-3">
                                <div className="d-flex">
                                  <p className="mb-0  fw-bold">
                                    {reply?.created_by}
                                  </p>
                                  <p className="mb-0">
                                    &nbsp;
                                    {"at "}
                                    {moment(reply?.created_at).format(
                                      "MMM D, YYYY"
                                    )}
                                  </p>
                                </div>
                                <div className="mt-2">
                                  <p>{reply.comment}</p>
                                </div>
                              </div>
                              <div>
                                {user.id === reply.user_id && (
                                  <div>
                                    <i
                                      className=" bx bx-x bx-xs text-right"
                                      onClick={() => {
                                        toggleReply()
                                        setDeleteReply(reply)
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        })}
                        <div className="mx-3 d-flex">
                          <Input
                            placeholder="Write a Reply"
                            onChange={e =>
                              onChangeReply(
                                eachComment.comment_id,
                                e.target.value
                              )
                            }
                            onKeyDown={e => {
                              if (e.key == "Enter")
                                onReply(eachComment.comment_id)
                            }}
                            className="border-0 border-1 rounded-pill h-50 bg-white"
                            style={{
                              backgroundColor: " #F3F4F6",
                              marginLeft: "20px",
                            }}
                          />
                          <InputGroupText
                            className="mx-1 border-0 border-1 rounded-pill h-50"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <i
                              onClick={() => onReply(eachComment.comment_id)}
                              className="bx bx-send bx-xs"
                            />
                          </InputGroupText>
                          {/* <Input
                            placeholder="Reply"
                            name={`${eachComment.comment_id}`}
                            onChange={e =>
                              onChangeReply(
                                eachComment.comment_id,
                                e.target.value
                              )
                            }
                            onKeyDown={e => {
                              if (e.key == "Enter") {
                                return onReply(eachComment.comment_id)
                              }
                            }}
                          />
                          <Button
                            className="mx-1"
                            onClick={() => onReply(eachComment.comment_id)}
                          >
                            Send
                          </Button> */}
                        </div>
                      </div>
                    </div>
                    {user.id === eachComment.user_id && (
                      <>
                        <i
                          className=" bx bx-x bx-xs text-right"
                          onClick={() => {
                            setDeleteComment(eachComment)
                            toggleComment()
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              )
            })}
          </form>
        </CardBody>
      </Card>
      <Modal isOpen={modalReply} toggle={toggleReply}>
        <ModalHeader toggle={toggleReply} className=" text-danger">
          Do you want to delete the reply?
        </ModalHeader>
        <ModalBody>
          <Button onClick={() => removeComment(deleteReply)} color="danger">
            Delete
          </Button>
          <Button onClick={toggleReply} className="mx-2">
            Cancel
          </Button>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalComment} toggle={toggleComment}>
        <ModalHeader toggle={toggleComment} className=" text-danger">
          Do you want to delete the comment?
        </ModalHeader>
        <ModalBody>
          <Button
            onClick={() => {
              removeComment(deleteComment)
            }}
            color="danger"
          >
            Delete
          </Button>
          <Button onClick={toggleComment} className="mx-2">
            Cancel
          </Button>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalVersions} toggle={toggleVersionsModal}>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <h4
              style={{
                color: "black",
              }}
            >
              <i className="mdi mdi-clock mx-1" />
              All Versions
            </h4>
            <i
              style={{ cursor: "pointer", fontSize: "1.2rem" }}
              className="mdi mdi-close"
              onClick={toggleVersionsModal}
            ></i>
          </div>
          <AllVersions data={versions} policyName={policy?.policy_name} alert={alert}/>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={auditModal.show}
        toggle={() => setAuditModal({ show: false })}
        centered
      >
        <ModalBody
          style={{
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <div className="d-flex justify-content-between">
            <p className="fs-4 fw-bold">Audit Trail</p>
            <p
              className=""
              onClick={() => {
                setAuditModal({ show: false })
              }}
              role="button"
            >
              X
            </p>
          </div>
          <div className="">
            {audit.map((eachAudit, index) => {
              return (
                <div
                  key={eachAudit.id}
                  className="px-2 py-3"
                  style={{
                    backgroundColor: index % 2 == 0 ? "#F6F7E5" : "white",
                  }}
                >
                  <div className=" d-flex justify-content-between">
                    <div className="d-flex">
                      <div className="avatar-sm ">
                        <span
                          className={
                            "avatar-title rounded-circle bg-soft bg-" +
                            "gray-500" +
                            " text-" +
                            "white" +
                            " font-size-16"
                          }
                        >
                          {eachAudit?.user_name?.charAt(0) || "NA"}
                        </span>
                      </div>
                      <div className="mx-2">
                        <p className="fw-bold">{eachAudit.user_name || "NA"}</p>
                        <p
                          className="mb-0"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {eachAudit.description}
                        </p>
                      </div>
                    </div>

                    <p
                      style={{
                        fontSize: "0.6rem",
                      }}
                    >
                      {moment(eachAudit.created_at).format("MMM D, YYYY HH:mm")}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Policy)
