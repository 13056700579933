import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody, CloseButton } from "reactstrap"
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/delete-warning';

const DeleteModal = ({ show, onDeleteClick, onCloseClick, id, item }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody>
        <div className="d-flex">
          <div className='me-4'>
            <Lottie 
            options={defaultOptions}
            height={60}
            width={60}
            />
          </div>
          <div className='w-100'>
            <div className='d-flex justify-content-between'><h4 style={{color: "#ED7D2DFF"}}>Warning</h4><CloseButton onClick={onCloseClick}/></div>
            {item === "category" && "THIS WILL DELETE THIS CATEGORY AND ALL POLICIES AND SUB-CATEGORY INFORMATION FOR THIS CATEGORY"}
            {item === "subcategory" && "THIS WILL DELETE THIS SUBCATEGORY ALL POLICIES INFORMATION FOR THIS SUBCATEGORY"}
            {item === "Systems" && "THIS WILL DELETE THIS SYSTEM AND ALL RELATED MILESTONES, MILESTONE TASKS FROM POLICIES, AND THIS SYSTEM FROM THE RELATED POLICIES"}
            {item === "System Types" && "THIS WILL DELETE THE CURRENT SYSTEM TYPE AND MAP THE EXISTTING SYSTEMS IN THIS TYPE TO 'OTHER' SYSTEM TYPE"}
            {!item && "This item will be permanently deleted!"}
            <div className='d-flex mt-3' style={{color: "#EF9834FF"}}>
              <div
                className="me-5 new-btn-light"
                onClick={onCloseClick}
              >
                Cancel
              </div>
              <div
                className='new-btn-light'
                onClick={()=>{
                  onDeleteClick(id);
                  onCloseClick();
                }}
              >
                Delete Anyway
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DeleteModal
