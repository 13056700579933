import React from 'react';
import './Legend.css';

function Legend() {
    return (
        <div>
        <div className='d-flex justify-content-end div-1'>
            <div>
                <h5 className='d-flex justify-content-center'>Sentiment Score</h5>
                <div>
                    <div className='d-flex'>
                        <div className='d-flex align-items-center' style={{background: "#d30000", width: "20px"}}><i className="ph-smiley-sad" style={{fontSize: "20px"}}></i></div>
                        <div style={{background: "#e81817", width: "20px"}}></div>
                        <div style={{background: "#ff1918", width: "20px"}}></div>
                        <div style={{background: "#ff4b4c", width: "20px"}}></div>
                        <div style={{background: "#ffcccc", width: "20px"}}></div>
                        <div style={{background: "#fef100", width: "20px"}}></div>
                        <div style={{background: "#b2ffb7", width: "20px"}}></div>
                        <div style={{background: "#66ff6c", width: "20px"}}></div>
                        <div style={{background: "#44e84d", width: "20px"}}></div>
                        <div style={{background: "#12d21d", width: "20px"}}></div>
                        <div className='d-flex align-items-center' style={{background: "#03bd0a", width: "20px"}}><i className="ph-smiley" style={{fontSize: "20px"}}></i></div>
                    </div>
                    <div className='d-flex'>
                        <div style={{width: "20px"}}>-1</div>
                        <div style={{width: "20px"}}></div>
                        <div style={{width: "20px"}}></div>
                        <div style={{width: "20px"}}></div>
                        <div style={{width: "20px"}}></div>
                        <div style={{width: "20px"}}>0</div>
                        <div style={{width: "20px"}}></div>
                        <div style={{width: "20px"}}></div>
                        <div style={{width: "20px"}}></div>
                        <div style={{width: "20px"}}></div>
                        <div style={{width: "20px"}}>+1</div>
                    </div>
                </div>
            </div>
        </div>
        <div className='d-flex justify-content-end hide-parent'>
            <div className='hide'>
                {/*<div className='w-100 d-flex justify-content-end'><i className="ph-x" style={{fontSize: "18px"}}></i></div>*/}
                <p style={{fontSize: "12px"}} className='d-flex align-items-center'> <i className="ph-smiley me-1"></i> What is a sentiment score?</p>
                <p style={{fontSize: "10px"}}>Sentiment scores are a metric for measuring customer sentiment.
                Positive words are assigned a +1 scoring, while negative words are assigned a -1 scoring.</p>
            </div>
        </div>
        </div>
    );
}

export default Legend;