import React from "react"
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Label,
} from "reactstrap"
import { stages } from "constants/global"

const Preview = props => {
  const { form, questions, updateChanges, changeTab, countries } = props
  const responseName = props?.responseName ? props?.responseName : "responses"
  const { stage_id: stageID } = form
  const myCountries = countries.map(country => country.label)
  const ownersFromMyCountries = form.form_owners.filter(
    owner =>
      myCountries.includes(owner.country) ||
      myCountries.includes(owner.country_name)
  )
  return (
    <div className=" p-3">
      <Card>
        <CardBody style={{ border: "2px solid #d6c4f3", borderRadius: "5px" }}>
          <div className=" d-flex justify-content-between align-items-start">
            <p className=" fw-bold fs-3">{form.name}</p>
            <div className="d-flex">
              <div
                className="p-1 rounded-pill px-2 mx-2"
                style={{
                  backgroundColor: "#F6F2FD",
                  color: "#576175",
                }}
              >
                {form.type === "benchmarking" ? form?.name : stages[stageID]}
                {form.type.length > 0
                  ? form.type === "tracker"
                    ? "(E-Tracker)"
                    : form.type === "landscaping"
                    ? "(Landscaping)"
                    : "(Benchmarking)"
                  : null}
              </div>
            </div>
          </div>
          <div
            style={{
              color: "#9095A0",
            }}
          >
            <p>{form?.description}</p>
          </div>
        </CardBody>
      </Card>
      {questions.map((question, index) => {
        return (
          <Card
            key={index}
            style={{ border: "2px solid #d6c4f3", borderRadius: "5px" }}
          >
            <CardBody>
              <Row>
                <Col md={question.required ? "11" : "12"}>
                  <p className="p-1 px-2 fs-5">
                    <span
                      style={{
                        color: "#8C6BE8",
                        marginInlineEnd: ".4em",
                      }}
                    >
                      {" "}
                      {question.question_number}
                      {"."}
                    </span>
                    {question.question_text}{" "}
                  </p>
                  <div className="align-self-center mt-3">
                    {question.question_type === "text" ? (
                      <Input
                        disabled
                        placeholder="Type your answer here"
                        className=" fs-5 border-0 border-bottom border-2  rounded-0"
                      />
                    ) : question.question_type == "dropdown" ? (
                      <div>
                        <FormGroup tag="fieldset">
                          {question?.question_options?.length > 0 &&
                            question?.question_options?.map((node, idx) => {
                              return (
                                <FormGroup check key={idx} className="mb-3">
                                  <Input
                                    disabled
                                    name={`radio${index}`}
                                    type="radio"
                                    value={node}
                                    defaultChecked={
                                      node ==
                                      (question[responseName] &&
                                        question[responseName][0].response)
                                    }
                                  />
                                  <Label check className="fs-5">
                                    {node}
                                  </Label>
                                </FormGroup>
                              )
                            })}
                        </FormGroup>
                      </div>
                    ) : (
                      <div>
                        <Input
                          disabled
                          type="number"
                          placeholder="Type your answer here"
                          className=" fs-5 border-0 border-bottom border-2  rounded-0"
                        />
                        <div
                          style={{
                            color: "#aaaaaa",
                          }}
                        >
                          <div className="mt-3">
                            On a scale of 0-5, please avoid rating it 3 or
                            neutral
                          </div>
                          <div>
                            More details on AGRA's rating framework can be found{" "}
                            <span className=" text-decoration-underline fst-italic">
                              here
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                {question.required && (
                  <Col md="1" style={{ paddingInline: 0 }}>
                    <i
                      style={{
                        verticalAlign: "middle",
                        fontSize: "1.25rem",
                        color: "hotpink",
                      }}
                      className="ph-asterisk-simple"
                    />
                    <span> Required</span>
                  </Col>
                )}
              </Row>
              {question.is_file_upload && form.type === "benchmarking" && (
                <Row>
                  <Col
                    md="7"
                    style={{
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <Input
                      disabled
                      type="textarea"
                      onChange={e =>
                        responseHandler({
                          id: question.id,
                          index,
                          value: e.target.value,
                          responseType: "reason",
                        })
                      }
                      placeholder="Please provide rationale"
                      style={{}}
                    />
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <Button
                      disabled
                      color="primary"
                      className=" align-self-center bg-white text-primary"
                      onClick={() => {
                        toggleUpload()
                        setFileKey(`question_${question.id}`)
                      }}
                    >
                      <i className="bx bx-upload mx-1 bx-xs" /> Upload Evidence
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        )
      })}
      {ownersFromMyCountries.length > 0 && (
        <Card>
          <CardBody
            style={{ border: "2px solid #d6c4f3", borderRadius: "5px" }}
          >
            <div>
              <p className=" fw-bold fs-3">Owners</p>
              <ol>
                {form.form_owners.map((owner, idx) => (
                  <li
                    key={idx}
                  >{`${owner.user_name} - ${owner.country_name}`}</li>
                ))}
              </ol>
            </div>
          </CardBody>
        </Card>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBlock: "2rem",
        }}
      >
        <Button
          onClick={() => changeTab("step3")}
          color="primary"
          style={{
            backgroundColor: "#8353E2",
            border: "none",
          }}
        >
          <i className="ph-caret-left" style={{ verticalAlign: "middle" }} />{" "}
          Prev
        </Button>
        <Button
          onClick={updateChanges}
          color="primary"
          style={{
            backgroundColor: "#50A050",
            border: "none",
          }}
        >
          Save Changes{" "}
          <i className="ph-upload-simple" style={{ verticalAlign: "middle" }} />
        </Button>
      </div>
    </div>
  )
}

export default Preview
