export const prettifyTime = UTCTime => {
  const localTime = new Date(UTCTime).toLocaleString()
  const dd = localTime.slice(0, 2)
  const mm = localTime.slice(3, 5)
  const yy = localTime.slice(8, 10)
  const time = localTime.slice(12, 17)
  return dd.concat("-", mm, "-", yy, " ", time)
}

export const prettifyTimeUpdated = UTCTime => {
  if (UTCTime) {
    const localTime = new Date(UTCTime).toLocaleString().split(",")
    const fullDate = localTime[0].split("/")
    const date = [fullDate[0], fullDate[1], fullDate[2].slice(2, 4)].join("-")
    const fullTime = localTime[1].split(":")
    const time = [fullTime[0], fullTime[1]].join(":")
    const ampm = fullTime[2].split(" ")[1]
    return `${date} ${time}`
  }
  return "undefined"
}
