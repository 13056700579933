import React, { useState } from "react"
import { Link } from "react-router-dom"
import { map, set } from "lodash"
//redux
import { formateOnlyDate } from "helpers/utlities"
import { EnumStage } from "constants/global"
import Reassign from "./Reassign"
import { REASSIGN_POLICY_TASK } from "services/CONSTANTS"
import { post } from "services/AXIOS"

function Tasks({ tasks, loadTasksFiltered, alert }) {
  const [reassignModal, setReassignModal] = useState(false)

  const toggleReassign = () => setReassignModal(!reassignModal)

  const [selectedMilestoneOwner, setSelectedMilestoneOwner] =
    useState(undefined)
  const [selectedTaskOwner, setSelectedTaskOwner] = useState(undefined)
  const [selectedFormsPolicyId, setSelectedFormsPolicyId] = useState(null)
  const [selectedFormId, setSelectedFormId] = useState(null)
  const [selectedPolicyId, setSelectedPolicyId] = useState(null)
  const [selectedTask, setSelectedTask] = useState({})

  const reassign = selectedOwner => {
    post(REASSIGN_POLICY_TASK, {
      selectedOwnerId: selectedOwner,
      milestoneOwnerId: selectedMilestoneOwner.ownerId,
      formsPolicyId: selectedFormsPolicyId,
      form_id: selectedFormId,
      policy_id: selectedPolicyId,
    })
      .then(data => loadTasksFiltered())
      .catch(err => {
        alert("Task Rassign", false)
        console.log(err)
      })
  }

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Task Owner</th>
            <th scope="col" style={{ width: "30px" }}>
              &nbsp;
            </th>
            <th scope="col">Policy Name</th>
            <th scope="col">Stage</th>
            <th scope="col">Milestone</th>
            <th scope="col">Deadline</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {!tasks.length && (
            <tr>
              <td colSpan="6" className="text-center">
                No Data Available
              </td>
            </tr>
          )}
          {map(tasks, (task, index) => (
            <tr key={index}>
              <td>
                <div>
                  <Link
                    className="text-start"
                    to={`/user-management/${task.user_id}`}
                  >
                    <i className="mdi mdi-account" /> {task.first_name}{" "}
                    {task.last_name}
                  </Link>
                  {task.task_owner_id && (
                    <div>
                      <Link
                        className="text-start d-flex align-items-center"
                        style={{ color: "blue", opacity: 0.5 }}
                        to={`/user-management/${task.task_owner_id}`}
                      >
                        <i className="ph-user-switch me-1" />{" "}
                        {task.task_owner_first_name} {task.task_owner_last_name}
                      </Link>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <i className="mdi mdi-arrow-top-right" />
              </td>
              <td>
                <Link 
                  to={`policy/${task.policy_id}`}
                  className="form-link"
                >
                  <h5 className="font-size-14">{task.policy_name}</h5>
                </Link>
                <p className="text-muted mb-0">
                  {task.form_status == "NEW" && "NEW"}
                </p>
              </td>
              <td>
                <span
                  className="badge badge-break-word"
                  style={{
                    color: task.color,
                    background: task.backgroundColor,
                  }}
                >
                  {task.stage_id ===
                  (EnumStage.BENCHMARKING || EnumStage.COLLECTION)
                    ? task.label
                    : task.stage_id + "-" + task.label}
                </span>
              </td>
              <td>
                <Link
                  to={`/forms/${task.policy_id}/${task.stage_id}/${task.form_id}`}
                  className="form-link"
                >
                  <i className="ph-arrow-up-right me-2"></i>
                  {task.name}
                </Link>
              </td>
              <td>
                <span className={task.progress_font_color}>
                  <i className="bx bx-bell me-1"></i>
                  {task.deadline ? formateOnlyDate(task.deadline) : "NA"}
                </span>
              </td>
              <td>{task.form_status || "PENDING"}</td>
              <td>
                {task.form_status != ("COMPLETE" || "OVERRIDE") && (
                  <button
                    className="common-outlined-button"
                    onClick={() => {
                      toggleReassign()
                      setSelectedMilestoneOwner({
                        owner: task.first_name + " " + task.last_name,
                        ownerId: task.user_id,
                      })
                      setSelectedFormsPolicyId(task.forms_policy_id)
                      setSelectedFormId(task.form_id)
                      setSelectedPolicyId(task.policy_id)
                      setSelectedTaskOwner(
                        task?.task_owner_id && {
                          owner:
                            task.task_owner_first_name +
                            " " +
                            task.task_owner_last_name,
                          ownerId: task?.task_owner_id,
                        }
                      )
                      setSelectedTask({
                        stage_id: task.stage_id,
                        country_id: task.country_id,
                        index: index,
                      })
                    }}
                  >
                    + reassign
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Reassign
        modal={reassignModal}
        toggle={toggleReassign}
        milestoneOwner={selectedMilestoneOwner}
        selectedTask={selectedTask}
        taskOwner={selectedTaskOwner}
        reassign={reassign}
        alert={alert}
      />
    </div>
  )
}

export default Tasks
