import { useMutation } from "@tanstack/react-query"
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalHeader,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"
import { get, patch, post } from "services/AXIOS"
import Preview from "pages/FormManagement/preview"
import { CATEGORY, DELETECATEGORY, DELETESUBCATEGORY } from "services/CONSTANTS"
import "./Step1.scss"
import AddCategory from "./AddCategory"
import EditCategory from "./EditCategory"
import AddSubCategory from "./AddSubCategory"
import EditSubCategory from "./EditSubCategory"
import { useHistory } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"

function Step1({ country, countryId, alert }) {
  const history = useHistory()
  const [forms, setForms] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleCategory = () => setDropdownOpen(prevState => !prevState)

  const [categorySettingOpen, setCategorySettingOpen] = useState(false)
  const toggleCategorySetting = () =>
    setCategorySettingOpen(prevState => !prevState)

  const [selectedCategoryId, setSelectedCategoryId] = useState()
  const [selectedCategory, setSelectedCategory] = useState()

  const responseObject = {
    sub_category_id: null,
    question_id: null,
    response: "",
    form_id: null,
  }

  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false)
  const [deleteSubcategoryModal, setDeleteSubcategoryModal] = useState(false)

  const [categories, setCategories] = useState([])
  const [additionalFeedbackModal, setAdditionalFeedbackModal] = useState({
    show: false,
  })

  const [addCategoryModal, setAddCategoryModal] = useState({
    show: false,
    category: {},
  })

  const [editCategoryModal, setEditCategoryModal] = useState(false)
  const [editedCategory, setEditedCategory] = useState()
  const [editSubcategoryModal, setEditSubcategoryModal] = useState(false)
  const toggleEditSubcategory = () => {
    setEditSubcategoryModal(prevState => !prevState)
  }
  const [editedSubCategory, setEditedSubCategory] = useState()

  const [selectedSubcategory, setSelectedSubcategory] = useState()

  const [addSubCategoryModal, setAddSubCategoryModal] = useState({
    show: false,
    subcategory: {},
  })

  useEffect(() => {
    getCategories()
    getPolicyForms()
  }, [country])

  useEffect(() => {
    getCategory(selectedCategoryId)
  }, [categories])

  const routeResponseForm = (sub_category_id, form_id) => {
    history.push({
      pathname: `/landscaping-forms/${sub_category_id}/${form_id}`,
      state: {
        category: true,
        subcategory_id: sub_category_id,
      },
    })
  }

  const { mutate: getPolicyForms } = useMutation(
    ["policyForms"],
    () =>
      post(`/form/find`, {
        stages: [7],
      }),
    {
      onSuccess: data => {
        setForms(data.data.forms)
      },
    }
  )

  const getCategories = () => {
    get(`${CATEGORY}/${country}`)
      .then(res => {
        Array.isArray(res) && setCategories(res)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const { mutate: addCategory } = useMutation(
    ["addcategory"],
    () =>
      post("/category", {
        name: addCategoryModal.category.name,
        country: countryId,
      }),
    {
      onSuccess: category => {
        alert("Category creation", true) 
        getCategories()
        setSelectedCategoryId(category.id)
        setAddCategoryModal({ show: false, category: {} })
      },
    }
  )

  const editCategory = () => {
    toggleEditCategory()
    patch("/category/" + selectedCategoryId, editedCategory)
      .then(res => {
        alert("Category updation", true)
        getCategories()
      })
      .catch(err => {
        alert("Category updation", false)
        console.log(err)
      })
  }

  const editSubCategory = () => {
    toggleEditSubcategory()
    patch("/sub-category/" + selectedSubcategory.id, editedSubCategory)
      .then(res => {
        getCategories()
        alert("Subcategory Updation", true)
      })
      .catch(err => {
        alert("Subcategory updation", false)
        console.log(err)
      })
  }

  const { mutate: addSubCategory } = useMutation(
    ["addsubcategory"],
    subcategory => {
      return post("/sub-category", {
        ...addSubCategoryModal.subcategory,
        category_id: selectedCategory.id,
      })
    },
    {
      onSuccess: category => {
        getCategories()
        setAddSubCategoryModal({ show: false, subcategory: {} })
      },
    }
  )

  const { mutate: saveResponse } = useMutation(
    ["saveFeedbackResponse"],
    () => {
      // const responses = formData.questions.map(quest => {
      //   return { responses: quest.responses[0],
      //   question }
      // })
      return post("/feedback-form-response", {
        questions: additionalFeedbackModal?.sub_category_form?.form?.questions,
        sub_category: additionalFeedbackModal?.sub_category_form?.id,
        form_id: additionalFeedbackModal?.sub_category_form?.form.id,
      }).then(data => data)
    },
    {
      onSuccess: data => {
        if (data.statusCode == 401) {
          setModalError(true)
        } else {
          getCategories()
          setAdditionalFeedbackModal({
            ...additionalFeedbackModal,
            show: false,
          })
        }
      },
    }
  )

  const responseHandler = response => {
    const { id, index, value } = response

    const feedbackModal = { ...additionalFeedbackModal }
    const tempFormData = { ...additionalFeedbackModal?.sub_category_form?.form }

    tempFormData.questions[index].feedback_responses[0].response = value

    feedbackModal.sub_category_form.form = tempFormData

    setAdditionalFeedbackModal(feedbackModal)
  }

  useEffect(() => {
    const tempFeedbackModal = { ...additionalFeedbackModal }
    for (
      let i = 0;
      i < tempFeedbackModal?.sub_category_form?.form?.questions?.length > 0;
      i++
    ) {
      const tempResponseObject = JSON.parse(JSON.stringify(responseObject))
      tempResponseObject.sub_category_id =
        tempFeedbackModal.sub_category_form.id
      tempResponseObject.form_id = tempFeedbackModal.sub_category_form.form.id
      tempResponseObject.question_id =
        tempFeedbackModal.sub_category_form.form.questions[i].id
      tempFeedbackModal.sub_category_form.form.questions[
        i
      ].feedback_responses.push(tempResponseObject)
    }
    setAdditionalFeedbackModal(tempFeedbackModal)
  }, [additionalFeedbackModal.sub_category_form])

  const getCategory = id => {
    let found = false
    categories.map(category => {
      if (category.id === id) {
        found = true
        setSelectedCategory(category)
      }
    })
    !found && setSelectedCategory()
  }

  const [open, setOpen] = useState("0")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const addCategoryName = e => {
    setAddCategoryModal({
      ...addCategoryModal,
      category: {
        ...addCategoryModal.category,
        name: e.target.value,
      },
    })
  }

  const editCategoryName = e => {
    setEditedCategory({
      name: e.target.value,
    })
  }

  const editSubCategoryDetails = e => {
    setEditedSubCategory({
      ...editedSubCategory,
      [e.target.id]: e.target.value,
    })
  }

  const toggleAddCategory = () => {
    setAddCategoryModal({
      ...addCategoryModal,
      show: !addCategoryModal.show,
    })
  }

  const toggleEditCategory = () => {
    setEditCategoryModal(!editCategoryModal)
  }

  const addSubCategoryDetails = e => {
    const subcat = addSubCategoryModal.subcategory
    if (e.target.id === "policies") {
      subcat.policy_count = e.target.value
    } else if (e.target.id === "name") {
      subcat.name = e.target.value
    } else if (e.target.id === "notes") {
      subcat.notes = e.target.value
    }
    setAddSubCategoryModal({
      ...addSubCategoryModal,
      subcategory: subcat,
    })
  }

  const onOpenedAddCategory = () => {
    setAddCategoryModal({ show: true, category: {} })
  }

  const onOpenedAddSubcategory = () => {
    setAddSubCategoryModal({ show: true, subcategory: { policy_count: 1 } })
  }

  const onOpenedEditCategory = () => {
    setEditedCategory()
  }

  const onOpenedEditSubcategory = () => {
    setEditedSubCategory()
  }

  const deleteCategory = () => {
    patch(`${DELETECATEGORY}/${selectedCategoryId}`)
      .then(() => {
        setCategories(
          categories.filter(category => category.id != selectedCategoryId)
        )
        setSelectedCategoryId()
        alert("Category deletion", true)
      })
      .catch(err => {
        console.log(err)
        alert("Category deletion", false)
      })
  }

  const deleteSubcategory = () => {
    patch(`${DELETESUBCATEGORY}/${selectedSubcategory.id}`)
      .then(() => {
        getCategories()
        alert("Subcategory deletion", true)
      })
      .catch(err => {
        alert("Subcategory deletion", false)
        console.log(err)
      })
  }

  return (
    <div>
      <Card>
        <CardBody>
          <div className=" d-flex justify-content-between align-items-start">
            <p className=" fs-5 mb-0 fw-bold d-flex align-items-center">
              <i className="ph-list-plus me-1 fs-3"></i>Step 1: Collection
            </p>
            <div className="d-flex align-items-center">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleCategory}
                direction={"down"}
              >
                <DropdownToggle
                  caret
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: "#000000",
                    color: "#ffffff",
                    border: "none",
                  }}
                >
                  <i className="ph-caret-down me-1"></i>
                  View Category
                </DropdownToggle>
                <DropdownMenu>
                  {categories.length ? (
                    categories.map((category, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setSelectedCategoryId(category.id)
                            getCategory(category.id)
                          }}
                        >
                          {category.name}
                        </DropdownItem>
                      )
                    })
                  ) : (
                    <DropdownItem>
                      No category available in the country
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
              <Button
                style={{
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  borderColor: "#ffffff",
                }}
                onClick={toggleAddCategory}
                className="ms-3 d-flex align-items-center"
              >
                <i className="ph-plus-circle me-1"></i>Add Category
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
      <div>
        {selectedCategory && (
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-3 justify-content-between">
                <div className="d-flex align-items-center">
                  <i className="ph-suitcase me-2 fs-4"></i>
                  <p className="fw-bold fs-5 m-0">{selectedCategory.name}</p>
                  <div
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: "10px",
                      fontSize: "11px",
                      fontWeight: "lighter",
                    }}
                    className="px-2 ms-2"
                  >
                    Category
                  </div>
                </div>
                <div
                  style={{
                    color: "#000000",
                    background: "lightgray",
                    borderRadius: "6px",
                    margin: "10px 20px 0 0",
                  }}
                  className="d-flex align-items-center"
                >
                  <Dropdown
                    isOpen={categorySettingOpen}
                    toggle={toggleCategorySetting}
                  >
                    <DropdownToggle
                      data-toggle="dropdown"
                      tag="span"
                      role={"button"}
                    >
                      <i className="ph-gear-six p-2 fs-4" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={toggleEditCategory}
                        className="d-flex align-items-center"
                      >
                        <i className="ph-pencil-simple me-2"></i>Edit Category
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setDeleteCategoryModal(true)}
                        className="d-flex align-items-center"
                      >
                        <i className="ph-trash me-2"></i>Delete Category
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className=" d-flex">
                <Card
                  style={{
                    backgroundColor: "#FFE5EEFF",
                    width: "200px",
                  }}
                >
                  <CardBody className="p-0">
                    <div className="d-flex justify-content-between align-items-start p-2">
                      <div>
                        <p className="fw-bold" style={{ fontSize: "12px" }}>
                          {" "}
                          Total Subcatagories
                        </p>
                        <p className=" fs-3 fw-bold">
                          {selectedCategory?.sub_category?.length}
                        </p>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#FF669BFF",
                          color: "white",
                        }}
                        className="p-1 rounded-2"
                      >
                        <i className="ph-folder fs-2" />
                      </div>
                    </div>
                    {/**/}
                  </CardBody>
                </Card>
                <Card
                  style={{
                    backgroundColor: "#FFE9E5FF",
                    width: "200px",
                  }}
                  className="mx-3"
                >
                  <CardBody className="p-0">
                    <div className="d-flex justify-content-between align-items-start p-2">
                      <div>
                        <p className="fw-bold" style={{ fontSize: "12px" }}>
                          {" "}
                          Total Policies
                        </p>
                        <p className=" fs-3 fw-bold">
                          {selectedCategory?.sub_category?.reduce(
                            (prev, cur) => {
                              return prev + cur?.policy_count
                            },
                            0
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#FF785CFF",
                          color: "white",
                        }}
                        className="p-1 rounded-2"
                      >
                        <i className="ph-file-text fs-2" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center mb-2">
                  <i className="ph-folder me-2 fs-4"></i>
                  <p className=" fw-bold fs-5 mb-0">Sub-Categories</p>
                </div>
                <div
                  className="d-flex align-items-center px-2 pt-0"
                  style={{
                    backgroundColor: "#000000",
                    color: "white",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setAddSubCategoryModal({
                      ...addSubCategoryModal,
                      show: true,
                      // category_id: selectedCategory.id,
                    })
                  }
                >
                  <i className="ph-plus"></i>
                  <p className="my-0 ms-2">Add Subcategory</p>
                </div>
              </div>
              <br />
              <Accordion open={open} toggle={toggle}>
                {selectedCategory?.sub_category.map(
                  (subcategory, subcatIndex) => {
                    return (
                      <div key={subcatIndex}>
                        <AccordionItem>
                          <AccordionHeader targetId={subcatIndex.toString()}>
                            <div>{subcategory.name}</div>
                          </AccordionHeader>
                          <AccordionBody accordionId={subcatIndex.toString()}>
                            <div>
                              <div className="d-flex justify-content-end">
                                <div
                                  style={{
                                    color: "#FF785CFF",
                                    background: "#FFF2F0FF",
                                    borderRadius: "6px",
                                    margin: "10px 20px 0 0",
                                  }}
                                  className="d-flex align-items-center"
                                  role={"button"}
                                  onClick={() => {
                                    setSelectedSubcategory(subcategory)
                                    toggleEditSubcategory()
                                  }}
                                >
                                  <i className="ph-pencil-simple p-2 fs-4"></i>
                                </div>
                                <div
                                  style={{
                                    color: "#FF785CFF",
                                    background: "#FFF2F0FF",
                                    borderRadius: "6px",
                                    margin: "10px 20px 0 0",
                                  }}
                                  className="d-flex align-items-center"
                                  role={"button"}
                                  onClick={() => {
                                    setSelectedSubcategory(subcategory)
                                    setDeleteSubcategoryModal(true)
                                  }}
                                >
                                  <i className="ph-trash p-2 fs-4"></i>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="mx-3 mb-0">No. of policies:</p>
                                <div
                                  style={{
                                    borderRadius: "4px",
                                    border: "1px solid #ED7D2DFF",
                                    color: "#ED7D2DFF",
                                    padding: "2px 7px",
                                  }}
                                  className="d-flex align-items-center"
                                >
                                  {subcategory.policy_count}
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="mx-3 mt-4">Milestones:</p>
                                {forms.length > 0 &&
                                  forms.map((form, findex) => {
                                    return (
                                      <Button
                                        key={findex}
                                        style={{
                                          backgroundColor: "#ED7D2DFF",
                                          border: "none",
                                        }}
                                        className="d-flex align-items-center mx-2 ms-3 mt-1"
                                        onClick={() =>
                                          routeResponseForm(
                                            subcategory.id,
                                            form.id
                                          )
                                        }
                                      >
                                        <i className="ph-pencil-simple me-1"></i>
                                        {form.name}
                                      </Button>
                                    )
                                  })}
                              </div>
                              <div className="d-flex mt-1">
                                <p className="mx-3">Notes:</p>
                                <p className="ms-5">{subcategory.notes}</p>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                        <br />
                      </div>
                    )
                  }
                )}
              </Accordion>
            </CardBody>
          </Card>
        )}
      </div>
      {/*<Card
                            style={{
                              backgroundColor: "#FBF1F5",
                              //   width: "250px",
                              // margin: "0px 50px",
                            }}
                          >
                            <CardBody className="p-0">
                              <div className=" d-flex p-2 fs-4 justify-content-center">
                                <i className="bx bx-folder bx-sm" />
                                {subcategory.name}
                              </div>
                              <hr className="mt-0" />
                              <div className="p-2">
                                <div>
                                  <p className="mb-0">No. of Policies</p>
                                  <Input
                                    type="number"
                                    placeholder="0"
                                    value={subcategory.policy_count}
                                    onChange={e => {
                                      const tempCategories = [...categories]
                                      tempCategories[selectedCategory?.id].sub_category[
                                        subcatIndex
                                      ].policy_count = parseInt(e.target.value)
                                      setCategories(tempCategories)
                                    }}
                                  />
                                </div>
                                <br />
                                <div>
                                  <p className="mb-0">Notes</p>
                                  <Input
                                    placeholder="Input Text"
                                    value={subcategory.notes}
                                    onChange={e => {
                                      const tempCategories = [...categories]
                                      tempCategories[selectedCategory?.id].sub_category[
                                        subcatIndex
                                      ].notes = e.target.value
                                      setCategories(tempCategories)
                                    }}
                                  />
                                </div>
                              </div>
                              <br />
                              <div className="p-2">
                                <div
                                  className=" d-flex justify-content-center p-2 my-1 rounded-1"
                                  style={{
                                    backgroundColor: "#9095A0",
                                    color: "#ffffff",
                                  }}
                                >
                                <i className="ph-pencil-simple"></i> Stakeholder
                                  Feedback
                                </div>
                                <div
                                  className=" d-flex justify-content-center p-2 rounded-2"
                                  style={{
                                    backgroundColor: "#9095A0",
                                    color: "#ffffff",
                                  }}
                                  onClick={() => {
                                    if (subcategory?.form_category_map[0])
                                      setAdditionalFeedbackModal({
                                        show: true,
                                        sub_category_form:
                                          subcategory?.form_category_map[0],
                                      })
                                  }}
                                >
                                <i class="ph-pencil-simple"></i> Additional Q&A
                                </div>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#A23B72",
                                  color: "#ffffff",
                                  borderRadius: "0px 0px 3px 3px",
                                  cursor: "pointer",
                                }}
                                className="p-2 d-flex justify-content-center"
                                onClick={() =>
                                  addSubCategory({
                                    ...subcategory,
                                    category_id: category.id,
                                  })
                                }
                              >
                                Save Changes
                              </div>
                            </CardBody>
                              </Card>*/}
      <Modal
        isOpen={addCategoryModal.show}
        toggle={toggleAddCategory}
        centered
        onOpened={onOpenedAddCategory}
      >
        <ModalHeader toggle={toggleAddCategory}>
          <div className="d-flex align-items-center">
            <i className="ph-plus-circle me-1 fw-bold"></i>
            <h4 className="mb-0" style={{ color: "black" }}>
              Add Category
            </h4>
          </div>
        </ModalHeader>
        <ModalBody>
          <AddCategory
            addCategory={addCategory}
            addCategoryName={addCategoryName}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={editCategoryModal}
        toggle={toggleEditCategory}
        centered
        onOpened={onOpenedEditCategory}
      >
        <ModalHeader toggle={toggleEditCategory}>
          <div className="d-flex align-items-center">
            <i className="ph-pencil-simple me-1 fw-bold"></i>
            <h4 className="mb-0" style={{ color: "black" }}>
              Edit Category
            </h4>
          </div>
        </ModalHeader>
        <ModalBody>
          <EditCategory
            editCategory={editCategory}
            editCategoryName={editCategoryName}
            selectedCategory={selectedCategory}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={addSubCategoryModal.show}
        toggle={() => {
          setAddSubCategoryModal({
            ...addSubCategoryModal,
            show: !addSubCategoryModal.show,
          })
        }}
        centered
        onOpened={onOpenedAddSubcategory}
      >
        <ModalHeader
          toggle={() => {
            setAddSubCategoryModal({
              ...addSubCategoryModal,
              show: !addSubCategoryModal.show,
            })
          }}
        >
          <div className="d-flex align-items-center">
            <i className="ph-plus-circle me-1"></i>
            <h4 className="mb-0" style={{ color: "black" }}>
              Add Subcategory
            </h4>
          </div>
        </ModalHeader>
        <ModalBody>
          <AddSubCategory
            addSubCategory={addSubCategory}
            addSubCategoryDetails={addSubCategoryDetails}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={editSubcategoryModal}
        toggle={toggleEditSubcategory}
        centered
        onOpened={onOpenedEditSubcategory}
      >
        <ModalHeader toggle={toggleEditSubcategory}>
          <div className="d-flex align-items-center">
            <i className="ph-pencil-simple me-1 fw-bold"></i>
            <h4 className="mb-0" style={{ color: "black" }}>
              Edit Subcategory
            </h4>
          </div>
        </ModalHeader>
        <ModalBody>
          <EditSubCategory
            editSubCategory={editSubCategory}
            editSubCategoryDetails={editSubCategoryDetails}
            subCategory={selectedSubcategory}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={additionalFeedbackModal.show}
        toggle={() =>
          setAdditionalFeedbackModal({
            ...additionalFeedbackModal,
            show: !additionalFeedbackModal,
          })
        }
        centered
        size="xl"
      >
        <ModalBody>
          <div>
            <Preview
              form={additionalFeedbackModal?.sub_category_form?.form || {}}
              responseHandler={responseHandler}
              responseName="feedback_responses"
            />
            <Button
              style={{
                backgroundColor: "#F08F02",
                border: "none",
              }}
              onClick={() => saveResponse()}
            >
              Submit
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <DeleteModal
        show={deleteCategoryModal}
        onCloseClick={() => setDeleteCategoryModal(false)}
        onDeleteClick={deleteCategory}
        item={"category"}
      />
      <DeleteModal
        show={deleteSubcategoryModal}
        onCloseClick={() => setDeleteSubcategoryModal(false)}
        onDeleteClick={deleteSubcategory}
        item={"subcategory"}
      />
    </div>
  )
}

export default Step1
