import React from 'react';

function SortPolicies({applySort, sortForm, handleSortChange, component}) {
    
    return (
        <div>
            {component === "directory" && <div>
            <div className='mt-2'>Last Updated</div>
            <div className='d-flex'>
                <div className="form-check">
                    <input
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="lastUpdatedAsc" 
                        defaultChecked={sortForm?.last_updated_at === "ASC"}
                        onChange={()=>handleSortChange({last_updated_at: "ASC"})}/>
                    <label className="form-check-label" htmlFor="lastUpdatedAsc">
                        Ascending
                    </label>
                </div>
                <div className="form-check ms-3">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="lastUpdatedDesc" 
                        defaultChecked={sortForm?.last_updated_at === "DESC"}
                        onChange={()=>handleSortChange({last_updated_at: "DESC"})}/>
                    <label className="form-check-label" htmlFor="lastUpdatedDesc">
                        Descending
                    </label>
                </div>
            </div>
            </div>
            }
            <div className='mt-2'>Uploaded</div>
            <div className='d-flex'>
                <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="uploadedAsc"
                        defaultChecked={sortForm?.uploaded_on === "ASC"} 
                        onChange={()=>handleSortChange({uploaded_on: "ASC"})}/>
                    <label className="form-check-label" htmlFor="uploadedAsc">
                        Oldest First
                    </label>
                </div>
                <div className="form-check ms-3">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="uploadedDesc" 
                        defaultChecked={sortForm?.uploaded_on === "DESC"}
                        onChange={()=>handleSortChange({uploaded_on: "DESC"})}/>
                    <label className="form-check-label" htmlFor="uploadedDesc">
                        Recent First
                    </label>
                </div>
            </div>
            {component != "activities" && <div>
            <div className='mt-2'>Year</div>
            <div className='d-flex'>
                <div className="form-check">
                    <input
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="yearAsc" 
                        defaultChecked={sortForm?.year === "ASC"}
                        onChange={()=>handleSortChange({year: "ASC"})}/>
                    <label className="form-check-label" htmlFor="yearAsc">
                        Ascending
                    </label>
                </div>
                <div className="form-check ms-3">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="yearDesc" 
                        defaultChecked={sortForm?.year === "DESC"}
                        onChange={()=>handleSortChange({year: "DESC"})}/>
                    <label className="form-check-label" htmlFor="yearDesc">
                        Descending
                    </label>
                </div>
            </div>
            </div>
            }
            <div className='mt-2'>Policy Name</div>
            <div className='d-flex'>
                <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="policyNameAsc"
                        defaultChecked={sortForm?.policy_name === "ASC"} 
                        onChange={()=>handleSortChange({policy_name: "ASC"})}/>
                    <label className="form-check-label" htmlFor="policyNameAsc">
                        Ascending
                    </label>
                </div>
                <div className="form-check ms-3">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="policyNameDesc" 
                        defaultChecked={sortForm?.policy_name === "DESC"}
                        onChange={()=>handleSortChange({policy_name: "DESC"})}/>
                    <label className="form-check-label" htmlFor="policyNameDesc">
                        Descending
                    </label>
                </div>
            </div>
            {component != "directory" && <div>
            <div className='mt-2'>Policy Score</div>
            <div className='d-flex'>
                <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="policyScoreAsc"
                        defaultChecked={sortForm?.policy_score === "ASC"} 
                        onChange={()=>handleSortChange({policy_score: "ASC"})}/>
                    <label className="form-check-label" htmlFor="policyScoreAsc">
                        Ascending
                    </label>
                </div>
                <div className="form-check ms-3">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="form" 
                        id="policyScoreDesc" 
                        defaultChecked={sortForm?.policy_score === "DESC"}
                        onChange={()=>handleSortChange({policy_score: "DESC"})}/>
                    <label className="form-check-label" htmlFor="policyScoreDesc">
                        Descending
                    </label>
                </div>
            </div>
            </div>
            }
            {component === "activities" && <div>
                <div className = "mt-2">Deadline</div>
                <div className='d-flex'>
                    <div className="form-check">
                        <input
                            className="form-check-input" 
                            type="radio" 
                            name="form" 
                            id="deadlineAsc" 
                            defaultChecked={sortForm?.deadline === "ASC"}
                            onChange={()=>handleSortChange({deadline: "ASC"})}/>
                        <label className="form-check-label" htmlFor="deadlineAsc">
                            Ascending
                        </label>
                    </div>
                    <div className="form-check ms-3">
                        <input 
                            className="form-check-input" 
                            type="radio" 
                            name="form" 
                            id="deadlineDesc" 
                            defaultChecked={sortForm?.deadline === "DESC"}
                            onChange={()=>handleSortChange({deadline: "DESC"})}/>
                        <label className="form-check-label" htmlFor="deadlineDesc">
                            Descending
                        </label>
                    </div>
                </div>
            </div>}
            <div className='d-flex' style={{justifyContent: "center"}}>
                <div 
                    onClick={applySort} 
                    className="btn btn-success common-button" style={{width: "90%"}}>Apply</div>
            </div>
        </div>
        
    );
}

export default SortPolicies;