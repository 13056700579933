import React from "react"
import styled from "@emotion/styled"

const StyledLoadingSpinnerContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: grid;
  place-items: center;
`
const LoadingSpinner = props => (
  <StyledLoadingSpinnerContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={200}
      height={200}
      {...props}
    >
      {/* <g>
      <path
        fill="none"
        stroke="#343A40"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M138.768 100c0 21.411-17.356 38.768-38.768 38.768-21.411 0-38.768-17.356-38.768-38.768 0-21.411 17.357-38.768 38.768-38.768"
      />
      <animateTransform
        attributeName="transform"
        attributeType="xml"
        dur="4s"
        from="0 100 100"
        repeatCount="indefinite"
        to="360 100 100"
        type="rotate"
      />
    </g> */}
      <g>
        <path
          fill="none"
          stroke="#005F73"
          strokeMiterlimit={10}
          strokeWidth={3}
          d="M132.605 100c0 18.008-14.598 32.605-32.605 32.605-18.007 0-32.605-14.598-32.605-32.605 0-18.007 14.598-32.605 32.605-32.605"
        />
        <animateTransform
          attributeName="transform"
          attributeType="xml"
          dur="2s"
          from="0 100 100"
          repeatCount="indefinite"
          to="360 100 100"
          type="rotate"
        />
      </g>
      <g>
        <path
          fill="none"
          stroke="#0A9396"
          strokeMiterlimit={10}
          strokeWidth={3}
          d="M126.502 100c0 14.638-11.864 26.502-26.502 26.502-14.636 0-26.501-11.864-26.501-26.502 0-14.636 11.865-26.501 26.501-26.501"
        />
        <animateTransform
          attributeName="transform"
          attributeType="xml"
          dur="1s"
          from="0 100 100"
          repeatCount="indefinite"
          to="360 100 100"
          type="rotate"
        />
      </g>
      <g>
        <path
          fill="none"
          stroke="#94D2BD"
          strokeMiterlimit={10}
          strokeWidth={3}
          d="M120.494 100c0 11.32-9.174 20.494-20.494 20.494-11.319 0-20.495-9.174-20.495-20.494 0-11.319 9.176-20.495 20.495-20.495"
        />
        <animateTransform
          attributeName="transform"
          attributeType="xml"
          dur="0.5s"
          from="0 100 100"
          repeatCount="indefinite"
          to="360 100 100"
          type="rotate"
        />
      </g>
    </svg>
  </StyledLoadingSpinnerContainer>
)
export default LoadingSpinner
