import React from "react"
import styled from "@emotion/styled"
import DOMPurify from "dompurify"
import LoadingSpinner from "components/Common/Loading-Spinner"

const metricsMap = new Map([
  ["policy_score", "Policy Score"],
  ["number_of_policies", "No. of Policies"],
  ["policy_win", "Policy Wins"],
  ["days_past_deadline", "Policies Past Deadline"],
])

export default function Insights({
  dimensions,
  selectedMetrics,
  firstComparison,
  secondComparison,
  thirdComparison,
}) {
  return (
    <StyledInsights>
      <InsightList>
        {selectedMetrics.map((metric, idx) => (
          <li key={metric.value}>
            <header>{metricsMap.get(metric.value)}</header>
            <div className="insights">
              <div className="heading">
                <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="5" cy="5" r="5" fill="#13a800" />
                </svg>
                <p className="green__accent">Positive Insights</p>
              </div>
              {firstComparison[idx]?.isLoading && <LoadingSpinner />}
              {!firstComparison[idx]?.isLoading &&
                firstComparison[idx]?.data?.positive?.length === 0 && (
                  <p className="no__insights">
                    No positive insights available for this combination.
                  </p>
                )}
              {!firstComparison[idx]?.isLoading &&
                firstComparison[idx]?.data?.positive?.length > 0 && (
                  <ul>
                    {firstComparison[idx]?.data?.positive.map(
                      (insight, idx) => (
                        <li
                          key={idx}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(insight),
                          }}
                        />
                      )
                    )}
                  </ul>
                )}
              {secondComparison[idx]?.isLoading && <LoadingSpinner />}
              {!secondComparison[idx]?.isLoading &&
                secondComparison[idx]?.data?.positive?.length === 0 && (
                  <p className="no__insights">
                    No positive insights available for this combination.
                  </p>
                )}
              {!secondComparison[idx]?.isLoading &&
                secondComparison[idx]?.data?.positive?.length > 0 && (
                  <ul>
                    {secondComparison[idx]?.data?.positive.map(
                      (insight, idx) => (
                        <li
                          key={idx}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(insight),
                          }}
                        />
                      )
                    )}
                  </ul>
                )}
              {thirdComparison[idx]?.isLoading && dimensions.length === 3 && (
                <LoadingSpinner />
              )}
              {!thirdComparison[idx]?.isLoading &&
                dimensions.length === 3 &&
                thirdComparison[idx]?.data?.positive?.length === 0 && (
                  <p className="no__insights">
                    No positive insights available for this combination.
                  </p>
                )}
              {!thirdComparison[idx]?.isLoading &&
                dimensions.length === 3 &&
                thirdComparison[idx]?.data?.positive?.length > 0 && (
                  <ul>
                    {thirdComparison[idx]?.data?.positive.map(
                      (insight, idx) => (
                        <li
                          key={idx}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(insight),
                          }}
                        />
                      )
                    )}
                  </ul>
                )}
            </div>
            <div className="insights">
              <div className="heading">
                <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="5" cy="5" r="5" fill="#f22128" />
                </svg>
                <p className="red__accent">Negative Insights</p>
              </div>
              {firstComparison[idx]?.isLoading && <LoadingSpinner />}
              {!firstComparison[idx]?.isLoading &&
                firstComparison[idx]?.data?.negative?.length === 0 && (
                  <p className="no__insights">
                    No negative insights available for this combination.
                  </p>
                )}
              {!firstComparison[idx]?.isLoading &&
                firstComparison[idx]?.data?.negative?.length > 0 && (
                  <ul>
                    {firstComparison[idx]?.data?.negative.map(
                      (insight, idx) => (
                        <li
                          key={idx}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(insight),
                          }}
                        />
                      )
                    )}
                  </ul>
                )}

              {secondComparison[idx]?.isLoading && <LoadingSpinner />}
              {!secondComparison[idx]?.isLoading &&
                secondComparison[idx]?.data?.negative?.length === 0 && (
                  <p className="no__insights">
                    No negative insights available for this combination.
                  </p>
                )}
              {!secondComparison[idx]?.isLoading &&
                secondComparison[idx]?.data?.negative?.length > 0 && (
                  <ul>
                    {secondComparison[idx]?.data?.negative.map(
                      (insight, idx) => (
                        <li
                          key={idx}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(insight),
                          }}
                        />
                      )
                    )}
                  </ul>
                )}

              {thirdComparison[idx]?.isLoading && dimensions.length === 3 && (
                <LoadingSpinner />
              )}
              {!thirdComparison[idx]?.isLoading &&
                dimensions.length === 3 &&
                thirdComparison[idx]?.data?.negative?.length === 0 && (
                  <p className="no__insights">
                    No negative insights available for this combination.
                  </p>
                )}
              {!thirdComparison[idx]?.isLoading &&
                dimensions.length === 3 &&
                thirdComparison[idx]?.data?.negative?.length > 0 && (
                  <ul>
                    {thirdComparison[idx]?.data?.negative.map(
                      (insight, idx) => (
                        <li
                          key={idx}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(insight),
                          }}
                        />
                      )
                    )}
                  </ul>
                )}
            </div>
          </li>
        ))}
      </InsightList>
    </StyledInsights>
  )
}

const StyledInsights = styled.div`
  display: grid;

  & .insights {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 150px repeat(3, 1fr);
    column-gap: 2rem;
    margin-block-end: 1rem;
  }
  & .no__insights {
    opacity: 0.5;
  }
  & ul {
    list-style: none;
    padding-inline-start: 0;
  }
`
const DimensionCard = styled.div`
  color: #b333e9;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  outline: 1px solid;
  padding: 1em;
  box-shadow: var(--shadow-elevation-low);
`

const InsightList = styled.ul`
  & > * {
    margin-block-end: 1.5rem;
  }
  & > li {
    header {
      background-color: #1092f4;
      color: white;
      padding: 0.3em 0.6em;
      font-size: 16px;
      font-weight: 600;
      margin-block-end: 1rem;
    }
  }

  & .insights {
    padding: 0.3em 0.6em;

    & .heading {
      display: flex;
      align-items: baseline;
      gap: 0.5em;

      & .green__accent {
        color: #13a800;
        font-weight: 500;
      }

      & .red__accent {
        color: #f22128;
        font-weight: 500;
      }
    }
  }
  & .insights ul li {
    margin-block-end: 0.75rem;
  }
`
