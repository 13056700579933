import React, { useRef } from "react"
import * as Separator from "@radix-ui/react-separator"
import styled from "@emotion/styled"
import html2canvas from "html2canvas"
import Dialog from "components/Common/Dialog"
import { Button } from "reactstrap"

const DialogContent = styled(Dialog.Content)`
  background-color: white;
  border-radius: 1em;
  box-shadow: var(--shadow-elevation-medium);
  font-size: 14px;
  & .card {
    box-shadow: var(--shadow-elevation-low);
    padding: 0.4em 0.8em;
  }

  & button {
    all: unset;
    background-color: green;
    color: white;
    padding: 0.4em 1.6em;
    flex-basis: 150px;
    text-align: center;
    border-radius: 0.8em;
    border-radius: 0.5em;
  }
`
export const StyledSeparator = styled(Separator.Root)`
  background-color: #000;
  opacity: 0.3;
  &[data-orientation="vertical"] {
    height: 100%;
    width: 0.7px;
  }
  &[data-orientation="horizontal"] {
    width: 100%;
    height: 0.71px;
    margin-block-end: 1rem;
  }
`
const StyledChangesList = styled.ul`
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  padding-inline: 0;

  & li:not(:last-child)::after {
    content: ", ";
    margin-inline-end: 0.2em;
  }
`
const StyledContainer = styled.div`
  height: 50vh;
  overflow-y: auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 2px 1fr;
  column-gap: 0.5em;
  margin-block-end: 1.5rem;

  & .list_container {
    width: 90%;
    margin-inline: auto;
  }
`
const ChangesList = ({ data, oldValues = {} }) => {
  if (Object.keys(data).length === 0) {
    return (
      <div
        style={{
          display: "grid",
          placeItems: "center",
          marginBlockStart: "2rem",
        }}
      >
        <p className="nothing">Nothing to show</p>
      </div>
    )
  }
  const iconHashMap = new Map([
    ["stages", "ph-stack-simple"],
    ["countries", "ph-globe-hemisphere-east"],
    ["code", "ph-code-simple"],
    ["role", "ph-identification-badge"],
    ["users", "ph-users"],
    ["organization", "ph-briefcase"],
    ["email", "ph-envelope-simple"],
  ])
  const renderData = value => {
    if (Array.isArray(value)) {
      return (
        <StyledChangesList>
          {value.map((item, index) => (
            <li key={index}>{renderData(item)}</li>
          ))}
        </StyledChangesList>
      )
    } else if (typeof value === "object") {
      return JSON.stringify(value)
    } else if (value === true) {
      return "Yes"
    } else if (value === false) {
      return "No"
    } else {
      return value
    }
  }

  if (Object.keys(oldValues).length <= 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {Object.entries(data).map(([key, value]) => (
          <div key={key} style={{ marginBlockEnd: ".5rem" }} className="card">
            {/* {typeof value === "object" && !Array.isArray(value) ? null : ( */}
            <>
              <p
                style={{
                  textTransform: "capitalize",
                  color: "green",
                  marginBlockEnd: ".25rem",
                  fontSize: "14px",
                  fontWeight: 500,
                  display: "inline-block",
                }}
              >
                <i className={iconHashMap.get(key)} /> {key}:
              </p>
              {renderData(value)}
            </>
            {/* )} */}
          </div>
        ))}
      </div>
    )
  }
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {Object.entries(data).map(([key, value]) => (
        <div key={key} style={{ marginBlockEnd: ".5rem" }} className="card">
          {/* {typeof value === "object" ? (
            <>
              <p
                style={{
                  textTransform: "capitalize",
                  color: "green",
                  marginBlockEnd: ".25rem",
                  fontSize: "14px",
                  fontWeight: 500,
                  display: "inline-block",
                }}
              >
                <i className={iconHashMap.get(key)} /> {key}: Nested Data
              </p>
            </>
          ) : ( */}
          <>
            <p
              style={{
                textTransform: "capitalize",
                color: "green",
                marginBlockEnd: ".25rem",
                fontSize: "14px",
                fontWeight: 500,
                display: "inline-block",
              }}
            >
              <i className={iconHashMap.get(key) ?? ""} /> {key}:
            </p>
            {renderData(value)}
          </>
          {/* )} */}
        </div>
      ))}
    </div>
  )
}

const ChangesDetails = ({
  oldValues,
  newValues,
  description,
  timestamp,
  location,
  ip,
  user,
}) => {
  const ref = useRef(null)
  const handleExportJpg = async () => {
    if (ref.current) {
      ref.current.querySelector(".content_container").style.overflowY =
        "visible"
      ref.current.querySelector(".content_container").style.height = "auto"
      const canvas = await html2canvas(ref.current)
      const link = document.createElement("a")
      link.href = canvas.toDataURL("image/jpeg")
      link.download = `${description}.jpg`
      link.click()

      ref.current.querySelector(".content_container").style.overflowY = "auto"
      ref.current.querySelector(".content_container").style.height = "40vh"
    }
  }
  const detailsStr = (
    <>
      Changes made by <b>{user}</b> at <em>{timestamp}</em> from {location}({ip}
      )
    </>
  )
  return (
    <DialogContent ref={ref}>
      <div className="d-flex justify-content-between">
        <Dialog.Title>{description}</Dialog.Title>
        <div className="align-self-start">
          <Dialog.Close leading="ph-x" isCloseSVG />
        </div>
      </div>
      <Dialog.Description>{detailsStr}</Dialog.Description>
      <StyledContainer className="content_container">
        <div className="list_container">
          <h5
            style={{
              width: "90%",
              marginInline: "auto",
            }}
          >
            New Values
          </h5>
          <ChangesList data={newValues} oldData={oldValues} />
        </div>
        <StyledSeparator orientation="vertical" />
        <div className="list_container">
          <h5 style={{}}>Old Values</h5>
          <ChangesList data={oldValues} />
        </div>
      </StyledContainer>
      <StyledSeparator orientation="horizontal" decorative />
      <div className="d-flex justify-content-center gap-4">
        <Dialog.Close>Close</Dialog.Close>
        <Button color="primary" onClick={handleExportJpg}>
          Export as JPG
        </Button>
      </div>
    </DialogContent>
  )
}

export default ChangesDetails
