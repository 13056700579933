import React, { useEffect, useState, Fragment } from "react"
import Select from "react-select"
import { getCountrySpecificUsers } from "services/milestoneServices"
import { useQuery } from "@tanstack/react-query"
import { Button, Table, UncontrolledTooltip } from "reactstrap"
import styled from "@emotion/styled"
import { Role } from "constants/global"
const StyledButton = styled.button`
  background-color: #6c757d; /* Green */
  border: none;
  color: white;
  font-size: 13px;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 1000vmax;
`
const StepThree = ({
  form,
  changeTab,
  showPrevButton,
  currentFormOwners,
  setFormOwners,
  countries,
  systemSpecificCountries,
  newOwners,
  setNewOwners,
  existingFormOwners,
  setExistingFormOwners,
}) => {
  const myCountries = countries.map(country => country.label)
  const [country, setCountry] = useState(null)
  const [owner, setOwner] = useState(null)
  const [ownersFromMyCountries, setOwnersFromMyCountries] = useState([])
  const [countryOptions, setCountryOptions] = useState([])

  useEffect(() => {
    setOwnersFromMyCountries(
      existingFormOwners.filter(owner =>
        myCountries.includes(owner.country_name)
      )
    )
  }, [existingFormOwners])
  const loadCountrySpecificOwners = async body => {
    try {
      const { data } = await getCountrySpecificUsers(body)
      return data.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name} (${
          Role[user.auth.role] ?? ""
        })`,
      }))
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const { data: ownerOptions } = useQuery({
    queryKey: ["Country Owners", { id: country?.value }],
    queryFn: () =>
      loadCountrySpecificOwners({
        stage_id: form.stage_id,
        country_id: country?.value,
      }),
    enabled: !!country,
    staleTime: 60 * 1_000,
  })
  const handleCountryChange = option => {
    setCountry(option)
    setOwner(null)
  }
  const handleAddOwner = () => {
    setNewOwners(prev => [
      ...prev,
      {
        country_name: country.label,
        user_name: owner.label,
        country_id: country.value,
        user_id: owner.value,
      },
    ])
    setCountryOptions(prevOptions =>
      prevOptions.filter(option => option.value !== country.value)
    )
    setCountry(null)
    setOwner(null)
  }
  useEffect(() => {
    const countriesWithExistingOwners = existingFormOwners.map(
      owner => owner.country_name ?? owner.country
    )
    const countriesWithNewOwners = newOwners.map(owner => owner.country)
    if (countries.length > 0) {
      if (form.type === "benchmarking") {
        setCountryOptions(
          countries
            .filter(
              country =>
                !countriesWithExistingOwners.includes(country.label) &&
                !countriesWithNewOwners.includes(country.label) &&
                systemSpecificCountries.includes(country.id)
            )
            .map(country => ({
              value: country.id,
              label: country.label,
            }))
        )
      } else {
        setCountryOptions(
          countries
            .filter(
              country =>
                !countriesWithExistingOwners.includes(country.label) &&
                !countriesWithNewOwners.includes(country.label)
            )
            .map(country => ({
              value: country.id,
              label: country.label,
            }))
        )
      }
    }
  }, [countries, existingFormOwners, newOwners])

  const handleRemoveExistingOwner = userID => {
    setExistingFormOwners(prev =>
      prev.filter(owner => owner.user_id !== userID)
    )
  }
  const handlePairDelete = (country_name, country_id) => {
    setCountryOptions(prev => [
      ...prev,
      { label: country_name, value: country_id },
    ])
    setNewOwners(prev =>
      prev.filter(pair => pair.country_name !== country_name)
    )
  }
  return (
    <div
      style={{
        minHeight: "40vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBlock: "2rem",
      }}
    >
      {countryOptions.length === 0 &&
        newOwners.length === 0 &&
        form.type === "benchmarking" && (
          <div
            style={{
              width: "70%",
              marginInline: "auto",
              marginBlockEnd: "2rem",
              backgroundColor: "#f2f2f2",
              padding: "1em",
              borderRadius: ".8em",
            }}
          >
            <p style={{ fontSize: "14px", opacity: ".5" }}>
              <i className="ph-info" style={{ verticalAlign: "middle" }} /> If
              you are unable to see the country to whom you want to map this
              benchmarking form, it means that this particular system isn't
              mapped to that country. Please go to System Management -&gt;
              Systems, click "Add New" button, type this system name in the
              input bar, select the suggestion that matches the term and hit
              save and then comeback here.
            </p>
          </div>
        )}
      <div
        className="d-flex gap-4 mx-10"
        style={{ width: "70%", marginInline: "auto", marginBlockEnd: "2rem" }}
      >
        <div className="w-100">
          <label htmlFor="country">Country:</label>
          <Select
            aria-labelledby="country"
            value={
              country
                ? countryOptions.find(opt => opt.value === country.value)
                : null
            }
            options={countryOptions}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select a Country"
            onChange={option => handleCountryChange(option)}
          />
        </div>
        <div className="w-100">
          <label htmlFor="owner">Owner</label>
          <Select
            aria-labelledby="owner"
            value={
              owner ? ownerOptions.find(opt => opt.value === owner.value) : null
            }
            options={ownerOptions}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select a owner"
            onChange={option => setOwner(option)}
          />
        </div>
        <button
          style={{ flexBasis: "200px", alignSelf: "flex-end" }}
          onClick={handleAddOwner}
        >
          Add Owner
        </button>
      </div>
      <div
        style={{
          width: "70%",
          marginInline: "auto",
          display: "grid",
          gridTemplateColumns: "4fr 1fr",
          gap: "1em",
        }}
      >
        {newOwners.length > 0 ? (
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              flexWrap: "wrap",
              gap: ".5em",
            }}
          >
            {newOwners.map((pair, idx) => (
              <li key={idx}>
                <StyledButton
                  onClick={() =>
                    handlePairDelete(pair.country_name, pair.country_id)
                  }
                >
                  <i
                    className="ph-x-circle"
                    style={{ verticalAlign: "middle" }}
                  />
                  {`  ${pair.country_name},${pair.user_name}`}
                </StyledButton>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      {ownersFromMyCountries.length > 0 ? (
        <Table
          className="custom-table"
          striped
          style={{ width: "40%", margin: "2rem auto" }}
        >
          <thead>
            <tr>
              <th>Country</th>
              <th>Owner</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ownersFromMyCountries.map((owner, idx) => (
              <tr key={idx}>
                <td>{owner?.country_name}</td>
                <td>{owner?.user_name}</td>
                <td>
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveExistingOwner(owner.user_id)}
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
          marginInline: "auto",
          marginBlockStart: "2rem",
        }}
      >
        {showPrevButton && (
          <Button
            onClick={() => changeTab("step3")}
            color="primary"
            style={{
              backgroundColor: "#8353E2",
              border: "none",
            }}
          >
            <i className="ph-caret-left" style={{ verticalAlign: "middle" }} />{" "}
            Prev
          </Button>
        )}
        <Button
          color="primary"
          style={{
            backgroundColor: "#8353E2",
            border: "none",
            marginInlineStart: "auto",
          }}
          onClick={() => changeTab("step4")}
        >
          Preview{" "}
          <i className="ph-caret-right" style={{ verticalAlign: "middle" }} />
        </Button>
      </div>
    </div>
  )
}

export default StepThree
