import React, {useState} from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
import './ExportTable.css';

function ExportTable({loadFilteredData, searchField, component}) {

    const [dropdownOpen, setDropdownOpen] = useState(false);
  
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" className='policy-directory-dropdown'>
        <DropdownToggle caret className='common-button'>
            <i className="bx bx-printer me-1">
            </i>
            Export Table
        </DropdownToggle>
        <DropdownMenu className='px-2'>
          <DropdownItem className="rounded" onClick={()=>component === "SeperateFn" ? loadFilteredData('pdf') : loadFilteredData(searchField, 0, 'pdf')}><i className='bx bx-file-blank me-1'></i>PDF</DropdownItem>
          <DropdownItem className="rounded" onClick={()=>component === "SeperateFn" ? loadFilteredData('csv') : loadFilteredData(searchField, 0, 'csv')}><i className='bx bx-spreadsheet me-1'></i>CSV</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
}

export default ExportTable;