import DeleteModal from 'components/Common/DeleteModal';
import PolicyLink from 'components/Common/PolicyTable/PolicyLink';
import { prettifyTime, prettifyTimeUpdated } from 'components/Common/PrettifyTime';
import UserImage from 'components/Common/UserImage';
import React, { useState } from 'react';

function SubCategoryTable({policies, subId, deletePolicy}) {

    const [deleteModal, setDeleteModal] = useState(false);
    const [deletionId, setDeletionId] = useState();

    const onDelete = (id) => {
        setDeleteModal(true);
        setDeletionId(id);
    }

    const capitalizeFirstLetter= (string) =>{
        return string.charAt(0).toUpperCase() + string.slice(1);
    }  

    const mapPolicies=()=>{
        return policies?.map((policy, i)=> {
            if(policy){
                if(subId === policy.sub_category.id){
                    const time = prettifyTimeUpdated(policy.version[0].uploaded_on);
                    const userName = policy.version[0].uploaded_by;
                    return(
                    <tr key = {i}>
                        <td scope='row'><PolicyLink policy={policy}/></td>
                        <td>
                            <div className='d-flex align-items-center'>
                                <UserImage user={userName.first_name} photo={userName.photo}/>
                                <div className='ms-2'>
                                    <div>{capitalizeFirstLetter(userName.first_name) + ' ' + capitalizeFirstLetter(userName.last_name)}</div>
                                    <div style={{color: "gray"}}>{userName.organization?.toUpperCase()}</div>
                                </div>
                            </div>
                        </td>
                        <td>{time}</td>
                        <td><i className='ph-x delete-icon' onClick={()=>onDelete(policy.policy_id)}/></td>
                    </tr>
                    )
                }
            }
        })
    }

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped">
                <thead className="table-header ">
                    <tr>
                        <th className="">Policy Name</th>
                        <th className="">Uploaded by</th>
                        <th className="">Uploaded at</th>
                        <th className="">Action</th>
                    </tr>
                </thead>
                <tbody>{mapPolicies()}</tbody>
                </table>
            </div>
            <DeleteModal show = {deleteModal} onCloseClick = {()=>setDeleteModal(false)} onDeleteClick = {deletePolicy} id={deletionId}/>
        </div>
    );
}

export default SubCategoryTable;