import { organizationType, stages } from "constants/global"
import { csvmaker, downloadCSV } from "./downloadCSV"

export const getCSV = (component, policies, stage) => {
  const newPolicies = []
  if (component === "directory" || component === "tracker") {
    policies.map(policy => {
      const newPolicy = {};
      newPolicy["Policy Name"] = policy.policy_name.replaceAll(",","; ");
      newPolicy["Location"] = policy.country.label;
      newPolicy["Systems"] = policy.labels.map(label => label.label_name).join("; ");
      newPolicy["Progress"] = stages[policy.stage_id];
      newPolicy["Year"] = policy.year;
      newPolicy["Owners"] = policy.orgs.filter(org=>org.organization_type.id === organizationType.OWNER).map(org=> org.name.replaceAll(",","; ")).join("; ");
      newPolicy["Partners"] = policy.orgs.filter(org=>org.organization_type.id === organizationType.PARTNER).map(org=> org.name.replaceAll(",","; ")).join("; ");
      newPolicy["Status"] = policy.policy_status;
      newPolicy["Score"] = policy.policy_score;
      newPolicy["Notes"] = policy.policy_notes.map(note => note.description.replace( /(<([^>]+)>)/ig, '')).join(" ->"),
      newPolicies.push(newPolicy)
    })
  } else if (component === "activityTable") {
    policies.map(policy => {
      let newPolicy = {};
      newPolicy["Policy Name"] = policy.policy_name.replaceAll(",","; ");
      newPolicy["Location"] = policy.country.label;
      newPolicy["Score"] = policy.policy_score;

      let forms = policy.policy_form_map.filter(form => form.form.stage_id == stage.id)
      //forms = forms.sort();
      forms.forEach(form => {
        newPolicy[form.form.name] = form.form_status
      })
      //form name of numbers coming as first columns
      newPolicies.push(newPolicy)
    })
  }
  const csvdata = csvmaker(newPolicies)

  let name;
  if(component === "directory"){
    name = "policy_directory.csv"
  }else if(component === "tracker"){
    name = `${stages[stage.id]} tracker.csv`
  }else if(component === "activityTable"){
    name = `${stages[stage.id]} activities.csv`
  }

  return downloadCSV(csvdata, name)
}