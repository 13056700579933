import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ConfirmationModal from "components/Common/ConfirmationModal";

function UpdatePolicyStatus({policy, update}) {
    
    const currentDate = new Date();
    const defaultDeadline = new Date(currentDate.setMonth(currentDate.getMonth()+1));
    const { tracker_stages } = useSelector(state => state.list);
    const [confirmationModal, setConfirmationModal] = useState(false);

    const [updateStatus, setUpdateStatus] = useState({
        show: false,
        stage: null,
        status: "",
        deadline: "",
        new_stage_id: null,
        new_deadline: ""
      })

    useEffect(()=>{
        setUpdateStatus({
            ...updateStatus,
            stage: policy?.stage.id,
            status: policy?.current_task?.status,
            deadline: moment(policy?.current_task?.deadline).format(
                "YYYY-MM-DD"
            )
        })
    },[policy])

    const onOpened = ()=> {
        setUpdateStatus({
            ...updateStatus,
            new_stage_id: null,
            new_deadline: ""
        }) 
    }

    const toggle = () => setUpdateStatus({ ...updateStatus, show: !updateStatus.show })

    const hasChanges = () => {
        return updateStatus.stage !== policy?.stage.id ||
               updateStatus.status !== policy?.current_task?.status ||
               updateStatus.deadline !== moment(policy?.current_task?.deadline).format(
                "YYYY-MM-DD"
            ) ||
               updateStatus.new_stage_id !== null ||
               updateStatus.new_deadline !== "";
    };

    return (
        <div>
            <Button color="primary" onClick={toggle} >
                Update Policy Status
            </Button>
            <Modal
                isOpen={updateStatus.show} 
                toggle={toggle}
                style={{
                    backgroundColor: "#E1E6E2 !important",
                    
                }}
                onOpened={onOpened}
            >
                <ModalHeader toggle={toggle}><b>{policy?.policy_name}</b></ModalHeader>
                <ModalBody>
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <b>Current</b>
                                <div className='d-flex align-items-center my-1'><i className='ph-stack me-1'></i>Stage</div> 
                                <div>{policy?.stage.type === "landscaping"? "Landscaping" : policy?.stage.label}</div>
                                <div className='d-flex align-items-center my-1'><i className='ph-clock me-1'></i>Status</div>
                                <div>
                                    <select
                                        className="w-100  select2"
                                        title="Status"
                                        defaultValue={
                                            policy?.current_task.status
                                        }
                                        onChange={e => {
                                            setUpdateStatus({
                                                ...updateStatus,
                                                status: e.target.value,
                                            })
                                        }}
                                        style={{
                                            backgroundColor:
                                                (updateStatus.status === "COMPLETE" && "#bdffb8") ||
                                                (updateStatus.status === "OVERRIDE" && "#ffcccb")
                                        }}
                                        >
                                        <option value={policy?.current_task?.status}>Select</option>
                                        <option value="OVERRIDE">Override</option>
                                        <option value="COMPLETE">Complete</option>
                                    </select>
                                </div>
                                <div className='d-flex align-items-center my-1'>
                                    <i className='ph-bell-simple me-1'></i>
                                    Deadline
                                </div>
                                <div>
                                    <input
                                        // className="form-control p-1 m-0"
                                        defaultValue={moment(policy?.current_task?.deadline).format(
                                            "YYYY-MM-DD"
                                        )}
                                        onChange={e =>
                                            setUpdateStatus({
                                            ...updateStatus,
                                            deadline: e.target.value,
                                            })
                                        }
                                        type="date"
                                    />
                                </div>
                                {policy?.current_task.status === "PAST DEADLINE" && <p className='m-0'>Past Deadline</p>}
                            </div>
                            {policy?.stage.type != "landscaping" && <div 
                                style={{
                                    borderLeft: "1px solid gray",
                                    position: "absolute",
                                    left: "50%",
                                    top: 0,
                                    bottom: 0
                                }}
                                className='col'
                            ></div>}
                            {policy?.stage.type != "landscaping" && 
                            <div className='col'>
                                <b>Assign</b>
                                <div className='d-flex align-items-center my-1'><i className='ph-stack me-1'></i>Stage</div> 
                                <div>
                                    <select
                                        className="w-100 select2"
                                        title="Stage"
                                        defaultValue="none"
                                        onChange={e =>
                                            e.target.value === "none" ?
                                                setUpdateStatus({
                                                    ...updateStatus,
                                                    new_stage_id: null,  
                                                    new_deadline: ""
                                                }) 
                                            : 
                                            setUpdateStatus({
                                                ...updateStatus,
                                                new_stage_id: e.target.value,   
                                                new_deadline: moment(defaultDeadline).format(
                                                    "YYYY-MM-DD"
                                                )
                                            })
                                        }
                                    >
                                        <option value="none">
                                            Select
                                        </option>

                                        {tracker_stages?.map(node => {
                                            if(node.stage != policy?.stage_id){
                                                return (
                                                    <option key={node.id} value={node.id}>
                                                        Stage {node.stage}
                                                    </option>
                                                )
                                            }
                                        })}
                                        
                                    </select>
                                </div>
                                {updateStatus.new_stage_id && 
                                <div className='d-flex justify-content-between my-1'>
                                    <div className='d-flex align-items-center'><i className='ph-bell-simple me-1'></i>Deadline</div>
                                    <div>
                                        <input
                                            // className="form-control p-1 m-0"
                                            defaultValue={moment(defaultDeadline).format(
                                                "YYYY-MM-DD"
                                            )}
                                            onChange={e =>
                                                setUpdateStatus({
                                                    ...updateStatus,
                                                    new_deadline: e.target.value,
                                                })
                                            }
                                            type="date"
                                            min={moment(new Date()).format(
                                                "YYYY-MM-DD"
                                            )}
                                        />
                                    </div>
                                </div>}
                            </div>}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-start'>
                    <Button color="primary" 
                        onClick={()=>setConfirmationModal(true)} 
                        className='d-flex align-items-center'
                        disabled={!hasChanges()}
                        >
                        <i className='ph-check me-1'></i>Save changes
                    </Button>
                </ModalFooter>
            </Modal>
            <ConfirmationModal
                show={confirmationModal}
                onCloseClick={() => setConfirmationModal(false)}
                onConfirmClick={()=>update(updateStatus)}
                toggleParent = {toggle}
            />
        </div>
    );
}

export default UpdatePolicyStatus;