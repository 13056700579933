import React, { useEffect, useState } from "react"
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap"
import FormUpload from "./PolicyUpload"
import { postFormData, get, post } from "../../../services/AXIOS"
import {
  CREATE_POLICY,
  POLICY_NAME_VALID,
  CATEGORY,
  PARTNERS,
  OWNERS,
  LIST_ORGANIZATIONS,
  COUNTRY_SPECIFIC_LABELS,
} from "services/CONSTANTS"
import { useSelector } from "react-redux"
import Select, { components } from "react-select"
import colourStyles from "../LabelColorStyles"
import "./PolicyUploadDetails.css"
import { useHistory } from "react-router-dom"
import { EnumStage, organizationType } from "constants/global"
import styled from "@emotion/styled"

const StyledModalBody = styled(ModalBody)`
  & label {
    position: relative;

    &::after {
      content: "*";
      position:absolute;
      color: red;
      right: -7px;
    }
  }
`

function CreatePolicy({
  subId,
  isVisible,
  updateParent,
  lCountry,
  component,
  alert,
}) {
  const history = useHistory()
  const [labels, setLabels] = useState([])
  const [doesNameExist, setDoesNameExist] = useState(false)

  const [modalUpload, setModalUpload] = useState(false)
  const toggleUpload = () => {
    setModalUpload(!modalUpload)
    setFile(undefined)
  }

  const backToUpload = () => setModalUpload(!modalUpload)

  const [modalPolicyDetails, setModalPolicyDetails] = useState(false)
  const togglePolicyDetails = () => {
    setModalPolicyDetails(!modalPolicyDetails)
    // if(modalPolicyDetails === true){
    //     const bodyElement = document.getElementsByTagName("body");
    //     // console.log(bodyElement[0].style);
    // }
  }

  const [file, setFile] = useState(undefined)

  const defaultForm = {
    name: "",
    indicators: [],
    stage: "",
    year: "",
    country: [],
    owners: "",
    partners: [],
    componentx: "",
  }
  const [form, setForm] = React.useState(defaultForm)

  const onNextClick = (file, fileChanged) => {
    toggleUpload()
    togglePolicyDetails()
    setFile(file)
    if (fileChanged) {
      setForm({
        ...defaultForm,
        country: lCountry ? form.country : [],
      })
    }
  }

  const onBackClick = () => {
    togglePolicyDetails()
    backToUpload()
  }

  const submit = form => {
    const indicatorIDs = form.indicators.map(indicator => {
      return { label_id: indicator.value }
    })
    if (form.componentx["value"] === 2 && isSubCategoryAvailable) {
      subId = subCategorySelected.value
    }
    // const partners = form.partners.map(partner=>partner.label);
    const partners = form.partners.map(partner => ({ id: partner.value }))
    const owners = form.owners.map(owner => ({ id: owner.value }))

    const orgs = partners.concat(owners)

    // let orgs = form.partners.map(partner=>({id: partner.value}));
    // orgs.push({id: form.owner[0].value});

    const data = new FormData()
    data.append("file", file)
    data.append("policy_name", form.name)
    subId
      ? data.append("stage_id", EnumStage.VALIDATION)
      : data.append("stage_id", form.stage.value)
    data.append("country", form.country[0].value)
    data.append("labels", JSON.stringify(indicatorIDs))
    data.append("year", form.year)
    // data.append('owner', form.owner[0].label);
    // data.append('partners', JSON.stringify(partners));
    data.append("orgs", JSON.stringify(orgs))
    //["abab", 'kdjsgjk']
    subId && data.append("subId", subId)

    postFormData(CREATE_POLICY, data)
      .then(policyId => {
        if (lCountry) {
          updateParent()
        } else {
          history.push(`policy/${policyId}`), [history]
          //window.location.href=`policy/${response}`;
        }
        alert("Policy creation", true)
      })
      .catch(function (error) {
        alert("Policy creation", false)
        console.log(error)
      })
  }

  const [isDisabled, setIsDisabled] = useState("button-disabled")
  const [owners, setOwners] = useState([])
  const [partners, setPartners] = useState([])
  const [isNameValid, setIsNameValid] = useState(true)
  // const [isIndicatorValid, setIsIndicatorValid] = useState(true);
  const [isYearValid, setIsYearValid] = useState(true)
  const [isStageValid, setIsStageValid] = useState(true)
  const [isLocationValid, setIsLocationValid] = useState(true)
  const [isOwnerValid, setIsOwnerValid] = useState(true)
  // const [isPartnerValid, setIsPartnerValid] = useState(true)
  // const [isOwnerLengthValid, setIsOwnerLengthValid] = useState(true)
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(true)

  //load data from policy partners and policy owners table
  useEffect(() => {
    setForm({
      ...form,
      owners: "",
      partners: [],
    })
    post(LIST_ORGANIZATIONS, {
      countries: [form.country[0]?.value],
      organization_type: organizationType.PARTNER,
    })
      .then(response => {
        const partners = response.data.map(partner => {
          return {
            value: partner.id,
            label: partner.name,
          }
        })
        setPartners(partners)
      })
      .catch(err => {
        console.log(err)
        alert("Data loading", false)
      })

    post(LIST_ORGANIZATIONS, {
      countries: [form.country[0]?.value],
      organization_type: organizationType.OWNER,
    })
      .then(response => {
        const owners = response.data.map(owner => {
          return {
            value: owner.id,
            label: owner.name,
          }
        })
        setOwners(owners)
      })
      .catch(err => {
        console.log(err)
        alert("Data loading", false)
      })

    form.country.length &&
      get(`${COUNTRY_SPECIFIC_LABELS}/${form.country[0]?.value}`)
        .then(data => setLabels(data))
        .catch(err => {
          console.log(err)
          alert("Data loading", false)
        })
  }, [form.country])

  const [checkbox, setCheckbox] = useState(false)
  const checkboxChange = () => setCheckbox(!checkbox)

  const handleChange = (event, selectId = "") => {
    if (selectId) {
      setForm({
        ...form,
        [selectId]: event,
      })
      if (selectId === "componentx") {
        //for UX
        setIsCategoryValid(true)
        setIsSubCategoryValid(true)
        setIsStageValid(true)
        if (event.value === 1) {
          setIsLocationValid(true)
        } else {
          if (form.country.length) {
            setIsLocationValid(true)
          } else {
            setIsLocationValid(false)
          }
        }
      }
    } else {
      if (event.target.id === "name" && form.country[0]) {
        get(
          `${POLICY_NAME_VALID}/${event.target.value}/${form.country[0].value}`
        )
          .then(res => {
            if (res) {
              setDoesNameExist(true)
            } else {
              setDoesNameExist(false)
            }
          })
          .catch(err => {
            console.log(err)
            //alert("Same name check", false)
          })
      }
      setForm({
        ...form,
        [event.target.id]: event.target.value,
      })
    }
  }

  const { tracker_stages, countries } = useSelector(state => state.list)

  const handleCountry = country => {
    if (form.name) {
      get(`${POLICY_NAME_VALID}/${form.name}/${country.value}`)
        .then(res => {
          if (res) {
            setDoesNameExist(true)
          } else {
            setDoesNameExist(false)
          }
        })
        .catch(err => {
          console.log(err)
          //alert("Same name check", false)
        })
    }
    setForm({
      ...form,
      country: [
        { value: country.value, label: country.label, title: country.title },
      ],
    })
    setIsLocationValid(true)
  }

  const [countryOptions, setCountryOptions] = useState([])

  useEffect(() => {
    setCountryOptions(
      countries.map(country => {
        if (lCountry) {
          if (country.title === lCountry) {
            let landscapingCountry = {
              value: country.id,
              label: country.label,
              title: country.title,
            }
            handleCountry(landscapingCountry)
            // form.country = landscapingCountry;
          }
        } else {
          return {
            value: country.id,
            label: country.label,
            title: country.title,
          }
        }
      })
    )
  }, [lCountry])

  const stageIcons = {
    1: "ph-book-bookmark",
    2: "ph-wrench",
    3: "ph-check-square-offset",
    4: "ph-scroll",
    5: "ph-scales",
    6: "ph-gear",
  }

  // const noneStage = [
  //     {   value: 0,
  //         label: <div className='d-flex align-items-center'>
  //                 <i className="ph-warning me-1">
  //                 </i>None
  //                </div>,
  //         color: "#cccccc"
  //     }]

  const trackerStages = tracker_stages.map(stage => {
    return {
      value: stage.stage,
      label: (
        <div className="d-flex align-items-center">
          <i className={`${stageIcons[stage.stage]} me-1`}></i>Stage{" "}
          {stage.stage}: {stage.label}
        </div>
      ),
      color: stage.backgroundColor,
    }
  })

  const stages = trackerStages

  const componentOptions = [
    { value: 1, label: "E-tracker" },
    { value: 2, label: "Landscaping & Benchmarking" },
  ]

  const handlePartnerChange = (
    newValue
    //actionMeta
  ) => {
    // let allowPass = true
    // newValue.map((value, i) => {
    //   if (value.label.length > 25) {
    //     setIsPartnerValid(false)
    //     return (allowPass = false)
    //   }
    // })
    // if (allowPass) {
    //   setIsPartnerValid(true)
      setForm({
        ...form,
        partners: newValue.map(value => {
          return {
            value: value.value,
            label: value.label,
          }
        }),
      })
    // }
    // console.group('Value Changed');
    // console.log(newValue);
    //console.log(actionMeta);
    // console.groupEnd();
  }

  const handleOwnerChange = (
    newValue
    // actionMeta
  ) => {
    // let allowPass = true
    // newValue.map((value, i) => {
    //   if (value.label.length > 25) {
    //     // setIsOwnerLengthValid(false)
    //     return (allowPass = false)
    //   }
    // })
    // if (allowPass) {
      // setIsOwnerLengthValid(true)
      setForm({
        ...form,
        owners: newValue.map(value => {
          return {
            value: value.value,
            label: value.label,
          }
        }),
      })
    // }
  }

  const [categoryOptions, setCategoryOptions] = useState()
  const [subCategoryOptions, setSubCategoryOptions] = useState()
  const [isLoadingCategory, setIsLoadingCategory] = useState(false)
  const [isLoadingSubCategory, setIsLoadingSubCategory] = useState(false)
  const [categorySelected, setCategorySelected] = useState()
  const [subCategorySelected, setSubCategorySelected] = useState()

  const handleCategoryChange = newValue => {
    setIsLoadingSubCategory(true)
    setCategorySelected(newValue)
    setSubCategoryOptions(
      newValue.data.map(subCategory => {
        const spaceAvailable =
          subCategory.policy_count - subCategory.policy.length
        const allowed = spaceAvailable <= 0 ? false : true
        return {
          value: subCategory.id,
          label: subCategory.name,
          allowed: allowed,
        }
      })
    )
    setIsLoadingSubCategory(false)
  }

  const [isSubCategoryAvailable, setIsSubCategoryAvailable] = useState(true)

  const handleSubCategoryChange = newValue => {
    setIsSubCategoryAvailable(newValue.allowed)
    setSubCategorySelected(newValue)
  }

  useEffect(() => {
    setIsLoadingCategory(true)
    setCategorySelected()
    setSubCategorySelected()
    setCategoryOptions()
    setSubCategoryOptions()
    //for values to reset on changing country or component back to tracker
    if (form.country.length && form.componentx["value"] === 2) {
      get(`${CATEGORY}/${form.country[0].title}`)
        .then(res => {
          setCategoryOptions(
            res.map(category => {
              return {
                value: category.id,
                label: category.name,
                data: category.sub_category,
              }
            })
          )
          setIsLoadingCategory(false)
        })
        .catch(err => {
          alert("Category loading", false)
          console.log(err)
        })
    }
  }, [form.country, form.componentx])

  //use set state in hooks synchronously -not possible

  const onCreate = e => {
    e.preventDefault()

    if (!isDisabled) {
      submit(form)
      togglePolicyDetails()
    }

    //checkbox validation
    checkbox ? setIsCheckboxSelected(true) : setIsCheckboxSelected(false)
    //year validations
    form.year < 3000 && form.year > 1000
      ? setIsYearValid(true)
      : setIsYearValid(false)
    //policy name in country validation
    //load all policy names along with countries to check
    //policy partner length validation
    //try in react select
    //validations

    lCountry
      ? null
      : !form.stage
      ? setIsStageValid(false)
      : setIsStageValid(true)
    !form.name ? setIsNameValid(false) : setIsNameValid(true)
    // !form.indicators.length?setIsIndicatorValid(false):setIsIndicatorValid(true)
    !form.country.length ? setIsLocationValid(false) : setIsLocationValid(true)
    !form.owners.length ? setIsOwnerValid(false) : setIsOwnerValid(true)
    form.componentx["value"]
      ? setIsComponentValid(true)
      : setIsComponentValid(false)
    subCategorySelected
      ? setIsSubCategoryValid(true)
      : setIsSubCategoryValid(false)
    categorySelected ? setIsCategoryValid(true) : setIsCategoryValid(false)
  }

  const indicatorOptions = labels.map(label => {
    return {
      value: label.label_id,
      label: label.label_name,
      color: label.label_color,
    }
  })
  //alert("Failed to load labels due to network error. Please try again!")
  // alertOnUpdate("Labels", "loading", "Network Error. Please try again!");

  useEffect(() => {
    if (
      form.country.length &&
      form.name &&
      form.owners.length &&
      form.year < 3000 &&
      form.year > 1000 &&
      checkbox &&
      // isPartnerValid &&
      // isOwnerLengthValid &&
      !doesNameExist
    ) {
      if (lCountry || form.stage) {
        setIsDisabled("")
      }
      if (form.componentx["value"] === 2) {
        if (subCategorySelected && categorySelected) {
          if (isSubCategoryAvailable) {
            setIsDisabled("")
          } else {
            setIsDisabled("button-disabled")
          }
        }
      }
    } else {
      setIsDisabled("button-disabled")
    }
  }, [
    form,
    checkbox,
    doesNameExist,
    categorySelected,
    subCategorySelected,
    // isPartnerValid,
    // isOwnerLengthValid,
  ])

  const DropdownIndicator = props => {
    const obj = {
      indicators: "ph-tag",
      stage: "ph-stack",
      location: "ph-globe-hemisphere-east",
      owners: "ph-user",
      partners: "ph-user-circle-plus",
      componentx: "ph-squares-four",
      category: "ph-suitcase",
      subcategory: "ph-folder-star",
    }
    return (
      <components.DropdownIndicator {...props}>
        <i className={obj[props.selectProps.id]} />
      </components.DropdownIndicator>
    )
  }

  const [isComponentValid, setIsComponentValid] = useState(true)
  const [isCategoryValid, setIsCategoryValid] = useState(true)
  const [isSubCategoryValid, setIsSubCategoryValid] = useState(true)

  const onOpen = () => {
    setIsNameValid(true)
    // setIsIndicatorValid(true);
    setIsYearValid(true)
    setIsStageValid(true)
    setIsLocationValid(true)
    setIsOwnerValid(true)
    // setIsPartnerValid(true)
    // setIsOwnerLengthValid(true)
    setIsCheckboxSelected(true)
    setDoesNameExist(false)
    setIsComponentValid(true)
    setIsCategoryValid(true)
    setIsSubCategoryValid(true)
    setIsSubCategoryAvailable(true)
  }

  return (
    <div>
      {subId ? (
        isVisible ? (
          <div
            onClick={toggleUpload}
            className="btn d-flex align-items-center"
            style={{
              backgroundColor: "#FCC612FF",
              color: "#000000",
              border: "none",
            }}
          >
            <i className="ph-plus me-1"></i>
            <p className="m-0 fw-normal" style={{ width: "100px" }}>
              Add new policy
            </p>
          </div>
        ) : null
      ) : (
        <Button className="common-button" onClick={toggleUpload}>
          <div>
            <i className="ph ph-upload-simple me-1"></i>
            Upload Policy
          </div>
        </Button>
      )}
      <Modal isOpen={modalUpload} toggle={toggleUpload}>
        <ModalBody>
          <FormUpload
            toggle={toggleUpload}
            onNextClick={onNextClick}
            file={file}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalPolicyDetails}
        toggle={togglePolicyDetails}
        onOpened={onOpen}
      >
        <ModalHeader toggle={togglePolicyDetails}>
          Add Policy Details
        </ModalHeader>
        <StyledModalBody>
          <div>
            <div>
              <label htmlFor="name">Policy Name</label>
              <Input
                type="text"
                placeholder="Input text"
                rows={1}
                onChange={handleChange}
                id="name"
                className="file-icon"
                value={form.name}
                // className='is-invalid'
              />
              {/*<div className="invalid-feedback">
                                    Please choose a username.
                                </div>*/}
              {isNameValid ? null : (
                <div className="invalid-input">Please enter a name.</div>
              )}
              {doesNameExist ? (
                <div className="invalid-input">
                  A policy with this name exists in the selected country
                </div>
              ) : null}
            </div>
            <br />
            <div className="d-flex align-items-center">
              <div className="w-50">
                <label htmlFor="year">Policy Year</label>
                <Input
                  type="text"
                  placeholder="YYYY"
                  rows={1}
                  onChange={handleChange}
                  maxLength={4}
                  id="year"
                  className="calendar-icon"
                  value={form.year}
                />
                {isYearValid ? null : (
                  <div className="invalid-input">Please enter a valid year</div>
                )}
              </div>
              {!lCountry && (
                <div className="w-50 ms-1">
                  <label>Location</label>
                  <Select
                    id="location"
                    components={{ DropdownIndicator }}
                    onChange={handleCountry}
                    options={countryOptions}
                    defaultValue={form.country}
                  />
                  {isLocationValid ? null : (
                    <div className="invalid-input">
                      Please select a location.
                    </div>
                  )}
                </div>
              )}
            </div>
            <br />
            {component === "directory" && (
              <div>
                <div>
                  <label>Component</label>
                  <div className="w-100">
                    <Select
                      id="componentx"
                      components={{ DropdownIndicator }}
                      defaultValue={form.componentx}
                      onChange={selectedValues =>
                        handleChange(selectedValues, "componentx")
                      }
                      options={componentOptions}
                    />
                  </div>
                  {isComponentValid ? null : (
                    <div className="invalid-input">
                      Please select a component.
                    </div>
                  )}
                </div>
                <br />
              </div>
            )}
            {(component === "tracker" || form.componentx["value"] === 1) && (
              <div>
                <label>Stage</label>
                <div className="w-100">
                  <Select
                    id="stage"
                    components={{ DropdownIndicator }}
                    defaultValue={form.stage}
                    onChange={selectedValues =>
                      handleChange(selectedValues, "stage")
                    }
                    options={stages}
                    styles={colourStyles}
                  />
                </div>
                {isStageValid ? null : (
                  <div className="invalid-input">Please select a stage.</div>
                )}
                <br />
              </div>
            )}
            {form.componentx["value"] === 2 && isLocationValid && (
              <div>
                {
                  //try creating new component of catgery and sub category to reload
                }
                <div>
                  <label>Category</label>
                  <div className="w-100">
                    <Select
                      id="category"
                      components={{ DropdownIndicator }}
                      defaultValue={categorySelected}
                      key={categorySelected}
                      onChange={selectedValues =>
                        handleCategoryChange(selectedValues)
                      }
                      options={categoryOptions}
                      isLoading={isLoadingCategory}
                    />
                  </div>
                  {isCategoryValid ? null : (
                    <div className="invalid-input">
                      Please select a category.
                    </div>
                  )}
                </div>
                <br />
                <div>
                  <label>Subcategory</label>
                  <div className="w-100">
                    <Select
                      id="subcategory"
                      components={{ DropdownIndicator }}
                      defaultValue={subCategorySelected}
                      key={subCategorySelected}
                      onChange={selectedValues =>
                        handleSubCategoryChange(selectedValues)
                      }
                      options={subCategoryOptions}
                      isLoading={isLoadingSubCategory}
                    />
                  </div>
                  {isSubCategoryValid ? null : (
                    <div className="invalid-input">
                      Please select a subcategory.
                    </div>
                  )}
                  {isSubCategoryAvailable ? null : (
                    <div className="invalid-input">
                      This subcategory is full.
                    </div>
                  )}
                </div>
                <br />
              </div>
            )}
            {form.country.length ? (
              <div>
                <div>
                  <label>Systems</label>
                  <div className="w-100">
                    <Select
                      id="indicators"
                      onChange={selectedValues =>
                        handleChange(selectedValues, "indicators")
                      }
                      components={{ DropdownIndicator }}
                      closeMenuOnSelect={false}
                      isMulti
                      options={indicatorOptions}
                      styles={colourStyles}
                      placeholder="Select Systems"
                      defaultValue={form.indicators}
                    />
                  </div>
                  {/* { isIndicatorValid? null:  <div className='invalid-input'>Please select atleast 1 system.</div> } */}
                </div>
                <br />
                <div>
                  <label>Policy Owner</label>
                  <div className="w-100">
                    <Select
                      isMulti
                      isClearable
                      id="owners"
                      components={{ DropdownIndicator }}
                      onChange={handleOwnerChange}
                      options={owners}
                      defaultValue={form.owners}
                      key={form.owners}
                    />
                  </div>
                  {isOwnerValid ? null : (
                    <div className="invalid-input">
                      Please select atleast 1 owner.
                    </div>
                  )}
                  {/* {isOwnerLengthValid ? null : (
                    <div className="invalid-input">
                      Owner name should be less than 25 characters.
                    </div>
                  )} */}
                </div>
                <br />
                <div>
                  <label>Policy Partners</label>
                  <div className="w-100">
                    <Select
                      isMulti
                      id="partners"
                      components={{ DropdownIndicator }}
                      onChange={handlePartnerChange}
                      options={partners}
                      //icons and border color
                      defaultValue={form.partners}
                      key={form.partners}
                    />
                  </div>
                  {/* {isPartnerValid ? null : (
                    <div className="invalid-input">
                      Partner name should be less than 25 characters.
                    </div>
                  )} */}
                </div>
              </div>
            ) : null}
            <div className="pt-3 d-flex align-items-center">
              <input
                type="checkbox"
                id="tnc"
                name="tnc"
                value="agreement"
                onChange={checkboxChange}
                defaultChecked={checkbox}
              ></input>
              <label className="mx-2 mb-0 fw-normal" htmlFor="tnc">
                {" "}
                I have read and accept{" "}
                <a className="text-decoration-none">
                  {" "}
                  AGRA'S Data Privacy & Protection Policy
                </a>
                .
              </label>
              <br></br>
            </div>
            {isCheckboxSelected ? null : (
              <div className="invalid-input">
                Please agree to the terms and conditions to proceed.
              </div>
            )}
            <br />
            <div className="d-flex justify-content-center">
              <Button className="common-cancel-button" onClick={onBackClick}>
                Back
              </Button>
              <Button
                className={`common-button ${isDisabled}`}
                onClick={e => onCreate(e)}
              >
                Submit
              </Button>
            </div>
          </div>
        </StyledModalBody>
      </Modal>
    </div>
  )
}

export default CreatePolicy
