import React from "react"

function Pagination({
  currentPage,
  goToPage,
  totalEntries,
  entriesPerPage = 50,
}) {
  const totalPages = Math.ceil(totalEntries / entriesPerPage)
  const results =
    currentPage < totalPages ? currentPage * entriesPerPage : totalEntries

  return (
    <div>
      <nav className="pagination-bar d-flex justify-content-between align-items-center px-3 pt-2">
        <p>
          {totalEntries
            ? `Showing ${
                currentPage * entriesPerPage - `${entriesPerPage - 1}`
              }-${results} of ${totalEntries} results`
            : `Showing 0 of ${totalEntries} results`}
        </p>
        <ul className="pagination justify-content-end align-items-center ">
          {
            //<li className="page-item"><a className="page-link" onClick={()=>goToPage(1)}>First</a></li>
          }
          {currentPage > 1 && (
            <>
              <li className="page-item">
                <a className="page-link" onClick={() => goToPage(1)}>
                  <i className="ph-caret-double-left"></i>
                </a>
              </li>

              <li className="page-item">
                <a
                  className="page-link"
                  onClick={() => goToPage(currentPage - 1)}
                >
                  <i className="ph-caret-left"></i>
                </a>
              </li>
            </>
          )}

          {currentPage === 1 && totalPages >= 1 && (
            <>
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={() => goToPage(currentPage)}
                  style={{ color: "white", background: "#A1B3A1" }}
                >
                  {currentPage}
                </a>
              </li>
              {totalPages >= 2 && (
                <>
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={() => goToPage(currentPage + 1)}
                    >
                      {currentPage + 1}
                    </a>
                  </li>
                  {totalPages >= 3 && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        onClick={() => goToPage(currentPage + 2)}
                      >
                        {currentPage + 2}
                      </a>
                    </li>
                  )}
                </>
              )}
            </>
          )}

          {currentPage >= 2 && currentPage + 1 <= totalPages ? (
            <>
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={() => goToPage(currentPage - 1)}
                >
                  {currentPage - 1}
                </a>
              </li>
              <li className="page-item">
                <a
                  className="page-link"
                  style={{ color: "white", background: "#A1B3A1" }}
                  onClick={() => goToPage(currentPage)}
                >
                  {currentPage}
                </a>
              </li>
              {totalPages >= 2 && (
                <li className="page-item">
                  <a
                    className="page-link"
                    onClick={() => goToPage(currentPage + 1)}
                  >
                    {currentPage + 1}
                  </a>
                </li>
              )}
            </>
          ) : null}

          {currentPage === totalPages && totalPages > 1 && (
            <>
              {totalPages > 2 && (
                <li className="page-item">
                  <a
                    className="page-link"
                    onClick={() => goToPage(currentPage - 2)}
                  >
                    {currentPage - 2}
                  </a>
                </li>
              )}
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={() => goToPage(currentPage - 1)}
                >
                  {currentPage - 1}
                </a>
              </li>
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={() => goToPage(currentPage)}
                  style={{ color: "white", background: "#A1B3A1" }}
                >
                  {currentPage}
                </a>
              </li>
            </>
          )}

          {/* {currentPage + 1 >= totalPages ? null : (
          <li className="page-item">
            <a
              className="page-link"
              onClick={() => goToPage(currentPage + 2)}
            >
              {currentPage + 2}
            </a>
          </li>
        )} */}
          {currentPage < totalPages && totalPages > 1 && (
            <>
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={() => goToPage(currentPage + 1)}
                >
                  <i className="ph-caret-right"></i>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" onClick={() => goToPage(totalPages)}>
                  <i className="ph-caret-double-right"></i>
                </a>
              </li>
            </>
          )}
          {
            //<li className="page-item"><a className="page-link" onClick={()=>goToPage(totalPages)}>Last</a></li>
          }
        </ul>
      </nav>
    </div>
  )
}

export default Pagination
