import {
  POLICIES_DATA,
  REMOVE_POLICY_EVENT,
  ADD_POLICY_EVENT,
} from "./actionTypes"

export const policiesData = () => ({ type: POLICIES_DATA })

export const addPolicyEvent = policy => ({
  type: ADD_POLICY_EVENT,
  payload: policy,
})

export const removePolicyEvent = policy_id => ({
  type: REMOVE_POLICY_EVENT,
  payload: policy_id,
})
