import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  setAccessToken,
} from "./actions"

import { postLogin, postLogout } from "../../../services/authServices"
import { listCountries, listStages } from "../../list/actions"
import { userSession } from "../profile/actions"
import { resetProfile } from "../profile/actions"
import { resetList } from "../../list/actions"
function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      username: user.email,
      password: user.password,
      recaptchaValue: user.recaptchaValue,
    })
    localStorage.setItem("token", response.data.access_token)
    localStorage.setItem("loggedInTime", new Date())
    localStorage.setItem(
      "countries",
      JSON.stringify(response.data.user.countries)
    )
    localStorage.setItem(
      "countries_ids",
      JSON.stringify(response.data.user.countries_id)
    )
    localStorage.setItem("stages", JSON.stringify(response.data.user.stages))
    localStorage.setItem("role", response.data.user.role)
    yield put(setAccessToken(response.data.access_token))
    yield put(loginSuccess(response))
    yield put(userSession())
    yield put(listCountries())
    yield put(listStages())
    history.push("/statistics/e-tracker")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(postLogout)
    yield put(resetProfile())
    yield put(resetList())
    yield put(logoutUserSuccess(response))
    localStorage.clear()
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
