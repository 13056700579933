import React, { useState, useEffect } from "react"
import SearchBox from "components/Common/SearchBox"
import { POLICYACTIVITY, FORMSINSTAGE, AUDIT } from "services/CONSTANTS"
import { get, post } from "../../services/AXIOS"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import CreatePolicy from "./PolicyTable/CreatePolicy.js"
import ExportTable from "./ExportTable"
import Pagination from "./Pagination.js"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
// import html2canvas from "html2canvas";
import SortPolicies from "./PolicyTable/SortPolicies"
import PolicyActivityTable from "./PolicyActivityTable.jsx"
import { getCSV } from "./ExportCsv"
import { EnumStage } from "constants/global"
import FilterPolicyActivities from "./FilterPolicyActivities"
import LoadingSpinner from "./Loading-Spinner"

function PolicyActivityTableContainer({ stage, alert }) {
  //[stage for stage/step-> activeTab-> id & bg color & color]
  //stage->etracker
  //country->landscape

  const component = "activityTable"
  const [policies, setPolicies] = useState()
  const [isLoading, setIsLoading] = useState(true)

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)

  //filter
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedMilestones, setSelectedMilestones] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  //composite Filters
  const [compositeFilters, setCompositeFilters] = useState([
    { milestone: "", status: "" },
  ])
  const handleAddCompositeFilter = () => {
    setCompositeFilters([...compositeFilters, { milestone: "", status: "" }])
  }
  const handleRemoveCompositeFilter = idx => {
    const updatedCompositeFilters = [...compositeFilters].filter(
      (filter, index) => index !== idx
    )
    setCompositeFilters(
      updatedCompositeFilters.length !== 0
        ? updatedCompositeFilters
        : [{ milestone: "", status: "" }]
    )
  }

  const handleCompositeFilterChange = (index, key, value) => {
    const updatedCompositeFilters = [...compositeFilters]
    updatedCompositeFilters[index][key] = value
    setCompositeFilters(updatedCompositeFilters)
  }
  const [modalFilter, setModalFilter] = useState(false)
  const toggleFilter = () => setModalFilter(prevState => !prevState)

  //sort
  const [sortForm, setSortForm] = React.useState({ uploaded_on: "DESC" })
  const [modalSort, setModalSort] = useState(false)
  const toggleSort = () => setModalSort(prevState => !prevState)

  //search
  const [searchField, setSearchField] = useState("")

  const [activities, setActivities] = useState()

  const getMilestones = () => {
    const stageid = stage?.id ?? EnumStage.BENCHMARKING
    get(FORMSINSTAGE + "/" + stageid)
      .then(data => {
        setActivities(data)
      })
      .catch(err => {
        alert("Milestone loading", false)
        console.log(err)
      })
  }

  useEffect(() => {
    getMilestones()
    loadPoliciesFiltered()
  }, [stage])

  //runs filter, order, search, pagination query on backend
  const loadPoliciesFiltered = (
    searchfield = searchField,
    offsetParam = offset,
    exportTable = false
  ) => {
    setIsLoading(true)
    let limit = 50
    if (exportTable) {
      limit = totalEntries
      offsetParam = 0
    }

    let stages = stage?.id != "0"
      ? [Number(stage?.id)]
      : []

    post(`${POLICYACTIVITY}?limit=${limit}&offset=${offsetParam}`, {
      searchfield,
      stages,
      sortForm,
      selectedCountries,
      compositeFilters
    })
      .then(data => {
        //check data on empty response without error i.e. else condition and does it need an alert
        if (exportTable) {
          if (exportTable === "csv") {
            getCSV(component, data[0], stage)
          } else {
            generatePDF(data[0])
          }
          const folder = "E-tracker"
          const trackerStage = stage
            ? stage?.id === 0
              ? "in Overview"
              : `in Stage ${stage?.id} ${stage?.stage.label}`
            : null
          post(AUDIT, {
            description: `Exported ${folder} activity details ${
              trackerStage ? trackerStage : ""
            } as ${exportTable}`,
          })
        } else {
          setPolicies(data[0])
          setTotalEntries(data[1])
        }
      })
      .catch(err => {
        alert("Action", false)
      })
      .finally(() => setIsLoading(false))
  }

  //search
  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase())
    loadPoliciesFiltered(e.target.value.toLowerCase())
  }

  const applySort = () => {
    loadPoliciesFiltered()
    toggleSort()
  }

  //export to pdf
  // const generatePDF = () => {
  //     const input = document.getElementById("report1")
  //     html2canvas(input, {
  //       logging: true,
  //       letterRendering: 1,
  //       useCors: true,
  //     }).then(canvas => {
  //       const imgWidth = 800
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width
  //       const imgData = canvas.toDataURL("image/png")
  //       const pdf = new jsPDF("l", "pt", "a4")
  //       pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
  //       //pdf.output('dataurlnewwindow');
  //       pdf.save(stage? `stage ${stage.id} activities.pdf`: "landscape_activites.pdf")
  //     })
  //     // const report = new jsPDF('portrait','pt','a4');
  //     // report.html(document.querySelector('#report')).then(() => {
  //     //     report.save('report.pdf');
  //     // });
  //     //add width, height, icons readability or remove icons
  // }

  const generatePDF = policies => {
    const doc = new jsPDF()

    // It can parse html:
    // <table id="my-table"><!-- ... --></table>
    // autoTable(doc, { html: '#report1' })

    let body = []

    const headValues = ["Policy Name", "Location"]

    activities &&
      activities?.map(activity => {
        headValues.push(activity.name)
      })
    headValues.push("Policy Score")

    body = policies.map(policy => {
      const row = []
      let forms = policy.policy_form_map.filter(
        form => form.form.stage_id == stage.id
      )
      //or change to form status not override in filter, and then sort
      //can create a backend function to get only the forms in the given stage for all policies to reduce time complexity
      forms = forms.sort()
      if (policy.policy_form_map.length) {
        row.push(policy.policy_name)
        row.push(policy.country.label)
        forms.map(form => {
          row.push(form.form_status)
        })
        if (forms.length < activities.length) {
          for (let i = 0; i < activities.length - forms.length; i++) {
            row.push("")
          }
        }
        row.push(policy.policy_score)
      }
      return row
    })

    autoTable(doc, {
      head: [headValues],
      body: body,
    })

    doc.save(`stage ${stage.id} activities.pdf`)
  }

  //pagination
  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50
      setOffset(offsetParam)
      loadPoliciesFiltered(searchField, offsetParam)
      setCurrentPage(page)
    } else {
      console.log("page number should be greater than 0")
    }
  }

  const checkForFilters = () => {
    if (
      selectedCountries.length ||
      selectedMilestones.length ||
      selectedStatus.length
    ) {
      return true
    } else {
      return false
    }
  }

  const [clearedFilters, setClearedFilters] = useState(false)

  const clearFilters = () => {
    setSelectedCountries([])
    setSelectedStatus([])
    setSelectedMilestones([])
    setClearedFilters(true)
    return true
  }

  useEffect(() => {
    loadPoliciesFiltered()
    setClearedFilters(false)
  }, [clearedFilters])

  const applyFilter = () => {
    loadPoliciesFiltered()
    toggleFilter()
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <SearchBox searchChange={searchChange} />
          <div>
            <Button
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={toggleFilter}
            >
              <i className="mdi mdi-filter-variant me-1"></i>
              Filter
            </Button>
            <Modal
              isOpen={modalFilter}
              toggle={toggleFilter}
              className="modal-lg"
            >
              <ModalHeader toggle={toggleFilter}>
                <i className="mdi mdi-filter-variant me-1"></i>
                Filters
              </ModalHeader>
              <ModalBody>
                <FilterPolicyActivities
                  component={stage ? "tracker" : "landscape"}
                  handleCountries={setSelectedCountries}
                  selectedCountries={selectedCountries}
                  applyFilter={applyFilter}
                  activities={activities}
                  handleCompositeFilterChange={handleCompositeFilterChange}
                  handleAddCompositeFilter={handleAddCompositeFilter}
                  handleRemoveCompositeFilter={handleRemoveCompositeFilter}
                  compositeFilters={compositeFilters}
                />
              </ModalBody>
            </Modal>
          </div>
          <div>
            <Button
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={toggleSort}
            >
              <div>
                <i className="mdi mdi-sort me-1"></i>
                Sort
              </div>
            </Button>
            <Modal isOpen={modalSort} toggle={toggleSort}>
              <ModalHeader toggle={toggleSort}>
                <i className="mdi mdi-sort me-1"></i>Sort
              </ModalHeader>
              <ModalBody>
                <SortPolicies
                  applySort={applySort}
                  sortForm={sortForm}
                  handleSortChange={setSortForm}
                  component={"activities"}
                />
              </ModalBody>
            </Modal>
          </div>
          <div>
            {checkForFilters() && (
              <Button
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "#f8f9fa",
                  color: "black",
                  margin: "15px 10px",
                  border: "none",
                  fontSize: "14px",
                }}
                onClick={clearFilters}
              >
                <i className="ph-x me-1"></i>
                Clear All Filters
              </Button>
            )}
          </div>
        </div>
        <div className="d-flex">
          <CreatePolicy alert={alert}/>
          <ExportTable
            loadFilteredData={loadPoliciesFiltered}
            searchField={searchField}
          />
        </div>
      </div>
      {
        isLoading ? 
          <LoadingSpinner/> : 
          <div>
            <PolicyActivityTable
              policies={policies}
              id="report1"
              stage={stage}
              activities={activities}
              selectedMilestones={selectedMilestones}
            />
            <Pagination
              currentPage={currentPage}
              goToPage={goToPage}
              totalEntries={totalEntries}
            />
          </div>
      }
    </div>
  )
}

export default PolicyActivityTableContainer
