import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { downloadFile } from "../download"
import { DOWNLOAD_FILE } from "services/CONSTANTS"
import { get } from "services/AXIOS"
import styled from "@emotion/styled"
import { removePolicyEvent } from "store/actions"

const StyledSpan = styled.span`
  background-color: ${props =>
    props.type === "new"
      ? "hsl(120 100% 25% / 0.2)"
      : "hsl(240 100% 50% / 0.2)"};
  color: ${props =>
    props.type === "new" ? "hsl(120 100% 25%)" : "hsl(240 100% 50%)"};
  margin-inline-start: 0.75rem;
  padding: 0.2em 0.4em;
  display: inline-block;
  border-radius: 1000vmax;
  align-self: flex-start;
  box-shadow: var(--shadow-elevation-low);
  text-transform: capitalize;
`

function PolicyLink({ policy, alert }) {
  const dispatch = useDispatch()
  const { policies: policyEventData } = useSelector(
    state => state.dataIndicator
  )
  const { tracker_stages } = useSelector(state => state.list)

  let tracker_stages_id = tracker_stages.map(stage => {
    return stage.id
  })

  const [currentPolicy, setCurrentPolicy] = useState({})
  const [modalNoPolicyAccess, setModalNoPolicyAccess] = useState(false)

  const toggleModalNoPolicyAccess = () =>
    setModalNoPolicyAccess(!modalNoPolicyAccess)

  const download = () => {
    get(`${DOWNLOAD_FILE}/${currentPolicy.policy_id}`, { responseType: "blob" })
      .then(file => {
        downloadFile(currentPolicy.fileName, currentPolicy.policyName, file)
      })
      .catch(err => {
        console.log(err)
        alert("Policy download", false)
      })
  }

  const history = useHistory()
  const noPolicyAccess = (policy_id, policyName, fileName) => {
    setCurrentPolicy({
      policy_id,
      policyName,
      fileName,
    })
    setModalNoPolicyAccess(true)
  }

  const goToPolicy = (policy_id, stage_id, policyName, fileName, deleted) => {
    if (policyEventData.hasOwnProperty(policy_id)) {
      dispatch(removePolicyEvent(policy_id))
    }
    if (deleted) {
      noPolicyAccess(policy_id, policyName, fileName)
    } else if (
      tracker_stages_id.includes(stage_id) ||
      ![1, 2, 3, 4, 5, 6].includes(stage_id)
    ) {
      history.push(`policy/${policy_id}`), [history]
      // window.location.href = `policy/${policy_id}`
    } else {
      noPolicyAccess(policy_id, policyName, fileName)
    }
  }
  return (
    <div className="d-flex flex-wrap gap-1">
      <a
        onClick={() =>
          goToPolicy(
            policy.policy_id,
            policy.stage_id,
            policy.policy_name,
            policy.policy_filename,
            policy.deleted_at
          )
        }
        className="link-hover"
        target="_blank"
      >
        <span>
          <i className="ph-arrow-up-right me-1" />
          {policy.policy_name}
        </span>
        {policyEventData.hasOwnProperty(policy.policy_id) && (
          <StyledSpan>{policyEventData[policy.policy_id]}</StyledSpan>
        )}
      </a>

      <Modal isOpen={modalNoPolicyAccess} toggle={toggleModalNoPolicyAccess}>
        <ModalHeader toggle={toggleModalNoPolicyAccess}>
          Access Not Authorised
        </ModalHeader>
        <ModalBody>
          Sorry! you can't access this policy, but you can download the Policy
          document below.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={download}>
            Download
          </Button>{" "}
          <Button color="secondary" onClick={toggleModalNoPolicyAccess}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default PolicyLink
