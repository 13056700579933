import axios from "axios"
import { get, post, postFormData, axiosApi } from "./AXIOS"
import { GET_FORM, GET_LANDSCAPING_FORM, GET_RESPONSES_OF_FORM } from "./CONSTANTS"

export const getForm = query =>
  get(`${GET_FORM}/${query.policyID}/${query.stageID}/${query.formID}`)

export const getLandscapingForm = query =>
  get(`${GET_LANDSCAPING_FORM}/${query.subCategoryId}/${query.formID}`)

export const getResponsesOfForm = query =>
  get(
    `${GET_RESPONSES_OF_FORM}/${query.policyID}/${query.stageID}/${query.formID}`
  )
