import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  Container,
  Button,
} from "reactstrap"
import Dropzone from "react-dropzone";

import { Link } from "react-router-dom"

const FormUpload = ({toggle, onNextClick, file}) => {

  const [selectedFile, setselectedFile] = useState(file)
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMessage, setErrorMessage] =useState(null);
  const [fileChanged, setFileChanged] = useState(false)

  function handleAcceptedFile(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFile(files[0]);
    setIsDisabled(false);
    setErrorMessage(null);
    setFileChanged(true);
  }

  useEffect(()=>selectedFile? setIsDisabled(false): setIsDisabled(true), [])
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>
                    <div>
                      <h3 style={{color:"black", textAlign: "center", fontWeight:"bold"}}>Upload File</h3>
                      <p style={{textAlign: "center", fontWeight:"normal", color: "gray"}}>PDF, Docx, or MS Word. Max 25MB each.</p>
                    </div>
                  </CardTitle>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFile => {acceptedFile.length ? handleAcceptedFile(acceptedFile) : null}}
                      accept = {['.pdf','.docx','.doc']}
                      maxFiles = {1}
                      maxSize = {2.5e7}
                      onDropRejected = {fileRejection=>{
                        setErrorMessage(fileRejection[0].errors[0].message);
                        setselectedFile(null);
                    }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h6>Drag & drop your file here </h6>
                            <h6>OR</h6>
                            <div className="btn common-button">Browse file</div> 
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFile? <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {selectedFile.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{selectedFile.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>:null
                    }
                    </div>
                  </Form>
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}} className="mt-4">
                    <div>
                    {errorMessage?<p style={{color: "red", marginBottom: "0"}}>{
                      errorMessage === "File is larger than 25000000 bytes"? "Max file size allowed is 25MB": errorMessage
                    }</p>:null}
                    </div>
                    <div>
                      <Button style={{background: "lightgray", color: "black", border:"none"}} onClick={toggle}>
                        Cancel
                      </Button>{' '}
                      {/*check why uneven button sizes */}
                      <Button className="common-button" onClick={()=>onNextClick(selectedFile, fileChanged)} disabled={isDisabled}>
                        Next
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormUpload
