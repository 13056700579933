import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import {
  getSystemSuggestions,
  getSystemTypes,
  getSystems,
  createNewSystem,
  editSpecificSystem,
} from "services/indicatorServices"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormFeedback,
  Form,
  Label,
} from "reactstrap"
import Select from "react-select"
import useDebounce from "hooks/useDebounce"
import SystemsFilters from "./Systems-Filters"
import styled from "@emotion/styled"
import LoadingSpinner from "components/Common/Loading-Spinner"
import ExportTable from "components/Common/ExportTable"
import { DELETE_LABEL } from "services/CONSTANTS"
import { patch } from "services/AXIOS"
import { SystemsTable } from "./SystemsTable"
import { getSystemsCSV, getSystemsPDF } from "./export"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Alert } from "reactstrap"
export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.5em;

  & .basic-single {
    flex-basis: 150px;
  }
`
const Systems = ({ alert }) => {
  const { countries } = useSelector(state => state.list)

  //fetch systems
  const loadSystems = async (query, body) => {
    try {
      const { data } = await getSystems(query, body)
      setTotalEntries(data?.labelsCount)
      return data
    } catch (err) {
      console.error(err)
      setTotalEntries(0)
      return []
    }
  }
  //fetch systemTypes
  const loadSystemTypes = async () => {
    try {
      const { data } = await getSystemTypes({ limit: 0, offset: 0 })
      return data?.types
    } catch (err) {
      console.error(err)
      return []
    }
  }
  //fetch System Suggestions
  const loadSystemSuggestions = async input => {
    try {
      const { data } = await getSystemSuggestions(input)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }

  //pagination
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const handlePageChange = pageNum => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }
  //search
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)

  //filters
  const [selectedCountryFilters, setSelectedCountryFilters] = useState([])
  const [selectedTypeFilters, setSelectedTypeFilters] = useState([])

  const applyFilters = (selectedCountries, selectedTypes) => {
    setSelectedCountryFilters(selectedCountries)
    setSelectedTypeFilters(selectedTypes)
  }
  const [modalFilter, setModalFilter] = useState(false)
  const toggleModalFilter = () => setModalFilter(prevState => !prevState)

  //sort
  const [sortBy, setSortBy] = useState("updatedAtDESC")
  const sortOptions = [
    {
      value: "updatedAtDESC",
      label: "Newest",
    },
    {
      value: "updatedAtASC",
      label: "Oldest",
    },
    {
      value: "nameASC",
      label: "A-Z",
    },
    {
      value: "nameDESC",
      label: "Z-A",
    },
  ]
  const sortMap = new Map([
    ["updatedAtDESC", { updated_at: "DESC" }],
    ["updatedAtASC", { updated_at: "ASC" }],
    ["nameDESC", { name: "DESC" }],
    ["nameASC", { name: "ASC" }],
  ])
  const {
    data: systemsData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [
      "Systems",
      {
        debouncedSearchTerm,
        selectedCountryFilters,
        selectedTypeFilters,
        sortBy,
        page: currentPage,
      },
    ],
    queryFn: () =>
      debouncedSearchTerm.length > 0
        ? loadSystems(
            { searchTerm: debouncedSearchTerm, limit: 50, offset },
            {
              types: selectedTypeFilters,
              countries: selectedCountryFilters,
              sortForm: sortMap.get(sortBy),
            }
          )
        : loadSystems(
            { limit: 50, offset },
            {
              types: selectedTypeFilters,
              countries: selectedCountryFilters,
              sortForm: sortMap.get(sortBy),
            }
          ),
    staleTime: 60 * 1_000,
  })

  const { data: systemTypes } = useQuery({
    queryKey: ["System Types"],
    queryFn: () => loadSystemTypes(),
    initialData: [],
  })

  const handleSearchInputChange = evt => {
    setSearchTerm(evt.target.value)
    setOffset(0)
    setCurrentPage(1)
    setTotalEntries(systemsData?.labelsCount)
  }
  //clear Filters
  const areAnyFiltersSelected = () => {
    if (selectedCountryFilters.length > 0 || selectedTypeFilters.length > 0) {
      return true
    } else {
      return false
    }
  }
  const clearFilters = () => {
    setSelectedCountryFilters([])
    setSelectedTypeFilters([])
  }
  //userInputs for creating new System
  const [userInput, setUserInput] = useState("")
  const [charLeftUserInput, setCharLeftUserInput] = useState(20)
  const [description, setDescription] = useState("")
  const [charLeftDescription, setCharLeftDescription] = useState(100)
  const [hasVisitedInput, setHasVisitedInput] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(true)
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)
  const [selectedSuggestionsSystemType, setSelectedSuggestionsSystemsType] =
    useState([])
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [editingRowID, setEditingRowID] = useState(null)
  const [systemAlreadyExistsError, setSystemAlreadyExistsError] =
    useState(false)
  const [selectedCountries, setSelectedCountries] = useState([])
  const [systemType, setSystemsType] = useState({ label: "", value: "" })

  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState("")

  const openModal = () => {
    validation.errors = {}
    setShowModal(true)
    if (!isUserEditing) {
      setUserInput("")
      setSystemAlreadyExistsError(false)
      setHasVisitedInput(false)
      setSelectedCountries([])
      setSystemsType({})
    }
  }
  const closeModal = () => {
    validation.errors = {}
    setShowModal(false)
    setIsUserEditing(false)
    setUserInput("")
    setEditingRowID(null)
    setUserInput("")
    setDescription("")
    setSelectedCountries([])
    setSystemsType({ label: "", value: "" })
    setSystemAlreadyExistsError(false)
    setHasVisitedInput(false)
    validation.errors = {}
  }

  const handleInputChange = evt => {
    setUserInput(evt.target.value)
    setShowSuggestions(true)
    setSelectedSuggestion(null)
    setSystemAlreadyExistsError(false)
    setCharLeftUserInput(20 - evt.target.value.length)
  }

  const handleSuggestionSelection = suggestion => {
    setSelectedSuggestion(suggestion)
    setUserInput(suggestion.label_name)
    setDescription(suggestion.label_description)
    setShowSuggestions(false)
    const selectedSuggestionsSystemType = systemTypes.find(
      systemType => systemType.id === suggestion.label_type.id
    )
    setSelectedSuggestionsSystemsType([selectedSuggestionsSystemType])
  }
  const handleDescriptionChange = evt => {
    setDescription(evt.target.value)
    setCharLeftDescription(100 - evt.target.value.length)
  }
  const handleSystemCreate = async () => {
    setError("")
    const selectedCountryCodes = selectedCountries.map(country => country.value)
    if (!isUserEditing) {
      let body
      if (selectedSuggestion && selectedSuggestion.label_name === userInput) {
        body = {
          label_id: selectedSuggestion.label_id,
          label_type: selectedSuggestion.label_type.id,
          label_countries: [
            ...selectedSuggestion.label_countries.map(country => country.id),
            ...selectedCountryCodes,
          ],
          label_name: userInput,
          label_description: description,
        }
      } else {
        body = {
          label_name: userInput,
          label_countries: selectedCountryCodes,
          label_type: systemType.value,
          label_description: description,
        }
      }
      try {
        const { data } = await createNewSystem(body)
        closeModal()
      } catch (err) {
        setError(error.response.data.message)
        if (err.response.data.statusCode === 409)
          setSystemAlreadyExistsError(true)
        console.error(err)
      }
    } else {
      try {
        const { data } = await editSpecificSystem(editingRowID, {
          label_name: userInput,
          label_countries: selectedCountryCodes,
          label_type: systemType.value,
          label_description: description,
        })
        closeModal()
      } catch (err) {
        setError(error.response.data.message)
        console.error(err)
      }
    }
    refetch()
  }
  const initiateEdit = id => {
    setShowModal(true)
    setIsUserEditing(true)
    const selectedRowData = systemsData?.labels.find(
      row => row.id === id || row.label_id === id
    )
    setEditingRowID(selectedRowData.label_id)
    setUserInput(selectedRowData.label_name)
    setDescription(selectedRowData.label_description)
    setSelectedCountries(
      selectedRowData.label_countries.map(country => ({
        label: country.label,
        value: country.id,
      }))
    )
    setSystemsType({
      label: selectedRowData.label_type.name,
      value: selectedRowData.label_type.id,
    })
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      system: userInput,
      description: description,
      countries: selectedCountries,
      systemType: systemType,
    },
    validationSchema: Yup.object({
      system: Yup.string().required("Please enter a System Name"),
      description: Yup.string().required("Please enter description"),
      countries: Yup.array()
        .required("Please select at least one country")
        .min(1, "Please select at least one country"),
      systemType: Yup.object().shape({
        label: Yup.string().required("Please select a System Type"),
        value: Yup.string().required("Please select a System Type"),
      }),
    }),
    onSubmit: async (values, { resetForm }) => {
      setError("")
      if (isUserEditing) {
        try {
          const { data } = await editSpecificSystem(editingRowID, {
            label_name: values.system,
            label_countries: values.countries.map(country => country.value),
            label_type: values.systemType.value,
            label_description: values.description,
          })
          resetForm()
          closeModal()
        } catch (err) {
          setError(err.response.data.message)
          console.error(err)
        }
      } else {
        const selectedCountryCodes = values.countries.map(
          country => country.value
        )
        let body
        if (
          selectedSuggestion &&
          selectedSuggestion.label_name === values.system
        ) {
          body = {
            label_id: selectedSuggestion.label_id,
            label_type: selectedSuggestion.label_type.id,
            label_countries: [
              ...selectedSuggestion.label_countries.map(country => country.id),
              ...selectedCountryCodes,
            ],
            label_name: values.system,
            label_description: values.description,
          }
        } else {
          body = {
            label_name: values.system,
            label_countries: selectedCountryCodes,
            label_type: values.systemType.value,
            label_description: values.description,
          }
        }
        try {
          const { data } = await createNewSystem(body)
          resetForm()
          closeModal()
        } catch (err) {
          setError(err.response.data.message)
          if (err.response.data.statusCode === 409)
            setSystemAlreadyExistsError(true)
          console.error(err)
        }
      }
      refetch()
    },
  })
  const { data: systemSuggestions } = useQuery({
    queryKey: ["System Suggestions", { userInput: validation.values.system }],
    queryFn: () => loadSystemSuggestions(validation.values.system),
    enabled: !!validation.values.system && hasVisitedInput,
  })
  const onDeleteClick = deletionObjectId => {
    patch(`${DELETE_LABEL}/${deletionObjectId}`)
      .then(() => {
        refetch()
        alert("System deletion", true)
      })
      .catch(err => {
        console.error(err)
        alert("System deletion", false)
      })
  }

  const exportTable = async type => {
    try {
      const { data } = await getSystems(
        { searchTerm: debouncedSearchTerm, limit: 0, offset: 0 },
        {
          types: selectedTypeFilters,
          countries: selectedCountryFilters,
          sortForm: sortMap.get(sortBy),
        }
      )
      type === "pdf" ? getSystemsPDF(data.labels) : getSystemsCSV(data.labels)
    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <StyledContainer>
          <SearchBox searchChange={handleSearchInputChange} />
          <Button
            style={{
              backgroundColor: "#f8f9fa",
              color: "black",
              margin: "15px 10px",
              border: "none",
              fontSize: "14px",
            }}
            onClick={toggleModalFilter}
          >
            <i className="mdi mdi-filter-variant me-1" /> Filters
          </Button>
          <Select
            options={sortOptions}
            defaultValue={sortBy}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Sort by"
            onChange={option => setSortBy(option?.value || "ASC")}
          />
          {areAnyFiltersSelected() && (
            <Button
              className="d-flex align-items-center"
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={clearFilters}
            >
              <i className="ph-x me-1"></i>
              Clear All Filters
            </Button>
          )}
        </StyledContainer>
        <div className="d-flex align-items-center">
          <Button
            className="common-button"
            onClick={openModal}
            style={{ marginInline: 0 }}
          >
            Create New
          </Button>
          <ExportTable
            loadFilteredData={exportTable}
            component={"SeperateFn"}
          ></ExportTable>
        </div>
        <Modal
          isOpen={modalFilter}
          toggle={toggleModalFilter}
          className="modal-lg"
        >
          <ModalHeader toggle={toggleModalFilter}>
            <i className="mdi mdi-filter-variant me-1"></i>
            Filters
          </ModalHeader>
          <ModalBody>
            <SystemsFilters
              typeOptions={systemTypes.map(val => ({
                label: val.name,
                value: val.id,
              }))}
              countryOptions={countries.map(country => ({
                label: country.label,
                value: country.id,
              }))}
              applyFilters={applyFilters}
              toggleModalFilter={toggleModalFilter}
              selectedCountryFilters={selectedCountryFilters}
              selectedTypeFilters={selectedTypeFilters}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showModal}
          toggle={showModal ? closeModal : openModal}
          style={{ borderRadius: "0.25rem" }}
        >
          <ModalHeader
            style={{
              alignItems: "flex-start",
              height: "80px",
              paddingBottom: "0px",
            }}
            toggle={showModal ? closeModal : openModal}
          >
            <b>
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                {isUserEditing ? (
                  <>
                    <i className="mdi mdi-pencil me-2"></i>
                    Edit System
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-plus me-2"></i>
                    Create New System
                  </>
                )}
              </p>
            </b>
          </ModalHeader>
          <ModalBody>
            {error.length > 0 ? (
              <Alert color="danger">
                <p>{error}</p>
              </Alert>
            ) : null}
            <Form
              onSubmit={evt => {
                evt.preventDefault()
                validation.handleSubmit()
              }}
            >
              <div style={{ position: "relative" }} className="mb-3">
                <Label className="form-label" htmlFor="name">
                  System Name:
                </Label>
                <Input
                  name="system"
                  id="name"
                  type="text"
                  value={validation.values.system || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onFocus={() => setHasVisitedInput(true)}
                  invalid={
                    validation.touched.system && validation.errors.system
                      ? true
                      : false
                  }
                />
                {validation.touched.system && validation.errors.system ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {validation.errors.system}
                  </FormFeedback>
                ) : null}
                {systemAlreadyExistsError && (
                  <p style={{ color: "red" }}>
                    An system by this name already exits. Please retype the
                    system name and select it from the suggestions.
                  </p>
                )}
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "end",
                    marginBlockEnd: 0,
                  }}
                >
                  {charLeftUserInput} characters left
                </p>

                {systemSuggestions?.length > 0 && showSuggestions && (
                  <ul
                    style={{
                      position: "absolute",
                      top: "77%",
                      left: 0,
                      margin: 0,
                      padding: 0,
                      maxHeight: "100px",
                      overflowY: "auto",
                      zIndex: 999,
                      width: "100%",
                      backgroundColor: "#f2f2f2",
                      listStyle: "none",
                      paddingBlockStart: ".5em",
                    }}
                  >
                    {systemSuggestions.map(suggestion => (
                      <li
                        key={suggestion.label_id}
                        style={{ padding: "0.5rem", cursor: "pointer" }}
                        onClick={() => {
                          handleSuggestionSelection(suggestion)
                        }}
                      >
                        {suggestion.name ?? suggestion.label_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="mb-3">
                <Label className="form-label" htmlFor="description">
                  Add A Short Description:
                </Label>
                <Input
                  name="description"
                  id="description"
                  style={{ backgroundColor: "#F3F4F6" }}
                  type="textarea"
                  placeholder="This is for policies related to seeds."
                  rows={1}
                  maxLength="100"
                  value={validation.values.description || ""}
                  onChange={evt => {
                    validation.handleChange(evt)
                    setCharLeftDescription(100 - evt.target.value.length)
                  }}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "1rem",
                    // fontSize: "12px",
                  }}
                >
                  {validation.touched.description &&
                  validation.errors.description ? (
                    <FormFeedback type="invalid" style={{ display: "block" }}>
                      {validation.errors.description}
                    </FormFeedback>
                  ) : null}
                  <div style={{ width: "100%" }}>
                    <p style={{ textAlign: "end" }}>
                      {charLeftDescription} characters left
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <Label className="form-label" htmlFor="countries">
                  Countries:
                </Label>
                <Select
                  name="countries"
                  aria-labelledby="countries"
                  classNamePrefix="select2-selection"
                  options={countries.map(country => ({
                    label: country.label,
                    value: country.id,
                  }))}
                  isMulti
                  value={validation.values.countries || []}
                  onChange={selectedOptions =>
                    validation.setFieldValue("countries", selectedOptions)
                  }
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.countries && validation.errors.countries
                      ? true
                      : false
                  }
                />
                {validation.errors.countries && validation.touched.countries ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {validation.errors.countries}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label" htmlFor="systemType">
                  System Type:
                </Label>
                <Select
                  name="systemType"
                  id="systemType"
                  options={
                    selectedSuggestion
                      ? selectedSuggestionsSystemType.map(val => ({
                          label: val.name,
                          value: val.id,
                        }))
                      : systemTypes.map(val => ({
                          label: val.name,
                          value: val.id,
                        }))
                  }
                  classNamePrefix="select2-selection"
                  value={validation.values.systemType}
                  onChange={selectedOption =>
                    validation.setFieldValue("systemType", selectedOption)
                  }
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.systemType &&
                    validation.errors.systemType
                      ? true
                      : false
                  }
                />
                {validation.touched.systemType &&
                validation.errors.systemType ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {validation.errors.systemType.value}
                  </FormFeedback>
                ) : null}
              </div>
              <ModalFooter
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderTop: "none",
                  paddingTop: "0px",
                }}
              >
                <div>
                  <Button
                    style={{ backgroundColor: "#50A050", border: "none" }}
                    type="submit"
                  >
                    {isUserEditing ? "Save" : "Create"}
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
      </div>
      {isLoading && <LoadingSpinner />}
      {systemsData?.labels?.length > 0 && !isLoading ? (
        <>
          <SystemsTable
            data={systemsData.labels}
            caption="Systems"
            initiateEdit={initiateEdit}
            onDeleteClick={onDeleteClick}
          />
          <Pagination
            currentPage={currentPage}
            goToPage={handlePageChange}
            totalEntries={totalEntries}
          />
        </>
      ) : null}
    </div>
  )
}

export default Systems
