import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { logoutUser } from "../../../store/actions"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"

import { PROFILE_PHOTO } from "services/CONSTANTS"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const { user } = useSelector(state => state.Profile)

  const [username, setusername] = useState("AGRA")
  const [email, setEmail] = useState("")

  const dispatch = useDispatch()

  const logOut = useCallback(() => {
    dispatch(logoutUser(props.history))
  }, [dispatch])

  useEffect(() => {
    if (user && "id" in user) {
      setusername(user.first_name)
      setEmail(user.email)
    }
  }, [user])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {user.id &&
            (!user.photo ? (
              <div
                className="avatar-xs rounded-circle header-profile-user"
                style={{ float: "left" }}
              >
                <p className={"avatar-title bg-info text-white"}>
                  {user.first_name.charAt(0)}
                </p>
              </div>
            ) : (
              <img
                className="rounded-circle header-profile-user"
                src={PROFILE_PHOTO + `${user.photo}?${global.Date.now()}`}
                alt="Profile"
              />
            ))}
          <span
            className={
              "d-none d-xl-inline-block " +
              (user.id && !user.photo ? " mt-1 " : "") +
              " me-1"
            }
          >
            {username}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to={`/user-management/${user.id}`} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Profile")}</span>
          </Link>
          <div className="dropdown-divider" />
          <Link to="/login" onClick={logOut} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
