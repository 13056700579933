import React from "react"
import { Card, CardBody, Col, Input, Button, Row } from "reactstrap"
import Select from "react-select"
import { listSystems } from "services/listServices"
import { useQuery } from "@tanstack/react-query"
const StepOne = ({ form, handleInputChange, changeTab, save, alert }) => {
  const loadSystems = async () => {
    try {
      const { data } = await listSystems()
      return data.map(system => ({
        label: system.label_name,
        value: system.label_id,
      }))
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const typeOptions = [
    { value: "tracker", label: "E-Tracker" },
    { value: "landscaping", label: "Landscaping" },
    { value: "benchmarking", label: "Benchmarking" },
  ]
  const trackerStagesOptions = [
    { value: "1", label: "Initiation" },
    { value: "2", label: "Development" },
    { value: "3", label: "Validation" },
    { value: "4", label: "Approval" },
    { value: "5", label: "Legislation" },
    { value: "6", label: "Implementation" },
  ]

  const landscapingStagesOptions = [{ value: "7", label: "Collection" }]

  const { data: benchmarkingOptions } = useQuery({
    queryKey: ["All Systems"],
    queryFn: () => loadSystems(),
  })
  const proceed = () => {
    save(1)
    alert("Milestone details saved in draft", true)
    changeTab("step2")
  }
  return (
    <>
      <Card className="mt-5">
        <CardBody style={{ border: "2px solid #f4dac6", borderRadius: "5px" }}>
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1em",
              }}
            >
              <Input
                type="text"
                style={{ borderRadius: 5, borderWidth: 1, gridColumn: "1/-1" }}
                className={`form-control  ${form.error && "border-danger"}`}
                id="billing-name"
                value={form?.name}
                onChange={e => handleInputChange("name", e.target.value)}
                placeholder="Milestone Name"
              />
              <Input
                type="textarea"
                style={{ borderRadius: 5, borderWidth: 1, gridColumn: "1/-1" }}
                className={`form-control`}
                value={form?.description}
                onChange={e => handleInputChange("description", e.target.value)}
                placeholder="Milestone Description"
              />
              <Select
                className="basic-single"
                classNamePrefix="select"
                style={{ gridColumn: "1/2" }}
                placeholder="Type"
                options={typeOptions}
                onChange={option => handleInputChange("type", option.value)}
                value={
                  !!form?.type
                    ? typeOptions.find(option => option.value === form.type)
                    : null
                }
              />
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder={
                  form.type === "benchmarking"
                    ? "Select System"
                    : "Assign Stage"
                }
                value={
                  form.type === ""
                    ? null
                    : form.type === "tracker"
                    ? trackerStagesOptions.find(
                        option =>
                          option.value === form?.stage_id || form?.stage?.id
                      )
                    : landscapingStagesOptions.find(
                        option =>
                          option.value === form?.stage_id || form?.stage?.id
                      )
                }
                options={
                  form.type === ""
                    ? []
                    : form.type === "tracker"
                    ? trackerStagesOptions
                    : form.type === "landscaping"
                    ? landscapingStagesOptions
                    : benchmarkingOptions
                }
                onChange={option => handleInputChange("stage_id", option.value)}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="d-flex justify-content-end">
        <Button
          color="primary"
          style={{
            backgroundColor: "#8353E2",
            border: "none",
          }}
          onClick={proceed}
        >
          Next{" "}
          <i className="ph-caret-right" style={{ verticalAlign: "middle" }} />
        </Button>
      </div>
    </>
  )
}

export default StepOne
