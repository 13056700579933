import React, { Fragment, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { Filter, DefaultColumnFilter, SelectColumnFilter } from "./filters"
import Select from "react-select"
import { useSelector } from "react-redux"
import LoadingSpinner from "./Loading-Spinner"
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  headerGroups,
  filteredRowCount,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <>
      <Col sm={6}>
        <form className="app-search py-0">
          <div className="position-relative">
            <span style={{ color: "black" }} className="bx bx-search"></span>
            <input
              onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value)
              }}
              type="search"
              className="form-control"
              placeholder="Enter text to search"
            />
          </div>
        </form>
      </Col>
      {headerGroups[0].headers.map(
        (column, key) =>
          column.canFilter && (
            <Col sm={5} key={key}>
              <SelectColumnFilter column={column} />
            </Col>
          )
      )}
    </>
  )
}

const getTableDataForExport = (data, columns) =>
  data?.map(record =>
    columns.reduce(
      (recordToDownload, column) =>
        column.accessor
          ? {
              ...recordToDownload,
              [column.Header]: record[column.accessor],
            }
          : { ...recordToDownload },
      {}
    )
  )

/**
 * @desc make csv from given data
 * @param rows
 * @param filename
 */
const makeCsv = async (rows, filename) => {
  const separator = ";"
  const keys = Object.keys(rows[0])

  const csvContent = `${keys.join(separator)}\n${rows
    .map(row =>
      keys
        .map(k => {
          let cell = row[k] === null || row[k] === undefined ? "" : row[k]
          if (cell instanceof Array) {
            cell = cell.map(data => data.label || data).join(" | ")
          } else if (cell instanceof Date) {
            cell = cell.toLocaleString()
          } else {
            cell = cell.toString().replace(/"/g, '""')
          }
          // cell =
          //   cell instanceof Date
          //     ? cell.toLocaleString()
          //     : cell.toString().replace(/"/g, '""')

          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`
          }
          return cell
        })
        .join(separator)
    )
    .join("\n")}`

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
  if (navigator.msSaveBlob) {
    // In case of IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement("a")
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", filename)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

const makePdf = async (rows, filename) => {
  const doc = new jsPDF()
  const separator = ";"
  const columns = Object.keys(rows[0])

  const pdfContent = rows.map(row =>
    columns.map(k => {
      let cell = row[k] === null || row[k] === undefined ? "" : row[k]
      if (cell instanceof Array) {
        cell = cell.map(data => data.label || data).join(" | ")
      } else if (cell instanceof Date) {
        cell = cell.toLocaleString()
      } else {
        cell = cell.toString().replace(/"/g, '""')
      }
      if (cell.search(/("|,|\n)/g) >= 0) {
        cell = `"${cell}"`
      }
      return cell
    })
  )
  autoTable(doc, {
    head: [columns],
    body: pdfContent,
  })

  doc.save(filename)
}

const DefaultTableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddAccessCodeList,
  handleAccessCodeClick,
  className,
  customPageSize,
  customSort,
  isExport,
  setFilterCountries,
  ref,
  isLoading,
  // customPageSizeOptions,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    rows,
    setGlobalFilter,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          customSort || {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  //let reactTable = useRef(null)

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [isMenu, setIsMenu] = useState(false)

  const toggleMenu = () => {
    setIsMenu(!isMenu)
  }

  // const handleFilterChange = e => {
  //   const value = e.target.value || undefined;
  //   setFilter("name", value);
  // Update the name filter.
  //Now the table will search for that name and show only the rows which have a matching name
  // setFilterInput(value);
  // };

  const { countries } = useSelector(state => state.list)

  const bg_colors = {
    green: "#f2ffe5",
    red: "#f5cbcc",
    grey: "#f8f9fb",
    black: "#f8f9fb",
  }

  return (
    <Fragment>
      {/* <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col> */}
      {/* <input
          type="text"
          className="form-field"
          onChange={e => setFilter("role", e.target.value)}
        /> */}

      {/* <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder={"Search name"}
      /> */}

      <Row>
        <div className="d-flex justify-content-between">
          <div className="d-flex" style={{ margin: "15px 10px" }}>
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                headerGroups={headerGroups}
                filteredRowCount={rows.length}
              />
            )}

            {setFilterCountries && (
              <Col sm={5}>
                <Select
                  placeholder="All Countries"
                  // value={selectedMulti}
                  isMulti={true}
                  onChange={val => {
                    //   handleMulti()
                    setFilterCountries(val)
                  }}
                  options={countries.map(data => ({
                    label: data.label,
                    value: data.id,
                  }))}
                  // classNamePrefix="select2-selection"
                />
              </Col>
            )}
          </div>

          <div className="d-flex flex-wrap flex-row-reverse gap-2">
            {/* {https://react-table-v7.tanstack.com/docs/api/useFilters} */}
            {isExport && (
              // <>
              // <Button
              //   type="button"
              //   color="primary"
              //   className="btn mb-2 me-2"
              //   onClick={() =>
              //     makeCsv(
              //       getTableDataForExport(
              //         rows.map(d => d.values),
              //         columns
              //       ),
              //       `${isExport}-${Date.now()}.csv`
              //     )
              //   }
              // >
              //   <i className="mdi mdi-plus-circle-outline me-1" />
              //   Export CSV
              // </Button>
              //   <Button
              //     type="button"
              //     color="primary"
              //     className="btn mb-2 me-2"
              //     onClick={() =>
              //       makePdf(
              //         getTableDataForExport(
              //           rows.map(d => d.values),
              //           columns
              //         ),
              //         `${isExport}-${Date.now()}.pdf`
              //       )
              //     }
              //   >
              //     <i className="mdi mdi-plus-circle-outline me-1" />
              //     Export PDF
              //   </Button>
              // </>
              <Dropdown isOpen={isMenu} toggle={toggleMenu}>
                <DropdownToggle
                  type="button"
                  tag="button"
                  className="common-button btn btn-secondary"
                >
                  <i className="bx bx-printer me-1" />
                  <span className="d-none d-sm-inline-block">
                    Export <i className="mdi mdi-chevron-down" />
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
                  <DropdownItem href="#">
                    <Button
                      type="button"
                      color="primary"
                      className="common-button btn btn-secondary w-sm me-2"
                      onClick={() =>
                        makePdf(
                          getTableDataForExport(
                            rows.map(d => d.values),
                            columns
                          ),
                          `${isExport}-${Date.now()}.pdf`
                        )
                      }
                    >
                      <i className="mdi mdi-download d-block font-size-16" />
                      PDF
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="common-button btn btn-secondary w-sm"
                      onClick={() =>
                        makeCsv(
                          getTableDataForExport(
                            rows.map(d => d.values),
                            columns
                          ),
                          `${isExport}-${Date.now()}.csv`
                        )
                      }
                    >
                      <i className="mdi mdi-download d-block font-size-16" />
                      CSV
                    </Button>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
            {isAddAccessCodeList && (
              <Button
                type="button"
                color="primary"
                className="common-button btn btn-secondary"
                onClick={handleAccessCodeClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New
              </Button>
            )}
          </div>
        </div>
      </Row>
      {isLoading || (data.length >= 0 && page.length <= 0) ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <div className="table-responsive react-table">
            <Table
              bordered
              striped
              hover
              {...getTableProps()}
              className={className}
            >
              <thead className="table-light table-nowrap">
                {headerGroups.map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th key={column.id}>
                        <div {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          {generateSortingIndicator(column)}
                        </div>
                        {/* {column.canFilter && <SelectColumnFilter column={column} />} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {!page.length && (
                  <Fragment>
                    <tr className="text-center">
                      <td colSpan={columns.length}>
                        <Alert color="warning" role="alert">
                          No Data Available
                        </Alert>
                      </td>
                    </tr>
                  </Fragment>
                )}
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr
                        style={{
                          background: bg_colors[row.original.colour || ""],
                        }}
                      >
                        {row.cells.map(cell => {
                          return (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
          </div>

          <Row className="justify-content-md-end justify-content-center align-items-center">
            <Col className="col-md-auto">
              <div className="flex-grow-1 align-self-center">
                <p className="mb-0">Total Records: {rows.length}</p>
              </div>
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </Button>
                <Button
                  color="primary"
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto d-none d-md-block">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </Col>
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
              />
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={nextPage}
                  disabled={!canNextPage}
                >
                  {">"}
                </Button>
                <Button
                  color="primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </Button>
              </div>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  )
}

DefaultTableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DefaultTableContainer
