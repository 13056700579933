import axios from "axios"
import { get, post, postFormData, axiosApi } from "./AXIOS"
import {
  USER_SESSION,
  USER_LOGOUT,
  UPDATE_PROFILE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGN_IN,
  SIGN_UP,
  TOKEN_INFO,
} from "./CONSTANTS"

export const userSession = () => get(USER_SESSION)

export const postLogout = () => post(USER_LOGOUT)

export const forgotPassword = data => post(FORGOT_PASSWORD, data)

export const resetPassword = (data, token) =>
  post(`${RESET_PASSWORD}/${token}`, data)

export const updateProfile = profile =>
  postFormData(UPDATE_PROFILE, profile, {
    headers: { "content-type": "multipart/form-data" },
  })

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
export const postRegister = (data, token) => {
  return (
    axiosApi
      //.post(`${url.SIGN_UP}/${token}`, data, { params: { token } })
      .post(`${SIGN_UP}/${token}`, data)
      .then(response => {
        if (response.status >= 200 || response.status <= 299)
          return response.data
        throw response.data
      })
      .catch(err => {
        if (err.response) {
          let message
          err.response.status =
            err.response.data.statusCode || err.response.status
          if (err.response && err.response.status) {
            switch (err.response.status) {
              // case 404:
              // message = "Sorry! the page could not be found"
              //   break
              default:
                message =
                  err.response.data.message || "Sorry! something went wrong"
                break
            }
          }
          throw message
        } else {
          throw "Server Error"
        }
      })
  )
}

export const postLogin = data => {
  return axiosApi
    .post(`${SIGN_IN}`, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      if (err.response) {
        let message
        err.response.status =
          err.response.data.statusCode || err.response.status
        if (err.response && err.response.status) {
          switch (err.response.status) {
            // case 404:
            //   message = "Sorry! the page you are looking for could not be found"
            //   break
            // case 500:
            //   message =
            //     "Sorry! something went wrong, please contact our support team"
            //   break
            // case 401:
            //   message = "Invalid credentials"
            //   break
            default:
              message =
                err.response.data.message || "Sorry! something went wrong"
              break
          }
        }
        throw message
      } else {
        throw "Server Error"
      }
    })
}

export const getTokenInfo = token => get(`${TOKEN_INFO}/${token}`)
