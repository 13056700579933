import React, { Fragment, useEffect, useState } from "react"
import RadioFilters from "./RadioFilters"
import Map from "./Map/Map"
// import KeyMetrics from "./KeyMetrics"
import styled from "@emotion/styled"
import { StyledSeparator } from "pages/Audit/Audit-Details"
import OverviewCard from "./Overview-Card"
import CustomTabs from "components/Common/Custom-Tabs"
import { useQuery } from "@tanstack/react-query"
import {
  getLocationStats,
  getStats,
} from "services/intelligence/overviewServices"
import Tabs from "components/Common/Tabs"
import LocationStatsTable from "./Location-Stats-Table"
import LocationStatsGraph from "./Location-Stats-Graph"
import animationData from "../../assets/lotties/server-error"
import Lottie from "react-lottie"

export default function ETrackerOverview({ alert }) {
  const [filterValue, setFilterValue] = useState("number_of_policies")

  const loadStats = async body => {
    try {
      const res = await getStats(body)
      return res
    } catch (err) {
      console.error(err.response.data.message)
      throw new Error(err.response.data.message)
    }
  }
  const loadLocationStats = async () => {
    try {
      const res = await getLocationStats()
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }
  const query = useQuery({
    queryKey: ["Stats", { metric: filterValue }],
    queryFn: () => loadStats({ metric: filterValue }),
    staleTime: 5 * 60 * 1000,
    retry: 1,
  })
  const { data: statsData, isLoading, isError } = query
  const { data: locationStatsData, isLoading: isLocationDataLoading } =
    useQuery({
      queryKey: ["Location Stats"],
      queryFn: () => loadLocationStats(),
      staleTime: 5 * 60 * 1000,
    })
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <div>
      {!isLoading && isError ? (
        <Lottie options={defaultOptions} height={400} width={400} />
      ) : (
        <Fragment>
          <RadioFilters filter={filterValue} setFilter={setFilterValue} />
          <div style={{ width: "90%", marginInline: "auto" }}>
            <Overview
              statsData={statsData}
              filter={filterValue}
              isLoading={isLoading}
            />
            <Tabs>
              <StyledTabContainer>
                <header>
                  <i className="ph-globe-hemisphere-west" />
                  Location
                </header>
                <Tabs.Triggers>
                  <Tabs.TriggerButton value="tab1" leading="ph-map-pin">
                    Map
                  </Tabs.TriggerButton>
                  <Tabs.TriggerButton value="tab2" leading="ph-table">
                    Table
                  </Tabs.TriggerButton>
                  <Tabs.TriggerButton value="tab3" leading="ph-chart-bar">
                    Graph
                  </Tabs.TriggerButton>
                </Tabs.Triggers>
              </StyledTabContainer>
              <Tabs.TabContent value="tab1">
                <Map
                  data={locationStatsData}
                  filterValue={filterValue}
                  isLoading={isLocationDataLoading}
                />
              </Tabs.TabContent>
              <Tabs.TabContent value="tab2">
                <LocationStatsTable
                  data={locationStatsData}
                  filterValue={filterValue}
                  isLoading={isLocationDataLoading}
                />
              </Tabs.TabContent>
              <Tabs.TabContent value="tab3">
                <LocationStatsGraph
                  data={locationStatsData}
                  filterValue={filterValue}
                  isLoading={isLocationDataLoading}
                />
              </Tabs.TabContent>
            </Tabs>
          </div>
        </Fragment>
      )}
    </div>
  )
}

const StyledTabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & header {
    font-size: 24px;
    font-weight: 500;
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    column-gap: 0.5rem;
  }
`

const StyledOverview = styled.div`
  margin-block-end: 3rem;
  & > header {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    margin-block-end: 0.8rem;
    & i {
      margin-inline-end: 0.5rem;
      vertical-align: middle;
    }
  }
  & section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
  }
`
const Overview = ({ statsData, filter, isLoading }) => {
  return (
    <StyledOverview>
      <header>
        <i className="ph ph-eye" />
        Overview
      </header>
      <StyledSeparator data-orientation="horizontal" />
      <section>
        <OverviewCard
          type="Systems"
          data={statsData?.indicator}
          filter={filter}
          isLoading={isLoading}
        />
        <OverviewCard
          type="Locations"
          data={statsData?.location}
          filter={filter}
          isLoading={isLoading}
        />
        <OverviewCard
          type="Stages"
          data={statsData?.stage_id}
          filter={filter}
          isLoading={isLoading}
        />
      </section>
    </StyledOverview>
  )
}
