import {
  USER_SESSION,
  USER_SESSION_SUCCESS,
  USER_SESSION_ERROR,
  RESET_PROFILE_FLAG,
  EDIT_PROFILE,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  COUNTRY_FLAG,
  COUNTRY_ID,
  RESET_PROFILE,
} from "./actionTypes"

const initialState = {
  error: null,
  user: {},
  country: "",
  success: null,
  country_id: "",
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case USER_SESSION:
      state = { ...state }
      break
    case USER_SESSION_SUCCESS:
      state = { ...state, user: action.payload }
      break
    case USER_SESSION_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, user: null }
      break
    case COUNTRY_FLAG:
      state = { ...state, country: action.payload }
      break
    case COUNTRY_ID:
      state = { ...state, country_id: action.payload }
      break
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        success: "success",
        error: null,
      }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload, success: null }
      break
    case RESET_PROFILE:
      state = initialState
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
