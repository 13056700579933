import React,{useEffect, useState} from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from 'react-select';
import { ACCESSUSERLIST } from 'services/CONSTANTS';
import { post } from 'services/AXIOS';

function Reassign({modal, toggle, milestoneOwner, taskOwner, reassign, selectedTask, alert}) {

    const [selectedOwner, setSelectedOwner] = useState([]);
    const [ownerOptions, setOwnerOptions] = useState([]);
    const [currentTaskOwner, setCurrentTaskOwner] = useState(undefined);

    useEffect(()=>{
        const currentTaskOwner = taskOwner ? taskOwner : milestoneOwner;
        setCurrentTaskOwner(currentTaskOwner);

        post(ACCESSUSERLIST, {
            stage_id: selectedTask.stage_id,
            country_id: selectedTask.country_id,
        })
        .then(data=>{
            let owners = data.data.map(owner=>{
                return({
                    value: owner.id,
                    label: owner.first_name + " " + owner.last_name
                })
            })
            //filter out current owner from owner options
            owners = owners.filter((owner)=>{
                return owner.value !== currentTaskOwner?.ownerId;
            })

            setOwnerOptions(owners);
        })
        .catch(err=>{
            alert("Filters Data loading", false)
            console.log(err)
        })
       
    },[taskOwner, selectedTask])

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Reassign</ModalHeader>
            <ModalBody>
                <h6 className='mb-0'>Current Owner: <i className="mdi mdi-account ms-3" /> {currentTaskOwner?.owner}</h6>
                <br/>
                <div className='d-flex'>
                    <div className='d-flex align-items-center'> 
                        <h6 className='mb-0'>Reassign Owner:</h6>
                    </div>
                    <div className='ms-2' style={{width: "60%"}}>
                        <Select
                            options={ownerOptions}
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Select Owner"
                            onChange={setSelectedOwner} 
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <Button color="primary" onClick={()=>{
                    reassign(selectedOwner.value);
                    toggle();
                }}>
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default Reassign;