import React, { useState, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap"
import Select from "react-select"
import {
  createNewOrganization,
  getOrganizations,
  getOrganizationSuggestions,
  getOrganizationTypes,
  editSpecificOrganization,
} from "services/organizationServices"
import { CustomTable } from "./Organization-Management"
import OrganizationFilters from "./Organization-Filters"
import useDebounce from "hooks/useDebounce"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import { StyledContainer } from "pages/Labels/Systems"
import LoadingSpinner from "components/Common/Loading-Spinner"
import ExportTable from "components/Common/ExportTable"
import { getOrgCSV, getOrgPDF } from "./export"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Alert } from "reactstrap"

const Organizations = ({ alert }) => {
  const { countries } = useSelector(state => state.list)
  const { user } = useSelector(state => state.Profile)

  //fetch Organizations
  const loadOrganizations = async (query, body) => {
    try {
      const { data } = await getOrganizations(query, body)
      setTotalEntries(data?.organizationsCount)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }
  //fetch OrgTypes
  const loadOrganizationTypes = async () => {
    try {
      const { data } = await getOrganizationTypes({ limit: 0, offset: 0 })
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }
  //fetch Organization Suggestions
  const loadOrganizationSuggestions = async input => {
    try {
      const { data } = await getOrganizationSuggestions(input)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const { data: orgTypesData } = useQuery({
    queryKey: ["Organization Types"],
    queryFn: () => loadOrganizationTypes(),
    initialData: { types: [] },
  })

  //pagination
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const handlePageChange = pageNum => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }
  //search
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)

  const handleSearchInputChange = evt => {
    setSearchTerm(evt.target.value)
    setOffset(0)
    setCurrentPage(1)
    setTotalEntries(orgData?.organizationsCount)
  }
  //filters
  const [selectedCountryFilters, setSelectedCountryFilters] = useState([])
  const [selectedTypeFilters, setSelectedTypeFilters] = useState([])

  const applyFilters = (selectedCountries, selectedTypes) => {
    setSelectedCountryFilters(selectedCountries)
    setSelectedTypeFilters(selectedTypes)
  }

  //clear Filters
  const areAnyFiltersSelected = () => {
    if (selectedCountryFilters.length > 0 || selectedTypeFilters.length > 0) {
      return true
    } else {
      return false
    }
  }
  const clearFilters = () => {
    setSelectedCountryFilters([])
    setSelectedTypeFilters([])
  }
  const [modalFilter, setModalFilter] = useState(false)
  const toggleModalFilter = () => setModalFilter(prevState => !prevState)

  //sort
  const [sortBy, setSortBy] = useState("updatedAtDESC")
  const sortOptions = [
    {
      value: "updatedAtDESC",
      label: "Newest",
    },
    {
      value: "updatedAtASC",
      label: "Oldest",
    },
    {
      value: "nameASC",
      label: "A-Z",
    },
    {
      value: "nameDESC",
      label: "Z-A",
    },
  ]
  const sortMap = new Map([
    ["updatedAtDESC", { updated_at: "DESC" }],
    ["updatedAtASC", { updated_at: "ASC" }],
    ["nameDESC", { name: "DESC" }],
    ["nameASC", { name: "ASC" }],
  ])

  const {
    data: orgData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [
      "Organizations",
      {
        debouncedSearchTerm,
        selectedCountryFilters,
        selectedTypeFilters,
        sortBy,
        page: currentPage,
      },
    ],
    queryFn: () =>
      debouncedSearchTerm.length > 0
        ? loadOrganizations(
            { searchTerm: debouncedSearchTerm, limit: 50, offset },
            {
              types: selectedTypeFilters,
              countries: selectedCountryFilters,
              sortForm: sortMap.get(sortBy),
            }
          )
        : loadOrganizations(
            { limit: 50, offset },
            {
              types: selectedTypeFilters,
              countries: selectedCountryFilters,
              sortForm: sortMap.get(sortBy),
            }
          ),
  })

  //modal

  //userInputs
  const [userInput, setUserInput] = useState("")
  const [hasVisitedInput, setHasVisitedInput] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)
  const [selectedSuggestionsOrgType, setSelectedSuggestionsOrgType] = useState(
    []
  )
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [editingRowID, setEditingRowID] = useState(null)
  const [orgAlreadyExistsError, setOrgAlreadyExistsError] = useState(false)
  const [selectedCountries, setSelectedCountries] = useState([])
  const [orgType, setOrgType] = useState({ label: "", value: "" })

  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState("")
  const handleInputChange = evt => {
    setUserInput(evt.target.value)
    setShowSuggestions(true)
    setSelectedSuggestion(null)
    setOrgAlreadyExistsError(false)
  }
  const handleSuggestionSelection = suggestion => {
    setSelectedSuggestion(suggestion)
    setUserInput(suggestion.name)
    setShowSuggestions(false)
    const selectedSuggestionsOrgType = orgTypesData.types.find(
      orgType => orgType.id === suggestion.organization_type.id
    )
    setSelectedSuggestionsOrgType([selectedSuggestionsOrgType])
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization: userInput,
      countries: selectedCountries,
      orgType: orgType,
    },
    validationSchema: Yup.object({
      organization: Yup.string().required("Please enter a Organization Name"),
      countries: Yup.array()
        .required("Please select at least one country")
        .min(1, "Please select at least one country"),
      orgType: Yup.object().shape({
        label: Yup.string().required("Please select a Organization Type"),
        value: Yup.string().required("Please select a Organization Type"),
      }),
    }),
    onSubmit: async (values, { resetForm }) => {
      setError("")
      const selectedCountryCodes = values.countries.map(
        country => country.value
      )
      if (!isUserEditing) {
        let body
        if (selectedSuggestion && selectedSuggestion.name === userInput) {
          body = {
            id: selectedSuggestion.id,
            organization_type: selectedSuggestion.organization_type.id,
            countries: [
              ...selectedSuggestion.countries.map(country => country.id),
              ...selectedCountryCodes,
            ],
            name: values.organization,
          }
        } else {
          body = {
            name: values.organization,
            countries: selectedCountryCodes,
            organization_type: values.orgType.value,
          }
        }
        try {
          const { data } = await createNewOrganization(body)
          resetForm()
          closeModal()
          alert("New organization created!", true)
        } catch (err) {
          setError(err.response.data.message)
          if (err.response.data.statusCode === 409)
            setOrgAlreadyExistsError(true)
          console.error(err)
        }
      } else {
        try {
          const { data } = await editSpecificOrganization(editingRowID, {
            name: values.organization,
            countries: selectedCountryCodes,
            organization_type: values.orgType.value,
          })
          resetForm()
          closeModal()
          alert("Changes saved", true)
        } catch (err) {
          setError(err.response.data.message)
          console.error(err)
        }
      }
      refetch()
    },
  })
  const openModal = () => {
    validation.resetForm()
    setShowModal(true)
    setHasVisitedInput(false)
    if (!isUserEditing) {
      setUserInput("")
      setOrgAlreadyExistsError(false)
      setSelectedCountries([])
      setOrgType({ label: "", value: "" })
    }
  }
  const closeModal = () => {
    validation.resetForm()
    setShowModal(false)
    setIsUserEditing(false)
    setUserInput("")
    setOrgAlreadyExistsError(false)
    setHasVisitedInput(false)
  }
  const { data: orgSuggestions } = useQuery({
    queryKey: [
      "Organization Suggestions",
      { userInput: validation.values.organization },
    ],
    queryFn: () => loadOrganizationSuggestions(validation.values.organization),
    enabled: validation.values.organization.length >= 3 && hasVisitedInput,
    initialData: [],
  })
  const initiateEdit = id => {
    setShowModal(true)
    setIsUserEditing(true)
    const selectedRowData = orgData.organizations.find(row => row.id === id)
    setEditingRowID(selectedRowData.id)
    setUserInput(selectedRowData.name)
    setSelectedCountries(
      selectedRowData.countries.map(country => ({
        label: country.label,
        value: country.id,
      }))
    )
    setOrgType({
      label: selectedRowData.organization_type.name,
      value: selectedRowData.organization_type.id,
    })
  }

  const exportTable = async type => {
    try {
      const { data } = await getOrganizations(
        { searchTerm: debouncedSearchTerm, limit: 50, offset },
        {
          types: selectedTypeFilters,
          countries: selectedCountryFilters,
          sortForm: sortMap.get(sortBy),
        }
      )
      type === "pdf"
        ? getOrgPDF(data.organizations)
        : getOrgCSV(data.organizations)
    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <StyledContainer>
          <SearchBox searchChange={handleSearchInputChange} />
          <Button
            style={{
              backgroundColor: "#f8f9fa",
              color: "black",
              margin: "15px 10px",
              border: "none",
              fontSize: "14px",
            }}
            onClick={toggleModalFilter}
          >
            <i className="mdi mdi-filter-variant me-1" /> Filters
          </Button>
          <Select
            options={sortOptions}
            defaultValue={sortBy}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Sort by"
            onChange={option => setSortBy(option?.value || "ASC")}
          />
          {areAnyFiltersSelected() && (
            <Button
              className="d-flex align-items-center"
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={clearFilters}
            >
              <i className="ph-x me-1"></i>
              Clear All Filters
            </Button>
          )}
        </StyledContainer>
        <div className="d-flex">
          <Button
            className="common-button"
            onClick={openModal}
            style={{ marginInline: 0 }}
          >
            Create New
          </Button>
          <ExportTable
            loadFilteredData={exportTable}
            component={"SeperateFn"}
          />
        </div>
        <Modal
          isOpen={modalFilter}
          toggle={toggleModalFilter}
          className="modal-lg"
        >
          <ModalHeader toggle={toggleModalFilter}>
            <i className="mdi mdi-filter-variant me-1"></i>
            Filters
          </ModalHeader>
          <ModalBody>
            <OrganizationFilters
              typeOptions={orgTypesData.types.map(val => ({
                label: val.name,
                value: val.id,
              }))}
              countryOptions={countries.map(country => ({
                label: country.label,
                value: country.id,
              }))}
              applyFilters={applyFilters}
              toggleModalFilter={toggleModalFilter}
              selectedCountryFilters={selectedCountryFilters}
              selectedTypeFilters={selectedTypeFilters}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showModal}
          toggle={showModal ? closeModal : openModal}
          style={{ borderRadius: "0.25rem" }}
        >
          <ModalHeader
            style={{
              alignItems: "flex-start",
              height: "80px",
              paddingBottom: "0px",
            }}
            toggle={showModal ? closeModal : openModal}
          >
            <b>
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                {isUserEditing ? (
                  <>
                    <i className="mdi mdi-pencil me-2"></i>
                    Edit Organization
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-plus me-2"></i>
                    Create New Organization
                  </>
                )}
              </p>
            </b>
          </ModalHeader>
          <ModalBody>
            {error.length > 0 ? (
              <Alert color="danger">
                <p>{error}</p>
              </Alert>
            ) : null}
            <Form
              onSubmit={evt => {
                evt.preventDefault()
                validation.handleSubmit()
              }}
            >
              <div style={{ position: "relative" }}>
                <Label className="form-label" htmlFor="organization">
                  Org Name:
                </Label>
                <Input
                  name="organization"
                  id="organization"
                  type="text"
                  value={validation.values.organization}
                  onChange={evt => {
                    validation.handleChange(evt)
                    setShowSuggestions(true)
                  }}
                  onFocus={() => setHasVisitedInput(true)}
                />
                {validation.touched.organization &&
                validation.errors.organization ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {validation.errors.organization}
                  </FormFeedback>
                ) : null}
                {orgAlreadyExistsError && (
                  <p style={{ color: "red" }}>
                    An organization by this name already exits. Please retype
                    the org name and select it from the suggestions.
                  </p>
                )}
                {orgSuggestions.length > 0 && showSuggestions && (
                  <ul
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      margin: 0,
                      padding: 0,
                      maxHeight: "100px",
                      overflowY: "auto",
                      zIndex: 999,
                      width: "100%",
                      backgroundColor: "#f2f2f2",
                      listStyle: "none",
                    }}
                  >
                    {orgSuggestions.map(suggestion => (
                      <li
                        key={suggestion.id}
                        style={{ padding: "0.5rem", cursor: "pointer" }}
                        onClick={() => {
                          handleSuggestionSelection(suggestion)
                        }}
                      >
                        {suggestion.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="mb-3">
                <Label className="form-label" htmlFor="countries">
                  Countries:
                </Label>
                <Select
                  name="countries"
                  id="countries"
                  classNamePrefix="select2-selection"
                  options={countries.map(country => ({
                    label: country.label,
                    value: country.id,
                  }))}
                  isMulti
                  value={validation.values.countries}
                  onChange={selectedOptions =>
                    validation.setFieldValue("countries", selectedOptions)
                  }
                />
                {validation.touched.countries && validation.errors.countries ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {validation.errors.countries}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label" htmlFor="orgType">
                  Organization Type:
                </Label>
                <Select
                  name="orgType"
                  id="orgType"
                  options={
                    selectedSuggestion
                      ? selectedSuggestionsOrgType.map(val => ({
                          label: val.name,
                          value: val.id,
                        }))
                      : orgTypesData.types.map(val => ({
                          label: val.name,
                          value: val.id,
                        }))
                  }
                  classNamePrefix="select2-selection"
                  value={validation.values.orgType}
                  onChange={selectedOption =>
                    validation.setFieldValue("orgType", selectedOption)
                  }
                />
                {validation.touched.orgType && validation.errors.orgType ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {validation.errors.orgType.value}
                  </FormFeedback>
                ) : null}
              </div>
              <ModalFooter
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderTop: "none",
                  paddingTop: "0px",
                }}
              >
                <div>
                  <Button
                    style={{ backgroundColor: "#50A050", border: "none" }}
                    type="submit"
                  >
                    {isUserEditing ? "Save" : "Create"}
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
      </div>
      {isLoading && <LoadingSpinner />}
      {orgData?.organizations.length > 0 && !isLoading ? (
        <>
          <CustomTable
            data={orgData.organizations}
            caption="Organizations"
            initiateEdit={initiateEdit}
            user={user}
          />
          <Pagination
            currentPage={currentPage}
            goToPage={handlePageChange}
            totalEntries={totalEntries}
          />
        </>
      ) : null}
    </div>
  )
}

export default Organizations
