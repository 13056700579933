import styled from "@emotion/styled"
import * as Tabs from "@radix-ui/react-tabs"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { listSystemSpecificCountries } from "services/listServices"
import {
  getMilestoneOwners,
  getQuestionsOfExistingMilestone,
  updateSpecificMilestoneDetails,
} from "services/milestoneServices"
import Preview from "./Step-Four"
import StepOne from "./Step-One"
import StepThree from "./Step-Three"
import StepTwo from "./Step-Two"
import { useHistory } from "react-router-dom"
import { Button } from "reactstrap"
//styles
const TabsRoot = styled(Tabs.Root)`
  display: flex;
  flex-direction: column;
`

const TabsList = styled(Tabs.List)`
  flex-shrink: 0;
  display: flex;
  margin-block-end: 1.5rem;
  border-bottom: 1px solid hsla(0deg 0% 0%/0.3);
`

const TabsTrigger = styled(Tabs.Trigger)`
  all: unset;
  font-size: 1.15rem !important;
  padding: 0 20px;
  height: 45px;
  flex: 1;
  flex-basis: 200px;
  display: grid;
  place-content: center;
  font-size: 1px;
  line-height: 1;
  user-select: none;
  color: white;
  transition: all 250ms;

  &:first-of-type {
    border-top-left-radius: 6px;
  }
  &:last-child {
    border-top-right-radius: 6px;
  }
  &:hover {
    color: black;
  }
  &[data-state="active"] {
    box-shadow: inset 0 -3px 0 0 red;
  }
  &[data-disabled] {
    cursor: not-allowed;
  }
  &:focus-visible {
    position: relative;
    box-shadow: 0 0 0 2px black;
  }

  &:nth-of-type(1) {
    background-color: #168aad;
  }
  &:nth-of-type(2) {
    background-color: #1a759f;
  }
  &:nth-of-type(3) {
    background-color: #1e6091;
  }
  &:nth-of-type(4) {
    background-color: #184e77;
  }
`

const TabsContent = styled(Tabs.Content)`
  flex-grow: 1;
  padding: 20px;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
  &:focus-visible {
    box-shadow: 0 0 0 2px black;
  }
`
//component
const EditMilestone = props => {
  const { location } = props
  const queryClient = useQueryClient()
  const history = useHistory()
  const { countries } = useSelector(state => state.list)
  const isUserSuperAdmin = location?.state?.userRole === "admin" ? true : false
  const [activeTab, setActiveTab] = useState(
    isUserSuperAdmin ? "step1" : "step3"
  )
  const [form, setForm] = useState({
    ...location?.state?.form,
    form_owners: [],
    questions: [],
  })

  const [existingOwners, setExistingOwners] = useState(form?.form_owners)
  const [newOwners, setNewOwners] = useState([])

  //benchmarking specific fetching logic
  const loadSystemSpecificCountries = async id => {
    try {
      const { data } = await listSystemSpecificCountries(id)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const { data: systemSpecificCountries } = useQuery({
    queryKey: ["System Specific Countries", { id: form?.label_id }],
    queryFn: () => loadSystemSpecificCountries(form.label_id),
    enabled: form.type === "benchmarking" && !!form.label_id,
    initialData: [],
  })

  //fetch questions of existing milestone
  const loadQuestions = async id => {
    try {
      const { data } = await getQuestionsOfExistingMilestone(id)
      setForm(prev => ({ ...prev, questions: data }))
    } catch (err) {
      console.error(err)
      setForm(prev => ({
        ...prev,
        questions: [
          {
            question_text: "",
            question_type: "text",
            question_weight: 0,
            question_number: 1,
            question_options: [""],
            question_options_weight: [0],
            required: false,
          },
        ],
      }))
    }
  }
  //fetch current owners of existing milestone
  const loadCurrentMileStoneOwners = async id => {
    try {
      const { data } = await getMilestoneOwners(id)
      setForm(prev => ({ ...prev, form_owners: data }))
      setExistingOwners(data)
    } catch (err) {
      console.error(err)
      setForm(prev => ({ ...prev, form_owners: [] }))
    }
  }

  useEffect(() => {
    loadQuestions(form.id)
    loadCurrentMileStoneOwners(form.id)
  }, [])

  const handleInputChange = (key, value) => {
    setForm(form => ({
      ...form,
      [key]: value,
    }))
  }
  const handleQuestionsChange = questionsArr => {
    setForm(prev => ({ ...prev, questions: questionsArr }))
  }
  const handleOwnersChange = (prevOwners, newOwners) => {
    setForm(prev => ({
      ...prev,
      form_owners: [...prevOwners, ...newOwners],
    }))
  }
  useEffect(() => {
    handleOwnersChange(existingOwners, newOwners)
  }, [existingOwners, newOwners])

  const handleTabChange = val => setActiveTab(val)

  const questionsHaveNoError = () => {
    const tempQuestions = [...questions]
    tempQuestions.map(question => {
      if (!question.question_text) {
        //|| !question.question_weight
        question.error = true
        result = true
      } else question.error = false

      if (question.question_type === "dropdown") {
        question.question_options.map(option => {
          if (!option) {
            question.error = true
            result = true
          }
        })
      }
    })
    setQuestions(tempQuestions)
    return result
  }
  const updateChanges = () => {
    try {
      const res = updateSpecificMilestoneDetails(form.id, form)
      props.alert("Milestone details updated successfully", true)
      queryClient.invalidateQueries({ queryKey: ["Milestones"] })
      setTimeout(() => {
        history.push(`/forms`)
      }, 2500)
    } catch (err) {
      console.error(err)
      props.alert("Unable to update Milestone Details", false)
    }
  }
  return (
    <div className="page-content">
      <div className="d-flex mb-4 px-2">
        <Button
          className="common-button d-flex align-items-center"
          onClick={() => history.push("/forms")}
        >
          <i className="ph ph-caret-left" />
          <span>Go back</span>
        </Button>
      </div>
      <TabsRoot
        defaultValue={isUserSuperAdmin ? "step1" : "step3"}
        value={activeTab}
      >
        <TabsList>
          <TabsTrigger
            value="step1"
            disabled={!isUserSuperAdmin}
            onClick={() => setActiveTab("step1")}
          >
            1. Enter Details
          </TabsTrigger>
          <TabsTrigger
            value="step2"
            disabled={!isUserSuperAdmin}
            onClick={() => setActiveTab("step2")}
          >
            2. Add Questions
          </TabsTrigger>
          <TabsTrigger
            value="step3"
            onClick={() => setActiveTab("step3")}
            disabled={form.type === "landscaping"}
          >
            3. Assign Owners
          </TabsTrigger>
          <TabsTrigger value="step4" onClick={() => setActiveTab("step4")}>
            4. Preview
          </TabsTrigger>
        </TabsList>
        <TabsContent value="step1">
          <StepOne
            form={form}
            handleInputChange={handleInputChange}
            changeTab={handleTabChange}
          />
        </TabsContent>
        <Tabs.Content value="step2">
          <StepTwo
            questions={form?.questions}
            setQuestions={handleQuestionsChange}
            changeTab={handleTabChange}
            formType={form?.type}
          />
        </Tabs.Content>
        <Tabs.Content value="step3">
          <StepThree
            form={form}
            countries={countries}
            changeTab={handleTabChange}
            currentFormOwners={form.form_owners}
            setFormOwners={handleOwnersChange}
            systemSpecificCountries={systemSpecificCountries}
            alert={props.alert}
            showPrevButton={isUserSuperAdmin}
            newOwners={newOwners}
            setNewOwners={setNewOwners}
            existingFormOwners={existingOwners}
            setExistingFormOwners={setExistingOwners}
          />
        </Tabs.Content>
        <Tabs.Content value="step4">
          <Preview
            form={{ ...form }}
            countries={countries}
            questions={form.questions}
            updateChanges={updateChanges}
            changeTab={handleTabChange}
          />
        </Tabs.Content>
      </TabsRoot>
    </div>
  )
}

export default EditMilestone
