import { csvmaker, downloadCSV } from "components/Common/downloadCSV"
import { formatDateUTC } from "helpers/utlities"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { AUDITROLES } from "constants/global"

export const getAuditPDF = data => {
  const doc = new jsPDF("landscape", "mm")
  const totalWidth = doc.internal.pageSize.getWidth() - 20
  const columnWidths = [15, 10, 10, 35, 15, 15]
  const columnWidthsInUnits = columnWidths.map(
    width => (totalWidth * width) / 100
  )
  const head = []
  let body = []
  const makeTable = () => {
    const headValues = [
      "Actor",
      "Activity Type",
      "Event",
      "Description",
      "Location & IP",
      "Timestamp",
    ]

    headValues.map(value => head.push(value))

    body = data.map(log => {
      const row = [
        !log.users_last_name && !log.users_last_name
          ? "System"
          : `${log.users_first_name} ${log.users_last_name} \n
            ${AUDITROLES[log.audit_role]} ${log.users_email}`,
        log.audit_type ?? "NA",
        log.audit_event ?? "default",
        log.audit_description,
        `${log.audit_ip_address} \n
        ${log.audit_ip_location ?? "No Location Data"}`,
        formatDateUTC(log.audit_created_at),
      ]
      return row
    })
  }
  makeTable()

  autoTable(doc, {
    head: [head],
    body: body,
    styles: { overflow: "linebreak" },
    columnStyles: {
      0: { cellWidth: columnWidthsInUnits[0] },
      1: { cellWidth: columnWidthsInUnits[1] },
      2: { cellWidth: columnWidthsInUnits[2] },
      3: { cellWidth: columnWidthsInUnits[3] },
      4: { cellWidth: columnWidthsInUnits[4] },
      5: { cellWidth: columnWidthsInUnits[5] },
    },
    rowPageBreak: "avoid",
  })

  doc.save("Audit Logs" + ".pdf")
}

export const getAuditCSV = data => {
  const newData = []
  data.map(log => {
    const newDataObject = {}
    newDataObject["Actor"] =
      !log.users_last_name && !log.users_last_name
        ? "System"
        : `${log.users_first_name} ${log.users_last_name}`
    newDataObject["Activity Type"] = log.audit_type ?? "NA"
    newDataObject["Event"] = log.audit_event ?? "default"
    newDataObject["Description"] = log.audit_description
    newDataObject["Location & IP"] = `${log.audit_ip_address} - ${
      log.audit_ip_location ?? "NA"
    }`
    newDataObject["Timestamp"] = formatDateUTC(log.audit_created_at)
    newData.push(newDataObject)
  })
  const csvdata = csvmaker(newData)
  return downloadCSV(csvdata, "Audit Logs.csv")
}
