import React, { useEffect, useState } from "react"
import NewMap from "./NewMap"
import LoadingSpinner from "components/Common/Loading-Spinner"

function Map({ data, filterValue, isLoading }) {
  if (isLoading) return <LoadingSpinner />

  const [mapValues, setMapValues] = useState([])

  useEffect(() => {
    for (var key in data) {
      let returnValue
      if (filterValue === "number_of_policies") {
        returnValue = data[key].number_of_policies
      } else if (filterValue === "policy_score") {
        returnValue = data[key].policy_score
      } else if (filterValue === "policy_win") {
        returnValue = data[key].policy_win
      } else if (filterValue === "days_past_deadline") {
        returnValue = data[key].days_past_deadline
      }
      data[key]["param"] = filterValue
      data[key]["count"] = returnValue
    }
    setMapValues(data)
  }, [filterValue, data])

  return <NewMap data={mapValues} />
}

export default Map
