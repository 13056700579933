import React, { useState } from "react"
import styled from "@emotion/styled"
import { Button } from "reactstrap"
import * as Dialog from "@radix-ui/react-dialog"
import {
  COUNTRIES,
  ETRACKER_STAGES,
  Country,
  ETRACKER_STAGES_OBJ,
} from "constants/global"

const DIMENSIONS = ["stage_id", "country_label", "indicator"]
const dimensionMap = new Map([
  ["indicator", "System"],
  ["country_label", "Location"],
  ["stage_id", "Stage"],
])
const iconMap = new Map([
  ["stage_id", "ph-stack"],
  ["country_label", "ph-globe-hemisphere-east"],
  ["indicator", "ph-tag-chevron"],
])

export default function DimensionCards({
  dimensions,
  systems,
  updateDimensions,
  deleteDimensionCard,
  addDimensionCard,
}) {
  const update1A = (type, value) => updateDimensions(type, value, "1A")
  const update1B = (type, value) => updateDimensions(type, value, "1B")
  const update2A = (type, value) => updateDimensions(type, value, "2A")
  const update2B = (type, value) => updateDimensions(type, value, "2B")
  const update3A = (type, value) => updateDimensions(type, value, "3A")
  const update3B = (type, value) => updateDimensions(type, value, "3B")

  const funcMap = new Map([
    ["1A", update1A],
    ["1B", update1B],
    ["2A", update2A],
    ["2B", update2B],
    ["3A", update3A],
    ["3B", update3B],
  ])

  return (
    <DimensionCardsContainer>
      <div></div>
      {dimensions.map((dimension, idx) => (
        <DimensionCard key={idx}>
          <div className="dimension">
            <div className="dimension__text">
              <i className={iconMap.get(dimension.value1)} />
              <span>{dimensionMap.get(dimension.value1)}:</span>
              <span>
                {Country[dimension.type1] ??
                  ETRACKER_STAGES_OBJ[dimension.type1] ??
                  dimension.type1}
              </span>
            </div>
            <Dialog.Root>
              <Dialog.Trigger>
                <i className="ph-funnel" />
              </Dialog.Trigger>
              <Dialog.Portal>
                <DialogOverlay>
                  <DimensionsDialog
                    systems={systems}
                    currentSelection={dimension.type1}
                    updateDimension={funcMap.get(`${idx + 1}A`)}
                    dimensionsToDisplay={DIMENSIONS.filter(
                      dm => dm !== dimension.value2
                    )}
                  />
                </DialogOverlay>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
          {dimension.value2 && dimension.type2 ? (
            <div className="dimension">
              <div className="dimension__text">
                <i className={iconMap.get(dimension.value2)} />
                <span>{dimensionMap.get(dimension.value2)}:</span>
                <span>
                  {Country[dimension.type2] ??
                    ETRACKER_STAGES_OBJ[dimension.type2] ??
                    dimension.type2}
                </span>
              </div>
              <Dialog.Root>
                <Dialog.Trigger>
                  <i className="ph-funnel" />
                </Dialog.Trigger>
                <Dialog.Portal>
                  <DialogOverlay>
                    <DimensionsDialog
                      systems={systems}
                      currentSelection={dimension.type2}
                      updateDimension={funcMap.get(`${idx + 1}B`)}
                      dimensionsToDisplay={DIMENSIONS.filter(
                        dm => dm !== dimension.value1
                      )}
                    />
                  </DialogOverlay>
                </Dialog.Portal>
              </Dialog.Root>
            </div>
          ) : (
            <Dialog.Root>
              <Dialog.Trigger>Add Dimension</Dialog.Trigger>
              <Dialog.Portal>
                <DialogOverlay>
                  <DimensionsDialog
                    systems={systems}
                    updateDimension={funcMap.get(`${idx + 1}B`)}
                    dimensionsToDisplay={DIMENSIONS.filter(
                      dm => dm !== dimension.value1
                    )}
                  />
                </DialogOverlay>
              </Dialog.Portal>
            </Dialog.Root>
          )}
          <button
            className="delete__dimension"
            onClick={() => deleteDimensionCard(idx)}
          >
            <i className="ph-trash" />
          </button>
        </DimensionCard>
      ))}
      {dimensions.length < 3 && (
        <button onClick={addDimensionCard}>
          <i className="ph-plus" />
          <span>Add Comparison</span>
        </button>
      )}
    </DimensionCardsContainer>
  )
}

const DimensionCard = styled.div`
  display: grid;
  row-gap: 1.5rem;
  color: #b333e9;
  text-transform: capitalize;
  font-size: 16px;
  padding: 2em 1em;
  border-radius: 5px;
  box-shadow: var(--shadow-elevation-medium);
  outline: 1px solid;
  position: relative;

  & .dimension {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;

    & .dimension__text {
      flex: 1;
      /* flex-wrap: wrap; */
      font-weight: 600;
      display: flex;
      gap: 0.25rem;
      align-items: baseline;
      & i {
        vertical-align: middle;
        background-color: #b333e9;
        padding: 0.25rem;
        border-radius: 3px;
        color: white;
      }
    }
  }

  & button {
    all: unset;
    cursor: pointer;
    text-align: center;
    padding-block: 0.2em;
    background-color: transparent;
    color: #b333e9;

    &:focus-visible {
      outline: 2px solid #b333e9 !important;
      outline-offset: 3px;
    }
  }

  & > button {
    width: 100%;
    background-color: #b333e9;
    color: white;
  }

  & .delete__dimension {
    position: absolute;
    top: 5px;
    right: 5px;
    width: max-content;
    padding: 0 0.2em;

    & i {
      vertical-align: middle;
    }
  }
`
const DimensionCardsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 150px repeat(3, 1fr);
  column-gap: 2rem;
  margin-block-end: 2rem;

  & > button {
    display: grid !important;
    grid-auto-flow: column !important;
    place-items: center !important;
    /* justify-content: center;
    align-items: center !important; */
    column-gap: 0.25rem !important;
    font-size: 14px;
    all: unset;
    cursor: pointer;
    text-align: center;
    padding: 1em 3em;
    background-color: transparent;
    box-shadow: var(--shadow-elevation-low);
    border: 1px solid #b333e9;
    color: #b333e9;
    border-radius: 5px;
    place-self: end center;
  }
`
const DialogContent = styled(Dialog.Content)`
  background-color: white;
  border-radius: 1em;
  box-shadow: var(--shadow-elevation-medium);
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  width: 60vw;
  max-width: 1100px;
  overflow-x: auto;
  min-height: 70vh;
  padding: 25px;
  font-size: 14px;

  & .content__container {
    display: flex;
    flex-direction: column;

    & > button {
      align-self: flex-end !important;
      width: fit-content;
      all: unset;
      cursor: pointer;
      background-color: green;
      color: white;
      padding: 0.4em 1.6em;
      text-align: center;
      border-radius: 0.8em;
    }
  }

  & .dimensions__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    column-gap: 2rem;

    & header {
      font-size: 16px;
      font-weight: 500;
      margin-block-end: 0.75rem;
    }
    & .list {
      max-height: 40vh;
      overflow-y: auto;
      list-style: none;
      padding-inline-start: 0;

      li {
        display: flex;
        gap: 0.5em;
        align-items: stretch;

        input {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          transition: box-shadow 0.3s;
          background: lightgrey;
          cursor: pointer;
          border: 0;
          appearance: none;
          -webkit-appearance: none;

          &:checked {
            box-shadow: inset 0 0 0 10px #b333e9;
          }
        }
        label {
          font-weight: 400;
          text-transform: capitalize;
          cursor: pointer;
        }
      }
    }
  }
`
const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background-color: hsl(0 0% 0%/ 0.5);
  z-index: 998;
`
const DimensionsDialog = React.forwardRef(
  (
    {
      systems,
      dimensionsToDisplay,
      updateDimension = () => {},
      currentSelection = "",
    },
    ref
  ) => {
    const [type, setType] = useState(currentSelection)
    const [value, setValue] = useState("")

    const handleInputChange = (evt, value) => {
      if (evt.target.checked) {
        setType(evt.target.value)
        setValue(value)
      }
    }
    return (
      <DialogContent ref={ref}>
        <Dialog.Title>Dimensions</Dialog.Title>
        <Dialog.Description>
          {`Please select one value from any of the below ${dimensionsToDisplay.length} categories`}
        </Dialog.Description>
        <div className="content__container">
          <div className="dimensions__container">
            {dimensionsToDisplay.includes("indicator") && (
              <div>
                <header>Systems</header>
                <ul className="list">
                  {systems?.map(system => (
                    <li key={system}>
                      <input
                        type="radio"
                        name="selection"
                        id={system}
                        value={system}
                        onChange={evt => handleInputChange(evt, "indicator")}
                        defaultChecked={
                          system.toLowerCase() ===
                          currentSelection.toLowerCase()
                        }
                      />
                      <label htmlFor={system}>{system}</label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {dimensionsToDisplay.includes("country_label") && (
              <div>
                <header>Locations</header>
                <ul className="list">
                  {COUNTRIES?.map(country => (
                    <li key={country.code}>
                      <input
                        type="radio"
                        name="selection"
                        id={country.code}
                        value={country.code}
                        defaultChecked={country.code === currentSelection}
                        onChange={evt =>
                          handleInputChange(evt, "country_label")
                        }
                      />
                      <label htmlFor={country.code}>{country.name}</label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {dimensionsToDisplay.includes("stage_id") && (
              <div>
                <header>Stages</header>
                <ul className="list">
                  {ETRACKER_STAGES?.map(stage => (
                    <li key={stage.id}>
                      <input
                        type="radio"
                        name="selection"
                        id={stage.id}
                        value={stage.id}
                        defaultChecked={stage.id === currentSelection}
                        onChange={evt => {
                          handleInputChange(evt, "stage_id")
                        }}
                      />
                      <label htmlFor={stage.id}>{stage.name}</label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <Dialog.Close asChild>
            <button onClick={() => updateDimension(type, value)}>Apply</button>
          </Dialog.Close>
        </div>
      </DialogContent>
    )
  }
)
DimensionsDialog.displayName = "DimensionsDialog"
