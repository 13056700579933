import React, {useEffect, useState} from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Example from './Example';
import './mindmap-styles.css';
import { useMutation } from "@tanstack/react-query";
import { get } from "services/AXIOS";
import { CATEGORY } from "services/CONSTANTS";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from 'reactstrap';

function MindMap({country}) {

    const [categories, setCategories] = useState([]);
    const [data, setData] = useState({})

    const { mutate: getAllCategories } = useMutation(
        ["categories"],
        () => get(`${CATEGORY}/${country}`),
        {
        onSuccess: category => {
            setCategories(category)
        },
        }
    )
    
    useEffect(() => {
        getAllCategories();
    }, [country])

    useEffect(()=>{
        loadData();
    }, [categories])

    const loadData = () => {
        const data = {name: categories[0]?.country.label, children: []};
        data.children = categories.map((category)=>{
            if(category.sub_category.length){
                return {
                    "name": category.name,
                    "children": category.sub_category.map((sub_category)=>{
                        if(sub_category.policy.length){
                            return{
                                "name": sub_category.name, 
                                "children": sub_category.policy.map((policy)=>{
                                    return {"name": policy.policy_name}
                                })
                            }
                        }
                        else{
                            return {
                                "name": sub_category.name,
                            }
                        }
                    })
                }
            }
            else{
                return {
                    "name": category.name
                }
            }
        })
        setData(data);
    }

    //export to pdf
    const generatePDF = () => {
        const input = document.getElementById("mindmap")
        html2canvas(input, {
        logging: true,
        letterRendering: 1,
        useCors: true,
        }).then(canvas => {
        const imgWidth = 800
        const imgHeight = 600
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF("l", "pt", "a4")
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
        //pdf.output('dataurlnewwindow');
        pdf.save("mindmap.pdf")
        })
    }
    //can make into a common function
  
    return (
            <ParentSize id="parent">
            {({ width, height }) => {
                const maxHeight = 1000;
                const h = Math.min(height, maxHeight);
                const minWidth = 800;
                const w = Math.max(width, minWidth);
                return (
                    <div>
                        <div className= "d-flex justify-content-between pb-2">
                            <p className=" fs-5 mb-0 fw-bold d-flex align-items-center"><i className="ph-map-trifold me-1 fs-3"></i>Step 3: Mapping</p>
                            <Button style={{background: "#EF8D21FF", border: "none"}} onClick={generatePDF}>Export PDF</Button>  
                        </div>
                        <Example width={w} height={h} data={data}/>
                    </div>
                );
            }}
            </ParentSize>
    )
}

export default MindMap;

