import React, { Fragment, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"

export const ExportTable = (data, ref, header, page, backgroundColor) => {
  const generatePDF = () => {
    const input = ref.current
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCors: true,
    }).then(canvas => {
      const imgWidth = 800
      const imgHeight = (canvas.height * imgWidth) / canvas.width
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("l", "pt", "a4")
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
      pdf.save("policies.pdf")
    })
  }

  const download = function (data) {
    const blob = new Blob([data], { type: "text/csv" })

    const url = window.URL.createObjectURL(blob)

    const a = document.createElement("a")

    a.setAttribute("href", url)

    a.setAttribute("download", "policies.csv")

    a.click()
  }

  const csvmaker = function (data) {
    const csvRows = []

    const headers = header.map(head => head.Header)

    csvRows.push(headers.join(","))

    const getLabels = labels => {
      return labels.map(label => label.label_name).join(";")
    }

    page.map(row => {
      const tempRow = []
      const celldata = row.original
      tempRow.push(
        `${celldata?.policy_name},${getLabels(celldata?.labels)},${
          celldata?.stage?.stage
        },${celldata?.last_updated_at},${celldata?.updated_by || ""},${
          celldata?.tasks[0]?.status
        },${"Score"}`
      )

      csvRows.push(tempRow)
    })

    return csvRows.join("\n")
  }

  const get = () => {
    const csvdata = csvmaker(data)
    return download(csvdata)
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
      <DropdownToggle
        caret
        style={{
          backgroundColor,
        }}
      >
        Export Table
        <i className="mdi mdi-chevron-down ms-1"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Export as</DropdownItem>
        <DropdownItem onClick={generatePDF}>PDF</DropdownItem>
        <DropdownItem onClick={get}>CSV</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <Col sm={4}>
      <div className="search-box me-2 mb-2 d-inline-block w-100">
        {/* <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value)
              }}
              id="search-bar-0"
              type="text"
              className="form-control w-100"
              placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div> */}
        <input
          onChange={e => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          id="search-bar-0"
          type="text"
          className="form-control w-100"
          placeholder={`${count} records...`}
          value={value || ""}
        />
      </div>
    </Col>
  )
}

const TableContainer = ({
  backgroundColor,
  hideTable,
  topComponent,
  columns,
  data,
  tableTitle,
  isFilters,
  FilterComponent,
  isSort,
  SortComponent,
  isExport,
  ExportComponent,
  isDropDown,
  DropDownComponent,
  isProgress,
  ProgressComponent,
  isGlobalFilter,
  isSearch,
  SearchComponent,
  isAddOptions,
  isAddUserList,
  isAddAccessCodeList,
  isPolicyDetails,
  PolicyDetailsHeader,
  handleOrderClicks,
  handleUserClick,
  handleAccessCodeClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  customSort,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSizeOptions ? customPageSize : 50,
        sortBy:
          customSort == "asc"
            ? []
            : [
                {
                  desc: true,
                },
              ],
        hiddenColumns: columns.map(column => {
          if (column.show === false)
            return column.accessor || column.id || column.Header
        }),
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const tableRef = useRef(null)

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }
  return (
    <Fragment>
      {tableTitle && (
        <Row>
          <Col sm={6}>
            <div className=" fs-4  fw-bold">
              {" "}
              <i className=" bx bx-file bx-sm" /> {tableTitle}
            </div>
            <br />
          </Col>
          <Col sm={6} className=" text-end">
            <div className=" fs-4  fw-bold">
              {topComponent && topComponent()}
            </div>
          </Col>
        </Row>
      )}

      <Row className="mb-4">
        {/* <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col> */}

        {/* <Col sm={1}></Col> */}

        <Col sm={4}>{isSearch && SearchComponent()}</Col>

        <Col sm={3}>
          <div className=" d-flex ">
            {isSort && SortComponent()}
            {isFilters && FilterComponent()}
          </div>
        </Col>
        <Col sm={3}></Col>

        <Col sm={2} className="text-end">
          {isExport && (
            <div>
              {ExportTable(
                data,
                tableRef,
                headerGroups[0].headers,
                page,
                backgroundColor
              )}
            </div>
          )}
        </Col>

        {isPolicyDetails && <PolicyDetailsHeader />}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddAccessCodeList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleAccessCodeClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Access Code
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col md="4">{isProgress && ProgressComponent()}</Col>
        <Col md="4"></Col>
        <Col md="4">{isDropDown && DropDownComponent()}</Col>
      </Row>

      {!hideTable && (
        <div className="table-responsive react-table" ref={tableRef}>
          <Table
            hover
            {...getTableProps()}
            className={className}
            style={{
              border: "none",
            }}
          >
            <thead
              className=" table-nowrap"
              style={
                backgroundColor
                  ? {
                      backgroundColor:
                        backgroundColor && backgroundColor + "5e",
                    }
                  : { backgroundColor: "#F3F4F6" }
              }
            >
              {headerGroups.map(headerGroup => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      key={column.id}
                      style={{
                        border: "none",
                      }}
                    >
                      <div className="mb-2" {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                      {!isGlobalFilter && <Filter column={column} />}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row, index) => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr
                        style={{
                          backgroundColor:
                            index % 2 != 0 ? "#F3F4F6" : "#ffffff",
                        }}
                      >
                        {row.cells.map(cell => {
                          return (
                            <td
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  minWidth: cell.column.minWidth,
                                  width: cell.column.width,
                                  maxWidth: cell.column.maxWidth,
                                  border: "none",
                                },
                              })}
                            >
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )
                })
              ) : (
                <>
                  <tr>
                    <td
                      colSpan={headerGroups[0]?.headers?.length}
                      className=" text-center"
                    >
                      No data available yet.
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </div>
      )}

      {!hideTable && (
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
