import React, { useState, useEffect } from "react"
import {
  updateAxiosLocationHeaders,
  updateAxiosIPHeader,
  axiosApi,
} from "../../services/AXIOS"
import { useLocation } from "react-router-dom"
import axios from "axios"
import Lottie from "react-lottie"
import animationData from "../../assets/lotties/enable_location"
import background from "../../assets/images/permissions_background.svg"
import styled from "styled-components"

//Styles
const StyledLocationPermissionPage = styled.div`
  display: grid;
  place-items: center;
  min-height: 100vh;
  gap: 2.5em;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
`

const StyledCard = styled.div`
  background-color: whitesmoke;
  padding: 2em 4em;
  border-radius: 2em;
  text-align: center;
`

const EnableLocation = ({ errorMessage }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <StyledCard>
      <Lottie options={defaultOptions} height={250} width={250} />
      <h1>Enable Location</h1>
      {errorMessage.length === 0 ? (
        <p>
          This application contains sensitive data and requires location access
          to proceed.
          <br /> Please click on 'Allow' when your browser notifies you to grant
          location permission.
        </p>
      ) : (
        <p style={{ color: "red" }}>
          You need to enable the location permission to be able to access the
          portal.
          <br /> Please do the same and reload the page.
        </p>
      )}
    </StyledCard>
  )
}

const LocationRequired = ({ children }) => {
  const [locationPermission, setLocationPermission] = useState(() =>
    localStorage.getItem("enabled location") ? true : false
  )
  const [errorMessage, setErrorMessage] = useState("")
  const location = useLocation()

  useEffect(() => {
    const checkLocationPermission = async () => {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      })
      localStorage.setItem(
        "enabled location",
        permissionStatus.state === "granted"
      )
      setLocationPermission(permissionStatus.state === "granted")
    }

    checkLocationPermission()
  }, [location.pathname])

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const { latitude: lat, longitude: long } = position.coords
          updateAxiosLocationHeaders(axiosApi, { lat, long })
          localStorage.setItem("enabled location", true)
          setLocationPermission(true)
        },
        GeolocationPositionError => {
          localStorage.setItem("enabled location", false)
          setLocationPermission(false)
          setErrorMessage(
            "You need to grant location permission to be able to use this portal."
          )
        }
      )
    } else {
      localStorage.setItem("enabled location", false)
      setLocationPermission(false)
      setErrorMessage("Geolocation is not supported by this browser")
    }
  }

  const getIP = async () => {
    try {
      const { data } = await axios.get("https://ipapi.co/json/")
      updateAxiosIPHeader(axiosApi, data?.ip)
    } catch (err) {
      console.error({ err })
      updateAxiosIPHeader(axiosApi, "0.0.0.0")
    }
  }

  useEffect(() => {
    getLocation()
    getIP()
  }, [location.pathname])

  if (!locationPermission) {
    return (
      <StyledLocationPermissionPage>
        <EnableLocation errorMessage={errorMessage} />
      </StyledLocationPermissionPage>
    )
  }
  return <>{children}</>
}

export default LocationRequired
