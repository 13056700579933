import { get, patch, post } from "./AXIOS"
import {
  LABELS,
  GET_LABEL_TYPES,
  GET_SPECIFIC_LABEL_TYPE_DETAILS,
  CREATE_NEW_LABEL_TYPE,
  FIND_LABELS,
  GET_LABEL_SUGGESTIONS,
  CREATE_NEW_LABEL,
  ALL_LABELS,
} from "./CONSTANTS"

export const getIndicators = () => get(ALL_LABELS)

export const getSystemTypes = (query, body) => {
  if (query?.searchTerm) {
    return post(
      `${GET_LABEL_TYPES}/${query.searchTerm}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  } else {
    return post(
      `${GET_LABEL_TYPES}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  }
}

export const createNewSystemType = body => post(CREATE_NEW_LABEL_TYPE, body)

export const editSpecificSystemType = (id, body) =>
  patch(`${GET_SPECIFIC_LABEL_TYPE_DETAILS}/${id}`, body)

export const editSpecificSystem = (id, body) => patch(`${LABELS}/${id}`, body)

export const getSystems = (query, body) => {
  if (query?.searchTerm) {
    return post(
      `${FIND_LABELS}/${query.searchTerm}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  } else {
    return post(
      `${FIND_LABELS}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  }
}

export const getSystemSuggestions = input =>
  get(`${GET_LABEL_SUGGESTIONS}/${input}`)

export const createNewSystem = body => post(CREATE_NEW_LABEL, body)
