import React from "react"
import SystemTypes from "./System-Types"
import Systems from "./Systems"
import Tabs from "components/Common/Tabs"

export default function SystemsManagement({ alert }) {
  return (
    <div className="page-content">
      <Tabs defaultValue="tab1">
        <Tabs.Triggers>
          <Tabs.TriggerButton value="tab1">Systems</Tabs.TriggerButton>
          <Tabs.TriggerButton value="tab2">System Types</Tabs.TriggerButton>
        </Tabs.Triggers>
        <Tabs.TabContent value="tab1">
          <Systems alert={alert} />
        </Tabs.TabContent>
        <Tabs.TabContent value="tab2">
          <SystemTypes alert={alert} />
        </Tabs.TabContent>
      </Tabs>
    </div>
  )
}
