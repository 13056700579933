import { csvmaker, downloadCSV } from "components/Common/downloadCSV";
import { formateOnlyDate } from "helpers/utlities"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

export const getPDF = (data) => {
    const doc = new jsPDF("portrait", "mm")

    const head = []
    let body = []

    const makeTable = () => {
      const headValues = [
        "Policy Name",
        "Stage",
        "Deadline",
        "Status"
      ]

      headValues.map(value => head.push(value))

      body = data.map(data => {
        const row = [
            data.policy_policy_name,
            `${data.stage_id}: ${data.stage_label}`,
            data.policy_deadline ? formateOnlyDate(data.policy_deadline): "NA",
            data.task_status
        ]
        return row
      })
    }

    makeTable()

    autoTable(doc, {
      head: [head],
      body: body,
    })

    doc.save("AdminTasks" + ".pdf")

}

export const getCSV = (data) => {
    const newData = []
    data.map(data => {
        const newDataObject = {};
        newDataObject["Policy Name"] = data.policy_policy_name;
        newDataObject["Stage"] = `${data.stage_id}: ${data.stage_label}`
        newDataObject["Deadline"] =  data.policy_deadline ? formateOnlyDate(data.policy_deadline): "NA",
        newDataObject["Status"] = data.task_status;
        newData.push(newDataObject)
    })
    const csvdata = csvmaker(newData)
    return downloadCSV(csvdata, "AdminTasks.csv")
}