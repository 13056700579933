import { useState, useEffect } from "react"

function useDebounce(value = "", time = 250) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timerID = setTimeout(() => {
      setDebouncedValue(value)
    }, time)

    return () => {
      clearTimeout(timerID)
    }
  }, [value, time])

  return debouncedValue
}

export default useDebounce
