import React, {useEffect, useState} from "react"
import { useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import { getDashboardAdminTasks } from "services/reportServices"
import LoadingSpinner from "components/Common/Loading-Spinner"
import AdminTasksTable from "pages/TaskManagement/AdminTasks/AdminTasksTable"
import SearchBox from "components/Common/SearchBox"
import useDebounce from "hooks/useDebounce"
import Select from "react-select"
import ExportTable from "components/Common/ExportTable"
import { Button } from "reactstrap"
import styled from "@emotion/styled"
import Filter from "./Filter"
import Pagination from "components/Common/Pagination"
import { getCSV, getPDF } from "./export"

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.5em;

  & .basic-single {
    flex-basis: 150px;
  }
`

const AdminTasks = ({component, alert}) => {
  const { country_id } = useSelector(state => state.Profile)

  const loadAdminTasks = async (query, body) => {
    try {
      const { data } = await getDashboardAdminTasks(query, body)
      setTotalEntries(data.dataCount)
      return data
    } catch (err) {
      console.error(err)
      setTotalEntries(0)
      return err
    }
  }

  //pagination
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const handlePageChange = pageNum => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }

  useEffect(()=>{
    setTotalEntries(adminTasks?.dataCount)
  },[])

  //search
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)

  const handleSearchInputChange = evt => {
    setSearchTerm(evt.target.value)
    setOffset(0)
    setCurrentPage(1)
    setTotalEntries(adminTasks?.dataCount)
  }

  //filters
  const [selectedStageFilters, setSelectedStageFilters] = useState([])
  const [applyFilter, setApplyFilter] = useState(0)

  const applyFilters = () => {
    setApplyFilter(applyFilter+1)
  }

  const areAnyFiltersSelected = () => {
    if (selectedStageFilters.length > 0) {
      return true
    } else {
      return false
    }
  }

  const clearFilters = () => {
    setSelectedStageFilters([])
    applyFilters()
  }

  //sort
  const [sortBy, setSortBy] = useState("deadlineASC")
  const sortOptions = [
    {
      value: "deadlineASC",
      label: "Recent Deadline First",
    },
    {
      value: "deadlineDESC",
      label: "Recent Deadline Last",
    },
  ]
  const sortMap = new Map([
    ["deadlineDESC", { deadline: "DESC" }],
    ["deadlineASC", { deadline: "ASC" }],
  ])

  const {
    data: adminTasks,
    isLoading,
  } = useQuery({
    queryKey: ["Admin Tasks", { 
      country_id,
      debouncedSearchTerm,
      applyFilter,
      sortBy,
      page: currentPage
     }],
    queryFn: () => loadAdminTasks({ searchTerm: debouncedSearchTerm, limit: 50, offset },
      {
        stages: selectedStageFilters.map((stage)=>stage.value),
        sortForm: sortMap.get(sortBy),
        country_id,
      }),
    // enabled: !!country_id,
    staleTime: 60 * 1000,
  })

  const exportTable = async (type) => {
    try{ 
      const {data} = await getDashboardAdminTasks(
        { searchTerm: debouncedSearchTerm, limit: 0, offset: 0},
        {
          stages: selectedStageFilters.map((stage)=>stage.value),
          sortForm: sortMap.get(sortBy),
          country_id
        })
      type === "pdf" ? getPDF(data.result) : getCSV(data.result)
    } catch(err){
      console.log(err);
      alert("Data export", false)
    }
  }

  return (
    <div>
      {component != "Dashboard" && 
        <div className="d-flex justify-content-between">
          <StyledContainer>
            <SearchBox searchChange={handleSearchInputChange} />
            <Filter 
              selectedStageFilters={selectedStageFilters} 
              setSelectedStageFilters={setSelectedStageFilters} 
              applyFilters={applyFilters}
            />
            <Select
              options={sortOptions}
              defaultValue={sortBy}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Sort by"
              onChange={option => setSortBy(option?.value)}
            />
            {areAnyFiltersSelected() && (
              <Button
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "#f8f9fa",
                  color: "black",
                  margin: "15px 10px",
                  border: "none",
                  fontSize: "14px",
                }}
                onClick={clearFilters}
              >
                <i className="ph-x me-1"></i>
                Clear All Filters
              </Button>
            )}
          </StyledContainer>
          <ExportTable loadFilteredData={exportTable} component={"SeperateFn"}></ExportTable>
        </div>
      }
      {isLoading ? 
        <LoadingSpinner/> : 
        <div>
          <AdminTasksTable adminTasks={adminTasks?.result}/>
          {component != "Dashboard" && 
            <Pagination
              currentPage={currentPage}
              goToPage={handlePageChange}
              totalEntries={totalEntries}
            />}
        </div>
      }
    </div>
  )
}

export default AdminTasks
