import colourStyles from 'components/Common/LabelColorStyles';
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import { ALL_LABELS } from "services/CONSTANTS"
import {get} from "services/AXIOS";
import { Button } from 'reactstrap';
import { Chart } from "react-google-charts";
import NewMap from './DrillDownMap3';
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { indicatorTrends } from 'services/reportServices';
import LoadingOverlay from 'react-loading-overlay';

function Trends(props) {
    let newDate = new Date();

    const [labels, setLabels] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState("");
    const [chartData, setChartData] = useState([]);
    const [params, setParams] = useState({});
    const [startPickDate, setStartPickDate] = useState(newDate.getTime() - (24*60*60*1000) * 7);
    const [fromDate, setFromDate] = useState(convertDate('2021-01-01'));
    const [toDate, setToDate] = useState(convertDate(startPickDate));
    const [tickMarks, setTickMarks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadLabels()
    }, [])

    const loadLabels = () => {
        get(ALL_LABELS)
          .then(data => setLabels(data))
          .catch(err => {
            //add alert
          })
    }

    const labelOptions = labels?.map(label=>{
        return({
            value: label.label_id, 
            label: label.label_name, 
            color: label.label_color
            }
        )}
    );

    const handleLabels = labels => {
        setSelectedLabels(labels)
    }

    const handleFromDate = (value) => {
        setFromDate(convertDate(value))
    }

    const handleToDate = (value) => {
        setToDate(convertDate(value));
    }

    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [d.getFullYear(), pad(d.getMonth()+1), pad(d.getDate())].join('-')
      }

    function convertDate2(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [d.getFullYear(), pad(d.getMonth()+1), pad(d.getDate())].join(', ')
    }
    
    function splitDateIntoEqualIntervals(startDate, endDate, numberOfIntervals){

        let diff =  endDate.getTime() - startDate.getTime();
        let intervalLength = diff/numberOfIntervals;
        let intervals = [];
        for(let i = 1 ; i <= numberOfIntervals;i++)
            intervals.push(new Date(startDate.getTime()+i*intervalLength))
        return intervals;
        
    }
    
    const handleGenerate = () => {
        setIsLoading(true);
        const labels = selectedLabels.map(label=>label.label.charAt(0).toLowerCase() + label.label.slice(1));
        const params = {
            "select_indicator": labels,
            "start_date": fromDate,
            "end_date": toDate
        }
        setParams(params);
        
        indicatorTrends(params)
        .then(res=>{
            const chartData = [];
            const a = [];
            chartData.push(res.data.columns);
            res.data.data.map((data)=>{
                //console.log(data[0]);
                data[0] = new Date(convertDate2(data[0]))
                chartData.push(data);
                a.push(data[0]);
            })
            setTickMarks(a);
            setChartData(chartData);
        })
        .finally(()=>setIsLoading(false))
    }

    const options = {
        hAxis: {
            title: "Date",
            titleTextStyle: { italic: false},
            format: 'MMM dd, YYYY',
            ticks: splitDateIntoEqualIntervals(new Date(convertDate2(fromDate)), new Date(convertDate2(toDate)), 4)
        },
        vAxis: {
            title: "System Count",
            titleTextStyle: { italic: false}
        },
    };

    return (
        <div>
            <div style={{backgroundColor: "white"}} className="m-3">
                <h4 className='d-flex align-items-center' style={{color: "black"}}><i className="ph-stack-simple me-2"></i>Comparing trends for AGRA systems</h4>
                <hr/>
                <div className='d-flex my-2'>
                    <div className='d-flex align-items-center'>
                        <i className='bx bx-purchase-tag light-icon me-1'/>
                        <h6 className='mb-0'>Systems</h6>
                    </div>
                    <div className='ms-2 w-100'>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            options={labelOptions}
                            // defaultValue={selectedLabels}
                            styles={colourStyles}
                            placeholder="Select Multiple" 
                            onChange={handleLabels}
                            isOptionDisabled={() => selectedLabels.length >= 3}
                        />
                    </div>
                </div>
                <hr/>
                <h5 className='d-flex align-items-center' style={{color: "black"}}><i className="ph-calendar-blank me-2"></i>Duration</h5>
                <div className='d-flex'>
                    <div className='d-flex align-items-center'>
                        <h6 className='pe-2'>From</h6>
                        <Flatpickr
                            name="from"
                            className="form-control d-block"
                            placeholder="Start Period"
                            options={{
                            altInput: true,
                            altFormat: "Y-m-d",
                            dateFormat: "Y-m-d",
                            defaultDate: '2021-01-01',
                            maxDate: "today",
                            }}
                            onChange={function(value) {
                                handleFromDate(value)
                            }}   
                        /> 
                    </div>
                    <div className='d-flex align-items-center px-3'>
                        <h6 className='pe-2'>To</h6>
                        <Flatpickr
                            name="to"
                            className="form-control d-block"
                            placeholder="End Period"
                            options={{
                            altInput: true,
                            altFormat: "Y-m-d",
                            dateFormat: "Y-m-d",
                            defaultDate: startPickDate,
                            maxDate: "today",
                            }}
                            onChange={function(value) {
                                handleToDate(value)
                            }}   
                        /> 
                    </div>
                    <Button style={{background: "#8353E2FF", border: "none"}} onClick={handleGenerate}>Generate</Button>
                </div>
            </div>
            <br/>
            <br/>
            <LoadingOverlay
                active={isLoading}
                spinner
                text="Generating data..."
            >
                <div style={{backgroundColor: "white"}} className="m-3">
                    <h4 className='d-flex align-items-center' style={{color: "black"}}><i className="ph-chart-line-up me-2"></i>Trending systems over time</h4>
                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={chartData}
                        options={options}
                    />
                    <div className='w-100 d-flex justify-content-center'><p style={{fontSize: "12px", fontStyle: "italic"}}>Note: System count represents total no. of tweets containing selected keywords across AGRA countries</p></div>
                </div>
                <div style={{backgroundColor: "white"}} className="m-3">
                    <h4 className='d-flex align-items-center pb-3' style={{color: "black"}}><i className="ph-chart-line-up me-2"></i>Compared breakdown by systems</h4>
                    <div style={{border: "0.5px solid lightgray", borderRadius: "5px"}}>
                        <NewMap params={params}/>
                    </div>
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default Trends;