import React, { useEffect, useState, Fragment } from "react"
import { useSelector } from "react-redux"
import Select from "react-select"
import {
  getCountrySpecificUsers,
  getMilestoneOwners,
} from "services/milestoneServices"
import { useQuery } from "@tanstack/react-query"
import { Button, Table } from "reactstrap"
import styled from "@emotion/styled"
import { Role } from "constants/global"

const StyledButton = styled.button`
  background-color: #6c757d; /* Green */
  border: none;
  color: white;
  font-size: 13px;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 1000vmax;
`
const StepThree = ({
  form,
  changeTab,
  alert,
  showPrevButton,
  save,
  setOwners,
  systemSpecificCountries,
}) => {
  const { countries } = useSelector(state => state.list)

  const [country, setCountry] = useState(null)
  const [owner, setOwner] = useState(null)
  const [pairs, setPairs] = useState(form.form_owners)

  const [countryOptions, setCountryOptions] = useState([])

  const loadCountrySpecificOwners = async body => {
    try {
      const { data } = await getCountrySpecificUsers(body)
      return data.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name} (${
          Role[user.auth.role] ?? ""
        })`,
      }))
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const { data: ownerOptions } = useQuery({
    queryKey: ["Country Owners", { id: country?.value }],
    queryFn: () =>
      loadCountrySpecificOwners({
        stage_id: form.stage_id,
        country_id: country?.value,
      }),
    enabled: !!country,
    staleTime: 60 * 1_000,
  })
  const handleCountryChange = option => {
    setCountry(option)
    setOwner(null)
  }
  const handleAddOwner = () => {
    console.log({ pairs, country, owner })
    setPairs(prev => [
      ...prev,
      {
        country_name: country.label,
        user_name: owner.label,
        country_id: country.value,
        user_id: owner.value,
      },
    ])
    setCountryOptions(prevOptions =>
      prevOptions.filter(option => option.value !== country.value)
    )
    setCountry(null)
    setOwner(null)
  }
  useEffect(() => {
    const countriesWithExistingOwners = pairs.map(pair => pair.country_name)
    if (countries && countries.length > 0) {
      if (form.type === "benchmarking" && !!form.stage_id) {
        setCountryOptions(
          countries
            .filter(
              country =>
                !countriesWithExistingOwners.includes(country.label) &&
                systemSpecificCountries.includes(country.id)
            )
            .map(country => ({
              value: country.id,
              label: country.label,
            }))
        )
      } else {
        setCountryOptions(
          countries
            .filter(
              country => !countriesWithExistingOwners.includes(country.label)
            )
            .map(country => ({
              value: country.id,
              label: country.label,
            }))
        )
      }
    }
  }, [countries, pairs])

  useEffect(() => {
    setOwners(pairs)
  }, [pairs])
  const handlePairDelete = (country_name, country_id) => {
    setPairs(prev => prev.filter(pair => pair.country_name !== country_name))
    setCountryOptions(prev => [
      ...prev,
      { label: country_name, value: country_id },
    ])
  }
  const proceed = () => {
    save(3)
    alert("Milestone owners saved in draft", true)
    changeTab("step4")
  }
  return (
    <div
      style={{
        minHeight: "40vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBlock: "2rem",
      }}
    >
      <div
        className="d-flex gap-4 mx-10"
        style={{ width: "70%", marginInline: "auto", marginBlockEnd: "2rem" }}
      >
        <div className="w-100">
          <label htmlFor="country">Country:</label>
          <Select
            aria-labelledby="country"
            value={
              country
                ? countryOptions.find(opt => opt.value === country.value)
                : null
            }
            options={countryOptions}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select a Country"
            onChange={option => handleCountryChange(option)}
          />
        </div>
        <div className="w-100">
          <label htmlFor="owner">Owner</label>
          <Select
            aria-labelledby="owner"
            value={
              owner ? ownerOptions.find(opt => opt.value === owner.value) : null
            }
            options={ownerOptions}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select a owner"
            onChange={option => setOwner(option)}
          />
        </div>
        <button
          style={{ flexBasis: "200px", alignSelf: "flex-end" }}
          onClick={handleAddOwner}
        >
          Add Owner
        </button>
      </div>
      <div
        style={{
          width: "70%",
          marginInline: "auto",
          display: "grid",
          gridTemplateColumns: "4fr 1fr",
          gap: "1em",
        }}
      >
        {pairs.length > 0 ? (
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              flexWrap: "wrap",
              gap: ".5em",
            }}
          >
            {pairs.map((pair, idx) => (
              <li key={idx}>
                <StyledButton
                  onClick={() =>
                    handlePairDelete(pair.country_name, pair.country_id)
                  }
                >
                  <i
                    className="ph-x-circle"
                    style={{ verticalAlign: "middle" }}
                  />
                  {`  ${pair.country_name},  ${pair.user_name}`}
                </StyledButton>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
          marginInline: "auto",
          marginBlockStart: "2rem",
        }}
      >
        {showPrevButton && (
          <Button
            onClick={() => changeTab("step3")}
            color="primary"
            style={{
              backgroundColor: "#8353E2",
              border: "none",
            }}
          >
            <i className="ph-caret-left" style={{ verticalAlign: "middle" }} />{" "}
            Prev
          </Button>
        )}
        <Button
          color="primary"
          style={{
            backgroundColor: "#8353E2",
            border: "none",
            marginInlineStart: "auto",
          }}
          onClick={proceed}
        >
          Preview{" "}
          <i className="ph-caret-right" style={{ verticalAlign: "middle" }} />
        </Button>
      </div>
    </div>
  )
}

export default StepThree
