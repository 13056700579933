import React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Card, CardBody, Col, Button, Row, Input } from "reactstrap"

const StepTwo = ({
  formType,
  questions,
  setQuestions,
  save,
  changeTab,
  alert,
}) => {
  const questionObject = {
    question_text: "",
    question_type: "text",
    question_weight: 0,
    question_number: 1,
    question_options: [""],
    question_options_weight: [0],
    required: false,
    is_file_upload: false,
  }
  const GRID = 8
  const onDragEnd = data => {
    const tempQuestions = [...questions]
    const fromIndex = data.source.index
    const toIndex = data.destination.index
    const fromQuestion = tempQuestions[fromIndex]
    tempQuestions[fromIndex] = tempQuestions[toIndex]
    tempQuestions[toIndex] = fromQuestion
    const fromQuestionNumber =
      tempQuestions[fromIndex].question_number.toString()
    tempQuestions[fromIndex].question_number =
      tempQuestions[toIndex].question_number
    tempQuestions[toIndex].question_number = parseInt(fromQuestionNumber)
    setQuestions(tempQuestions)
  }
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    // paddingBottom: GRID * 2,
    margin: `0 0 ${GRID * 2}px 0`,
    border: `2px solid #f4dac6`,
    borderRadius: `5px`,
    background: isDragging ? "#e2dede4d" : "",
    ...draggableStyle,
  })

  const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightgreen" : "",
    // padding: GRID,
    width: "100%",
  })
  const questionHandler = (operation, index, key, value) => {
    const tempQuestions = [...questions]
    switch (operation) {
      case "add": {
        const tempQuestionObject = { ...questionObject }
        const count = tempQuestions.length || 0
        tempQuestionObject.question_number = count + 1
        tempQuestions.push(tempQuestionObject)
        break
      }
      case "change": {
        const question = tempQuestions[index]
        question[key] = value
        break
      }
      case "remove": {
        tempQuestions.splice(index, 1)

        for (let i = index; i < tempQuestions.length; i++) {
          --tempQuestions[i].question_number
        }
        break
      }
    }
    setQuestions(tempQuestions)
  }
  const optionsHandler = (operation, index, optionIndex, value) => {
    const tempQuestions = [...questions]
    const question = tempQuestions[index]
    switch (operation) {
      case "add": {
        question.question_options.push("")
        question.question_options_weight.push(0)
        break
      }
      case "change": {
        question.question_options[optionIndex] = value
        break
      }
      case "remove": {
        question.question_options.splice(optionIndex, 1)
        question.question_options_weight.splice(optionIndex, 1)
        break
      }
      case "weight": {
        question.question_options_weight[optionIndex] = parseInt(value)
        question.question_weight = question.question_options_weight.reduce(
          (total, curVal) => total + curVal,
          0
        )
        break
      }
    }
    setQuestions([...tempQuestions])
  }
  const proceed = () => {
    save(2)
    alert("Milestone questions saved in draft", true)
    changeTab("step3")
  }
  return (
    <div
      style={{
        minHeight: "40vh",
        width: "90%",
        marginInline: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBlock: "2rem",
      }}
    >
      <DragDropContext onDragEnd={onDragEnd} style={{ width: "100%" }}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              key={1}
            >
              {questions.map((question, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      key={index}
                    >
                      <Card
                        key={index}
                        className="w-100"
                        style={{
                          width: "250px",
                          margin: "0px",
                        }}
                      >
                        <CardBody className="rounded-1 p-0">
                          <div className=" d-flex">
                            <div
                              className=" w-100 "
                              style={{
                                padding: "1.5rem",
                                paddingRight: "0px",
                              }}
                            >
                              <Row>
                                <Col md="8">
                                  <Input
                                    value={question.question_text}
                                    style={{
                                      borderRadius: 0,
                                      borderWidth: 2,
                                      backgroundColor: "#efefef",
                                    }}
                                    placeholder="Enter the question"
                                    className={`${
                                      question.error && " border-danger"
                                    } border-top-0 border-start-0 border-end-0`}
                                    onChange={e =>
                                      questionHandler(
                                        "change",
                                        index,
                                        "question_text",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {question.question_type == "numeric" && (
                                    <div>
                                      <div
                                        style={{
                                          color: "#aaaaaa",
                                        }}
                                      >
                                        <p>
                                          Rate in the scale of 1-5, please avoid
                                          rating it 3 or neutral
                                        </p>
                                        <p>
                                          Find the link to the policy{" "}
                                          <span className=" text-decoration-underline fst-italic">
                                            here
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  <br />
                                  {question.question_type == "dropdown" && (
                                    <div className="pt-1 ">
                                      {question?.question_options.map(
                                        (option, optionIndex) => {
                                          return (
                                            <div
                                              className="d-flex justify-content-between"
                                              key={optionIndex}
                                            >
                                              <div className="d-flex  w-50 mb-2">
                                                <Input
                                                  value={option}
                                                  onChange={e =>
                                                    optionsHandler(
                                                      "change",
                                                      index,
                                                      optionIndex,
                                                      e.target.value
                                                    )
                                                  }
                                                  className={`${
                                                    question.error &&
                                                    "border-danger"
                                                  } border-top-0 border-start-0 border-end-0`}
                                                  placeholder={`Option ${
                                                    optionIndex + 1
                                                  }`}
                                                  style={{
                                                    borderRadius: 0,
                                                    borderWidth: 2,
                                                    backgroundColor: "#efefef",
                                                  }}
                                                />
                                                <p
                                                  className="text-center mb-0 align-self-center"
                                                  style={{
                                                    color: "#666666",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    optionsHandler(
                                                      "remove",
                                                      index,
                                                      optionIndex
                                                    )
                                                  }
                                                >
                                                  <i className="bx bx-x fa-2x" />
                                                </p>
                                              </div>
                                              <div>
                                                <p className="p-0 m-0">
                                                  Assign Weight
                                                </p>
                                                <Input
                                                  onChange={e =>
                                                    optionsHandler(
                                                      "weight",
                                                      index,
                                                      optionIndex,
                                                      e.target.value
                                                    )
                                                  }
                                                  type="number"
                                                  style={{
                                                    borderRadius: 0,
                                                    borderWidth: 2,
                                                    backgroundColor: "#efefef",
                                                  }}
                                                  className="border-top-0 border-start-0 border-end-0"
                                                  // placeholder="Assign Weight"
                                                  value={
                                                    question
                                                      .question_options_weight[
                                                      optionIndex
                                                    ]
                                                  }
                                                />
                                              </div>
                                            </div>
                                          )
                                        }
                                      )}
                                      <Button
                                        onClick={() => {
                                          optionsHandler("add", index)
                                        }}
                                        color="primary"
                                      >
                                        Add Options
                                      </Button>
                                    </div>
                                  )}
                                </Col>
                                <Col md="2">
                                  <Row>
                                    <select
                                      className="form-control select2"
                                      title="Type"
                                      value={question.question_type}
                                      onChange={e =>
                                        questionHandler(
                                          "change",
                                          index,
                                          "question_type",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="text">Text</option>
                                      <option value="dropdown">Dropdown</option>
                                      <option value="numeric">Numeric</option>
                                    </select>
                                  </Row>

                                  {question.question_type == "numeric" && (
                                    <Row className=" mt-5">
                                      <p className="p-0 m-0">Assign Weight</p>
                                      <Input
                                        onChange={e =>
                                          questionHandler(
                                            "change",
                                            index,
                                            "question_weight",
                                            parseInt(e.target.value)
                                          )
                                        }
                                        type="number"
                                        style={{
                                          borderRadius: 0,
                                          borderWidth: 2,
                                          backgroundColor: "#efefef",
                                        }}
                                        className="border-top-0 border-start-0 border-end-0"
                                        // placeholder="Assign Weight"
                                        value={question.question_weight}
                                      />
                                    </Row>
                                  )}
                                </Col>
                                <Col md="2">
                                  <div className=" form-check form-switch mb-3 align-self-center">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onClick={e =>
                                        questionHandler(
                                          "change",
                                          index,
                                          "required",
                                          e.target.checked
                                        )
                                      }
                                      defaultChecked={question.required}
                                      id={`required${index}`}
                                    />
                                    <label htmlFor={`required${index}`}>
                                      Required
                                    </label>
                                  </div>
                                  {formType === "benchmarking" &&
                                    question.question_type == "dropdown" && (
                                      <div className=" form-check form-switch mb-3 align-self-center">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          onClick={e =>
                                            questionHandler(
                                              "change",
                                              index,
                                              "is_file_upload",
                                              e.target.checked
                                            )
                                          }
                                          defaultChecked={
                                            question.is_file_upload
                                          }
                                          id={`requiresEvidence${index}`}
                                        />
                                        <label
                                          htmlFor={`requiresEvidence${index}`}
                                        >
                                          Requires Evidence
                                        </label>
                                      </div>
                                    )}
                                </Col>
                              </Row>
                            </div>
                            <div
                              className=""
                              style={{
                                width: "9s0px",
                                backgroundColor: "#eeeeee",
                                fontSize: "12px",
                              }}
                            >
                              <button
                                className=" d-block text-center mt-2"
                                style={{ cursor: "copy" }}
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    questions[index].question_text
                                  )
                                }
                              >
                                <i className="ph-copy-simple" />
                                <p>Copy</p>
                              </button>
                              <div
                                className=" d-block text-center"
                                onClick={() => questionHandler("remove", index)}
                              >
                                <i className="bx bx-trash bx-sm" />
                                <p> Delete</p>
                              </div>
                              <div className=" d-block text-center">
                                <i className="bx bx-menu bx-sm" />
                                <p> Description</p>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <br />
      <div className="p-3" style={{ marginBlockEnd: "1rem" }}>
        <button onClick={() => questionHandler("add")}>
          <i
            className="ph-plus-circle"
            style={{
              color: "#EE7D2D",
            }}
          />
          Add question
        </button>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => changeTab("step1")}
          color="primary"
          style={{
            backgroundColor: "#8353E2",
            border: "none",
          }}
        >
          <i className="ph-caret-left" style={{ verticalAlign: "middle" }} />{" "}
          Prev
        </Button>
        <Button
          onClick={proceed}
          color="primary"
          style={{
            backgroundColor: "#8353E2",
            border: "none",
          }}
        >
          Next{" "}
          <i className="ph-caret-right" style={{ verticalAlign: "middle" }} />
        </Button>
      </div>
    </div>
  )
}

export default StepTwo
