import React, {useEffect, useState} from 'react';
import {Button, Input, Modal, ModalBody, ModalHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import { POLICY_NAME_VALID, LABELS, EDIT_POLICY_METADATA, SOFTDELETEPOLICY, LIST_ORGANIZATIONS, COUNTRY_SPECIFIC_LABELS } from 'services/CONSTANTS';
import Select, {components} from "react-select";
import "./UpdatePolicy.css";
import { get, patch, post} from 'services/AXIOS';
import colourStyles from 'components/Common/LabelColorStyles';
import { organizationType, stages } from "constants/global";
import DeleteModal from 'components/Common/DeleteModal';

function UpdatePolicy({policy, updateParent, alert}) {

    const [doesNameExist, setDoesNameExist] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [modalPolicyDetails, setModalPolicyDetails] = useState(false);
    const togglePolicyDetails = () => {
        setModalPolicyDetails(!modalPolicyDetails);
        // if(modalPolicyDetails === true){
        //     const bodyElement = document.getElementsByTagName("body");
        //     // console.log(bodyElement[0].style);
        // }
    }

    const defaultForm = {
        name: '',
        indicators: [],
        year: '',
        owners: [],
        partners: [],
    }
    const [form, setForm] = React.useState(defaultForm);
    const [policyForm, setPolicyForm] = useState(defaultForm);

    const submit = (form) => {
        const indicatorIDs = form.indicators.map((indicator)=>{
            return(
                {label_id: indicator.value}
            )
        });
        const partners = form.partners.map(partner=>({id: partner.value}));
        const owners = form.owners.map(owner=>({id: owner.value}));
        const orgs = partners.concat(owners);

        patch(EDIT_POLICY_METADATA, {
            policy_id: policy.policy_id,
            policy_name: form.name,
            year: form.year,
            labels: JSON.stringify(indicatorIDs),
            //["abab", 'kdjsgjk']
            orgs: JSON.stringify(orgs)
        })
        .then(res=>{
            updateParent();
            alert("Policy metadata updation", true)
        })
        .catch(err=>{
            alert("Policy metadata updation", false);
            console.log(err)
        })
    }

    const [isDisabled, setIsDisabled] = useState("button-disabled");
    const [owners, setOwners] = useState([]);
    const [partners, setPartners] = useState([]);
    const [isNameValid, setIsNameValid] = useState(true);
    // const [isIndicatorValid, setIsIndicatorValid] = useState(true);
    const [isYearValid, setIsYearValid] = useState(true);
    const [isOwnerValid, setIsOwnerValid] = useState(true);
    // const [isPartnerValid, setIsPartnerValid] = useState(true);
    // const [isOwnerLengthValid, setIsOwnerLengthValid] = useState(true);
    const [labels, setLabels] = useState([])

    const loadLabels = () => {
        policy && get(`${COUNTRY_SPECIFIC_LABELS}/${policy?.country.id}`)
          .then(data => setLabels(data))
          .catch(err => {
            console.log(err)
            alert("Data loading", false)
          })
      }

    useEffect(()=>{
        loadLabels();
        const ownersArray = [];
        const partnersArray = [];
        policy?.orgs?.map(org=>{
            if(org.organization_type.id === organizationType.OWNER){
                ownersArray.push({
                    value: org.id,
                    label: org.name
                })
            }else if(org.organization_type.id === organizationType.PARTNER){
                partnersArray.push({
                    value: org.id,
                    label: org.name
                })
            }
        })
        const form = {
            name: policy?.policy_name,
            indicators: policy?.labels.map(label=>{
                return({
                    value: label.label_id, 
                    label: label.label_name, 
                    color: label.label_color
                    }
                )}
            ),
            year: policy?.year,
            owners: ownersArray,
            partners: partnersArray
            // owner: [{
            //     value: policy?.owner?.owner_id, 
            //     label: policy?.owner?.name
            // }],
            // partners: policy?.partners.map((partner) =>{
            //     return {
            //         value: partner.partner_id,
            //         label: partner.name
            //     }
            // }) 
        }
        setPolicyForm(form);
        setForm(form);

        post(LIST_ORGANIZATIONS, {
            countries: [policy?.country?.id],
            organization_type: organizationType.PARTNER
        })
        .then(response=>{
            const partners = response.data.map((partner) =>{
                return {
                    value: partner.id,
                    label: partner.name
                }
            });
            setPartners(partners); 
        })
        .catch(err=>{
            console.log(err)
            alert("Data loading", false)
        })
        
        post(LIST_ORGANIZATIONS, {
            countries: [policy?.country?.id],
            organization_type: organizationType.OWNER
        })
        .then(response=>{
            const owners = response.data.map((owner) => {
                return{
                    value: owner.id, 
                    label: owner.name
                }});
            setOwners(owners); 
        })
        .catch(err=>{
            console.log(err)
            alert("Data loading", false)
        })
    },[policy])

    const handleChange = (event, selectId = "") => {
        
        if(selectId){
            setForm({
                ...form,
                [selectId]: event,
            })
        }
        else{
            if(event.target.id === "name"){
                get(`${POLICY_NAME_VALID}/${event.target.value}/${policy.country.id}`)
                .then(res=>{if(res){
                    setDoesNameExist(true)
                }else{
                    setDoesNameExist(false)
                }
                })
            }
            setForm({
                ...form,
                [event.target.id]: event.target.value,
            });
        }
    };
    
    const handlePartnerChange = (
        newValue
        //actionMeta
      ) => {
        // let allowPass = true;
        // newValue.map((value, i)=>{
        //     if(value.label.length > 25){
        //         setIsPartnerValid(false);
        //         return (allowPass = false);
        //     }               
        // })
        // if(allowPass){
        //     setIsPartnerValid(true);
            setForm({
                ...form,
                partners: newValue.map(value=>{
                    return {
                        value: value.value,
                        label: value.label
                    }
                    }),
                });
        // }
        // console.group('Value Changed');
        // console.log(newValue);
        //console.log(actionMeta);
        // console.groupEnd();
    };

    const handleOwnerChange = (
        newValue
        //actionMeta
      ) => {
        // let allowPass = true;
        // newValue.map((value, i)=>{
        //     if(value.label.length > 25){
        //         setIsOwnerLengthValid(false);
        //         return (allowPass = false);
        //     }               
        // })
        // if(allowPass){
        //     setIsOwnerLengthValid(true);
            setForm({
                ...form,
                owners: newValue.map(value=>{
                    return {
                        value: value.value,
                        label: value.label
                    }
                    }),
                });
        // }
    };

//use set state in hooks synchronously -not possible

    const onUpdate = (e) => {
        e.preventDefault();
        if(!isDisabled){
            submit(form);
            togglePolicyDetails();
        }
        
        //year validations
        (form.year < 3000 && form.year > 1000)?setIsYearValid(true):setIsYearValid(false)
        //policy name in country validation
            //load all policy names along with countries to check
        //policy partner length validation
            //try in react select
        //validations
        !form.name? setIsNameValid(false): setIsNameValid(true)
        // !form.indicators.length?setIsIndicatorValid(false):setIsIndicatorValid(true)
        !form.owners.length? setIsOwnerValid(false): setIsOwnerValid(true)
    }
    const indicatorOptions = labels.map(label=>{
        return({
            value: label.label_id, 
            label: label.label_name, 
            color: label.label_color
            }
        )}
    )
        //alert("Failed to load labels due to network error. Please try again!")
        // alertOnUpdate("Labels", "loading", "Network Error. Please try again!");

    useEffect(()=>{
        if(form.name && form.owners.length && form.year < 3000 && form.year > 1000 &&
            //  isPartnerValid && isOwnerLengthValid && 
             !doesNameExist){
            setIsDisabled("");
        }else{
            setIsDisabled("button-disabled");
        }  
    }, [form, doesNameExist, 
        // isPartnerValid, isOwnerLengthValid
    ]);

    const DropdownIndicator = (props) => {
        const obj = {
            indicators: "ph-pencil-simple",
            stage: "ph-stack" ,
            location: "ph-globe-hemisphere-east" ,
            owners: "ph-pencil-simple",
            partners: "ph-pencil-simple",
        }
        return (
          <components.DropdownIndicator {...props}>
            <i className={obj[props.selectProps.id]}/>
          </components.DropdownIndicator>
        );
    };

    const deletePolicy = () => {
        patch(`${SOFTDELETEPOLICY}/${policy.policy_id}`)
        .then(()=>alert("Policy deletion", true))
        .catch(err=>{
            alert("Policy deletion", false);
            console.log(err);
        })
    }

    const onOpened = () => {
        setForm(policyForm);
        setIsNameValid(true);
        // setIsIndicatorValid(true);
        setIsOwnerValid(true);
        setIsYearValid(true);
    }

    return (
            <div>
                <div
                    style={{
                    color: "#000000",
                    background: "#ffffff",
                    borderRadius: "6px",
                    }}
                    className="d-flex align-items-center"
                >
                    <Dropdown
                        isOpen={dropdownOpen}
                        toggle={()=>setDropdownOpen(!dropdownOpen)}
                    >
                    <DropdownToggle
                        data-toggle="dropdown"
                        tag="span"
                        role={"button"}
                    >
                        <i className="ph-gear-six p-2 fs-4" />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            onClick={togglePolicyDetails}
                            className="d-flex align-items-center"
                        >
                        <i className="ph-pencil-simple me-2"></i>Edit Policy
                        </DropdownItem>
                        <DropdownItem
                            onClick={()=>{setDeleteModal(true)}}
                            className="d-flex align-items-center"
                        >
                        <i className="ph-trash me-2"></i>Delete Policy
                        </DropdownItem>
                    </DropdownMenu>
                    </Dropdown>
                </div>
                <br/>
                <Modal isOpen={modalPolicyDetails} toggle={togglePolicyDetails} onOpened={onOpened}>
                    <ModalHeader toggle={togglePolicyDetails}><div className='policy-edit-header'>Edit Policy Details</div></ModalHeader>
                    <ModalBody>
                        <div>
                            <div className='d-flex'>
                                <div className='w-50 me-2'>
                                    <p>Location</p>
                                    <Input
                                        type="text"
                                        placeholder={policy?.country.label}
                                        rows={1}
                                        id="location"
                                        className='lock-icon'
                                        disabled={true}
                                    />
                                </div>
                                <div className='w-50'>
                                    <p>Stage</p>
                                    <Input
                                        type="text"
                                        placeholder={stages[policy?.stage_id]}
                                        rows={1}
                                        id="stage"
                                        className='lock-icon'
                                        //value={ }
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <br/>
                            {policy?.sub_category_id && 
                            <div>
                                <div className='d-flex'>
                                    <div className='w-50 me-2'>
                                        <p>Category</p>
                                        <Input
                                            type="text"
                                            placeholder={policy?.sub_category?.category?.name}
                                            rows={1}
                                            id="location"
                                            className='lock-icon'
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <p>Subcategory</p>
                                        <Input
                                            type="text"
                                            placeholder={policy?.sub_category?.name}
                                            rows={1}
                                            id="stage"
                                            className='lock-icon'
                                            //value={ }
                                            disabled={true}
                                        />
                                    </div>
                                </div> 
                                <br/>
                            </div>
                            }
                            <div>
                                <p>Policy Name</p>
                                <Input
                                type="text"
                                placeholder="Input text"
                                rows={1}
                                onChange={handleChange}
                                id="name"
                                className='pencil-icon'
                                //value={form.name}
                                defaultValue={policy?.policy_name}
                                // className='is-invalid'
                                />
                                {/*<div className="invalid-feedback">
                                    Please choose a username.
                                </div>*/}
                                { isNameValid? null:  <div className='invalid-input'>Please enter a name.</div> }
                                { doesNameExist? <div className='invalid-input'>A policy with this name exists in the selected country</div> : null}
                            </div>
                            <br/>
                            <div>
                                <p>Systems</p>
                                <div className='w-100'>
                                    <Select
                                        id="indicators"
                                        onChange = {(selectedValues)=>handleChange(selectedValues, "indicators")}
                                        components={{DropdownIndicator}}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={indicatorOptions}
                                        styles={colourStyles} 
                                        placeholder="Select Systems"
                                        defaultValue={policyForm.indicators}
                                    />
                                </div>
                                {/* { isIndicatorValid? null:  <div className='invalid-input'>Please select atleast 1 system.</div> } */}
                            </div>  
                            <br/>
                            <div>
                                <p>Policy Owner</p>
                                <div className='w-100'>
                                    <Select
                                        isMulti
                                        isClearable
                                        id="owners"
                                        components={{DropdownIndicator}}
                                        onChange={handleOwnerChange}
                                        options={owners}
                                        defaultValue={policyForm.owners}
                                    />
                                </div> 
                                { isOwnerValid? null:  <div className='invalid-input'>Please select atleast 1 owner.</div> }
                                {/* { isOwnerLengthValid? null:  <div className='invalid-input'>Owner name should be less than 25 characters.</div> } */}
                            </div>
                            <br/>
                            <div>
                                <p>Policy Partners</p>
                                <div className='w-100'>
                                    <Select
                                        isMulti
                                        id="partners"
                                        components={{DropdownIndicator}}
                                        onChange={handlePartnerChange}
                                        options={partners}
                                        //icons and border color
                                        defaultValue={policyForm.partners}
                                    />
                                </div>
                                {/* { isPartnerValid? null:  <div className='invalid-input'>Partner name should be less than 25 characters.</div> } */}
                            </div>
                            <br/>
                            <div>
                                <p>Policy Year</p>
                                <Input
                                type="text"
                                placeholder="YYYY"
                                rows={1}
                                onChange={handleChange}
                                maxLength={4}
                                id="year"
                                className='pencil-icon'
                                defaultValue={policy?.year}
                                />
                                { isYearValid? null:  <div className='invalid-input'>Please enter a valid year</div> }
                            </div> 
                            <br/>
                            <div className='d-flex justify-content-center'>
                                <Button className={`common-button ${isDisabled}`} onClick={(e)=>onUpdate(e)}>
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <DeleteModal show = {deleteModal} onCloseClick = {()=>setDeleteModal(false)} onDeleteClick = {deletePolicy}/>
        </div>
    );
}

export default UpdatePolicy;