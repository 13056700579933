import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import "./policylandscapping.scss"
import Step2 from "./Step 2"
import Step1 from "./Step1/Step1"
import MindMap from "./MindMap/MindMap"
import { get } from "services/AXIOS"
import { STAGE } from "services/CONSTANTS"
import AccessDenied from "components/Common/Alerts/Access-Denied"
// import { setCountryFlag } from "store/actions"
// import Step4 from "./Step4"
// import Step5 from "./Step5"

function PolicyLandscaping(props) {

  const [stages, setStages] = useState([]);

  useEffect(()=>{
    get(STAGE)
    .then(data=>{
      const stages = data?.filter(data=>{
        return data.id > 6 && data.id < 10; //change to type === landscaping
      })
      setStages(stages)
      setActiveTab({ ...activeTab, stage: stages[0] })
    })
  },[])
  
  const { landscaping_stages } = useSelector(state => state.list)
  const [landscapingStagesSet, setLandscapingStagesSet] = useState();

  useEffect(()=>{
    const landscapingStagesSet = new Set(landscaping_stages.map(stage => stage.id));
    setLandscapingStagesSet(landscapingStagesSet);
  },[landscaping_stages])

  const isLocked = (id) => {
    return !landscapingStagesSet?.has(id)
  }

  const [activeTab, setActiveTab] = useState({
    id: "1",
    stage: null,
  })

  const toggleTab = (tab, stage) => {
    const tempActiveTab = { ...activeTab }
    tempActiveTab.id = tab
    tempActiveTab.stage = stage
    setActiveTab(tempActiveTab)
  }

  // const dispatch = useDispatch()

  // useEffect(() => {
  //   loadCountry()
  // }, [])

  // const loadCountry = () => {
  //   localStorage.setItem("COUNTRY", "GH")
  //   dispatch(setCountryFlag("GH"))
  // }

  const { country } = useSelector(state => state.Profile)
  const { country_id } = useSelector(state => state.Profile)

  return (
    <div
      className={`page-content e-tracker`}
      style={{
        marginTop: "0",
      }}
    >
      <div
        className=" d-flex justify-content-between p-0"
        style={{
          height: "60px",
          // position: "absolute",
          top: "70px",
          width: "fit",
        }}
      >
        {stages?.map((node, index) => {
          return (
            <div
              key={index}
              className={`w-100 text-center d-flex justify-content-center align-items-center
                ${activeTab.id == index + 1 ? "" : "mt-2"}`}
              style={{
                cursor: "pointer",
                backgroundColor: node.backgroundColor,
                color: node.color,
                borderRadius: "10px 10px 0px 0px ",
              }}
              onClick={() => {
                toggleTab(node.stage.toString(), node)
              }}
            >
              <i className={`me-1 ${node.icon}`}></i>
              <p className="mb-0" style={{ letterSpacing: "1px" }}>
                {node.label}
              </p>
              {isLocked(node.id) && <i className="ms-2 ph-lock"></i>}
            </div>
          )
        })}
      </div>
      <div>
        {isLocked(activeTab.stage?.id) ? <AccessDenied/> : 
          <div
            style={{
              backgroundColor: activeTab?.stage?.backgroundColor,
              padding: "50px 10px 10px 10px",
            }}
          >
            {activeTab.id == "1" && (
              <Step1 country={country} countryId={country_id} alert={props.alert} />
            )}
            {activeTab.id == "2" && <Step2 country={country} alert={props.alert} />}
            {activeTab.id == "3" && (
              <MindMap country={country} alert={props.alert} />
            )}
          </div>
        }
      </div>
    </div>
  )
}

export default PolicyLandscaping
