import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"
import DefaultTableContainer from "../../../components/Common/DefaultTableContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
} from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"
import { motion, AnimatePresence } from "framer-motion"

import { Name, Date, Boolean, Countries, Tags } from "./accesscodelistCol"

import { useSelector } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import ErrorModal from "components/Common/ErrorModal"
import SuccessModal from "components/Common/SuccessModal"

import {
  Country,
  Role,
  Permission,
  LANDSCAPING_STAGES,
  ETRACKER_STAGES,
  BENCHMARKING_STAGES,
} from "constants/global"

import {
  getAccessCodes,
  addNewAccessCode,
  deleteAccessCode,
  updateAccessCode,
  listOrganizationTypes,
  listOrganizations,
} from "services"
import { isValid } from "redux-form"
import { listOrganizationSpecificCountries } from "services/organizationServices"
import { useQuery } from "@tanstack/react-query"

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}
const AccessCodesList = props => {
  //meta title

  const loadOrganizationSpecificCountries = async org_id => {
    try {
      const { data } = await listOrganizationSpecificCountries(org_id)
      return data?.countries?.map(country => ({
        label: country?.label,
        value: country?.id,
      }))
    } catch (err) {
      return err
    }
  }
  const [accessCode, setAccessCode] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [code, setCode] = useState()
  const [accessCodeList, setAccessCodeList] = useState([])
  const [modal, setModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [errorModal, setErrorModal] = useState("")
  const [successModal, setSuccessModal] = useState("")
  const [filterCountries, setFilterCountries] = useState([])

  const { data: organizationSpecificCountries } = useQuery({
    queryKey: [
      "Organization Specific Countries",
      { id: accessCode?.org?.value },
    ],
    queryFn: () => loadOrganizationSpecificCountries(accessCode?.org.value),
    enabled: !!(accessCode && !!accessCode?.org?.value),
    staleTime: 2 * 60 * 1_000,
  })
  const {
    countries,
    stages,
    landscaping_stages,
    tracker_stages,
    benchmarking_stages,
  } = useSelector(state => state.list)

  const { country } = useSelector(state => state.Profile)

  //email limit state management
  const [numOfEmailsLeft, setNumOfEmailsLeft] = useState(20)

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const stageOptions = [
    {
      label: "E-Tracker",
      options: ETRACKER_STAGES.map(stage => ({
        value: Number(stage.id),
        label: stage.name,
      })),
    },
    {
      label: "Landscaping",
      options: LANDSCAPING_STAGES.map(stage => ({
        value: stage.id,
        label: stage.name,
      })),
    },
    {
      label: "Benchmarking",
      options: BENCHMARKING_STAGES.map(stage => ({
        value: stage.id,
        label: stage.name,
      })),
    },
  ]
  const groupHeadingStyles = {
    color: "blue",
    fontWeight: "bold",
    letterSpacing: 1.125,
  }
  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      paddingRight: "30px",
      position: "relative",
    }),
    icon: {
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
    },
  }
  const validate = values => {
    const errors = {}

    if (!values.countries.length) {
      errors.countries = "Please select at least 1 country"
    }

    if (!values.emails.length) {
      errors.emails = "Please enter emails"
    }

    if (!values.role.hasOwnProperty("value")) {
      errors.role = "Please select role"
    } else if (values.role.value == "user" && !values.stages.length) {
      errors.stages = "Please select at least 1 stage"
    }

    if (!values.orgType.hasOwnProperty("value")) {
      errors.orgType = "Please select an Org type."
    }
    if (!values.org.hasOwnProperty("value")) {
      errors.org = "Please select an Organization."
    }
    return errors
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      countries: (accessCode && accessCode.countries) || [],
      role: (accessCode && accessCode.role) || "",
      emails: (accessCode && accessCode.emails) || "",
      stages: (accessCode && accessCode.stages) || [],
      orgType: (accessCode && accessCode.orgType) || "",
      org: (accessCode && accessCode.org) || "",
    },
    validate,
    // validationSchema: Yup.object({
    //   countries: Yup.array()
    //     .min(1, "Pick at least 1 country")
    //     .required("Please Select Country"),
    //   role: Yup.object().required("Please Select Role"),
    //   emails: Yup.string().required("Please Enter Email"),
    //   stages: Yup.array().when("role", {
    //     is: role => role && "value" in role && role.value == "user",
    //     then: Yup.array()
    //       .min(1, "Please select at least one stage")
    //       .required("Please Select Stages"),
    //     otherwise: Yup.array().required("Please Select First Role"),
    //   }),
    // }),
    onSubmit: values => {
      if (isEdit) {
        const accessCodeData = {
          countries: values["countries"].map(country => country.value),
          users: values["emails"].replace(/\s/g, "").split(","),
          role: values["role"].value,
          stages: values["stages"].map(stage => stage.value),
          orgType: values["orgType"].value,
          org: values["org"].value,
        }
        updateAccessCode(accessCode.id, accessCodeData)
          .then(res => {
            //setCode(res.data.code)
            setRefresh(!refresh)
            validation.resetForm()
            toggle()
            //setSuccessModal(res.message)
            props.alert(res.message, true)
          })
          .catch(err => {
            //setErrorModal(err.response.data.message)
            props.alert(err.response.data.message, false)
          })
        setIsEdit(false)
      } else {
        const newAccessCode = {
          countries: values["countries"].map(country => country.value),
          users: values["emails"].replace(/\s/g, "").split(","),
          role: values["role"].value,
          stages: values["stages"].map(stage => Number(stage.value)),
          organization_id: values["org"].value,
        }
        addNewAccessCode(newAccessCode)
          .then(res => {
            setCode(res.data.code)
            validation.resetForm()
            toggle()
            //setSuccessModal(`${res.message} \n Access Code: ${res.data.code}`)
            //props.alert(`${res.message} \n Access Code: ${res.data.code}. It `, true)
            setShowConfirmationModal(true)
          })
          .catch(err => {
            // setErrorModal(err.response.data.message)
            props.alert(err.response.data.message, false)
          })
      }
    },
  })

  useEffect(() => {
    if (validation.values.emails === "") {
      setNumOfEmailsLeft(20)
    } else {
      const numOfEmailsAdded = validation.values.emails.split(",").length - 1
      setNumOfEmailsLeft(20 - numOfEmailsAdded)
    }
  }, [validation.values.emails])

  const columns = useMemo(
    () => [
      // {
      //   Header: "ID",
      //   accessor: "id",
      //   disableGlobalFilter: true,
      //   disableFilters: true,
      // },
      {
        Header: "Access Code",
        accessor: "code",
        disableSortBy: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        disableSortBy: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Role",
        accessor: "role",
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: cellProps => {
          return Role[cellProps.row.original.role]
        },
      },
      {
        Header: "Organization",
        accessor: "organization.name",
        disableSortBy: true,
        disableGlobalFilter: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Location",
        accessor: "countries",
        disableSortBy: true,
        disableGlobalFilter: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Tags {...cellProps} />
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableGlobalFilter: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Joined At",
        accessor: "joined_at",
        disableGlobalFilter: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableSortBy: true,
        disableFilters: true,
        disableGlobalFilter: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/access-code-detail/${cellProps.row.original.id}`}
                className="text-success"
              >
                <i
                  className="mdi mdi-open-in-app font-size-18"
                  id="viewtooltip"
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              {!cellProps.row.original.joined_at && (
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const accessCodeData = cellProps.row.original
                    handleAccessCodeClick(accessCodeData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              )}
              {!cellProps.row.original.joined_at && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const accessCodeData = cellProps.row.original
                    onClickDelete(accessCodeData)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  // useEffect(
  //   function () {
  //     if (country) {
  //       let params = {}
  //       params = { country: country }
  //       getAccessCodes(params)
  //         .then(res => {
  //           setAccessCodeList(res.data)
  //         })
  //         .catch(err => setAccessCodeList([]))
  //     }
  //   },
  //   [country, code, refresh]
  // )

  useEffect(
    function () {
      setIsLoading(true)
      let params = {}
      params = { country: [] }
      getAccessCodes(params)
        .then(res => {
          setAccessCodeList(res.data)
        })
        .catch(err => setAccessCodeList([]))
        .finally(setIsLoading(false))
    },
    [country, code, refresh]
  )

  useEffect(
    function () {
      if (filterCountries.length > 0) {
        setIsLoading(true)
        let params = {}
        params = { country: filterCountries.map(data => data.value) }
        getAccessCodes(params)
          .then(res => {
            setAccessCodeList(res.data)
          })
          .catch(err => setAccessCodeList([]))
          .finally(setIsLoading(false))
      }
    },
    [filterCountries]
  )

  const toggle = () => {
    setModal(!modal)
  }

  const handleAccessCodeClick = arg => {
    const access_code = arg
    setAccessCode({
      id: access_code.id,
      countries: access_code.countries.map(data => ({
        label: data.label,
        value: data.id,
      })),
      role: {
        label: Role[access_code.role],
        value: access_code.role,
      },
      emails: access_code.email,
      stages: access_code.stages.map(data => ({
        label: data.label,
        value: data.id,
      })),
      org: {
        label: access_code.organization.name,
        value: access_code.organization.id,
      },
      orgType: {
        label: access_code.organization.organization_type.name,
        value: access_code.organization.organization_type.id,
      },
      countryOptionsThatAdminHasAccessTo: organizationSpecificCountries
        ?.filter(country =>
          countries.some(adminCountry => adminCountry.id === country.id)
        )
        .map(data => ({
          label: data.label,
          value: data.id,
        })),
    })
    setIsEdit(true)

    toggle()
  }

  const handleAccessCodeClicks = () => {
    setAccessCode("")
    setIsEdit(false)
    toggle()
  }

  //delete access code
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = access_code => {
    setAccessCode(access_code)
    setDeleteModal(true)
  }

  const handleDeleteAccessCode = () => {
    deleteAccessCode(accessCode.id)
      .then(res => {
        setRefresh(!refresh)
        props.alert(`Access Code Deleted`, true)
      })
      .catch(err => {
        props.alert("Access Code Not Deleted", false)
      })
    //  setDeleteModal(false)
  }

  const onRoleChange = selectedOption => {
    if (selectedOption.value != "user") validation.values.stages = []
  }

  const keyField = "id"

  //fetching orgTypes and orgs
  const [orgTypes, setOrgTypes] = useState([])
  const [orgs, setOrgs] = useState([])

  useEffect(() => {
    const fetchOrgTypes = async () => {
      // const countryCodes = validation.values.countries.map(
      //   country => country.value
      // )
      try {
        const { data } = await listOrganizationTypes({})
        setOrgTypes(data)
      } catch (err) {
        console.error(err)
      }
    }

    fetchOrgTypes()
  }, [])

  useEffect(() => {
    const fetchOrganizations = async () => {
      const countryCodes = validation.values.countries.map(
        country => country.value
      )
      try {
        const { data } = await listOrganizations({
          countries: countryCodes,
          organization_type: validation.values.orgType.value,
        })
        setOrgs(data)
      } catch (err) {
        console.error(err)
      }
    }
    if (
      validation.values.countries.length > 0 &&
      validation.values.orgType.value
    ) {
      fetchOrganizations()
    } else {
      validation.values.org = ""
      setOrgs([])
    }
  }, [validation.values.orgType.value])

  return (
    <React.Fragment>
      <Modal
        isOpen={showConfirmationModal}
        toggle={
          showConfirmationModal
            ? () => {
                setShowConfirmationModal(false)
              }
            : () => {
                setShowConfirmationModal(true)
              }
        }
        style={{ borderRadius: "0.25rem", maxHeight: "500px" }}
      >
        <ModalHeader
          style={{
            alignItems: "baseline",
          }}
          toggle={
            showConfirmationModal
              ? () => {
                  setShowConfirmationModal(false)
                }
              : () => {
                  setShowConfirmationModal(true)
                }
          }
        >
          <b>
            <p
              style={{
                fontSize: "32px",
                marginBottom: "0px",
                color: "black",
              }}
            >
              Access Code Created Successfully!
            </p>
          </b>
        </ModalHeader>
        <ModalBody>
          Access created and email sent! 🚀 It may take a few minutes for the
          email to arrive.
        </ModalBody>
      </Modal>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAccessCode}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Access Code" : "Add Access Code"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return "false"
            }}
          >
            <Row>
              <Col xs={12}>
                {validation.errors &&
                  Object.keys(validation.errors).length > 0 && (
                    <Alert color="danger">
                      {validation.errors.emails ||
                        validation.errors.role ||
                        validation.errors.countries ||
                        validation.errors.stages}
                    </Alert>
                  )}

                <div className="mb-3">
                  <Label className="form-label">Location</Label>
                  <Select
                    name="countries"
                    options={
                      !!isEdit
                        ? organizationSpecificCountries?.filter(country =>
                            countries.some(
                              adminCountry => adminCountry.id === country.value
                            )
                          )
                        : countries.map(val => ({
                            label: val.label,
                            value: val.id,
                          }))
                    }
                    isMulti={true}
                    classNamePrefix="select2-selection"
                    value={validation.values.countries || []}
                    onChange={selectedOption => {
                      let event = {
                        target: {
                          name: "countries",
                          value: selectedOption,
                        },
                      }
                      validation.handleChange(event)
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label className="form-label">Emails</Label>
                  <Input
                    name="emails"
                    label="Emails"
                    placeholder="Please enter email addresses separated by commas(,)"
                    type="textarea"
                    disabled={!!isEdit ? true : false}
                    className={!!isEdit && "lock-icon"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.emails || ""}
                    invalid={
                      validation.touched.emails && validation.errors.emails
                        ? true
                        : false
                    }
                  />
                  {!isEdit && (
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "end",
                        marginBlockEnd: 0,
                      }}
                    >
                      You can add {numOfEmailsLeft} more emails.
                    </p>
                  )}
                  {validation.touched.emails && validation.errors.emails ? (
                    <FormFeedback type="invalid">
                      {validation.errors.emails}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Role</Label>
                  <Select
                    name="role"
                    options={Object.entries(Role)
                      .filter(val => val[0] !== "admin")
                      .map(val => ({
                        label: val[1],
                        value: val[0],
                      }))}
                    value={validation.values.role || ""}
                    invalid={
                      validation.touched.role && validation.errors.role
                        ? true
                        : false
                    }
                    onChange={selectedOption => {
                      let event = {
                        target: {
                          name: "role",
                          value: selectedOption,
                        },
                      }
                      validation.handleChange(event)
                      onRoleChange(selectedOption)
                    }}
                    isDisabled={!!isEdit}
                  />
                </div>

                <AnimatePresence mode="wait">
                  {validation.values.role.value == "user" && (
                    <motion.div
                      className="mb-3"
                      key="stages options"
                      variants={variants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition={{ duration: 0.25 }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Stages</Label>
                        <Select
                          name="stages"
                          options={stageOptions}
                          isMulti={true}
                          classNamePrefix="select2-selection"
                          value={stageOptions.reduce((acc, group) => {
                            const matchingOptions = group.options.filter(
                              option =>
                                validation.values.stages
                                  .map(
                                    preselectedOption => preselectedOption.value
                                  )
                                  .includes(option.value)
                            )
                            return [...acc, ...matchingOptions]
                          }, [])}
                          onChange={selectedOption => {
                            let event = {
                              target: {
                                name: "stages",
                                value: selectedOption,
                              },
                            }
                            validation.handleChange(event)
                          }}
                          styles={{
                            groupHeading: base => ({
                              ...base,
                              ...groupHeadingStyles,
                            }),
                          }}
                        />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                <div className="mb-3">
                  <Label className="form-label">Organization Type:</Label>
                  <Select
                    name="orgType"
                    value={validation.values.orgType}
                    options={orgTypes.map(val => ({
                      label: val.name,
                      value: val.id,
                    }))}
                    invalid={
                      validation.touched.orgType && validation.errors.orgType
                        ? true
                        : false
                    }
                    onChange={selectedOption => {
                      const event = {
                        target: {
                          name: "orgType",
                          value: selectedOption,
                        },
                      }
                      validation.handleChange(event)
                      validation.values.org = ""
                    }}
                    isDisabled={!!isEdit}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Organization:</Label>
                  <Select
                    name="org"
                    options={orgs.map(val => ({
                      label: val.name,
                      value: val.id,
                    }))}
                    value={validation.values.org || ""}
                    onChange={selectedOption => {
                      const event = {
                        target: {
                          name: "org",
                          value: selectedOption,
                        },
                      }
                      validation.handleChange(event)
                    }}
                    isDisabled={!!isEdit}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/*
      <ErrorModal show={errorModal} onCloseClick={() => setErrorModal("")} />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal("")}
      /> */}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Access Codes" breadcrumbItem="Access Code List" /> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DefaultTableContainer
                    columns={columns}
                    data={accessCodeList}
                    isLoading={isLoading}
                    isGlobalFilter={true}
                    isAddAccessCodeList={true}
                    isExport={"access-code"}
                    handleAccessCodeClick={handleAccessCodeClicks}
                    customPageSize={10}
                    setFilterCountries={setFilterCountries}
                    customSort={{ id: "created_at", desc: true }}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AccessCodesList)
