import React, { Fragment } from "react"
import * as RadixTabs from "@radix-ui/react-tabs"
import styled from "@emotion/styled"

const TabsRoot = styled(RadixTabs.Root)`
  display: grid;
`
export default function Tabs({ children, defaultValue = "tab1" }) {
  return <TabsRoot defaultValue={defaultValue}>{children}</TabsRoot>
}

const TabsList = styled(RadixTabs.List)`
  flex-shrink: 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  border-bottom: 1px solid hsla(0deg 0% 0%/0.3);
`
function TabTriggers({ children, ariaLabel }) {
  return <TabsList aria-label={ariaLabel}>{children}</TabsList>
}

Tabs.Triggers = TabTriggers

const TabsTrigger = styled(RadixTabs.Trigger)`
  all: unset;
  cursor: pointer;
  padding: 0 0.8em;
  height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  user-select: none;
  transition: all 250ms;
  display: flex;
  min-width: max-content;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  &:first-of-type {
    border-top-left-radius: 6px;
  }
  &:last-child {
    border-top-right-radius: 6px;
  }
  &:hover {
    color: #4069e5;
  }
  &[data-state="active"] {
    box-shadow: inset 0 -1px 0 0 #4069e5, 0 1px 0 0 #4069e5;
  }
  &:focus-visible {
    position: relative;
    box-shadow: 0 0 0 2px black;
  }
`

function TabTriggerButton({ value, children, leading }) {
  return (
    <TabsTrigger asChild value={value}>
      <button>
        {leading && <i className={leading} />}
        <span>{children}</span>
      </button>
    </TabsTrigger>
  )
}

Tabs.TriggerButton = TabTriggerButton

const StyledTabContent = styled(RadixTabs.Content)`
  padding-block-start: 2rem;
`
function TabContent({ value, children }) {
  return <StyledTabContent value={value}>{children}</StyledTabContent>
}

Tabs.TabContent = TabContent
