import { formateOnlyDate } from "helpers/utlities"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

export const generatePDF = data => {
    const doc = new jsPDF("portrait", "mm")

    const head = []
    let body = []

    const makeTable = () => {
      const headValues = [
        "System",
        "Benchmarking Milestone",
        "Status",
        "Score",
        "Task Owner",
        "Temporary Owner",
        "Updated At",
      ]

      headValues.map(value => head.push(value))

      body = data.map(data => {

        const row = [
            data.label_name,
            data.form_name,
            data.form_status || "PENDING",
            data.score,
            data.owner_name,
            data.task_owner_first_name ? (data.task_owner_first_name + data.task_owner_last_name) : "None",
            data.updated_at && formateOnlyDate(data.updated_at)
        ]
        return row
      })
    }

    makeTable()

    autoTable(doc, {
      head: [head],
      body: body,
    })

    doc.save("Benchmarking" + ".pdf")
  }