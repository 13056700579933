import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Form,
  FormFeedback,
} from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import useDebounce from "hooks/useDebounce"
import {
  createNewOrganizationType,
  getOrganizationTypes,
  editSpecificOrgType,
} from "services/organizationServices"
import { CustomTable } from "./Organization-Management"
import Pagination from "components/Common/Pagination"
import { StyledContainer } from "../Labels/Systems"
import SearchBox from "components/Common/SearchBox"
import Select from "react-select"
import LoadingSpinner from "components/Common/Loading-Spinner"
import ExportTable from "components/Common/ExportTable"
import { orgTypesCSV, orgTypesPDF } from "./export"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Alert } from "reactstrap"

const OrganizationTypeManagement = ({ alert }) => {
  //fetch OrgTypes
  const { user } = useSelector(state => state.Profile)

  const loadOrganizationTypes = async (query, body) => {
    try {
      const { data } = await getOrganizationTypes(query, body)
      setTotalEntries(data?.typesCount)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }

  //pagination
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const handlePageChange = pageNum => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }

  //search
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)

  const handleSearchInputChange = evt => {
    setSearchTerm(evt.target.value)
    setOffset(0)
    setCurrentPage(1)
    setTotalEntries(data?.typesCount)
  }
  //sort
  const [sortBy, setSortBy] = useState("updatedAtDESC")
  const sortOptions = [
    {
      value: "updatedAtDESC",
      label: "Newest",
    },
    {
      value: "updatedAtASC",
      label: "Oldest",
    },
    {
      value: "nameASC",
      label: "A-Z",
    },
    {
      value: "nameDESC",
      label: "Z-A",
    },
  ]
  const sortMap = new Map([
    ["updatedAtDESC", { updated_at: "DESC" }],
    ["updatedAtASC", { updated_at: "ASC" }],
    ["nameDESC", { name: "DESC" }],
    ["nameASC", { name: "ASC" }],
  ])

  const { data, refetch, isLoading } = useQuery({
    queryKey: [
      "Organization Types",
      { debouncedSearchTerm, sortBy, page: currentPage },
    ],
    queryFn: () =>
      debouncedSearchTerm.length > 0
        ? loadOrganizationTypes(
            {
              searchTerm: debouncedSearchTerm,
              limit: 50,
              offset,
            },
            {
              sortForm: sortMap.get(sortBy),
            }
          )
        : loadOrganizationTypes(
            { limit: 50, offset },
            {
              sortForm: sortMap.get(sortBy),
            }
          ),
  })

  //state for Inputs
  const [userInput, setUserInput] = useState("")
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [editingRowID, setEditingRowID] = useState(null)
  const [orgTypeAlreadyExistsError, setOrgTypeAlreadyExistsError] =
    useState(false)
  const handleInputChange = evt => {
    setUserInput(evt.target.value)
    setOrgTypeAlreadyExistsError(false)
  }
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState("")
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userInput,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[a-zA-Z]*$/, "Please enter only alphabetical characters.")
        .required("Please enter a Org Type Name"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setError("")
      if (isUserEditing) {
        try {
          const { data } = await editSpecificOrgType(editingRowID, {
            name: values.name,
          })
          resetForm()
          closeModal()
          alert("Changes saved", true)
        } catch (err) {
          setError(err.response.data.message)
          console.error(err)
        }
      } else {
        try {
          const { data } = await createNewOrganizationType({
            name: values.name,
          })
          resetForm()
          closeModal()
          alert("New organization Type successfully created!", true)
        } catch (err) {
          setError(err.response.data.message)
          if (err.response.data.statusCode === 409)
            setOrgTypeAlreadyExistsError(true)
          console.error({ err })
        }
      }
      refetch()
    },
  })
  const openModal = () => {
    validation.resetForm()
    setShowModal(true)
    if (!isUserEditing) {
      setUserInput("")
      setOrgTypeAlreadyExistsError(false)
    }
  }
  const closeModal = () => {
    validation.resetForm()
    setShowModal(false)
    setIsUserEditing(false)
    setUserInput("")
    setOrgTypeAlreadyExistsError(false)
  }
  const initiateEdit = id => {
    setShowModal(true)
    setIsUserEditing(true)
    setEditingRowID(data.types.find(row => row.id === id).id)
    setUserInput(data.types.find(row => row.id === id).name)
  }

  const exportTable = async type => {
    try {
      const { data } = await getOrganizationTypes(
        {
          searchTerm: debouncedSearchTerm,
          limit: 50,
          offset,
        },
        {
          sortForm: sortMap.get(sortBy),
        }
      )
      type === "pdf" ? orgTypesPDF(data.types) : orgTypesCSV(data.types)
    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }
  return (
    <div style={{ marginBlockEnd: "3rem" }}>
      <div className="d-flex justify-content-between">
        <StyledContainer>
          <SearchBox searchChange={handleSearchInputChange} />
          <Select
            options={sortOptions}
            defaultValue={sortBy}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Sort by"
            onChange={option => setSortBy(option.value)}
          />
        </StyledContainer>
        <div className="d-flex">
          <Button
            className="common-button"
            onClick={showModal ? closeModal : openModal}
            style={{ marginInline: 0 }}
          >
            Create New
          </Button>
          <ExportTable
            loadFilteredData={exportTable}
            component={"SeperateFn"}
          />
        </div>
        <Modal
          isOpen={showModal}
          toggle={showModal ? closeModal : openModal}
          style={{ borderRadius: "0.25rem", maxHeight: "500px" }}
        >
          <ModalHeader
            style={{
              alignItems: "flex-start",
              height: "80px",
              paddingBottom: "0px",
            }}
            toggle={showModal ? closeModal : openModal}
          >
            <b>
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                {isUserEditing ? (
                  <>
                    {" "}
                    <i className="mdi mdi-pencil me-2"></i>
                    Edit Organization Type
                  </>
                ) : (
                  <>
                    {" "}
                    <i className="mdi mdi-plus me-2"></i>
                    Create New Organization Type
                  </>
                )}
              </p>
            </b>
          </ModalHeader>
          <ModalBody>
            {error.length > 0 ? (
              <Alert color="danger">
                <p>{error}</p>
              </Alert>
            ) : null}
            <Form
              onSubmit={evt => {
                evt.preventDefault()
                validation.handleSubmit()
              }}
            >
              <div className="mb-3">
                <label htmlFor="name">Name</label>
                <Input
                  id="name"
                  type="text"
                  placeholder="Enter new organization type"
                  value={validation.values.name}
                  onChange={validation.handleChange}
                />
              </div>
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid" style={{ display: "block" }}>
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
              {orgTypeAlreadyExistsError && (
                <p style={{ color: "red" }}>
                  An organization type by this name already exits
                </p>
              )}
              <ModalFooter
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderTop: "none",
                  paddingTop: "0px",
                }}
              >
                <div>
                  <Button
                    style={{ backgroundColor: "#50A050", border: "none" }}
                    type="submit"
                  >
                    {isUserEditing ? "Save" : "Create"}
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
      </div>
      {isLoading && <LoadingSpinner />}
      {data?.types.length > 0 && !isLoading ? (
        <>
          <CustomTable
            data={data.types}
            initiateEdit={initiateEdit}
            caption="Organization Types"
            user={user}
          />
          <Pagination
            currentPage={currentPage}
            goToPage={handlePageChange}
            totalEntries={totalEntries}
          />
        </>
      ) : null}
    </div>
  )
}

export default OrganizationTypeManagement
