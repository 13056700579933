import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Form,
  FormFeedback,
} from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import {
  getSystemTypes,
  editSpecificSystemType,
  createNewSystemType,
} from "services/indicatorServices"
import styled from "@emotion/styled"
import * as HoverCard from "@radix-ui/react-hover-card"
import SearchBox from "components/Common/SearchBox"
import Select from "react-select"
import Pagination from "components/Common/Pagination"
import useDebounce from "hooks/useDebounce"
import { StyledContainer } from "./Systems"
import LoadingSpinner from "components/Common/Loading-Spinner"
import ExportTable from "components/Common/ExportTable"
import { DELETE_LABEL_TYPE } from "services/CONSTANTS"
import { patch } from "services/AXIOS"
import { SystemsTable } from "./SystemsTable"
import { getSystemTypesCSV, getSystemTypesPDF } from "./export"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Alert } from "reactstrap"

const StyledModalBody = styled(ModalBody)`
  & fieldset {
    & legend {
      font-size: 1rem;
    }
    & .options {
      display: flex;
      gap: 1em;
    }
    & #pink {
      --system-type-color: #dc267f;
    }
    & #teal {
      --system-type-color: #30d5c8;
    }
    & #violet {
      --system-type-color: #785ef0;
    }
    & #orange {
      --system-type-color: #fe6100;
    }
    & #yellow {
      --system-type-color: #ffb000;
    }

    & input[type="radio"] {
      appearance: none;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background-color: var(--system-type-color);
      cursor: pointer;

      &:checked {
        outline: 2px solid var(--system-type-color);
        outline-offset: 3px;
      }
    }
  }
`
export default function SystemTypes({ alert }) {
  const loadSystemTypes = async (query, body) => {
    try {
      const { data } = await getSystemTypes(query, body)
      setTotalEntries(data?.typesCount)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }
  //pagination
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const handlePageChange = pageNum => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }
  //search
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)

  //sort
  const [sortBy, setSortBy] = useState("updatedAtDESC")
  const sortOptions = [
    {
      value: "updatedAtDESC",
      label: "Newest",
    },
    {
      value: "updatedAtASC",
      label: "Oldest",
    },
    {
      value: "nameASC",
      label: "A-Z",
    },
    {
      value: "nameDESC",
      label: "Z-A",
    },
  ]
  const sortMap = new Map([
    ["updatedAtDESC", { updated_at: "DESC" }],
    ["updatedAtASC", { updated_at: "ASC" }],
    ["nameDESC", { name: "DESC" }],
    ["nameASC", { name: "ASC" }],
  ])
  const { data, refetch, isLoading } = useQuery({
    queryKey: [
      "System Types",
      { debouncedSearchTerm, sortBy, page: currentPage },
    ],
    queryFn: () =>
      debouncedSearchTerm.length > 0
        ? loadSystemTypes(
            {
              searchTerm: debouncedSearchTerm,
              limit: 50,
              offset,
            },
            {
              sortForm: sortMap.get(sortBy),
            }
          )
        : loadSystemTypes(
            { limit: 50, offset },
            {
              sortForm: sortMap.get(sortBy),
            }
          ),
    staleTime: 60 * 1_000,
  })

  const handleSearchInputChange = evt => {
    setSearchTerm(evt.target.value)
    setOffset(0)
    setCurrentPage(1)
    setTotalEntries(data?.typesCount)
  }
  const [userInput, setUserInput] = useState("")
  const [selectedColor, setSelectedColor] = useState("")
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [editingRowID, setEditingRowID] = useState(null)
  const [systemTypeAlreadyExistsError, setSystemTypeAlreadyExistsError] =
    useState(false)
  const handleInputChange = evt => {
    setUserInput(evt.target.value)
    setSystemTypeAlreadyExistsError(false)
  }
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState("")
  const openModal = () => {
    setShowModal(true)
    if (!isUserEditing) {
      setUserInput("")
      setSystemTypeAlreadyExistsError(false)
      setSelectedColor(null)
    }
  }
  const closeModal = () => {
    setShowModal(false)
    setIsUserEditing(false)
    setUserInput("")
    setSelectedColor(null)
    setSystemTypeAlreadyExistsError(false)
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      systemType: userInput,
      color: selectedColor,
    },
    validationSchema: Yup.object({
      systemType: Yup.string().required("Please enter a System Type Name"),
      color: Yup.string().nullable().required("Please select a color"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setError("")
      if (isUserEditing) {
        try {
          const { data } = await editSpecificSystemType(editingRowID, {
            name: values.systemType,
            color: values.color,
          })
          resetForm()
          closeModal()
        } catch (err) {
          setError(err.response.data.message)
          console.error(err)
        }
      } else {
        try {
          const { data } = await createNewSystemType({
            name: values.systemType,
            color: values.color,
          })
          resetForm()
          closeModal()
        } catch (err) {
          setError(err.response.data.message)
          if (err.response.data.statusCode === 409)
            setSystemTypeAlreadyExistsError(true)
          console.error({ err })
        }
      }
      refetch()
    },
  })
  const handleColorChange = evt => {
    setSelectedColor(evt.target.value)
  }
  const initiateEdit = id => {
    setShowModal(true)
    setIsUserEditing(true)
    const editingRow = data.types.find(row => row.id === id)
    setEditingRowID(editingRow.id)
    setUserInput(editingRow.name)
    setSelectedColor(editingRow.color)
  }

  const onDeleteClick = deletionObjectId => {
    patch(`${DELETE_LABEL_TYPE}/${deletionObjectId}`)
      .then(() => {
        refetch()
        alert("System Type deletion", true)
      })
      .catch(err => {
        console.error(err)
        alert("System Type deletion", false)
      })
  }

  const exportTable = async type => {
    try {
      const { data } = await getSystemTypes(
        { searchTerm: debouncedSearchTerm, limit: 0, offset: 0 },
        {
          sortForm: sortMap.get(sortBy),
        }
      )

      type === "pdf"
        ? getSystemTypesPDF(data.types)
        : getSystemTypesCSV(data.types)
    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }
  return (
    <div style={{ marginBlockEnd: "3rem" }}>
      <div className="d-flex justify-content-between">
        <StyledContainer>
          <SearchBox searchChange={handleSearchInputChange} />
          <Select
            options={sortOptions}
            defaultValue={sortBy}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Sort by"
            onChange={option => setSortBy(option.value)}
          />
        </StyledContainer>
        <div className="d-flex align-items-center">
          <Button
            className="common-button"
            onClick={showModal ? closeModal : openModal}
            style={{ marginInline: 0 }}
          >
            Create New
          </Button>
          <ExportTable
            loadFilteredData={exportTable}
            component={"SeperateFn"}
          ></ExportTable>
        </div>
        <Modal
          isOpen={showModal}
          toggle={showModal ? closeModal : openModal}
          style={{ borderRadius: "0.25rem", maxHeight: "500px" }}
        >
          <ModalHeader
            style={{
              alignItems: "flex-start",
              height: "80px",
              paddingBottom: "0px",
            }}
            toggle={showModal ? closeModal : openModal}
          >
            <b>
              <p
                style={{
                  fontSize: "1.5rem",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                {isUserEditing ? (
                  <>
                    {" "}
                    <i className="mdi mdi-pencil me-2"></i>
                    Edit System Type
                  </>
                ) : (
                  <>
                    {" "}
                    <i className="mdi mdi-plus me-2"></i>
                    Create New System Type
                  </>
                )}
              </p>
            </b>
          </ModalHeader>
          <StyledModalBody>
            {error.length > 0 ? (
              <Alert color="danger">
                <p>{error}</p>
              </Alert>
            ) : null}
            <Form
              onSubmit={evt => {
                evt.preventDefault()
                validation.handleSubmit()
              }}
            >
              <div className="mb-3">
                <label htmlFor="name">Name</label>
                <Input
                  id="name"
                  name="systemType"
                  type="text"
                  placeholder="Enter new System type"
                  value={validation.values.systemType}
                  onChange={validation.handleChange}
                  invalid={
                    validation.touched.systemType &&
                    validation.errors.systemType
                      ? true
                      : false
                  }
                />

                {validation.touched.systemType &&
                validation.errors.systemType ? (
                  <FormFeedback type="invalid">
                    {validation.errors.systemType}
                  </FormFeedback>
                ) : null}
                {systemTypeAlreadyExistsError && (
                  <p style={{ color: "red" }}>
                    A system type by this name already exits
                  </p>
                )}
              </div>
              <div className="mb-3">
                <fieldset>
                  <legend>Color:</legend>
                  <div className="options">
                    <label htmlFor="teal" className="sr-only">
                      Teal
                    </label>
                    <input
                      type="radio"
                      id="teal"
                      value="#30D5C8"
                      name="color"
                      onChange={validation.handleChange}
                      checked={validation.values.color === "#30D5C8"}
                    />
                    <label htmlFor="violet" className="sr-only">
                      Violet
                    </label>
                    <input
                      type="radio"
                      id="violet"
                      value="#785EF0"
                      name="color"
                      onChange={validation.handleChange}
                      checked={validation.values.color === "#785EF0"}
                    />
                    <label htmlFor="pink" className="sr-only">
                      Pink
                    </label>
                    <input
                      type="radio"
                      id="pink"
                      value="#DC267F"
                      name="color"
                      onChange={validation.handleChange}
                      checked={validation.values.color === "#DC267F"}
                    />
                    <label htmlFor="orange" className="sr-only">
                      Orange
                    </label>
                    <input
                      type="radio"
                      id="orange"
                      value="#FE6100"
                      name="color"
                      onChange={validation.handleChange}
                      checked={validation.values.color === "#FE6100"}
                    />
                    <label htmlFor="yellow" className="sr-only">
                      Yellow
                    </label>
                    <input
                      type="radio"
                      id="yellow"
                      value="#FFB000"
                      name="color"
                      onChange={validation.handleChange}
                      checked={validation.values.color === "#FFB000"}
                    />
                  </div>
                </fieldset>
                {validation.touched.color && validation.errors.color ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {validation.errors.color}
                  </FormFeedback>
                ) : null}
              </div>
              <ModalFooter
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderTop: "none",
                  paddingTop: "0px",
                }}
              >
                <div>
                  <Button
                    style={{ backgroundColor: "#50A050", border: "none" }}
                    type="submit"
                  >
                    {isUserEditing ? "Save" : "Create"}
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          </StyledModalBody>
        </Modal>
      </div>
      {isLoading && <LoadingSpinner />}
      {data?.types.length > 0 && !isLoading ? (
        <>
          <SystemsTable
            data={data.types}
            caption="System Types"
            initiateEdit={initiateEdit}
            onDeleteClick={onDeleteClick}
          />
          <Pagination
            currentPage={currentPage}
            goToPage={handlePageChange}
            totalEntries={totalEntries}
          />
        </>
      ) : null}
    </div>
  )
}
