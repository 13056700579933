import * as Popover from "@radix-ui/react-popover"
import { formateOnlyDate } from "helpers/utlities"
import React, { useState } from "react"
import { Table, UncontrolledTooltip } from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import styled from "@emotion/styled"

const StyledButtonGroup = styled.div`
  display: flex;
  gap: 1em;
  & button {
    all: unset;
  }
`

const PopoverTrigger = styled(Popover.Trigger)`
  all: unset;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`
const PopoverContent = styled(Popover.Content)`
  background-color: #000;
  color: whitesmoke;
  padding: 0.2em 0.4em;
`

const StyledTable = styled(Table)`
  & tbody {
    & tr .description_cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const SystemsTable = ({
  data,
  initiateEdit,
  caption,
  onDeleteClick,
}) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [deletionObjectId, setDeletionObjectId] = useState()

  const isSystemsData = data[0].hasOwnProperty("label_type")
  return (
    <StyledTable className="custom-table table-striped">
      <colgroup>
        {!isSystemsData && <col style={{ width: "25%" }} />}
        {!isSystemsData && <col style={{ width: "25%" }} />}
        {!isSystemsData && <col style={{ width: "25%" }} />}
        {!isSystemsData && <col style={{ width: "25%" }} />}

        {isSystemsData && <col style={{ width: "15%" }} />}
        {isSystemsData && <col style={{ width: "15%" }} />}
        {isSystemsData && <col style={{ width: "15%" }} />}
        {isSystemsData && <col style={{ width: "15%" }} />}
        {isSystemsData && <col style={{ width: "25%" }} />}
        {isSystemsData && <col style={{ width: "15%" }} />}
        {isSystemsData && <col style={{ width: "15%" }} />}
        {isSystemsData && <col style={{ width: "10%" }} />}
      </colgroup>
      <thead>
        <tr>
          <th>Name</th>
          {isSystemsData && <th>Description</th>}
          {isSystemsData && <th>System Type</th>}
          {isSystemsData && <th>Benchmarking</th>}
          {isSystemsData && <th>Countries</th>}
          <th>Updated On</th>
          <th>Updated By</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map(system => (
          <tr key={system.id || system.label_id}>
            {!isSystemsData && <td>{system.name}</td>}
            {isSystemsData && (
              <td>
                <div
                  className="px-2 color-contrast-tab d-flex align-items-center"
                  style={{
                    "--tabcolor": `${system.label_type?.color}`,
                    "--bgcolor": `${system.label_type?.color}` + "1a",
                  }}
                >
                  {system.label_name}
                </div>
              </td>
            )}
            {isSystemsData && (
              <td className="description_cell">
                <Popover.Root>
                  <PopoverTrigger>{system.label_description}</PopoverTrigger>
                  <Popover.Anchor />
                  <Popover.Portal>
                    <PopoverContent sideOffset={15} side="right">
                      {system.label_description}
                      <Popover.Arrow />
                    </PopoverContent>
                  </Popover.Portal>
                </Popover.Root>
              </td>
            )}
            {isSystemsData && <td>{system?.label_type?.name}</td>}
            {isSystemsData && <td>{system?.form?.type ? "Yes" : "No"}</td>}
            {isSystemsData && (
              <td>
                <ol
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    listStyle: "none",
                    gap: ".5em",
                    padding: 0,
                  }}
                >
                  {system.label_countries.map(country => (
                    <li
                      key={country.id}
                      style={{
                        backgroundColor: "rgb(80,165,241)",
                        color: "white",
                        padding: ".2em .4em",
                        borderRadius: ".8em",
                      }}
                    >
                      {country.label}
                    </li>
                  ))}
                </ol>
              </td>
            )}
            <td>{formateOnlyDate(system.updated_at)}</td>
            <td>{`${system.updated_by?.first_name} ${system.updated_by?.last_name}`}</td>
            <td>
              {!isSystemsData && system?.is_locked ? (
                <>
                  <i
                    className="ph ph-lock"
                    id="locked"
                    style={{ cursor: "pointer" }}
                  />
                  <UncontrolledTooltip placement="top" target="locked">
                    Locked (Can't be edited or deleted)
                  </UncontrolledTooltip>
                </>
              ) : (
                <StyledButtonGroup>
                  <button
                    onClick={() => initiateEdit(system.id ?? system.label_id)}
                  >
                    <i className="mdi mdi-pencil font-size-18 hover-event text-success" />
                  </button>
                  <button
                    onClick={() => {
                      setDeleteModal(true)
                      setDeletionObjectId(system.id ?? system.label_id)
                    }}
                  >
                    <i className="mdi mdi-delete font-size-18 del-icon text-danger" />
                  </button>
                </StyledButtonGroup>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => onDeleteClick(deletionObjectId)}
        item={caption}
      />
    </StyledTable>
  )
}
