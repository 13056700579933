import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import colourStyles from '../LabelColorStyles';
import { useSelector } from "react-redux";
import {post} from "services/AXIOS";
import { LIST_ORGANIZATIONS} from 'services/CONSTANTS';
import { EnumStage, organizationType } from 'constants/global';

function FilterPolicies({labels, handleCountries, handleLabels, handleStages, handleOwners, handlePolicyPartners, selectedCountries, selectedLabels, selectedStages ,applyFilter, selectedOwners, selectedPolicyPartners, stage, component, selectedStatus, handleStatus}) {

    const [owners, setOwners] = useState([]);
    const [partners, setPartners] = useState([]);

    useEffect(()=>{
        post(LIST_ORGANIZATIONS, {
            // countries: null,
            organization_type: organizationType.PARTNER
        })
        .then(response=>{
            const partners = response.data.map((partner) =>{
                return {
                    value: partner.organization_id,
                    label: partner.organization_name
                }
            });
            setPartners(partners); 
        })
        .catch(err=>{
            console.log(err)
            alert("Data loading", false)
        })
        
        post(LIST_ORGANIZATIONS, {
            // countries: null,
            organization_type: organizationType.OWNER
        })
        .then(response=>{
            const owners = response.data.map((owner) => {
                return{
                    value: owner.organization_id,
                    label: owner.organization_name
                }});
            setOwners(owners); 
        })
        .catch(err=>{
            console.log(err)
            alert("Data loading", false)
        })
    }, [])

    const { countries } = useSelector(
        state => state.list
    )

    const countryOptions = countries.map((country)=>{
        return (
            {value: country.id, label: country.label }
        )
    });

    const labelOptions = labels?labels.map(label=>{
        return({
            value: label.label_id, 
            label: label.label_name, 
            color: label.label_color
            }
        )}
    ): null;

    // const noneStage = [{value: '0', label: 'None'}];
    const benchmarkingStage = [{value: EnumStage.VALIDATION, label: 'Validation'}];

    let stageOptions = [
        {value: '1', label: 'Stage 1'},
        {value: '2', label: 'Stage 2'},
        {value: '3', label: 'Stage 3'},
        {value: '4', label: 'Stage 4'},
        {value: '5', label: 'Stage 5'},
        {value: '6', label: 'Stage 6'},
    ]

    // if(!stage){
    //     stageOptions = noneStage.concat(stageOptions);
    // }

    if(!stage){
        stageOptions = stageOptions.concat(benchmarkingStage);
    }

    const statusOptions = [
        {value: '1', label: 'NEW'},
        {value: '2', label: 'PENDING'},
        {value: '3', label: 'IN PROGRESS'},
        {value: '4', label: 'WAITING FOR APPROVAL'},
        {value: '5', label: 'COMPLETE'},
        {value: '6', label: 'OVERRIDE'},
        {value: '7', label: 'PAST DEADLINE'},
        {value: '8', label: 'ARCHIVED'},
    ]

    return (
        <div>
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'>
                    <i className='bx bx-purchase-tag light-icon me-1'/>
                    <h6 className='mb-0'>Systems</h6>
                </div>
                <div className='ms-2 w-100'>
                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={labelOptions}
                        defaultValue={selectedLabels}
                        styles={colourStyles}
                        placeholder="Select Multiple" 
                        onChange={handleLabels}
                    />
                </div>
            </div>
            {(!stage || stage.id === '0') && (component != "landscape") &&
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='mdi mdi-buffer light-icon me-1'/> 
                    <h6 className='mb-0'>Stage</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        options={stageOptions}
                        defaultValue={selectedStages}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleStages}
                    />
                </div>
            </div>
            }
            {(component != "landscape") &&
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='bx bx-world light-icon me-1'/> 
                    <h6 className='mb-0'>Location</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        defaultValue={selectedCountries}
                        options={countryOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleCountries}
                    />
                </div>
            </div>
            }
            {(component != "landscape") &&
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='mdi mdi-account-outline light-icon me-1'/> 
                    <h6 className='mb-0'>Owner</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        defaultValue={selectedOwners} 
                        options={owners}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleOwners} 
                    />
                </div>
            </div>
            }
            {(component != "landscape") &&
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='mdi mdi-account-multiple-outline light-icon me-1'/> 
                    <h6 className='mb-0'>Partners</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        defaultValue={selectedPolicyPartners} 
                        options={partners}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handlePolicyPartners}
                    />
                </div>
            </div>
            }
            <div className='d-flex my-2'>
                <div className='d-flex align-items-center'> 
                    <i className='mdi mdi-account-multiple-outline light-icon me-1'/> 
                    <h6 className='mb-0'>Status</h6>
                </div>
                <div className='ms-2' style={{width: "100%"}}>
                    <Select
                        isMulti
                        defaultValue={selectedStatus} 
                        options={statusOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Multiple"
                        onChange={handleStatus}
                    />
                </div>
            </div>
            <div className='d-flex' style={{justifyContent: "center"}}>
                <div 
                    className="btn btn-success common-button" 
                    style={{width: "90%"}}
                    onClick={applyFilter}
                >
                    Apply
                </div>
            </div>
        </div>
    );
}

export default FilterPolicies;